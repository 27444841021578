import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  Gender,
  PeopleStatus,
  TotalfitDirection,
  TotalfitExamLang,
  TotalfitExperience,
  TShirtSize
} from '@app/people/_shared/people.model';
import * as countriesJson from '../../../assets/data/countries.json';

@Injectable({ providedIn: 'root' })
export class SharedFormService {
  public userStatus: Array<{ name: string, id: string }>;
  public userGender: Array<{ name: string, id: string }>;
  public tShirtSize: Array<{ name: string, id: string }>;
  public totalfitExperience: Array<{ name: string, id: string }>;
  public totalfitDirection: Array<{ name: string, id: string }>;
  public totalfitExamLang: Array<{ name: string, id: string }>;

  constructor(private fb: UntypedFormBuilder) {
    this.userStatus = Object.keys(PeopleStatus).map((key) => {
      return { name: key, id: PeopleStatus[key] };
    });
    this.userGender = Object.keys(Gender).map((key) => {
      return { name: key, id: Gender[key] };
    });
    this.totalfitDirection = Object.keys(TotalfitDirection).map((key) => {
      return { name: key, id: TotalfitDirection[key] };
    });
    this.tShirtSize = Object.keys(TShirtSize).map((key) => {
      return { name: key, id: TShirtSize[key] };
    });
    this.totalfitExperience = Object.keys(TotalfitExperience).map((key) => {
      return { name: key, id: TotalfitExperience[key] };
    });
    this.totalfitExamLang = Object.keys(TotalfitExamLang).map((key) => {
      return { name: key, id: TotalfitExamLang[key] };
    });
  }

  public getNewFromControl(): UntypedFormControl {
    return this.fb.control('');
  }

  public getFormArray(arrayName: string, form: AbstractControl): UntypedFormArray {
    return form.get(arrayName) as UntypedFormArray;
  }

  public addNewFormControlToFormArray(arrayName: string, form: AbstractControl): void {
    const control = form.get(arrayName) as UntypedFormArray;

    control.push(this.getNewFromControl());
  }

  public addNewFormGroupToFormArray(arrayName: string, form: AbstractControl, formToAdd: UntypedFormGroup): void {
    const control = form.get(arrayName) as UntypedFormArray;

    control.push(formToAdd);
  }

  public removeFormControl(arrayName: string, i: number, form: AbstractControl): void {
    const control = form.get(arrayName) as UntypedFormArray;

    control.removeAt(i);
  }

  public get countries() {
    return countriesJson.default;
  }
}
