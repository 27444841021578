export type TwelveHourType = 'AM' | 'PM';

export interface TwelveHourFormat {
  hours: string;
  minutes: string;
  suffix: TwelveHourType;
}

export function TwelveHourFormat(time): TwelveHourFormat {
  // @ts-ignore
  let [hours, minutes, suffix] = time.split(':');
  let militaryHours = parseInt(hours);
  let twelveHourHours = (militaryHours % 12 || 12).toString();
  let twelveHourSuffix = militaryHours < 12 ? 'AM' : 'PM';

  return {
    hours: twelveHourHours.padStart(2, '0'),
    minutes: minutes,
    suffix: twelveHourSuffix as TwelveHourType
  };
}
