<div class="container">
  <div class="d-flex-between-center" *ngFor="let entity of entities" (click)="selectEntity($event, entity)">
    <button mat-button class="text-transform-none container__filter-action">
      {{entity}}
    </button>
    <div *ngIf="entity === changedFilter.value || changedFilter.values?.includes(entity)" class="container__dot"></div>
  </div>
  <div class="d-flex">
    <button mat-button class="text-transform-none container__clear-action" (click)="selectEntity($event, '', true)">
      Clear all
    </button>
    <button mat-raised-button color="primary" (click)="updateEntity()">
      Apply
    </button>
  </div>
</div>
