<div class="totalfit-form">
  <form [formGroup]="workoutFormService.workoutForm"
      class="mat-typography">

  <ng-container *ngIf="!isProgramTemplate">
    <h2>Workout</h2>
<!--    <totalfit-select *ngIf="!this.workoutFormService.workoutForm.value.bundleId && showSelectWod"-->
<!--                     (selectEvent)="initForm($event)"-->
<!--                     [hasFilter]="true"-->
<!--                     [formControl]="workoutFormService.workoutForm.get('id')"-->
<!--                     selectorPlaceholder="+ Add new"-->
<!--                     nameKey="theme"-->
<!--                     [options]="workoutList">-->
<!--    </totalfit-select>-->

    <totalfit-workout-search-dropdown (selectWod)="initForm($event)"></totalfit-workout-search-dropdown>

    <div [totalfitFormGroupValidator]="workoutFormService.workoutForm.get('theme')"
         class="theme-container form-group form-group_full-width">
      <input formControlName="theme"
             placeholder="Workout Name"
             class="form-group__control">
    </div>

    <div [totalfitFormGroupValidator]="workoutFormService.customWorkoutForm.get('wod')" class="form-group wod-container form-group_full-width">
              <textarea [formControl]="workoutFormService.customWorkoutForm.get('wod')"
                        class="form-group__text-control mat-typography"
                        placeholder="Type something "
                        rows="6">
              </textarea>
    </div>

    <ul>
      <li class="folder tabs workout-select__item">
        <div class="tabs__item" *ngFor="let exerciseTab of exerciseTabs$ | async">
          {{ exerciseTab.name }}
        </div>
      </li>
    </ul>
  </ng-container>

  <h2>Coaching notes </h2>

  <div class="form-group form-group_full-width">
      <textarea [formControl]="workoutFormService.workoutForm.get('coachNotes')"
                class="form-group__text-control mat-typography"
                rows="6">
      </textarea>
  </div>

  <div class='toggle-title'>
    <mat-slide-toggle [checked]="workoutFormService.workoutSelectValues.skill" (change)="toggleChange($event, 'skill')" color="primary" class="custom-toggle"><h2>Skill</h2></mat-slide-toggle>
  </div>

  <div *ngIf="workoutFormService.workoutSelectValues.skill"
       class="form-group form-group_full-width">
    <div class="form-group form-group_full-width">
              <textarea [formControl]="workoutFormService.customWorkoutForm.get('skill')"
                        class="form-group__text-control mat-typography"
                        rows="4">
              </textarea>
    </div>
  </div>

  <div class='toggle-title'>
    <mat-slide-toggle [checked]="workoutFormService.workoutSelectValues.warmup" (change)="toggleChange($event, 'warmup')" color="primary" class="custom-toggle"><h2>Warm-up</h2></mat-slide-toggle>
  </div>

  <ng-container *ngIf="workoutFormService.workoutSelectValues.warmup">
    <totalfit-autofill label="Recommended Warm-up list" [options]="recommendedWarmUp" (autofill)="updateAutofill($event, workoutFormService.customWorkoutForm.get('warmup'))">
    </totalfit-autofill>

    <div class="form-group form-group_full-width">
              <textarea [formControl]="workoutFormService.customWorkoutForm.get('warmup')"
                        class="form-group__text-control mat-typography"
                        rows="4">
              </textarea>
    </div>
  </ng-container>


  <div class='toggle-title'>
    <mat-slide-toggle [checked]="workoutFormService.workoutSelectValues.cooldown" (change)="toggleChange($event, 'cooldown')" color="primary" class="custom-toggle"><h2>Cooldown</h2></mat-slide-toggle>
  </div>

  <ng-container *ngIf="workoutFormService.workoutSelectValues.cooldown">
    <totalfit-autofill label="Recommended Cooldown list" [options]="recommendedCooldown" (autofill)="updateAutofill($event, workoutFormService.customWorkoutForm.get('cooldown'))">
    </totalfit-autofill>

    <div class="form-group form-group_full-width">
              <textarea [formControl]="workoutFormService.customWorkoutForm.get('cooldown')"
                        class="form-group__text-control mat-typography"
                        rows="4">
              </textarea>
    </div>

  </ng-container>

  <div class='toggle-title'>
    <mat-slide-toggle [checked]="workoutFormService.workoutSelectValues.story" (change)="toggleChange($event, 'story')" color="primary" class="custom-toggle"><h2>Story</h2></mat-slide-toggle>
    <totalfit-select *ngIf="workoutFormService.workoutSelectValues.story"
                     (selectEvent)="workoutFormService.setStory($event)"
                     [hasSearch]="true"
                     [allItemsLength]="workoutStoryService.storyList.length"
                     [totalPagination]="+workoutStoryService.storyTotalSize"
                     [isDisabled]="!workoutFormService.workoutSelectValues.story"
                     [formControl]="workoutFormService.workoutForm.get('story.id')"
                     [isLoading]="isStoryLoading$ | async"
                     [selectorPlaceholder]="workoutFormService.workoutForm.get('story.name').value || '+ Add new'"
                     (loadPagination)="loadStories()"
                     (search)="searchStories($event)"
                     (searchType)="storySearchType.next($event)"
                     [options]="storyList$ | async">
    </totalfit-select>
  </div>

  <ng-container formGroupName="story">
    <ng-container *ngIf="workoutFormService.workoutSelectValues.story">

      <div [totalfitFormGroupValidator]="workoutFormService.workoutForm.get('story.name')"
           class="theme-container form-group form-group_full-width">
        <input formControlName="name"
               placeholder="Story Name"
               class="form-group__control">
      </div>

      <div [totalfitFormGroupValidator]="workoutFormService.workoutForm.get('story.story')" class="form-group wod-container form-group_full-width">
              <textarea formControlName="story"
                        class="form-group__text-control mat-typography"
                        placeholder="Type something"
                        rows="4">
              </textarea>
      </div>
    </ng-container>
  </ng-container>


  <ng-container *ngIf="!isProgramTemplate">
    <div class='toggle-title'>
      <mat-slide-toggle [checked]="workoutFormService.workoutSelectValues.hlh" (change)="toggleChange($event, 'hlh')" color="primary" class="custom-toggle"><h2>Healthy Habit</h2></mat-slide-toggle>
      <totalfit-select *ngIf="workoutFormService.workoutSelectValues.hlh"
                       (selectEvent)="workoutFormService.setHabits($event)"
                       [hasFilter]="true"
                       [isDisabled]="!workoutFormService.workoutSelectValues.hlh"
                       selectorPlaceholder="Select template"
                       [options]="workoutService.habitList">
      </totalfit-select>
    </div>

    <div *ngIf="workoutFormService.workoutSelectValues.hlh" [totalfitFormGroupValidator]="workoutFormService.workoutForm.get('healthyLifestyleHabit.id')"
         class="form-group form-group_full-width">
      <div class="form-group form-group_full-width">
                <textarea [formControl]="workoutFormService.customWorkoutForm.get('hlh')"
                          class="form-group__text-control mat-typography"
                          rows="4">
                </textarea>
      </div>

    </div>
  </ng-container>

  <!-- COMPLEX LIST -->
</form>
</div>
