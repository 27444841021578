import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Autofill } from '@shared/models/workout.model';
import { WorkoutService } from '@app/workout/_shared/workout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-autofill',
  templateUrl: './autofill.component.html',
  styleUrls: ['./autofill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutofillComponent {
  @Input() public label: string;
  @Input() public options: Array<{ name: string, reqName?: string }>;
  @Output() public autofill: EventEmitter<Autofill> = new EventEmitter<Autofill>();
  public selectedAutofillName: string;

  constructor(private workoutService: WorkoutService) {}

  public getExercises(autofillName: string): void {
    this.workoutService.getAutofill(autofillName)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.autofill.emit(data);
        this.selectedAutofillName = autofillName;
      });
  }
}
