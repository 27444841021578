import { createAction, props } from '@ngrx/store';
import { LibraryItem } from '@app/library/_shared/library.model';

export const STREAM_START_PLAY = '[Library] Stream start play';
export const STREAM_STOP_PLAY = '[Library] Stream stop play';
export const SET_NEW_STREAM = '[Library] Stream set new stream';
export const SET_NEW_STREAM_NAME = '[Library] Stream set new stream name';
export const CLOSE_STREAM = '[Library] Close stream';
export const ADD_NEW_FILE_TO_LIBRARY = '[Library] Add new file to library';
export const DELETE_FILE_FROM_LIBRARY = '[Library] Delete file from library';
export const ADD_NEW_FOLDER_TO_LIBRARY = '[Library] Add new folder to library';
export const ADD_NEW_FILE_SUCCESS = '[Library] Add new file success';
export const ADD_NEW_FILE_FAILED = '[Library] Add new file failed';
export const REMOVE_LAST_FILE = '[Library] Remove last file';

export const startPlay = createAction(
  STREAM_START_PLAY
);

export const stopPlay = createAction(
  STREAM_STOP_PLAY
);

export const streamClose = createAction(
  CLOSE_STREAM
);

export const streamSet = createAction(
  SET_NEW_STREAM,
  props<{ stream: string }>()
);

export const streamSetName = createAction(
  SET_NEW_STREAM_NAME,
  props<{ name: string }>()
);

export const addNewFileToLibrary = createAction(
  ADD_NEW_FILE_TO_LIBRARY,
  props<{ file: any; path: string; libraryFile: LibraryItem }>()
);

export const deleteFileFromLibrary = createAction(
  DELETE_FILE_FROM_LIBRARY,
  props<{ path: string }>()
);

export const addNewFolderToLibrary = createAction(
  ADD_NEW_FOLDER_TO_LIBRARY,
  props<{ path: string; fileName: any; }>()
);

export const addFileSuccess = createAction(
  ADD_NEW_FILE_SUCCESS,
  props<{ file: LibraryItem }>()
);

export const addFileFailed = createAction(
  ADD_NEW_FILE_FAILED,
  props<{ file: LibraryItem }>()
);

export const removeLastFile = createAction(
  REMOVE_LAST_FILE
);
