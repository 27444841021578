<div class="dialog mat-typography">
  <button mat-button class="dialog__close icon-button" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="dialog__header">
    <h3> Publish </h3>
  </div>
  <div class="dialog__body">
    The time for checking the Admin is from 1 to 7 days.
  </div>
  <div class="dialog__footer">
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="true">
      Ok
    </button>
  </div>
</div>
