import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter, ViewChild, OnInit
} from '@angular/core';

import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';
import { FilterCategory, FilterConfig, FilterItem } from '@shared/models/filter.model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// Need to fix fieldName and filterKey.
// Cause it makes some unusable changes. Can create label for save and store it in the store remove filterKey
@UntilDestroy()
@Component({
  selector: 'totalfit-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDropdownComponent implements OnInit {
  // @ts-ignore
  public configValue: FilterConfig<unknown>;
  private filterKey: string;
  private firstInit = true;

  @Input() public set config(value: FilterConfig<unknown>) {
    value.categories.forEach((category) => {
      if (category.filters) {
        const selectedValues = category.filters.reduce((items: any, filterItem: FilterItem) => {
          if (filterItem.isSelected) {
            items.push(filterItem.filterValue);
          }

          return items;
        }, []);

        if (selectedValues.length) {
          this.filterObject[category.filterKey] = selectedValues;
        }
      }
    });
    this.configValue = value;
    this.filterKey = value.categories[0].filterKey;
    // this.clearFilter();
  }
  @Input() public iconClass = 'icon-filter_list';
  @Input() public isPanelAlignedToRight = false;
  @Input() public emitWithFilterKey = false;
  @Input() public objectStateObservable: Observable<any>;
  @Input() public stateUpdater: any;
  @Input() public stateKiller: any;
  @Input() public tableFilterKey: string;
  @Input() public filterType: string;
  @Output() public filteredEntity = new EventEmitter();
  @Output() public apply = new EventEmitter();
  @ViewChild(DropdownToggleDirective, { static: false }) public dropdownToggle: DropdownToggleDirective;

  public filterObject: {} = {};

  constructor(
    public changeDetector: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    if (this.objectStateObservable) {
      this.objectStateObservable
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          if (!data) {
            if (!this.firstInit) {
              this.clearFilter(true);
            }

            this.firstInit = false;
          }

          if (data && data.tableSearch) {
            let hasCoincidences = false;
            this.configValue.categories.forEach((category) => {
              if (category.filterKey === data.tableSearch.fieldName) {
                hasCoincidences = true;
              }
            });

            if(!hasCoincidences && !this.configValue.categories.some((i) => i.filterType !== 'search')) {
              this.clearFilter(true);
            }
          }
        });
    }
  }

  public filterEntity(): void {
    this.dropdownToggle.hideDropdown();

    if (this.stateUpdater) {
      this.apply.emit(true);

      return;
    }

    if (this.emitWithFilterKey) {
      this.filteredEntity.emit({
        filterObject: this.filterObject,
        filterKey: this.filterKey
      });
    } else {
      this.filteredEntity.emit(this.filterObject);
    }
  }

  public toggleFilterKey(filterCategory: FilterCategory<any>, filterItem: FilterItem, multiple): void {
    if (this.filterObject[filterCategory.filterKey]) {
      const newFilterObject = { ...this.filterObject };

      if (!multiple) {
        filterCategory.filters.forEach((filtersElement) => {
          if (filtersElement.filterValue !== filterItem.filterValue) {
            filtersElement.isSelected = false;
          }
        });
      }

      if (filterItem.isSelected) {
        // Clear other items to.
        newFilterObject[filterCategory.filterKey] = newFilterObject[filterCategory.filterKey].filter((i) => i !== filterItem.filterValue);
        filterItem.isSelected = false;
      } else {
        newFilterObject[filterCategory.filterKey] = multiple
          ? [...newFilterObject[filterCategory.filterKey], filterItem.filterValue]
          : [filterItem.filterValue];

        filterItem.isSelected = true;
      }

      // if (multiple) {
      //   if (filterItem.isSelected) {
      //     newFilterObject[filterCategory.filterKey]
      //     = newFilterObject[filterCategory.filterKey].filter((i) => i !== filterItem.filterValue);
      //     filterItem.isSelected = false;
      //   } else {
      //     newFilterObject[filterCategory.filterKey] = [...newFilterObject[filterCategory.filterKey], filterItem.filterValue];
      //     filterItem.isSelected = true;
      //   }
      // }
      // else {
      //   filterCategory.filters.forEach((filtersElement) => filtersElement.isSelected = false);
      //   filterItem.isSelected = true;
      //   newFilterObject[filterCategory.filterKey] = [filterItem.filterValue];
      // }

      this.filterObject = newFilterObject;
    } else {
      if (this.configValue.oneGroupActive) {
        this.filterObject = {};
        this.configValue.categories = this.configValue.categories.map((cat) => {
          return {
            ...cat,
            filters: [ ...cat.filters.map((filter) => ({ ...filter, isSelected: false }))]
          };
        });
      }

      this.filterObject[filterCategory.filterKey] = [filterItem.filterValue];

      this.configValue.categories
        .find((cat) => cat.filterKey === filterCategory.filterKey).filters
        .find((f) => f.filterValue === filterItem.filterValue).isSelected = true;

      this.changeDetector.markForCheck();
    }

    if (this.stateUpdater) {
      this.store.dispatch(this.stateUpdater({ filter: this.filterObject, propsName: this.tableFilterKey }));
    }

    this.changeDetector.markForCheck();
  }

  public clearFilter(isInitObs?): void {
    const categoriesKeys = [];

    this.configValue.categories
      .forEach((categoryFilterKey) => {
        categoriesKeys.push(categoryFilterKey.filterKey);

        if (categoryFilterKey.filters) {
          categoryFilterKey.filters.forEach((item) => item.isSelected = false);
        }

        if (categoryFilterKey.filterController) {
          categoryFilterKey.filterController.reset();
        }
      });

    this.filterObject = {};

    if (this.emitWithFilterKey) {
      this.filteredEntity.emit({
        filterObject: this.filterObject,
        filterKey: this.filterKey
      });
    } else {
      this.filteredEntity.emit(this.filterObject);
    }

    if (this.stateKiller && !isInitObs) {
      this.store.dispatch(this.stateKiller({ propsName: this.tableFilterKey, objectKeys: categoriesKeys }));

      if (this.stateUpdater) {
        this.apply.emit(true);
      }
    }
  }

  public get hasFilterValue(): boolean {
    let isSelected = false;
    this.configValue.categories.forEach((category) => {
      if (category.filterType !== 'search' && !isSelected) {
        isSelected = category.filters.some((cat) => cat.isSelected);
      }

      if (category.filterType === 'search') {
        const controller = category.filterController.get([category.filterKey]);
        isSelected = controller.valid && controller.value;
      }

      if (isSelected) {
        return;
      }
    });

    return isSelected;
  }

  public registerSearchChange(category: FilterCategory<any>): void {
    this.store.dispatch(this.stateUpdater({
      propsName: this.tableFilterKey,
      tableSearch: {
        fieldName: category.filterKey,
        searchKey: category.filterController.value[category.filterKey]
      }
    }));
  }
}
