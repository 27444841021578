<div totalfitDragDrop
     dragoverClass="photo-drop_dragover"
     [class.photo-drop_empty-model]="!model"
     [class.photo-circle]="template === 'circle'"
     [class.is-drop-disable]="isDropDisable"
     [class.photo-has-model]="model"
     (fileDropped)="onFileChange($event)"
     class="photo-drop">
  <input (change)="onFileChange($event.target)"
         type="file"
         class="photo-drop__controller"
         multiple
         [disabled]="progress"
         [accept]="acceptType"
         #dropInput>
  <div *ngIf="!model && !progress && (template === 'square' || emptyText)" class="photo-drop__empty-model mat-body-2">
    <span [innerHTML]="emptyText ?? 'Drag photos here or'"></span>
    <button (click)="dropInput.click()" class="browse mat-body-2">browse</button>
  </div>

  <mat-spinner *ngIf="progress && !fileSrc" diameter="30"></mat-spinner>

  <ng-container *ngIf="template === 'square'">
    <img *ngIf="model && fileSrc" [src]="fileSrc">
  </ng-container>

  <ng-container *ngIf="template === 'circle'">
    <div (click)="dropInput.click()"
         class="photo-drop__photo">
      <img *ngIf="fileSrc"
           [class.placeholder]="placeholderImage === fileSrc"
           [src]="fileSrc">
    </div>
  </ng-container>

  <button *ngIf="showClose && model && fileSrc"
          mat-raised-button
          (click)="clearModel()"
          class="photo-drop__clear">
    <mat-icon>close</mat-icon>
  </button>
</div>
