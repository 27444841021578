<div class="training-header-container">
  <div class="training">
    <div class="training__image-container">
      <img [src]="training.photo ? training.photo : 'assets/img/gym/gym-placeholder-2.svg'">
    </div>
    <div class="training__container">
      <div class="training__instructors">
        <div class="training__instructor-images">
          <img *ngFor="let img of imageContainer"
               [src]="img ? img : 'assets/img/user/black.svg'"
               [ngClass]="{ empty: !img }"
          >
        </div>
        <div class="text-ellipsis" style="cursor: default">
          <span>{{ training.leadInstructor.name }}</span>
          <span [ngClass]="{ last: !training.instructors.length }" class="coma">, </span>
          <span *ngFor="let instructor of training.instructors; let i = index">
          {{ instructor.name }}<span [ngClass]="{ last: i === training.instructors.length - 1 }" class="coma">, </span>
        </span>
        </div>
      </div>


      <div class="training__content">
        <div class="training__info">
          <div class="training__type" [ngClass]="trainingUtilsService.getTrainingDirectionClass(training)">
            {{ trainingUtilsService.getTrainingDirection(training) }}
          </div>

          <div class="training__title">
            {{ trainingUtilsService.getTrainingTitle(training) }}
          </div>
          <div class="training__title">
            {{ trainingUtilsService.getLevel(training) }}
          </div>
          <div class="training__title">
            {{ trainingUtilsService.getTrainingDay(training) }}
          </div>

          <div class="training__subtitle">
            {{ training.address.address }}
          </div>
        </div>


        <div class="training__price">
          ${{ training.cost }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="training.hostPartner && (training.hostPartner.email || training.hostPartner.name || training.hostPartner.phone)" class="host-partner">
    <h2 class="host-partner__title">
      Host Partner
    </h2>

    <div *ngIf="training.hostPartner.name" class="host-partner__info">
      <h6>Name:</h6>
      <p>{{ training.hostPartner.name }}</p>
    </div>

    <div *ngIf="training.hostPartner.email" class="host-partner__info">
      <h6>Email:</h6>
      <p>{{ training.hostPartner.email }}</p>
    </div>

    <div *ngIf="training.hostPartner.phone" class="host-partner__info">
      <h6>Number:</h6>
      <p>{{ training.hostPartner.phone }}</p>
    </div>
  </div>
</div>
