import { WorkoutView } from '@shared/models/workout.model';
import { Observable, of } from 'rxjs';
import { PaginationModel } from '@shared/models/pagination.model';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BudgeOS, OfflineWorkoutState, SearchWorkout } from '@store/offline-workout/offline-workout.model';
import { Store } from '@ngrx/store';
import { resetOfflineWorkout } from '@store/offline-workout/offline-workout.actions';

@Injectable({
  providedIn: 'root'
})
export class OfflineWorkoutService {
  public offlineWorkoutList: WorkoutView[] = [];
  public offlineWorkoutTotalSize: string;
  public paginationValue = {
    pageSize: 20,
    pageNumber: 0
  };
  public lastSelectedOfflineWorkoutPagination = new Set();
  public searchStorage: Map<string, BudgeOS[]> = new Map();

  constructor(
    private http: HttpClient,
    private store$: Store<OfflineWorkoutState>
  ) {}

  public getOfflineWorkouts(
    pageSize = this.paginationValue.pageSize,
    pageNumber = this.paginationValue.pageNumber,
  ): Observable<PaginationModel<WorkoutView>> {
    return this.lastSelectedOfflineWorkoutPagination
      .has(`${pageSize}, ${pageNumber}`) || +this.offlineWorkoutTotalSize === this.offlineWorkoutList.length
      ?
      of({ objects: this.offlineWorkoutList, pagesCount: pageNumber } as PaginationModel<WorkoutView>)
      : this.http
        .get<PaginationModel<WorkoutView>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts?size=${pageSize}&page=${pageNumber}&favorite=${false}`)
        .pipe(
          map((res) => {
            this.offlineWorkoutTotalSize = res.totalElements;
            this.offlineWorkoutList = [...this.offlineWorkoutList, ...res.objects];
            this.lastSelectedOfflineWorkoutPagination.add(`${pageSize}, ${pageNumber}`);

            if (this.paginationValue.pageNumber < res.pagesCount) {
              this.paginationValue.pageNumber++;
            }

            return { objects: this.offlineWorkoutList, pagesCount: res.pagesCount, totalElements: res.totalElements } ;
          })
        );
  }

  public setOfflineWorkoutList(
    offlineWorkoutList: PaginationModel<WorkoutView>,
    pageNumber,
    pageSize
  ): Observable<PaginationModel<WorkoutView>> {
    this.offlineWorkoutTotalSize = offlineWorkoutList.totalElements;
    this.offlineWorkoutList = offlineWorkoutList.objects;
    this.paginationValue.pageNumber = pageNumber + 1;
    this.paginationValue.pageSize = pageSize;
    this.lastSelectedOfflineWorkoutPagination.add(`${pageSize}, ${pageNumber}`);

    return of({
      objects: this.offlineWorkoutList,
      pagesCount: offlineWorkoutList.pagesCount,
      totalElements: offlineWorkoutList.totalElements
    }) ;
  }

  public searchOfflineWorkouts(searchReq: SearchWorkout): Observable<BudgeOS[]> {
    const key = JSON.stringify(searchReq);
    return this.searchStorage.has(key)
      ?
      of(this.searchStorage.get(key))
      : this.http
        .post<BudgeOS[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/search/workouts`, searchReq)
        .pipe(
          map((res) => {
            this.searchStorage.set(key, res);
            return res;
          })
        );
  }

  public dropCache(): void {
    this.offlineWorkoutList = [];
    this.offlineWorkoutTotalSize = null;
    this.paginationValue = {
      pageSize: 20,
      pageNumber: 0
    };
    this.lastSelectedOfflineWorkoutPagination.clear();
    this.searchStorage.clear();
    this.store$.dispatch(resetOfflineWorkout());
  }
}
