import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOfflineWorkoutActions from '@app/store/offline-workout/offline-workout.actions';
import { map, switchMap } from 'rxjs/operators';
import { OfflineWorkoutService } from '@shared/services/offline-workout.service';

@Injectable()
export class OfflineWorkoutEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly offlineWorkoutService: OfflineWorkoutService
  ) {}

  public offlineWorkoutList = createEffect(() => this.actions$.pipe(
    ofType(fromOfflineWorkoutActions.getOfflineWorkoutList),
    switchMap(({ pageSize, pageNumber }) => {
      return this.offlineWorkoutService.getOfflineWorkouts(pageSize, pageNumber)
        .pipe(
          map((offlineWorkoutList) => {
            return fromOfflineWorkoutActions.getOfflineWorkoutListSuccess({ offlineWorkoutList });
          })
        );
    })
  ));

  public searchOfflineWorkoutList = createEffect(() => this.actions$.pipe(
    ofType(fromOfflineWorkoutActions.searchOfflineWorkoutList),
    switchMap(({ searchReq }) => {
      return this.offlineWorkoutService.searchOfflineWorkouts(searchReq)
        .pipe(
          map((budgeOS) => {
            return fromOfflineWorkoutActions.searchOfflineWorkoutListSuccess({ budgeOS });
          })
        );
    })
  ));

  public setOfflineWorkoutList = createEffect(() => this.actions$.pipe(
    ofType(fromOfflineWorkoutActions.setOfflineWorkoutList),
    switchMap(({ offlineWorkoutList, pageNumber, pageSize }) => {
      return this.offlineWorkoutService.setOfflineWorkoutList(offlineWorkoutList, pageNumber, pageSize);
    })
  ), { dispatch: false });

  // public searchStoryList = createEffect(() => this.actions$.pipe(
  //   ofType(fromStoryActions.searchStoryList),
  //   switchMap(({ name }) => {
  //     return this.workoutStoryService.searchStories(name)
  //       .pipe(
  //         map((searchedStories) => {
  //           return fromStoryActions.searchStoryListSuccess({ searchedStories });
  //         })
  //       );
  //   })
  // ));
}
