import { createAction, props } from '@ngrx/store';
import { ExerciseOSLimit } from '@store/exercise/exercise.model';

export const GET_EXERCISE_TAGS = '[EXERCISE] GET EXERCISE TAGS';

export const RESET_EXERCISE_TAGS = '[EXERCISE] RESET EXERCISE TAGS';
export const GET_EXERCISE_TAGS_SUCCESS = '[LOCATION] GET EXERCISE TAGS SUCCESS';

export const getExerciseTags = createAction(
  GET_EXERCISE_TAGS,
  props<{ limit: number, text: string }>()
);

export const getExerciseTagsSuccess = createAction(
  GET_EXERCISE_TAGS_SUCCESS,
  props<{ tabs: ExerciseOSLimit[] }>()
);

export const resetExerciseTags = createAction(
  RESET_EXERCISE_TAGS,
);
