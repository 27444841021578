import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { fromEvent, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FormControlComponent, getValueAccessor } from '@shared/models/form-control.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getValueAccessor(SearchInputComponent)],
})
export class SearchInputComponent extends FormControlComponent<string> implements OnInit {
  @Input() public border: 'all' | 'bottom' = 'all';
  @Input() public placeholder = 'Search...';
  @Input() public autocompleteItemsFn: (query: string) => Observable<unknown[] | null>;
  @Input() public autocompleteItemDisabledFn: (item: unknown) => boolean;
  @Input() public autocompleteItemTemplate: TemplateRef<unknown>;
  @Input() public autocompleteValueProperty: string;
  @Input() public isAutoSelected = true;
  @Input() public inputClass: string;
  @Input() public showSearchIcon = false;
  @Input() public initialList: unknown[];
  @Output() public autocompleteOptionSelected = new EventEmitter<any>();
  @Output() public searchFill = new EventEmitter<string>();
  @ViewChild('searchInput', { static: false, read: MatAutocompleteTrigger }) private triggerAutocompleteInput: MatAutocompleteTrigger;
  public autocompleteItems: unknown[] | null;
  public searchControl: UntypedFormControl;

  constructor(
    private element: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    fromEvent(document, 'wheel')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.triggerAutocompleteInput.closePanel();
        this.changeDetector.markForCheck();
      });
    this.searchControl = new UntypedFormControl(this.model);

    if (this.initialList) {
      this.autocompleteItems = this.initialList;
    }

    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      tap((value = '') => {
        if (value) {
          super.updateModel(value.trim());
        }
      }),
      switchMap((() => this.model && this.autocompleteItemsFn ? this.autocompleteItemsFn(this.model) : of(this.initialList || null))),
    ).subscribe((value) => this.autocompleteItems = value);
  }

  public clearSearchInput(): void {
    this.updateModel('');

    if (this.element.nativeElement) {
      this.element.nativeElement.focus();
    }
  }

  public updateModel(value: string) {
    super.updateModel(value);
    this.searchControl.setValue(value);
  }

  public writeValue(value: string) {
    super.writeValue(value);
    this.searchControl.setValue(value);
  }
}
