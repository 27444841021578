import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromBundleActions from '@store/bundle/bundle.actions';
import { BundleService } from '@shared/services/bundle.service';
import { map, switchMap } from 'rxjs/operators';
import { BundleTypes } from '@store/workout/workout.model';
@Injectable()
export class BundleEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly bundleService: BundleService
  ) {}

  public offlineWorkoutList = createEffect(() => this.actions$.pipe(
    ofType(fromBundleActions.getUserBundleWorkouts),
    switchMap(({ folder }) => {
      return this.bundleService.getBundleOfflineWorkouts(String(folder.id))
        .pipe(
          map((templateList) => {
            return fromBundleActions.getUserBundleWorkoutsSuccess({ templateList, folder: { ...folder, type: BundleTypes.USER_BUNDLE } });
          })
        );
    })
  ));

  public getTfBundleWorkouts = createEffect(() => this.actions$.pipe(
    ofType(fromBundleActions.getTfBundleWorkouts),
    switchMap(({ folder }) => {
      return this.bundleService.getTfBundleOfflineWorkouts(String(folder.id))
        .pipe(
          map((templateList) => {
            return fromBundleActions.getTfBundleWorkoutsSuccess({ templateList, folder: { ...folder, type: BundleTypes.TS_BUNDLE } });
          })
        );
    })
  ));

  public getRecentWorkouts = createEffect(() => this.actions$.pipe(
    ofType(fromBundleActions.getRecentWorkouts),
    switchMap(() => {
      return this.bundleService.getRecentFolder()
        .pipe(
          map((workoutList) => {
            return fromBundleActions.getRecentWorkoutsSuccess({ workoutList });
          })
        );
    })
  ));
}
