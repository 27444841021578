<div class="whiteboard" (click)="hideShowTimeSetter($event, 'hide')">
  <div class="whiteboard__timer">
    <h2 class="whiteboard__header">
      {{ selectedWorkout.theme }}
    </h2>
    <button [mat-dialog-close]="true"
            mat-button
            class="icon-button whiteboard__close">
      <i class="icon-close"></i>
    </button>
    <div class="timer">
      <div class="timer__action">
        <button (click)="setCurrentTimeType('stopwatch')"  [ngClass]="{ active: currentTimeType === 'stopwatch' }" mat-button>
          <img src="assets/img/timer-stopwatch.svg" alt=""> Stopwatch
        </button>
        <button (click)="setCurrentTimeType('timer')" [ngClass]="{ active: currentTimeType === 'timer' }" mat-button>
          <img src="assets/img/timer-24px.svg" alt=""> Timer
        </button>
      </div>

      <div class="timer__panel mat-typography" (click)="hideShowTimeSetter($event, 'show')">
<!--        <div class="timer__nums">00</div>-->
<!--        <div class="timer__double-dots">:</div>-->
<!--        <div class="timer__nums">00</div>-->
        <ng-container *ngIf="showTimeSetter">
<!--          <input placeholder="__" maxlength="2" [totalfitForceZero]="minutes" [formControl]="minutes" [ngModel]="getMinutes()" (ngModelChange)="updateState(min.value, 'minutes')" type="text" #min>-->
<!--          <span>:</span>-->
<!--          <input placeholder="__"-->
<!--                 maxlength="2"-->
<!--                 [totalfitForceZero]="seconds"-->
<!--                 [formControl]="seconds"-->
<!--                 [ngModel]="getSeconds()"-->
<!--                 (ngModelChange)="updateState(sec.value, 'seconds')"-->
<!--                 type="text"-->
<!--                 #sec>-->

          <input mask="00:00" [showMaskTyped]="true" [formControl]="timeControl" #timeControlRef>
        </ng-container>

        <ng-container *ngIf="!showTimeSetter">
          <div>
            <ng-container *ngIf="intervalObs$ | async as clock; else default">
              {{clock | timeFormat}}
            </ng-container>
            <ng-template #default>{{ state.getValue().totalTime | timeFormat }}</ng-template>
          </div>
        </ng-container>
      </div>

      <div class="timer__start">
        <button mat-raised-button #reset [ngClass]="{ disabled : streamRunning }"><i class="icon-Recovery-day"></i></button>
        <button [ngStyle]="{ display: streamRunning ? 'none' : 'block' }" [ngClass]="{ disabled : !getTotalSeconds() && currentTimeType !== 'stopwatch' }" class="timer__stream-control start-btn" mat-raised-button #start color="primary">Start</button>
        <button [ngStyle]="{ display: streamRunning ? 'block' : 'none' }" class="timer__stream-control" mat-raised-button #pause color="warn">Stop</button>
      </div>
    </div>

    <div class="social">
      <div>
        <div class="social__link mat-subheading-2"><i class="icon-Instagram"></i>@gymtotalfit</div>
        <div class="social__tag mat-subheading-2"><img src="assets/img/Hashtag.svg" alt="">sportismylife</div>
      </div>

      <div>
        <div class="social__link mat-subheading-2"><i class="icon-Instagram"></i>@trainergymtotalfit</div>
        <div class="social__tag mat-subheading-2"><img src="assets/img/Hashtag.svg" alt="">inspirationtf</div>
      </div>
    </div>
  </div>
  <div class="whiteboard__wod wod mat-typography">
    <div class="wod__body">
      <ng-container *ngIf="currentStep === 1">
        <div class="auto-scroll">
          <div *ngIf="selectedWorkout.skill" class="content-block-wod">
            <h3 class="mat-display-1">Skill: </h3>
            <div class="content-block-wod__item">
              <div class="content-block-wod__notation mat-headline">
                {{ selectedWorkout.skill.name }}
              </div>
            </div>
          </div>

          <div class="content-block-wod">
            <h3 class="mat-display-1">WOD: </h3>
            <div class="content-block-wod__item">
              <div class="content-block-wod__notation mat-headline">
                <div class="card-block">
                  <div class="content-block-wod__item">
                    <div class="mat-headline">
                      Group type:
                      <span class="mat-accent mat-headline">
                      {{ groupType[selectedWorkout.complexExts[0].groupType] }} {{ typeOfWork[selectedWorkout.complexExts[0].typeOfWork] ? '/ ' + typeOfWork[selectedWorkout.complexExts[0].typeOfWork] : ''}}
                  </span>
                    </div>

                    <div class="mat-headline mat-accent"> {{ workoutTypes[selectedWorkout.complexExts[0].wodType] }}, {{ selectedWorkout.complexExts[0].quantity }}{{ workoutService.getQuantityMetricDesignation(selectedWorkout.complexExts[0].wodType) }} </div>
                    <div  *ngFor="let exercise of selectedWorkout.complexExts[0].exercises; let i = index" class="content-block-wod__item">
                      <div class="content-block-wod__notation  content-block-wod__notation_wod mat-headline">
                        {{ exercise.name }}

                        <span *ngIf="checkoutWodType(selectedWorkout.complexExts[0].wodType)" class="mat-headline quantity"><i class="icon-close"></i> {{ exercise.quantity }} {{ exercise.metrics }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedWorkout.story" class="content-block-wod">
            <h3 class="mat-display-1">Story:</h3>
            <div class="content-block-wod__full-width mat-headline">{{ selectedWorkout.story?.name }}</div>
          </div>

          <div *ngIf="selectedWorkout.healthyLifestyleHabit" class="content-block-wod">
            <h3 class="mat-display-1">HLH:</h3>
            <div class="content-block-wod__full-width mat-headline">{{ selectedWorkout.healthyLifestyleHabit?.name }}</div>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="currentStep === 2">
        <h2 class="whiteboard__header whiteboard__header_wod">
          Skill
        </h2>

        <div class="wod__item mat-display-2">
          {{ selectedWorkout.skill ? selectedWorkout.skill.name : 'NA' }}
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === 3">
        <h2 class="whiteboard__header whiteboard__header_wod">
          WOD
        </h2>

        <div class="auto-scroll">
          <div class="wod__item">
            <div class="content-block-wod content-block-wod_wrap">
              <div class="card-block">
                <div class="content-block-wod__item">
                  <div class="mat-headline">
                    Group type:
                    <span class="mat-accent mat-headline">
                      {{ groupType[selectedWorkout.complexExts[0].groupType] }} {{ typeOfWork[selectedWorkout.complexExts[0].typeOfWork] ? '/ ' + typeOfWork[selectedWorkout.complexExts[0].typeOfWork] : ''}}
                  </span>
                  </div>

                  <span class="mat-headline mat-accent"> {{ workoutTypes[selectedWorkout.complexExts[0].wodType] }}, {{ selectedWorkout.complexExts[0].quantity }}{{ workoutService.getQuantityMetricDesignation(selectedWorkout.complexExts[0].wodType) }} </span>
                  <div  *ngFor="let exercise of selectedWorkout.complexExts[0].exercises; let i = index" class="content-block-wod__item">
                    <div class="content-block-wod__notation  content-block-wod__notation_wod mat-headline">
                      {{ exercise.name }}
                      <span *ngIf="checkoutWodType(selectedWorkout.complexExts[0].wodType)" class="mat-headline quantity"><i class="icon-close"></i> {{ exercise.quantity }} {{ exercise.metrics }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === 4">
        <h2 class="whiteboard__header whiteboard__header_wod">
          Story
        </h2>

        <div class="wod__item mat-display-2">
          {{ selectedWorkout.story ? selectedWorkout.story.name : 'NA' }}
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === 5">
        <h2 class="whiteboard__header whiteboard__header_wod">
          HLH
        </h2>

        <div class="wod__item mat-display-2">
          {{ selectedWorkout.healthyLifestyleHabit ? selectedWorkout.healthyLifestyleHabit?.name : 'NA' }}
        </div>
      </ng-container>
    </div>

    <div class="wod__footer">
      <div class="wod__logo"><img src="assets/img/Totalfit_Logo_Black.svg" alt="Totalfit Logo"></div>
      <div class="wod__action">
        <button *ngIf="currentStep !== 1" (click)="currentStep = currentStep - 1" mat-button class="arrow-btn"> <mat-icon class="prev">arrow_back_ios</mat-icon> Previous</button>
        <button *ngIf="currentStep !== 5" (click)="currentStep = currentStep + 1" mat-button class="arrow-btn"> Next <mat-icon class="next">arrow_forward_ios</mat-icon></button>
        <button *ngIf="currentStep === 5" mat-raised-button color="primary" [mat-dialog-close]="true" class="finish-btn">finish workout</button>
      </div>
    </div>
  </div>
</div>
