import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ChangeUserPassword, LoginCredentials, User, UserRole } from '@app/store/user/user.model';
import { environment } from '@environments/environment';
import { AmplitudeService } from '@shared/services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public selectedRole: BehaviorSubject<UserRole[]> = new BehaviorSubject(null);
  private user: User;
  private token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  public getUser(credentials: LoginCredentials, keepIn?: boolean): Observable<User> {
    return this.http
      .post<User>(`${ environment.apiUrl }/${environment.apiV.apiV1}/login`, credentials, { observe: 'response' })
      .pipe(map(({ body, headers }) => {
        this.setUpUser(body, keepIn);
        this.setUpToken(headers.get('authorization'), keepIn);

        return this.user;
      }));
  }

  public getUserByJWT(): Observable<User> {
    const keepIn = JSON.parse(window.localStorage.getItem('keepIn'));
    return this.http.get<User>(`${environment.apiUrl}/${environment.apiV.apiV1}/users`)
      .pipe(map((user) => {
        this.setUpUser(user, keepIn);

        return this.user;
      }));
  }

  public changePassword(changePasswordValue: ChangeUserPassword): Observable<number> {
    return this.http
      .get<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/change-password`, { params: { ...changePasswordValue } });
  }

  public forgotPassword(email: string): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/forgot-password`, {
      params: {
        email
      }
    });
  }

  public verifyRecoveryToken(token: string): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/verify-recovery-token/${token}`);
  }

  public verifyUserEmail(token: string): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/email/${token}`);
  }

  public recoveryPassword(passwordsValue: { password: string, confirmPassword: string }, token: string): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/recovery`, { ...passwordsValue, token });
  }

  public logout(): void {
    this.user = null;
    this.token = null;
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authorization');

    window.localStorage.removeItem('user');
    window.localStorage.removeItem('authorization');

    window.localStorage.removeItem('active-role');
    window.sessionStorage.removeItem('active-role');

    this.selectedRole.next(null);
    this.router.navigate(['/login']);
  }

  public setSelectedRole(role: 'ADMIN' | 'USER') {
    if (role === 'ADMIN') {
      this.selectedRole.next(['ADMIN' as UserRole]);
      this.setRoleStorage(['ADMIN' as UserRole]);
      this.router.navigate(['/admin-trainings']);
    } else {
      this.selectedRole.next(this.currentUser.roles.filter((i) => i !== 'ADMIN'));
      this.setRoleStorage(this.currentUser.roles.filter((i) => i !== 'ADMIN'));
      this.router.navigate(['/tribe']);
    }
  }

  public setRoleStorage(role: UserRole[]) {
    window.localStorage
      .setItem('active-role', JSON.stringify(role));
  }

  public get authToken(): string {
    // TODO Need to refactor with normal token and user req
    const sessionToken = window.sessionStorage.getItem('authorization');
    if (!this.token && sessionToken) {
      this.token = sessionToken;
    }

    const localToken = window.localStorage.getItem('authorization');
    if (!this.token && localToken) {
      this.token = localToken;
    }

    return this.token;
  }

  public get currentUser(): User {
    // TODO Need to refactor with normal token and user req
    if (!this.user && window.sessionStorage.getItem('user')) {
      this.user = JSON.parse(window.sessionStorage.getItem('user'));
    }

    if (!this.user && window.localStorage.getItem('user')) {
      this.user = JSON.parse(window.localStorage.getItem('user'));
    }

    if (!this.selectedRole.getValue() && this.currentRole) {
      this.selectedRole.next(this.currentRole);
    }

    if (this.user && this.user.email && !this.amplitudeService.amplitudeInstanceSessionId) {
      this.amplitudeService.setInstance(
        this.user.email,
        { user_account_type: this.user.accountType, user_roles: this.user.roles }
        );
    }

    return this.user;
  }

  public get currentRole(): UserRole[] {
    if (window.localStorage.getItem('active-role')) {
      return JSON.parse(window.localStorage.getItem('active-role'));
    }
  }

  public get isKeepIn(): boolean {
    if (window.localStorage.getItem('keepIn')) {
      return JSON.parse(window.localStorage.getItem('keepIn'));
    } else if (window.localStorage.getItem('keepIn')) {
      return JSON.parse(window.localStorage.getItem('keepIn'));
    }
  }

  public get isAdmin(): boolean {
    return this.selectedRole.getValue().includes('ADMIN' as UserRole);
  }

  public get isUserAdmin(): boolean {
    if (this.user) {
      return this.user.roles.includes('ADMIN' as UserRole);
    }
  }

  public setUpUser(user: User, keepIn: boolean, withRoleSet = true): void {
    this.user = user;

    if (withRoleSet) {
      this.selectedRole.next(this.user.roles.includes(UserRole.Admin) ? [UserRole.Admin] : this.user.roles );
      window.localStorage
        .setItem('active-role', JSON.stringify(this.user.roles.includes(UserRole.Admin) ? [UserRole.Admin] : this.user.roles));
    }

    if (keepIn) {
      window.localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      window.sessionStorage.setItem('user', JSON.stringify(this.user));
    }
  }

  public setUpToken(token: string, keepIn: boolean): void {
    this.token = token;

    if (keepIn) {
      window.localStorage.setItem('authorization', this.token);
    } else {
      window.sessionStorage.setItem('authorization', this.token);
    }
  }
}
