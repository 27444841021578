import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAppUIActions from '@app/store/app-ui-state/app-ui-state.actions';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SnackbarService } from '@shared/services/snackbar.service';


@Injectable()
export class AppUiStateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService
  ) {}

  public navigate$ = createEffect(() => this.actions$.pipe(
    ofType(fromAppUIActions.appNavigate),
    tap(({ route }) => {
      this.router.navigate([route]);
    })
  ), { dispatch: false });

  public showSnackBar = createEffect(() => this.actions$.pipe(
    ofType(fromAppUIActions.appShowSnackBar),
    tap(({ message }) => {
      this.snackbarService.openSnackbar(message);
    })
  ), { dispatch: false });

  public showSnackBarComponent = createEffect(() => this.actions$.pipe(
    ofType(fromAppUIActions.appShowSnackBarComponent),
    tap(({ message, title, notificationType }) => {
      this.snackbarService.openNotificationComponent({
        data: {
          message,
          title,
          type: notificationType
        }
      });
    })
  ), { dispatch: false });
}
