import { createAction, props } from '@ngrx/store';
import { CustomSnackBarDataType } from '@shared/services/snackbar.service';

export enum TablePropNames {
  ADMIN_TRAINING_TABLE = 'adminTrainingFilter',
  ADMIN_WORKOUT_TABLE = 'adminWorkoutFilter',
  ADMIN_USERS_TABLE = 'adminUsersFilter',
  PEOPLE_TABLE = 'peopleFilter',
  TRIBE_MEMBER_FILTER = 'tribeMemberFilter',
  LOCATION_COMPLETED_WORKOUT = 'locationCompletedWorkout'
}

export const APP_UI_NAVIGATE = 'APP_UI_SET_CURRENT_VIEW';
export const APP_UI_SET_ADMIN_WORKOUT_FILTER = 'APP_UI_SET_ADMIN_WORKOUT_FILTER';
export const APP_UI_REMOVE_TABLE_FILTER = 'APP_UI_REMOVE_TABLE_FILTER';
export const APP_UI_REMOVE_WHOLE_TABLE = 'APP_UI_REMOVE_WHOLE_TABLE';

export const APP_UI_SET_ADMIN_WORKOUT_SEARCH = 'APP_UI_SET_ADMIN_WORKOUT_SEARCH';
export const APP_UI_REMOVE_TABLE_SEARCH = 'APP_UI_REMOVE_TABLE_SEARCH';

export const APP_UI_SHOW_SNACK_BAR = 'APP_UI_SHOW_SNACK_BAR';
export const APP_UI_SHOW_SNACK_BAR_COMPONENT = 'APP_UI_SHOW_SNACK_BAR_COMPONENT';

export const appNavigate = createAction(APP_UI_NAVIGATE, props<{ route: string }>());

export const appSetTableFilter = createAction(
  APP_UI_SET_ADMIN_WORKOUT_FILTER,
  props<{ filter: any, propsName: TablePropNames }>()
);

export const appRemoveTableFilter = createAction(
  APP_UI_REMOVE_TABLE_FILTER,
  props<{ propsName: TablePropNames, objectKeys: string[] }>()
);

export const appSetTableSearch = createAction(
  APP_UI_SET_ADMIN_WORKOUT_SEARCH,
  props<{ tableSearch: any, propsName: TablePropNames }>()
);

export const appRemoveTableSearch = createAction(
  APP_UI_REMOVE_TABLE_SEARCH,
  props<{ propsName: TablePropNames, objectKeys: string[] }>()
);

export const appRemoveWholeTable = createAction(
  APP_UI_REMOVE_WHOLE_TABLE,
  props<{ propsName: TablePropNames }>()
);

export const appShowSnackBar = createAction(
  APP_UI_SHOW_SNACK_BAR,
  props<{ message: string }>()
);

export const appShowSnackBarComponent = createAction(
  APP_UI_SHOW_SNACK_BAR_COMPONENT,
  props<{ message: string, title: string, notificationType: CustomSnackBarDataType }>()
);
