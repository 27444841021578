import {
  Component,
  ChangeDetectionStrategy, ChangeDetectorRef, Input,
} from '@angular/core';
import { DialogService } from '@shared/services/dialog.service';
import { SocialNetworksDialogComponent } from '@shared/components/social-networks/social-networks-dialog/social-networks-dialog.component';
import { LoadingPageComponent } from '@shared/models/loading-page.component';
import { ProfileFormService } from '@shared/services/profile-form.service';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SocialNetworksComponent extends LoadingPageComponent {
  @Input() public hideEdit: boolean;
  @Input() private dialogActionFunction: () => any;
  @Input() private dialogActionSave: () => any;
  @Input() public form: UntypedFormGroup;
  constructor(
    public profileFormService: ProfileFormService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  public openEditDialog(): void {
    this.dialogService.openDialog(SocialNetworksDialogComponent, { form: this.form })
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (this.dialogActionFunction) {
            this.dialogActionFunction();
          }
        }),
        finalize(() => this.changeDetectorRef.markForCheck()),
        switchMap(() => of(this.dialogActionSave()))
      ).subscribe();
  }
}
