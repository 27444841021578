import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileFormService } from '@shared/services/profile-form.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'totalfit-edit-social-networks',
  templateUrl: './social-networks-dialog.component.html',
  styleUrls: ['./social-networks-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialNetworksDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { form: UntypedFormGroup },
    public profileFormService: ProfileFormService,
    ) {}
}
