import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PaymentStep } from '@shared/dialogs/dialogs.model';

@Component({
  selector: 'totalfit-payment-steps-header',
  templateUrl: './payment-steps-header.component.html',
  styleUrls: ['./payment-steps-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentStepsHeaderComponent {
  @Input() public step;
  @Input() public stepsLabels: PaymentStep[];

  constructor() {}
}
