import { Action, createReducer, on } from '@ngrx/store';
import * as fromStoryActions from '@app/store/story/story.actions';
import { StoryState } from '@store/story/story.model';

export const storyInitialState: StoryState = {
  storyList: null,
  searchedStories: null,
  isLoading: false
};

const storyReducer = createReducer(
  storyInitialState,
  on(
    fromStoryActions.getStoryList,
    fromStoryActions.searchStoryList,
    (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(fromStoryActions.getStoryListSuccess, (state,  { storyList }) => {
    return {
      ...state,
      storyList,
      isLoading: false
    };
  }),
  on(fromStoryActions.searchStoryListSuccess, (state,  { searchedStories }) => {
    return {
      ...state,
      searchedStories,
      isLoading: false
    };
  }),
);

export function reducer(state: StoryState | undefined, action: Action) {
  return storyReducer(state, action);
}
