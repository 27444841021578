<ng-container *ngIf="step === 1">
    <div class="payment-window">
      <h2 class="payment-window__title">
        Check everything
      </h2>

      <div class="payment-window__item">
        <h3>{{ paymentSetUp.actionName }}</h3>
        <div class="payment-window__type">
          <img src="assets/img/logo-small-primary.svg" alt=""> <span> {{ paymentSetUp.entityName }} </span>
        </div>
      </div>

      <div class="payment-window__item last">
        <h3>Have a promocode?</h3>
        <div class="payment-window__promo-code">
          <div class="form-group form-group_full-width">
            <input type="text" class="form-group__control" placeholder="Promocode">
          </div>
        </div>
      </div>


      <div class="payment-window__footer">
        <div class="payment-window__price">
          <div>Total: {{ paymentSetUp.price }}$</div>
          <span class="payment-window__price-label">{{ paymentSetUp.entityName }}</span>
        </div>

        <div class="payment-window__purchase">
          <button mat-raised-button color="primary" (click)="purchaseTrigger()">Purchase</button>
        </div>
      </div>
    </div>
</ng-container>

<ng-container *ngIf="step === 2">
  <form [formGroup]="userForm" class="payment-window" >
    <h2 class="payment-window__title">
      Payment details
    </h2>

    <div class="payment-window__item">
      <h3>Payment method</h3>
      <div class="payment-window__option">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" checked>Credit card</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="payment-window__item">
      <h3>Billing information</h3>
      <div class="payment-window__billing-information">
        <totalfit-stripe-payment [userForm]="userForm"
                                 [amount]="paymentSetUp.price"
                                 [chargeData]="chargeData"
                                 (confirmPayment)="registerConfirmValue($event)"
                                 (paymentInfo)="tokenReg.emit($event)"
                                 (createToken)="registerToken($event)">
        </totalfit-stripe-payment>

        <div class="form-group form-group_row">
          <div class="form-group payment-window__country-selection">

            <totalfit-search-input [formControl]="countryControl"
                                   [autocompleteItemsFn]="getFilteredValue.bind(this)"
                                   [autocompleteItemTemplate]="countryAutoCompleteTemp"
                                   [isAutoSelected]="false"
                                   [initialList]="sharedFormService.countries"
                                   (autocompleteOptionSelected)="setIndexValidation($event)"
                                   autocompleteValueProperty="name"
                                   placeholder="Country">
            </totalfit-search-input>

            <ng-template let-country="item" #countryAutoCompleteTemp>
              <span>{{ country.name }}</span>
            </ng-template>
          </div>

          <div class="form-group form-group_full-width">
            <input  formControlName="address_zip" type="text" class="form-group__control" placeholder="Postal code">
          </div>
        </div>
      </div>

      <img src="assets/img/stripe-black.svg" style="width: 120px">
    </div>

    <div class="payment-window__item detail-info last">
      {{ paymentSetUp.actionDescription }}
    </div>


    <div class="payment-window__footer">
      <div class="payment-window__price">
        <div>Total: ${{ paymentSetUp.price }}</div>
        <span class="payment-window__price-label">{{ paymentSetUp.entityName }}</span>
      </div>

      <div class="payment-window__purchase">
        <button [disabled]="!confirmValue || !confirmValue?.paymentId || !confirmValue?.paymentMethod"
                mat-raised-button
                color="primary" (click)="confirmTrigger()">
          PURCHASE
        </button>
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="step === 3">
  <div class="last-step last-step_in-popup">
    <img *ngIf="purchaseSuccessfully" class="last-step__img" src="../../../assets/img/icons/green_check.svg" alt="Checked">
    <img *ngIf="!purchaseSuccessfully" class="last-step__img" src="../../../assets/img/icons/red_close.svg" alt="Unchecked">

    <ng-container *ngIf="purchaseSuccessfully">
      <h2 class="last-step__title">Congratulations!</h2>
      <p class="last-step__subtitle">
        Check your mailbox to see confirmation of registration and payment!
      </p>
    </ng-container>

    <ng-container *ngIf="!purchaseSuccessfully">
      <h2 class="last-step__title">Something Went Haywire.</h2>
      <p class="last-step__subtitle">
        Please double-check your data and give it another go.
      </p>
    </ng-container>


    <button *ngIf="purchaseSuccessfully" class="last-step__action" mat-raised-button color="primary" (click)="finalClickSuccess.emit()">
      Lets go
    </button>
    <button *ngIf="!purchaseSuccessfully" class="last-step__action" mat-raised-button color="primary" (click)="setPreviousStep.emit()">Try again</button>
  </div>
</ng-container>
