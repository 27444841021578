import { NgModule, Type } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe as AngularDatePipe } from '@angular/common';

import { NavigationPageContainerComponent } from '@shared/components/navigation-page-container/navigation-page-container.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '@core/header/header.component';
import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';
import { BaseDialogComponent } from './dialogs/base-dialog/base-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { ManualsComponent } from './components/manuals/manuals.component';
import { StatisticComponent } from './components/statistic/statistic.component';
import { DotsMenuComponent } from '@shared/components/dots-menu/dots-menu.component';
import { JwtInterceptorProvider } from '@core/auth/jwt.interceptor';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { TopActionsContainerComponent } from './components/top-actions-container/top-actions-container.component';
import { DialogService } from '@shared/services/dialog.service';
import { SocialNetworksComponent } from './components/social-networks/social-networks.component';
import { SelectComponent } from './components/select/select.component';
import { AvatarProfileComponent } from './components/avatar-profile/avatar-profile.component';
import { PaymentComponent } from './components/payment/payment.component';
import { NgxMaskModule } from 'ngx-mask';
import { DragDropDirective } from '@shared/directives/drag-drop.directive';
import { PhotoDropComponent } from '@shared/components/photo-drop/photo-drop.component';
import { EditModeDirective } from '@shared/directives/editable/edit-mode.directive';
import { ViewModeDirective } from '@shared/directives/editable/view-mode.directive';
import { EditableComponent } from '@shared/components/editable/editable.component';
import { EditOnEnterDirective } from '@shared/directives/editable/edit-on-enter.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ErrorHandlingInterceptorProvider } from '@core/error-handling.interceptor';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { DynamicPipe } from '@shared/pipes/dynamic.pipe';
import { DatePipe } from '@shared/pipes/date.pipe';
import { LocationListService } from '@app/location/_shared/location-list.service';
import { ProfileFormService } from '@shared/services/profile-form.service';
import { SocialNetworksDialogComponent } from './components/social-networks/social-networks-dialog/social-networks-dialog.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DocumentService } from '@shared/services/document.service';
import { NgModelChangeDebouncedDirective } from './directives/ng-model-change-debounce-time.directive';
import { InputAutofocusDirective } from '@shared/directives/input-autofocus.directive';
import { MaterialModule } from '@shared/material.module';
import { PeopleService } from '@app/people/_shared/people.service';
import { FormGroupValidatorDirective } from '@shared/directives/form-group-validator.directive';
import { AddPlusInInputDirective } from '@shared/directives/addPlusInInput.directive';
import { WorkoutFormService } from '@app/workout/_shared/workout-form.service';
import { MyProgramService } from '@app/my-program/_shared/my-program.service';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';
import { WhiteboardComponent } from './dialogs/whiteboard/whiteboard.component';
import { TimeFormatPipe } from '@shared/dialogs/whiteboard/whiteboard-time-format.pipe';
import { ForceZeroDirective } from '@shared/directives/force-zero.directive';
import { ScrollPaginationDirective } from '@shared/directives/scroll-pagination.directive';
import { TotalfitSpinnerComponent } from './components/totalfit-spinner/totalfit-spinner.component';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';
import { UpdatePlanComponent } from './dialogs/update-plan/update-plan.component';
import { PhotoPreviewComponent } from './dialogs/photo-preview/photo-preview.component';
import { StripeService } from '@shared/services/stripe.service';
import { BytesPipe } from '@shared/pipes/bytes.pipe';
import { ResizeService } from '@shared/services/resize.service';
import { RoleCheckDirective } from '@shared/directives/role-check.directive';
import { LocationInfoComponent } from './dialogs/location-info/location-info.component';
import { TrainingTitleComponent } from '@shared/components/training-title/training-title.component';
import { PublishNotificationComponent } from '@shared/components/training-edit/publish-notification/publish-notification.component';
import { TrainingEditComponent } from '@shared/components/training-edit/training-edit.component';
import { TrainingsService } from '@shared/services/trainings.service';
import { ParticipantService } from '@shared/services/participant.service';
import { TrainingUtilsService } from '@shared/services/training-utils.service';
import { UpdatePlanInfoComponent } from './dialogs/update-plan-info/update-plan-info.component';
import { PolicyComponent } from './dialogs/policy/policy.component';
import { TermsOfUseComponent } from './dialogs/terms-of-use/terms-of-use.component';
import { PeopleTableFilterComponent } from '@app/people/people-table-filter/people-table-filter.component';
import { DownloadFileDirective } from '@shared/directives/download-file.directive';
import { PricingDirective } from '@shared/directives/pricing.directive';
import { AdminUsersService } from '@app/admin-users/_shared/admin-users.service';
import { CreateNewFolderComponent } from './dialogs/create-new-folder/create-new-folder.component';
import { LibraryViewComponent } from './components/library-view/library-view.component';
import { AddFileListComponent } from './components/add-file-list/add-file-list.component';
import { PaymentStepsHeaderComponent } from './components/payment-steps-header/payment-steps-header.component';
import { PaymentStepsComponent } from '@shared/components/payment-steps/payment-steps.component';
import { EmojiPickerComponent } from '@shared/components/emoji-picker/emoji-picker.component';
import { SwiperModule } from 'swiper/angular';
import { MilitaryTimeToAmPmPipe } from '@shared/pipes/militaryTimeToAmPm.pipe';
import { EditWorkoutFormComponent } from '@app/workout/edit-workout-form/edit-workout-form.component';
import { AutofillComponent } from '@app/workout/autofill/autofill.component';
import { CustomNotificationComponent } from './components/notification/notification.component';
import { LocationSelectorComponent } from '@shared/components/location-selector/location-selector.component';
import { TimeRangeSelectorComponent } from './components/time-range-selector/time-range-selector.component';
import { WorkoutSearchDropdownComponent } from '@app/workout/workout-search-dropdown/workout-search-dropdown.component';
import { SelectExtensionPipe } from '@app/workout/_shared/workout-select-extension.pipe';
import { PassToggleDirective } from '@shared/directives/pass-toggle.directive';
import { HighlightPipe } from '@shared/pipes/highlight-span.pipe';

const sharedDeclarations: Array<Type<any> | any[]> = [
  NavigationPageContainerComponent,
  HeaderComponent,
  DropdownToggleDirective,
  DotsMenuComponent,
  FilterDropdownComponent,
  InputDateComponent,
  ContentCardComponent,
  TopActionsContainerComponent,
  SelectComponent,
  PaymentComponent,
  ProfileComponent,
  CertificateComponent,
  StatisticComponent,
  ManualsComponent,
  AvatarProfileComponent,
  SocialNetworksComponent,
  DragDropDirective,
  PhotoDropComponent,
  EditModeDirective,
  ViewModeDirective,
  EditOnEnterDirective,
  EditableComponent,
  SearchInputComponent,
  TooltipDirective,
  SearchPipe,
  DatePipe,
  DynamicPipe,
  MilitaryTimeToAmPmPipe,
  InputAutofocusDirective,
  SocialNetworksDialogComponent,
  NgModelChangeDebouncedDirective,
  FormGroupValidatorDirective,
  AddPlusInInputDirective,
  ForceZeroDirective,
  ColorPickerComponent,
  EmojiPickerComponent,
  TimeFormatPipe,
  ScrollPaginationDirective,
  TotalfitSpinnerComponent,
  StripePaymentComponent,
  UpdatePlanComponent,
  BytesPipe,
  RoleCheckDirective,
  LocationInfoComponent,
  TrainingTitleComponent,
  PublishNotificationComponent,
  TrainingEditComponent,
  PeopleTableFilterComponent,
  DownloadFileDirective,
  PricingDirective,
  LibraryViewComponent,
  AddFileListComponent,
  PaymentStepsComponent,
  PaymentStepsHeaderComponent,
  EditWorkoutFormComponent,
  AutofillComponent,
  CustomNotificationComponent,
  LocationSelectorComponent,
  TimeRangeSelectorComponent,
  WorkoutSearchDropdownComponent,
  SelectExtensionPipe,
  HighlightPipe,
  PassToggleDirective
];

const sharedDialogs: Array<Type<any> | any[]> = [
  ConfirmDialogComponent,
  WhiteboardComponent
];

const sharedModules: Array<Type<any> | any[]> = [
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  DragDropModule,
  CommonModule,
  NgxMatIntlTelInputModule,
  MaterialModule,
  SwiperModule
];

export const sharedProviders = [
  JwtInterceptorProvider,
  ErrorHandlingInterceptorProvider
];

@NgModule({
    imports: [
        ...sharedModules,
        RouterModule,
        NgxMaskModule.forRoot(),
        FormsModule,
    ],
    declarations: [
        ...sharedDeclarations,
        ...sharedDialogs,
        BaseDialogComponent,
        DocumentListComponent,
        WhiteboardComponent,
        PhotoPreviewComponent,
        UpdatePlanInfoComponent,
        PolicyComponent,
        TermsOfUseComponent,
        CreateNewFolderComponent,
    ],
    exports: [
        ...sharedModules,
        ...sharedDeclarations,
        ...sharedDialogs,
        NgxMaskModule,
        DocumentListComponent,
    ],
    providers: [
        DialogService,
        ProfileFormService,
        PeopleService,
        SearchPipe,
        TimeFormatPipe,
        DatePipe,
        AngularDatePipe,
        DynamicPipe,
        MilitaryTimeToAmPmPipe,
        DocumentService,
        LocationListService,
        WorkoutFormService,
        MyProgramService,
        StripeService,
        ResizeService,
        TrainingsService,
        ParticipantService,
        TrainingUtilsService,
        AdminUsersService
    ]
})

export class SharedModule {}
