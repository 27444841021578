import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.model';
import { GroupType, TypeOfWork, WorkoutTypes, WorkoutView } from '@shared/models/workout.model';
import { WorkoutService } from '@app/workout/_shared/workout.service';
import { BehaviorSubject, fromEvent, interval, merge, Observable, of } from 'rxjs';
import { mapTo, scan, switchMap, tap } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'totalfit-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteboardComponent implements OnInit, AfterViewInit {
  @ViewChild('start', { static: false })
  public startBtn: MatButton;

  @ViewChild('reset', { static: false })
  public resetBtn: MatButton;

  @ViewChild('pause', { static: false })
  public pauseBtn: MatButton;

  @ViewChild('timeControlRef', { static: false })
  public timeControlRef: ElementRef;

  public timeControl = new UntypedFormControl();

  public whiteboardSteps: any[];
  public currentStep = 1;
  public workoutTypes = WorkoutTypes;
  public groupType = GroupType;
  public typeOfWork = TypeOfWork;

  public streamRunning$: Observable<boolean>;
  public streamStopped$: Observable<boolean>;
  public streamRunning = false;
  public intervalObs$;
  public currentTimeType: 'stopwatch' | 'timer';
  public showTimeSetter = false;
  public lastStateCommit: string;

  public state = new BehaviorSubject({
    seconds: 0,
    minutes: 0,
    totalTime: 0
  });

  public selectedWorkout: WorkoutView;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel & { workout: WorkoutView },
    public workoutService: WorkoutService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.currentTimeType = 'timer';
    this.selectedWorkout = Object.assign({}, this.data.workout);

    if (this.selectedWorkout.healthyLifestyleHabit) {
      this.workoutService.getAllHabits()
        .subscribe((hlh) => {
          this.selectedWorkout.healthyLifestyleHabit = hlh.objects.find((hlhItem) => {
            return hlhItem.id === this.selectedWorkout.healthyLifestyleHabit;
          });

          this.changeDetectorRef.markForCheck();
        });
    }

    this.timeControl.valueChanges.subscribe((value) => {
      if (value && this.lastStateCommit !== value) {
        this.lastStateCommit = value;
        const min = value.slice(0, 2);
        const sec = value.slice(2);

        if (min) {
          this.updateState(min, 'minutes');
        }

        if (sec) {
          this.updateState(sec, 'seconds');
        }
      }
    });
  }

  public ngAfterViewInit(): void {
    this.streamRunning$ = fromEvent(this.startBtn._elementRef.nativeElement, 'click')
      .pipe(mapTo(true), tap(() => {
        this.streamRunning = true;

        this.changeDetectorRef.markForCheck();
      }));
    this.streamStopped$ = fromEvent(this.pauseBtn._elementRef.nativeElement, 'click')
      .pipe(mapTo(false), tap(() => {
        this.streamRunning = false;

        this.changeDetectorRef.markForCheck();
      }));
    const reset$ = fromEvent(this.resetBtn._elementRef.nativeElement, 'click')
      .pipe(mapTo(null), tap(() => {
        this.streamRunning = false;

        this.changeDetectorRef.markForCheck();
      }));
    const stateChange$ = this.state.pipe(mapTo(null));

    this.intervalObs$ = merge(this.streamRunning$, this.streamStopped$, reset$, stateChange$).pipe(
      switchMap((isCounting) => {
        if (isCounting === null) {
          return of(null);
        }
        return isCounting ? interval(1000) : of();
      }),
      scan((accumulatedValue: any, currentValue: any) => {
        if (this.currentTimeType === 'timer') {
          if (accumulatedValue === 0) {
            return accumulatedValue;
          }
          if (currentValue === null || !accumulatedValue) {
            return this.getTotalSeconds();
          }
          return --accumulatedValue;
        }

        if (this.currentTimeType === 'stopwatch') {
          if (currentValue === null) {
            return 0;
          }

          if ((accumulatedValue && !currentValue) || currentValue < accumulatedValue) {
            return ++accumulatedValue;
          }

          return ++accumulatedValue && ++currentValue;
        }
      })
    );
  }

  public updateState(value, command): void {
    let valToNumber = parseInt(value || 0, 10);
    if (valToNumber < 0) {
      valToNumber = 0;
    }
    const update = this.state.value;

    if (command === 'seconds') {
      update.seconds = valToNumber;
    }
    if (command === 'minutes') {
      update.minutes = valToNumber;
    }

    update.totalTime = this.calculateSeconds(update);
    this.state.next(update);
  }

  public hideShowTimeSetter(event, state: 'hide' | 'show'): void {
    event.stopPropagation();

    if (state === 'hide' && event.target.closest('.start-btn') && this.streamRunning === false) {
      window.setTimeout(() => {
        this.startBtn._elementRef.nativeElement.click();
      });
    }

    if (state === 'hide') {
      if (this.getTotalSeconds()) {
        const minutes = ('0' + Math.floor(this.getTotalSeconds() / 60) % 60).slice(-2);
        const seconds = ('0' + this.getTotalSeconds() % 60).slice(-2);

        this.timeControl.patchValue(`${minutes}${seconds}`);
      }

      this.showTimeSetter = false;
    }

    if (state === 'show' && this.currentTimeType !== 'stopwatch') {
      this.streamRunning = false;
      this.showTimeSetter = true;

      window.setTimeout(() => {
        this.timeControlRef.nativeElement.focus();
      }, 300);
    }
  }

  public calculateSeconds(update): number {
    let totalTime = update.seconds;
    totalTime += update.minutes * 60;
    return totalTime;
  }

  public setCurrentTimeType(type): void {
    this.updateState(0, 'seconds');
    this.updateState(0, 'minutes');

    this.currentTimeType = type;
    this.pauseBtn._elementRef.nativeElement.click();
    this.timeControl.patchValue(null);
    this.changeDetectorRef.markForCheck();
  }

  public getSeconds(): number {
    return this.state.value.seconds;
  }

  public getMinutes(): number {
    return this.state.value.minutes;
  }

  public getTotalSeconds(): number {
    return this.state.value.totalTime;
  }

  public checkoutWodType(wodType: WorkoutTypes): boolean {
    return WorkoutTypes[wodType] !== 'TABATA';
  }
}
