import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { UserRole } from '@app/store/user/user.model';

export interface Permission {
  roles: UserRole[];
  subDomain: string;
  guestRoute?: boolean;
}

@Directive({
  selector: '[totalfitRoleCheck]'
})

export class RoleCheckDirective {
  private permission: Permission;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {
    this.authService.selectedRole.subscribe(() => {
      if (this.permission) {
        this.updateView();
      }
    });
  }

  public static checkDisabledState(permission, currentUser) {
    if (permission.guestRoute && !currentUser) {
      return true;
    }
    let hasPermission = true;
    // Check role
    if (currentUser) {
      for (const checkPermission of permission.roles) {
        const permissionFound = currentUser.roles.find((x) => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = false;
        }
      }
    }

    // Check sub domain
    if (permission.subDomain && !window.location.host.startsWith(`${permission.subDomain}.`)) {
      hasPermission = true;
    }

    return hasPermission;
  }

  @Input()
  set totalfitRoleCheck(permission: Permission) {
    this.permission = permission;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (this.permission.guestRoute && !this.authService.currentUser) {
      return true;
    }
    let hasPermission = false;
    // Check role
    if (this.authService.currentUser && this.permission.roles) {
      const selectedRoles = this.authService.selectedRole.getValue();
      for (const checkPermission of this.permission.roles) {
        const permissionFound = selectedRoles.find((x) => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = true;
        }
      }
    }

    // Check sub domain
    // if (this.permission.subDomain && !window.location.host.startsWith(`${this.permission.subDomain}.`)) {
    //   hasPermission = false;
    // }

    return hasPermission;
  }
}
