import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

export interface Order {
  amount: any;
  currency: string;
  id?: string;
  token: string;
}

export interface RequestConfirm {
  paymentId: string;
  paymentMethod: string;
}

@Injectable()
export class StripeService {
  constructor(private http: HttpClient) {}

  public purchase(order: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/payment/charge`, order);
  }

  public charge(amount: number, chargeData: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/payment/charge`, { ...chargeData, amount });
  }

  public paymentConfirm(confirmReq: RequestConfirm): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/payment/confirm`, confirmReq);
  }
}
