import { Action, createReducer, on } from '@ngrx/store';
import * as fromBundleActions from '@store/bundle/bundle.actions';
import { BundleState } from '@store/bundle/bundle.model';

export const bundleInitialState: BundleState = {
  bundleTemplateList: null,
  isLoading: false,
  selectedBundle: null,
  recentBundle: null
};

const bundleReducer = createReducer(
  bundleInitialState,
  on(
    fromBundleActions.getUserBundleWorkouts,
    fromBundleActions.getTfBundleWorkouts,
    fromBundleActions.getRecentWorkouts, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(
    fromBundleActions.getUserBundleWorkoutsSuccess,
    fromBundleActions.getTfBundleWorkoutsSuccess, (state, { templateList, folder }) => {
    return {
      ...state,
      bundleTemplateList: templateList,
      isLoading: false,
      selectedBundle: folder
    };
  }),
  on(fromBundleActions.resetSelectedBundle, (state) => {
      return {
        ...state,
        selectedBundle: null
      };
    }),

  on(fromBundleActions.getRecentWorkoutsSuccess, (state, { workoutList }) => {
      return {
        ...state,
        recentBundle: workoutList,
        selectedBundle: { id: null, name: 'Recent', size: workoutList.length },
        isLoading: false
      };
  }),
);

export function reducer(state: BundleState | undefined, action: Action) {
  return bundleReducer(state, action);
}
