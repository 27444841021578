import { PageModel, PaginationModel } from '@shared/models/pagination.model';
import {
  AdminWorkoutView,
  CustomWorkoutView,
  OnlineExercise,
  OnlineWorkout,
  WorkoutType,
  WorkoutView
} from '@shared/models/workout.model';
import { ScrollConfig } from '@shared/models/scroll-pagination.model';

export interface WorkoutState {
  adminWorkoutList: PaginationModel<OnlineWorkout>;
  workoutBundles: PaginationModel<WorkoutBundle>;
  selectedBundleWorkout: WorkoutView;
  selectedLastWorkout: WorkoutView;
  userWorkoutBundles: WorkoutBundle[];
  selectedUserBundle: WorkoutBundle;
  selectedUserShopBundle: WorkoutBundle;
  customWorkoutList: PaginationModel<CustomWorkoutView>;
  currentCustomWorkout: CustomWorkoutView;
  currentCustomWorkoutLoading: boolean;
  workoutTypeView: WorkoutType;
  onlineExercise: PaginationModel<OnlineExercise>;
  offlineWorkoutList: PaginationModel<any>;
  offlineWorkout: WorkoutView;
  workoutIsLoading: boolean;
  adminTemplateList: PaginationModel<AdminWorkoutView>;
  adminBundleTemplateList: AdminWorkoutView[];
  adminOfflineWorkout: AdminWorkoutView;
  adminTemplateListPagination: ScrollConfig;
  templateWorkoutBundleList: PaginationModel<TemplateWorkoutBundle>;
  templateWorkoutBundle: TemplateWorkoutBundle;
  currentTemplateForPreview: AdminWorkoutView;
  dropDownList: UserComplexOfflineWorkouts;
}

export enum WorkoutLabel {
  CUSTOM = 'CUSTOM',
  PREMIUM = 'PREMIUM'
}

export enum BundleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum CustomOfflineWorkoutBlocks {
  story = 'STORY', hlh = 'HLH', wod = 'WOD', warmup = 'WARMUP', cooldown = 'COOLDOWN', skill = 'SKILL'
}

export const CustomOfflineWorkoutBlocksFromForm = {
  story: CustomOfflineWorkoutBlocks.story,
  hlh: CustomOfflineWorkoutBlocks.hlh,
  wod: CustomOfflineWorkoutBlocks.wod,
  warmup: CustomOfflineWorkoutBlocks.warmup,
  cooldown: CustomOfflineWorkoutBlocks.cooldown,
  skill: CustomOfflineWorkoutBlocks.skill
};

export interface WorkoutBundle {
  id?: string;
  label?: WorkoutLabel;
  name?: string;
  usersId?: string[];
  workouts?: WorkoutView[];
  description?: string;
  image?: string;
  price?: string;
  oldPrice?: string;
  usersEmail?: string[];
  size?: number;
  type?: BundleTypes;
}

export enum BundleTypes {
  USER_BUNDLE = 'USER_BUNDLE',
  TS_BUNDLE = 'TS_BUNDLE'
}

export interface TemplateWorkoutBundle extends WorkoutBundle {
  status: BundleStatus;
}

export interface CustomOfflineWorkoutExtensions {
  content: string;
  extensionType: CustomOfflineWorkoutBlocks;
  id: string;
}

export interface UserComplexOfflineWorkouts {
  offlineWorkouts: PageModel<WorkoutView>;
  recentOfflineWorkouts: Partial<WorkoutBundle>;
  workoutBundleTemplates: Array<Partial<WorkoutBundle>>;
  workoutBundles: Array<Partial<WorkoutBundle>>;
}
