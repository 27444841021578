import { DatePipe as AngularDatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  constructor(
    private angularDatePipe: AngularDatePipe
  ) {}

  public transform(value: any, ...args): string {
    return this.angularDatePipe.transform(value, ...args);
  }
}
