<div class="form-group form-group_row expand time-range-picker" [class.invalid-state]="(startTimeControl.invalid && startTimeControl.touched) || (endTimeControl.invalid && endTimeControl.touched) || fromToDateValidationStatus">
  <div class="form-group" [ngClass]="{ 'form-group_invalid': fromToDateValidationStatus }">
    <label class="form-group__label">From</label>
    <input [matAutocomplete]="startTimeAuto"
           [formControl]="startTimeControl"
           placeholder="Start time"
           (blur)="onStartTimeBlur()"
           (keydown.enter)="onStartTimeBlur(); $event.stopPropagation(); $event.preventDefault()"
           (click)="startTimeTrigger.openPanel()"
           #startTimeTrigger="matAutocompleteTrigger"
           class="form-group__control form-group_full-width">
    <mat-autocomplete #startTimeAuto="matAutocomplete" (optionSelected)="onStartTimeChange()" (opened)="focusOnBestOption(startTimeControl.value, startTimeTrigger, filteredStartTimeSlots)">
      <mat-option *ngFor="let time of filteredStartTimeSlots" [value]="time">{{ time }}</mat-option>
    </mat-autocomplete>
  </div>


  <div class="form-group" [ngClass]="{ 'form-group_invalid': fromToDateValidationStatus }">
    <label class="form-group__label">to</label>
    <input [matAutocomplete]="endTimeAuto"
           [formControl]="endTimeControl"
           placeholder="End time"
           (blur)="onEndTimeBlur()"
           (keydown.enter)="onEndTimeBlur(); $event.stopPropagation(); $event.preventDefault()"
           (click)="endTimeTrigger.openPanel()"
           #endTimeTrigger="matAutocompleteTrigger"
           class="form-group__control form-group_full-width">
    <mat-autocomplete #endTimeAuto="matAutocomplete" [ngClass]="{ 'invalid': (startTimeControl.invalid && startTimeControl.touched) || (endTimeControl.invalid && endTimeControl.touched) }" (optionSelected)="onEndTimeChange()" (opened)="focusOnBestOption(endTimeControl.value, endTimeTrigger, filteredEndTimeSlots)">
      <mat-option *ngFor="let time of filteredEndTimeSlots" [value]="time">{{ time }}</mat-option>
    </mat-autocomplete>
  </div>

  <div *ngIf="(startTimeControl.invalid && startTimeControl.touched) || (endTimeControl.invalid && endTimeControl.touched) || fromToDateValidationStatus" class="mat-error">
    Invalid time format. Please enter a valid time.
    <div>
      (e.g., 05:30 PM)
    </div>
  </div>
</div>
