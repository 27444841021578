import { WorkoutView } from '@shared/models/workout.model';
import { Observable, of } from 'rxjs';
import { PaginationModel } from '@shared/models/pagination.model';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BundleService {

  public folderState: { [key: string]: {
      paginationValue: { pageSize: number, pageNumber: number },
      workoutList: WorkoutView[],
      totalSize: number,
      lastSelectedPagination: Set<string>
    }} = {};

  constructor(private http: HttpClient) {}

  private initFolderState(key: string) {
    if (!this.folderState[key]) {
      this.folderState[key] = {
        paginationValue: {
          pageSize: 20,
          pageNumber: 0
        },
        workoutList: [],
        totalSize: null,
        lastSelectedPagination: new Set()
      };
    }
  }

  private fetchAndCacheWorkouts(
    id: string,
    url: string
  ): Observable<PaginationModel<WorkoutView>> {
    const key = `${url},${id}`;
    this.initFolderState(key);
    const state = this.folderState[key];
    if (state.totalSize === null) state.totalSize = Infinity; // Default to Infinity
    const { pageSize, pageNumber } = state.paginationValue;
    const pageKey = `${pageSize}, ${pageNumber}`;

    // Check if we've already loaded all the workouts
    if (!state.lastSelectedPagination.has(pageKey) && state.workoutList.length < state.totalSize) {
      return this.http
        .get<PaginationModel<WorkoutView>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/${url}/${id}?size=${pageSize}&page=${pageNumber}&favorite=${false}`)
        .pipe(
          map((res) => {
            state.totalSize = +res.totalElements;
            state.workoutList = [...state.workoutList, ...res.objects];
            state.lastSelectedPagination.add(pageKey);

            if (state.paginationValue.pageNumber < res.pagesCount) {
              state.paginationValue.pageNumber++;
            }

            return { objects: state.workoutList, pagesCount: res.pagesCount, totalElements: res.totalElements } ;
          })
        );
    } else {
      return of({ objects: state.workoutList, pagesCount: pageNumber } as PaginationModel<WorkoutView>);
    }
  }

  public getBundleOfflineWorkouts(id: string): Observable<PaginationModel<WorkoutView>> {
    return this.fetchAndCacheWorkouts(id, 'offline-workouts/bundles');
  }

  public getTfBundleOfflineWorkouts(id: string): Observable<PaginationModel<WorkoutView>> {
    return this.fetchAndCacheWorkouts(id, 'templates/offline-workouts/bundles');
  }

  public getRecentFolder(): Observable<WorkoutView[]> {
    return this.http.get<WorkoutView[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/recent`);
  }

  public dropCache(): void {
    this.folderState = {};
  }
}
