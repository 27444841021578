import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { ProfileFormService } from '@shared/services/profile-form.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingPageComponent } from '@shared/models/loading-page.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaymentComponent extends LoadingPageComponent {
  @Input() public isEditable = false;

  constructor(
    public profileFormService: ProfileFormService,
    public route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);

    this.profileFormService.peopleForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }
}
