<div [ngClass]="{ 'card-disabled': disabledCard, 'card-selected': isSelected }" class="content-card">
  <div *ngIf="hasImage"
       class="content-card__image">
    <img *ngIf="entity[entityImageField] else imagePlaceholder" [src]="entity[entityImageField]" alt="Placeholder">
    <div *ngIf="authorOnImgPlaceholder" class="card-label-author">
      <img [src]="authorOnImgPlaceholder.img">{{ authorOnImgPlaceholder.name }}
    </div>
  </div>
  <div class="content-card__body">
    <div class="content-card__header">
      <div class="content-card__author" *ngIf="showAlias">
        <img [src]="authorPhoto ? authorPhoto : 'assets/img/library/totalfit-created-workout.svg'">
        <span *ngIf="!showOnlyHeaderAction" class="text-ellipsis" [totalfitTooltip]="author ? 'by ' + author : 'by Totalfit'">{{ author ? 'by ' + author : 'by Totalfit' }}</span>
      </div>

      <mat-chip-list *ngIf="!showAlias">
        <mat-chip *ngIf="cardLabel !== 'TOTALFIT'"
                  [ngClass]="cardLabelClass"
                  [innerHTML]="cardLabel"
                  class="author">
        </mat-chip>

        <mat-chip *ngIf="cardLabel === 'TOTALFIT'"
                  class="totalfit">
          Totalfit Created
        </mat-chip>
      </mat-chip-list>

      <ng-content select="totalfit-dots-menu"></ng-content>
    </div>

    <div (dblclick)="dblBodyClick.emit(entity)" (click)="bodyClick.emit(entity)" style="cursor: pointer">
      <ng-container *ngIf="!customBody">
        <span [totalfitTooltip]="subtitle" class="content-card__subtitle text-ellipsis">{{ subtitle }}</span>
        <div [totalfitTooltip]="title" class="content-card__title text-ellipsis">{{ title }}</div>
        <div [totalfitTooltip]="secondTitle" class="content-card__title text-ellipsis" *ngIf="secondTitle">{{ secondTitle }}</div>
        <div [totalfitTooltip]="description"
             style="font-weight: normal"
             class="content-card__description mat-subheading-1 text-ellipsis">
          {{ description }}
        </div>
      </ng-container>

      <ng-container *ngIf="customBody">
        <ng-container *ngTemplateOutlet="customBody"></ng-container>
      </ng-container>
    </div>
  </div>

  <div *ngIf="hasFooter && !customFooterRef"
       class="content-card__footer">
    <button  *ngIf="showWhiteboard" mat-button (click)="whiteBoard.emit(true)" class="icon-button icon-button__mute">
      <img src="assets/img/Frame%20234.svg" alt="">
    </button>
  </div>

  <div *ngIf="hasFooter && customFooterRef" class="content-card__footer_custom">
     <ng-container *ngTemplateOutlet="customFooterRef"></ng-container>
  </div>
</div>

<ng-template #imagePlaceholder>
  <img src="assets/img/gym/gym-placeholder-2.svg" alt="Placeholder">
</ng-template>

<ng-template #unselectedIcon>
  <mat-icon>turned_in_not</mat-icon>
</ng-template>
