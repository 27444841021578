import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const library = (state: AppState) => state.library;

export const selectStream = createSelector(
  library,
  (state) => state.streamSrc
);

export const selectStreamName = createSelector(
  library,
  (state) => state.streamName
);

export const streamPlayStatus = createSelector(
  library,
  (state) => state.playStatus
);

export const selectLastAddedLibraryItem = createSelector(
  library,
  (state) => state.lastAddedLibraryItem
);

export const selectFailedLibraryItem = createSelector(
  library,
  (state) => state.failedFile
);
