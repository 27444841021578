import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const offlineWorkout = (state: AppState) => state.offlineWorkout;

export const selectOfflineWorkoutList = createSelector(
  offlineWorkout,
  (storyState) => storyState.offlineWorkoutList
);

export const selectOfflineWorkoutIsLoading = createSelector(
  offlineWorkout,
  (storyState) => storyState.isLoading
);

export const selectSearchFolders = createSelector(
  offlineWorkout,
  (storyState) => storyState.searchFolders
);
