export enum TooltipPlacement {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export interface TooltipPositionConfig {
  firstPositionValue: number;
  secondPositionValue: number;
  firstPositionPropertyName?: string;
  secondPositionPropertyName?: string;
}

export interface TooltipTrianglePositionConfig extends TooltipPositionConfig {
  firstBorderPropertyName: string;
  secondBorderPropertyName: string;
}

export interface TooltipStyleSet {
  [key: string]: string | number;
}

export interface TooltipPosition<T = number> {
  top: T;
  right: T;
  bottom: T;
  left: T;
}

export interface TooltipPositionRect extends TooltipPosition {
  width: number;
  height: number;
}
