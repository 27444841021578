import { Address } from '@shared/models/location.model';
import { Direction } from '@shared/models/direction.model';
import { Gender } from '@app/people/_shared/people.model';
import { Dictionary } from '@shared/models/dictionary.model';

export interface Training {
  address: Address;
  cost: number;
  direction: Direction;
  finishDate: string;
  hostPartner: HostPartner;
  instructors: TrainingInstructor[];
  leadInstructor: TrainingInstructor;
  level: string;
  participantLimit: number;
  participants: Participant[];
  photo: string;
  registrationLink:	string;
  startDate: string;
  status: TrainingStatus;
  zoneId: string;
  id?: string;
  timeStatus?: string;
}

export interface HostPartner {
  email: string;
  name:	string;
  phone:	string;
}

export interface TrainingInstructor {
  id:	string;
  email: string;
  name: string;
  trainingId:	string;
  type:	InstructorType;
  userId: string;
  photo?: string;
}

export interface Participant {
  birth: string;
  contactPhone:	string;
  email: string;
  emergencyPhone:	string;
  examLanguage: string;
  gender: Gender;
  id:	string;
  limitationsDescribe: string;
  location: TrainingLocation;
  name:	string;
  other: string;
  shirtSize: string;
  totalfitExperience:	string;
  trainingId:	string;
  certificateLabel: string;
  comment: string;
  dateOfCreation?: string;
  examStatus: string;
  lastLetterTemplateName: string;
}

export interface TrainingLocation {
  city:	string;
  country: string;
}

export interface TrainingRequest {
  receiver: string;
  status: TrainingStatus;
  text: string;
  trainingId: string;
}

export interface TrainingsLetter {
  id?: string;
  scope?: 'WEB';
  theme?: Training | any;
  senderId: string; // Current User
  receiverEmail: any; // User for save
  name?: string;
  body?: string;
  trainingId?: string;
  templateName?: string;
}

export enum ExamStatus {
  NONE = 'NONE',
  DONE = 'DONE',
  WASTED = 'WASTED'
}

export enum ExamStatusLabels {
  NONE = 'None',
  DONE = 'Done',
  WASTED = 'Wasted'
}

export const ExamStatusDictionary: Dictionary<ExamStatus> = [
  {
    name: 'None',
    id: ExamStatus.NONE
  },
  {
    name: 'Done',
    id: ExamStatus.DONE
  },
  {
    name: 'Wasted',
    id: ExamStatus.WASTED
  }
];

export enum InstructorType {
  Instructor = 'INSTRUCTOR',
  LeadInstructor = 'LEAD_INSTRUCTOR '
}

export enum TrainingStatus {
  LocalSave = 'LOCAL_SAVE',
  WaitingForReview = 'WAITING_FOR_REVIEW',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED'
}

export enum TrainingLevels {
  level1= 'Level 1 Coach',
  level2= 'Level 2 Coach',
  TOI= 'TOI Coach',
}

export enum TrainingLevelsName {
  level1= 'Level 1',
  level2= 'Level 2',
  TOI= 'TOI',
}

export enum LetterPlaceholders {
  LOCATION = 'LOCATION',
  DATA = 'DATE',
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  LEAD_INSTRUCTOR = 'LEAD_INSTRUCTOR',
  LEVEL = 'LEVEL'
}
