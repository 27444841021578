<div class="policy mat-typography">
  <button mat-button class="icon-button" [mat-dialog-close]="false"><mat-icon>close</mat-icon></button>
  <div class="policy-container">
    <h2>Privacy Policy</h2>
    <p>Last modified: May 25, 2018</p>
    <h3>Introduction</h3>
    <p>Totalfit Inc., (“Company” or “We”) respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit the website totalfit.team (including its subdomains and related addresses) or download or use our mobile application (collectively, our “Website”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>

    <p>This policy applies to information we collect:</p>

    <ul>
      <li>On this Website.</li>
      <li>In email, text, and other electronic messages between you and this Website.</li>
      <li>When you interact with our advertising and applications on third-party websites and services, if
        those
        applications or advertising include links to this policy.
      </li>
    </ul>

    <p>It does not apply to information collected by:</p>

    <ul>
      <li>us offline or through any other means, including on any other website operated by Company or any
        third
        party
        (including our affiliates and subsidiaries); or
      </li>
      <li>any third party (including our affiliates and subsidiaries), including through any application or
        content
        (including advertising) that may link to or be accessible from or on the Website.
      </li>
    </ul>

    <p>Please read this policy carefully to understand our policies and practices regarding your information and
      how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
      Website. By accessing or using this Website, you agree to this privacy policy. This policy may change
      from
      time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make
      changes
      is deemed to be acceptance of those changes, so please check the policy periodically for updates.
    </p>
    <h3>Children Under the Age of 16</h3>
    <p>
      Our Website is not intended for children under 16 years of age. No one under age 16 may provide any information to or on the Website. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this Website or on or through any of its features/register on the Website, make any purchases through the Website, use any of the interactive or public comment features of this Website or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at support@totalfit.app.
    </p>
    <h3>Information We Collect About You and How We Collect It</h3>
    <p>We collect several types of information from and about users of our Website, including information:</p>
    <ul>
      <li>by which you may be personally identified, such as name, postal address, e-mail address, telephone
        number, any other identifier by which you may be contacted online or offline (“personal
        information”);
      </li>
      <li>that is about you but individually does not identify you, and/or</li>
      <li>about your internet connection, the equipment you use to access our Website and usage details.</li>
    </ul>
    <p>We collect this information:</p>
    <ul>
      <li>Directly from you when you provide it to us.</li>
      <li>Automatically as you navigate through the site. Information collected automatically may include
        usage
        details, IP addresses, and information collected through cookies.
      </li>
      <li>From third parties, for example, our business partners.&zwnj;</li>
    </ul>
    <h3>Information You Provide to Us.</h3>
    <p>The information we collect on or through our Website may include:</p>
    <ul>
      <li>Information that you provide by filling in forms on our Website. This includes information provided
        at
        the time of registering to use our Website or ordering our products through our Website. We may also
        ask
        you for information when you enter a contest or promotion sponsored by us, and when you report a
        problem
        with our Website.
      </li>
      <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
      <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
      <li>Details of transactions you carry out through our Website and of the fulfillment of your orders. You
        may
        be required to provide financial information before placing an order through our Website.
      </li>
      <li>Your search queries on the Website.&zwnj;</li>
    </ul>
    <h3>Information We Collect Through Automatic Data Collection Technologies.</h3>
    <p>As you navigate through and interact
      with our Website, we may use automatic data collection technologies to collect certain information about
      your equipment, browsing actions, and patterns, including:</p>
    <ul>
      <li>Details of your visits to our Website, including traffic data, location data, logs, and other
        communication data and the resources that you access and use on the Website.
      </li>
      <li>Information about your computer and internet connection, including your IP address, operating
        system,
        and browser type.&zwnj;
      </li>
    </ul>
    <p>The information we collect automatically is statistical data and may include personal information, or we
      may
      maintain it or associate it with personal information we collect in other ways or receive from third
      parties. It helps us to improve our Website and to deliver a better and more personalized service,
      including
      by enabling us to:</p>
    <ul>
      <li>Estimate our audience size and usage patterns.</li>
      <li>Store information about your preferences, allowing us to customize our Website according to your
        individual interests.
      </li>
      <li>Speed up your searches.</li>
      <li>Recognize you when you return to our Website.</li>
    </ul>
    <p>The technologies we use for this automatic data collection may include:</p>
    <ul>
      <li><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of
        your
        computer. You may
        refuse to accept browser cookies by activating the appropriate setting on your browser. However, if
        you
        select this setting you may be unable to access certain parts of our Website. Unless you have
        adjusted
        your
        browser setting so that it will refuse cookies, our system will issue cookies when you direct your
        browser
        to our Website.
      </li>
      <li><strong>Flash Cookies.</strong> Certain features of our Website may use local stored objects (or
        Flash
        cookies) to collect
        and store information about your preferences and navigation to, from, and on our Website. Flash
        cookies
        are
        not managed by the same browser settings as are used for browser cookies. For information about
        managing
        your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your
        Information.
      </li>
    </ul>
    <h3>Third-Party Use of Cookies and Other Tracking Technologies.</h3>
    <p>Some content or applications, including advertisements, on the Website may be served by third-parties,
      including advertisers, ad networks and servers, content providers, and application providers. These
      third
      parties may use cookies alone or in conjunction with web beacons or other tracking technologies to
      collect
      information about you when you use our website. The information they collect may be associated with your
      personal information or they may collect information, including personal information, about your online
      activities over time and across different websites and other online services. They may use this
      information
      to provide you with interest-based (behavioral) advertising or other targeted content.</p>

    <p>We do not control these third parties’ tracking technologies or how they may be used. If you have any
      questions about an advertisement or other targeted content, you should contact the responsible provider
      directly. For information about how you can opt out of receiving targeted advertising from many
      providers,
      see Choices About How We Use and Disclose Your Information.</p>
    <h3>How We Use Your Information</h3>
    <p>We use information that we collect about you or that you provide to us, including any personal
      information:</p>
    <ul>
      <li>To present our Website and its contents to you.</li>
      <li>To provide you with information, products, or services that you request from us.</li>
      <li>To fulfill any other purpose for which you provide it.</li>
      <li>To provide you with notices about your account or orders.</li>
      <li>To carry out our obligations and enforce our rights arising from any contracts entered into between
        you
        and
        us, including for billing and collection.
      </li>
      <li>To notify you about changes to our Website or any products or services we offer or provide though
        it.
      </li>
      <li>In any other way we may describe when you provide the information.</li>
      <li>For any other purpose with your consent.</li>
    </ul>

    <p>We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. If you do not want us to use your information in this way email us at support@totalfit.app. For more information, see Choices About How We Use and Disclose Your Information.</p>

    <p>We may use the information we have collected from you to enable us to display advertisements to our
      advertisers’ target audiences. Even though we do not disclose your personal information for these
      purposes
      without your consent, if you click on or otherwise interact with an advertisement, the advertiser may
      assume
      that you meet its target criteria.</p>
    <h3>Disclosure of Your Information</h3>
    <p>We may disclose aggregated information about our users, and information that does not identify any
      individual, without restriction.</p>
    <ul>
      <li>
        We may disclose personal information that we collect or you provide as described in this privacy policy:
      </li>
      <li>
        To our subsidiaries and affiliates, including but not limited to individual gyms and customers.
      </li>
      <li>
        To contractors, service providers, and other third parties we use to support our business.
      </li>
      <li>
        To fulfill the purpose for which you provide it.
      </li>
      <li>
        For any other purpose disclosed by us when you provide the information.
      </li>
      <li>
        With your consent.
      </li>
    </ul>

    <p>
      We may also disclose your personal information:
    </p>

    <ul>
      <li>
        To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
      </li>

      <li>
        To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.
      </li>

      <li>
        If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Totalfit Inc., our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
      </li>

      <li>
        If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Totalfit Inc., our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
      </li>
    </ul>

    <h3>Choices About How We Use and Disclose Your Information</h3>
    <p>We strive to provide you with choices regarding the personal information you provide to us. We have
      created
      mechanisms to provide you with the following control over your information:</p>

    <ul>
      <li><strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or
        some
        browser cookies, or to
        alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit
        the
        Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that
        some
        parts
        of this site may then be inaccessible or not function properly.
      </li>
      <li><strong>Disclosure of Your Information for Third-Party Advertising.</strong> If you do not want us
        to
        share your personal
        information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out
        by
        emailing us at support@totalfit.app.
      </li>
      <li><strong>Promotional Offers from the Company.</strong> If you do not wish to have your contact
        information used by the Company
        to promote our own or third parties’ products or services, you can opt-out by sending us an email
        stating
        your request to support@totalfit.app. If we have sent you a promotional email, you may send us a
        return
        email
        asking to be omitted from future email distributions. This opt out does not apply to information
        provided to
        the Company as a result of a product purchase, warranty registration, product service experience or
        other
        transactions.
      </li>
      <li><strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or
        that
        you provide to us to
        deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by
        emailing us at support@totalfit.app.
      </li>
      <li>We do not control third parties’ collection or use of your information to serve interest-based
        advertising.
        However these third parties may provide you with ways to choose not to have your information
        collected
        or
        used in this way. You can opt out of receiving targeted ads from members of the Network Advertising
        Initiative (“NAI”) on the NAI’s website.
      </li>
    </ul>
    &zwnj;
    <h3>Accessing and Correcting Your Information</h3>

    <p>You can review and change your personal information by logging into the Website and visiting your account
      profile page.</p>
    &zwnj;
    <h3>Your California Privacy Rights</h3>

    <p>California law permits minors under the age of 18 to request the removal of your User Contributions on our Application, subject to certain exceptions. If you are under the age of 18 in California, you may contact us at support@TOTALFIT.app with the subject “California Eraser Law Request.” We may not remove your User Contributions that we are required to retain under any federal or state law, or that have been provided to a third party, including your social media sites. While we will do our best to remove a California minor’s User Contributions upon a valid request, we cannot ensure the complete or comprehensive removal of your User Contributions from our Application or any information that has been republished, copied, downloaded, or reposted by any third party, and we cannot guarantee that any such User Contributions may not be accessible to users of the Internet in the future. We do not advertise or market any of the products or services identified in California Business and Professionals Code Section 22580(i) to users who we have actual knowledge are under 18 years of age.</p>
    &zwnj;

    <h3>
      Consent to Processing of Personal Data In Other Countries Outside the European Economic Area
    </h3>

    <p>
      If you are a resident of the European Economic Area (“EEA”), in order to provide our Application, products, and services to you, we may send and store your Personal Data outside of the EEA, including to the United States. Accordingly, your Personal Data may be transferred outside the country where you reside or are located, including to countries that may not or do not provide an equivalent level of protection for your Personal Data. Your Personal Data may be processed and stored in the United States and United States federal, state, and local governments, courts, or law enforcement or regulatory agencies may be able to obtain disclosure of your information through the laws of the United States. By using our Application, you represent that you have read and understood the above and hereby consent to the storage and processing of Personal Data outside the country where you reside or are located, including in the United States.
    </p>

    <p>
      Your Personal Data is transferred by TOTALFIT to another country only if it is required or permitted under applicable data protection law and provided that there are appropriate safeguards in place to protect your Personal Data. To ensure your Personal Data is treated in accordance with this Privacy Notice when we transfer it to a third party, TOTALFIT uses Data Protection Agreements between TOTALFIT and all other recipients of your data that include, where applicable, the Standard Contractual Clauses adopted by the European Commission (the “Standard Contractual Clauses”). The European Commission has determined that the transfer of Personal Data pursuant to the Standard Contractual Clauses provides for an adequate level of protection of your Personal Data. Under these Standard Contractual Clauses, you have the same rights as if your data was not transferred to such third country. You may request a copy of the Data Protection Agreement by contacting us through the Contact Information below.
    </p>

    <h3>
      Data Retention Periods
    </h3>

    <p>
      TOTALFIT will retain your Personal Data for the entire time that you keep your account open. After you close your account, we may retain your Personal Data:
    </p>

    <ul>
      <li>for as long as necessary to comply with any legal requirement;</li>
      <li>on our backup and disaster recovery systems in accordance with our backup and disaster recovery policies and procedures;</li>
      <li>for as long as necessary to protect our legal interests or otherwise pursue our legal rights and remedies; and</li>
      <li>for data that has been aggregated or otherwise rendered anonymous in such a manner that you are no longer identifiable, indefinitely.</li>
    </ul>


    <h3>Data Security</h3>
    <p>We have implemented measures designed to secure your personal information from accidental loss and from
      unauthorized access, use, alteration, and disclosure but the safety and security of your information
      also
      depends on you. Where we have given you (or where you have chosen) a password for access to certain
      parts of
      our Website, you are responsible for keeping this password confidential. We ask you not to share your
      password with anyone.</p>

    <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do
      our
      best to protect your personal information, we cannot guarantee the security of your personal information
      transmitted to our Website. Any transmission of personal information is at your own risk. We are not
      responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
    <h3>Changes to Our Privacy Policy</h3>
    <p>We may change this Privacy Notice at any time. It is our policy to post any changes we make to our Privacy Notice on this page with a notice that the Privacy Notice has been updated on the Application’ home screen. If we make material changes to how we treat our users’ Personal Data, we will notify you by email to the email address specified in your account and/or through a notice on the Application’ home screen. The date this Privacy Notice was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Application and this Privacy Notice to check for any changes.</p>
    <h3>Contact Information</h3>
    <p>If you have any questions, concerns, complaints or suggestions regarding our Privacy Notice, have any requests related to your Personal Data pursuant to applicable laws, or otherwise need to contact us, you must contact both us and our representative in the European Union at the contact information below or through the “Contact” page on our Application.</p>

    <p class="small">To Contact TOTALFIT (Controller) or Our Representative</p>
    <p class="small">TOTALFIT</p>
    <p class="small">9070 Glennloch Forest Dr</p>
    <p class="small">Spring, TX 77379</p>
    <p class="small">support@TOTALFIT.app</p>
  </div>
</div>
