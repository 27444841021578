import { createAction, props } from '@ngrx/store';
import { PaginationModel } from '@shared/models/pagination.model';
import {
  AdminWorkoutView,
  CustomWorkoutView,
  OnlineExercise,
  OnlineWorkout,
  WorkoutView
} from '@shared/models/workout.model';
import { BundleStatus, TemplateWorkoutBundle, UserComplexOfflineWorkouts, WorkoutBundle } from '@app/store/workout/workout.model';
import { ScrollConfig } from '@shared/models/scroll-pagination.model';
import { WorkoutOSLimited } from '@store/offline-workout/offline-workout.model';

export const GET_WORKOUT = '[WORKOUTS] GET_WORKOUT';
export const GET_WORKOUT_SUCCESS = '[WORKOUTS] GET_WORKOUT_SUCCESS';
export const SET_WORKOUT_IS_LOADING = '[WORKOUTS] SET_WORKOUT_IS_LOADING';

export const GET_ALL_PROGRAMS = '[WORKOUTS] GET_ALL_PROGRAMS';
export const GET_ALL_SKILLS = '[WORKOUTS] GET_ALL_SKILLS';
export const GET_ALL_HABITS = '[WORKOUTS] GET_ALL_HABITS';
export const GET_ALL_TEMPLATE_EXERCISES = '[WORKOUTS] GET_ALL_TEMPLATE_EXERCISES';

export const GET_WORKOUTS = '[WORKOUTS] GET_WORKOUTS';
export const GET_WORKOUTS_SUCCESS = '[WORKOUTS] GET_WORKOUTS_SUCCESS';

export const GET_CUSTOM_WORKOUT_LIST = '[WORKOUTS] GET_CUSTOM_WORKOUT_LIST';
export const GET_CUSTOM_WORKOUT_LIST_SUCCESS = '[WORKOUTS] GET_CUSTOM_WORKOUT_LIST_SUCCESS';

export const GET_OFFLINE_WORKOUT_LIST = '[WORKOUTS] GET_OFFLINE_WORKOUT_LIST';
export const GET_OFFLINE_WORKOUT_LIST_SUCCESS = '[WORKOUTS] GET_OFFLINE_WORKOUT_LIST_SUCCESS';

export const CREATE_CUSTOM_WORKOUT = '[WORKOUTS] CREATE_CUSTOM_WORKOUT';
export const CREATE_CUSTOM_WORKOUT_SUCCESS = '[WORKOUTS] CREATE_CUSTOM_WORKOUT_SUCCESS';

export const GET_CUSTOM_WORKOUT_BY_ID = '[WORKOUTS] GET_CUSTOM_WORKOUT_BY_ID';
export const EDIT_CUSTOM_WORKOUT = '[WORKOUTS] EDIT_CUSTOM_WORKOUT';

export const CHANGE_ADMIN_WORKOUTS_ORDER = '[WORKOUTS] CHANGE_ADMIN_WORKOUTS_ORDER';
export const CHANGE_ADMIN_WORKOUTS_ORDER_SUCCESS = '[WORKOUTS] CHANGE_ADMIN_WORKOUTS_ORDER_SUCCESS';
export const GET_ADMIN_WORKOUTS = '[WORKOUTS] GET_ADMIN_WORKOUTS';
export const GET_ADMIN_WORKOUTS_SUCCESS = '[WORKOUTS] GET_ADMIN_WORKOUTS_SUCCESS';
export const SET_CURRENT_CUSTOM_WORKOUT = '[WORKOUTS] SET_CURRENT_CUSTOM_WORKOUT';
export const RESET_CURRENT_CUSTOM_WORKOUT = '[WORKOUTS] RESET_CURRENT_CUSTOM_WORKOUT';

export const GET_BUNDLE = '[WORKOUTS] GET_BUNDLE';
export const GET_BUNDLE_SUCCESS = '[WORKOUTS] GET_BUNDLE_SUCCESS';
export const GET_USER_BUNDLES = '[WORKOUTS] GET_USER_BUNDLES';
export const GET_USER_BUNDLES_SUCCESS = '[WORKOUTS] GET_USER_BUNDLES_SUCCESS';
export const REMOVE_USER_BUNDLES = '[WORKOUTS] REMOVE_USER_BUNDLES';
export const CHANGE_BUNDLE_STATUS = '[WORKOUTS] CHANGE_BUNDEL_STATUS';
export const EDIT_USER_BUNDLES = '[WORKOUTS] EDIT_USER_BUNDLES';
export const SET_BUNDLE_WORKOUT = '[WORKOUTS] SET_BUNDLE_WORKOUT';

export const SELECT_LAST_WORKOUT = '[WORKOUTS] SELECT_LAST_WORKOUT';
export const REMOVE_LAST_WORKOUT = '[WORKOUTS] REMOVE_LAST_WORKOUT';
export const CREATE_NEW_FOLDER = '[WORKOUTS] CREATE_NEW_FOLDER';
export const DUPLICATE_NEW_FOLDER = '[WORKOUTS] DUPLICATE_NEW_FOLDER';
export const SET_USER_BUNDLE = '[WORKOUTS] SET_USER_BUNDLE';
export const SET_USER_SHOP_BUNDLE = '[WORKOUTS] SET_USER_SHOP_BUNDLE';

export const GET_USER_BUNDLE_BY_ID = '[WORKOUTS] GET_USER_BUNDLE_BY_ID';
export const GET_USER_BUNDLE_BY_ID_SUCCESS = '[WORKOUTS] GET_USER_BUNDLE_BY_ID_SUCCESS';
export const MOVE_WORKOUT_TO_ROOT = '[WORKOUTS] MOVE_WORKOUT_TO_ROOT';
export const MOVE_WORKOUT_TO_BUNDLE = '[WORKOUTS] MOVE_WORKOUT_TO_BUNDLE';

export const GET_ALL_ONLINE_EXERCISE = '[WORKOUTS] GET_ALL_ONLINE_EXERCISE';
export const GET_ALL_ONLINE_EXERCISE_SUCCESS = '[WORKOUTS] GET_ALL_ONLINE_EXERCISE_SUCCESS';


export const SET_ALL_TEMPLATES_PAGINATION = '[WORKOUTS] SET_ALL_TEMPLATES_PAGINATION';

export const GET_ALL_TEMPLATES = '[WORKOUTS] GET_ALL_TEMPLATES';
export const GET_ALL_TEMPLATES_SUCCESS = '[WORKOUTS] GET_ALL_TEMPLATES_SUCCESS';

export const CREATE_TEMPLATE = '[WORKOUTS] CREATE_TEMPLATE';
export const CREATE_TEMPLATE_SUCCESS = '[WORKOUTS] CREATE_TEMPLATE_SUCCESS';

export const GET_TEMPLATE_BY_ID = '[WORKOUTS] GET_TEMPLATE_BY_ID';
export const GET_TEMPLATE_BY_ID_SUCCESS = '[WORKOUTS] GET_TEMPLATE_BY_ID_SUCCESS';

export const EDIT_TEMPLATE_BY_ID = '[WORKOUTS] EDIT_TEMPLATE_BY_ID';
export const EDIT_TEMPLATE_BY_ID_SUCCESS = '[WORKOUTS] EDIT_TEMPLATE_BY_ID_SUCCESS';

export const MOVE_TEMPLATE_TO_BUNDLE = '[WORKOUTS] MOVE_TEMPLATE_TO_BUNDLE';

export const REMOVE_TEMPLATE_BY_ID = '[WORKOUTS] REMOVE_TEMPLATE_BY_ID';
export const REMOVE_TEMPLATE_BY_ID_SUCCESS = '[WORKOUTS] REMOVE_TEMPLATE_BY_ID_SUCCESS';

export const GET_ALL_TEMPLATES_ADMIN = '[WORKOUTS] GET_ALL_TEMPLATES_ADMIN';
export const GET_ALL_TEMPLATES_ADMIN_SUCCESS = '[WORKOUTS] GET_ALL_TEMPLATES_ADMIN_SUCCESS';

////////////
export const GET_ALL_TEMPLATES_BUNDLE_ADMIN = '[WORKOUTS] GET_ALL_TEMPLATES_BUNDLE_ADMIN';
export const GET_ALL_TEMPLATES_BUNDLE_ADMIN_SUCCESS = '[WORKOUTS] GET_ALL_TEMPLATES_BUNDLE_ADMIN_SUCCESS';

export const GET_WORKOUT_BUNDLE_TEMPLATE_BY_ID = '[WORKOUTS] GET_WORKOUT_BUNDLE_TEMPLATE_BY_ID';
export const GET_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS = '[WORKOUTS] GET_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS';

export const CREATE_WORKOUT_BUNDLE_TEMPLATE = '[WORKOUTS] CREATE_WORKOUT_BUNDLE_TEMPLATE';
export const CREATE_WORKOUT_BUNDLE_TEMPLATE_SUCCESS = '[WORKOUTS] CREATE_WORKOUT_BUNDLE_TEMPLATE_SUCCESS';

export const EDIT_WORKOUT_BUNDLE_TEMPLATE_BY_ID = '[WORKOUTS] EDIT_WORKOUT_BUNDLE_TEMPLATE_BY_ID';
export const EDIT_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS = '[WORKOUTS] EDIT_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS';

export const REMOVE_WORKOUT_BUNDLE_TEMPLATE_BY_ID = '[WORKOUTS] REMOVE_WORKOUT_BUNDLE_TEMPLATE_BY_ID';
export const REMOVE_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS = '[WORKOUTS] REMOVE_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS';

export const SET_TEMPLATE_FOR_PREVIEW = '[WORKOUTS] SET_TEMPLATE_FOR_PREVIEW';

export const GET_DROP_DOWN_LIST = '[WORKOUTS] GET DROP DOWN LIST';
export const GET_DROP_DOWN_LIST_SUCCESS = '[WORKOUTS] GET DROP DOWN LIST SUCCESS';

export const GET_WOD_BY_SEARCH = '[WORKOUTS] GET_WOD_BY_SEARCH';
export const GET_WOD_BY_SEARCH_SUCCESS = '[WORKOUTS] GET_WOD_BY_SEARCH_SUCCESS';


export const getDropDownList = createAction(
  GET_DROP_DOWN_LIST
);

export const getDropDownListSuccess = createAction(
  GET_DROP_DOWN_LIST_SUCCESS,
  props<{ list: UserComplexOfflineWorkouts }>()
);

export const setTemplateForPreview = createAction(
  SET_TEMPLATE_FOR_PREVIEW,
  props<{ template: AdminWorkoutView }>()
);

export const getAllTemplatesBundleAdmin = createAction(
  GET_ALL_TEMPLATES_BUNDLE_ADMIN,
  props<{ pageSize: number, pageNumber: number }>()
);

export const getAllTemplatesBundleAdminSuccess = createAction(
  GET_ALL_TEMPLATES_BUNDLE_ADMIN_SUCCESS,
  props<{ templateWorkoutBundleList: PaginationModel<TemplateWorkoutBundle> }>()
);

///////

export const getWorkoutBundleTemplateById = createAction(
  GET_WORKOUT_BUNDLE_TEMPLATE_BY_ID,
  props<{ id: string }>()
);

export const getWorkoutBundleTemplateByIdSuccess = createAction(
  GET_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS,
  props<{ templateWorkoutBundle: TemplateWorkoutBundle }>()
);

////////

export const createWorkoutBundleTemplate = createAction(
  CREATE_WORKOUT_BUNDLE_TEMPLATE,
  props<{ templateWorkoutBundle: Partial<TemplateWorkoutBundle> }>()
);

export const createWorkoutBundleTemplateSuccess = createAction(
  CREATE_WORKOUT_BUNDLE_TEMPLATE_SUCCESS,
  props<{ templateWorkoutBundle: TemplateWorkoutBundle }>()
);

////

export const editWorkoutBundleTemplate = createAction(
  EDIT_WORKOUT_BUNDLE_TEMPLATE_BY_ID,
  props<{ templateWorkoutBundle: TemplateWorkoutBundle }>()
);

export const editWorkoutBundleTemplateSuccess = createAction(
  EDIT_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS,
  props<{ templateWorkoutBundle: TemplateWorkoutBundle }>()
);

////

export const removeWorkoutBundleTemplateById = createAction(
  REMOVE_WORKOUT_BUNDLE_TEMPLATE_BY_ID,
  props<{ id: string }>()
);

export const removeWorkoutBundleTemplateByIdSuccess = createAction(
  REMOVE_WORKOUT_BUNDLE_TEMPLATE_BY_ID_SUCCESS,
  props<{ id: string }>()
);

////

export const getAllTemplates = createAction(
  GET_ALL_TEMPLATES,
  props<{ pageSize: number, pageNumber: number }>()
);

export const getAllTemplatesSuccess = createAction(
  GET_ALL_TEMPLATES_SUCCESS,
  props<{ workoutList: PaginationModel<AdminWorkoutView> }>()
);

export const setAllTemplatesPagination = createAction(
  SET_ALL_TEMPLATES_PAGINATION,
  props<{ pagination: ScrollConfig }>()
);

export const getAllTemplatesAdmin = createAction(
  GET_ALL_TEMPLATES_ADMIN,
  props<{ pageSize: number, pageNumber: number }>()
);

export const getAllTemplatesAdminSuccess = createAction(
  GET_ALL_TEMPLATES_ADMIN_SUCCESS,
  props<{ workoutList: PaginationModel<AdminWorkoutView> }>()
);

export const getTemplateById = createAction(
  GET_TEMPLATE_BY_ID,
  props<{ id: string }>()
);

export const getTemplateByIdSuccess = createAction(
  GET_TEMPLATE_BY_ID_SUCCESS,
  props<{ adminOfflineWorkout: AdminWorkoutView }>()
);

export const createTemplate = createAction(
  CREATE_TEMPLATE,
  props<{ workout: AdminWorkoutView }>()
);

export const createTemplatesSuccess = createAction(
  CREATE_TEMPLATE_SUCCESS,
  props<{ workout: AdminWorkoutView }>()
);

export const editTemplateById = createAction(
  EDIT_TEMPLATE_BY_ID,
  props<{ workout: AdminWorkoutView }>()
);

export const moveTemplateToBundle = createAction(
  MOVE_TEMPLATE_TO_BUNDLE,
  props<{ workout: AdminWorkoutView, isBundle?: boolean }>()
);

export const editTemplateByIdSuccess = createAction(
  EDIT_TEMPLATE_BY_ID_SUCCESS,
  props<{ workout: AdminWorkoutView }>()
);

export const removeTemplateById = createAction(
  REMOVE_TEMPLATE_BY_ID,
  props<{ id: string, isBundle?: boolean }>()
);

export const removeTemplateByIdSuccess = createAction(
  REMOVE_TEMPLATE_BY_ID_SUCCESS,
  props<{ id: string, isBundle?: boolean }>()
);


export const setBundleWorkout = createAction(
  SET_BUNDLE_WORKOUT,
  props<{ workout: WorkoutView }>()
);

export const setWorkoutIsLoading = createAction(
  SET_WORKOUT_IS_LOADING,
  props<{ workoutIsLoading: boolean }>()
);

export const getWorkout = createAction(
  GET_WORKOUT,
  props<{ id: string, bundleId?: string }>()
);

export const getWorkoutSuccess = createAction(
  GET_WORKOUT_SUCCESS,
  props<{ offlineWorkout: WorkoutView }>()
);

export const getOfflineWorkoutList = createAction(
  GET_OFFLINE_WORKOUT_LIST,
  props<{ pageSize?: number, pageNumber?: number }>()
);

export const getOfflineWorkoutListSuccess = createAction(
  GET_OFFLINE_WORKOUT_LIST_SUCCESS,
  props<{ workouts: PaginationModel<WorkoutView> }>()
);

export const getCustomWorkoutList = createAction(
  GET_CUSTOM_WORKOUT_LIST,
  props<{ pageSize: number, pageNumber: number }>()
);

export const getAllOnlineExercise  = createAction(
  GET_ALL_ONLINE_EXERCISE,
  props<{ pageSize: number, pageNumber: number }>()
);

export const getAllOnlineExerciseSuccess  = createAction(
  GET_ALL_ONLINE_EXERCISE_SUCCESS,
  props<{ exercise: PaginationModel<OnlineExercise> }>()
);

export const getCustomWorkoutListSuccess = createAction(
  GET_CUSTOM_WORKOUT_LIST_SUCCESS,
  props<{workouts: PaginationModel<CustomWorkoutView>}>()
);

export const getAdminWorkouts = createAction(
  GET_ADMIN_WORKOUTS,
  props<{ pageSize: number, pageNumber: number; tabFilter: string }>()
);

export const changeAdminWorkoutOrders = createAction(
  CHANGE_ADMIN_WORKOUTS_ORDER,
  props<{ workouts: OnlineWorkout[] }>()
);

export const changeAdminWorkoutOrdersSuccess = createAction(
  CHANGE_ADMIN_WORKOUTS_ORDER_SUCCESS,
  props<{ updateState: boolean }>()
);

export const getAdminWorkoutsSuccess = createAction(
  GET_ADMIN_WORKOUTS_SUCCESS,
  props<{adminWorkouts: PaginationModel<OnlineWorkout>}>()
);

export const getBundles = createAction(
  GET_BUNDLE,
  props<{ pageSize: number, pageNumber: number }>()
);

export const getBundlesSuccess = createAction(
  GET_BUNDLE_SUCCESS,
  props<{bundles: PaginationModel<WorkoutBundle>}>()
);

export const getUserBundles = createAction(
  GET_USER_BUNDLES,
  props<{ userEmail: string }>()
);

export const removeUserBundles = createAction(
  REMOVE_USER_BUNDLES,
  props<{ id: string }>()
);

export const changeBundlesActivity = createAction(
  CHANGE_BUNDLE_STATUS,
  props<{ id: string, status: BundleStatus, userEmail?: string }>()
);

export const editUserBundles = createAction(
  EDIT_USER_BUNDLES,
  props<{ bundle: WorkoutBundle }>()
);

export const getUserBundlesSuccess = createAction(
  GET_USER_BUNDLES_SUCCESS,
  props<{ bundles: any }>() // PaginationModel<WorkoutBundle>
);

export const selectLastWorkout = createAction(
  SELECT_LAST_WORKOUT,
  props<{ workout: WorkoutView }>()
);

export const setUserBundle = createAction(
  SET_USER_BUNDLE,
  props<{ bundle: WorkoutBundle }>()
);

export const getUserBundleById = createAction(
  GET_USER_BUNDLE_BY_ID,
  props<{ id: string }>()
);

export const getUserBundleByIdSuccess = createAction(
  GET_USER_BUNDLE_BY_ID_SUCCESS,
  props<{ bundle: WorkoutBundle }>()
);

export const setUserShopBundle = createAction(
  SET_USER_SHOP_BUNDLE,
  props<{ bundle: WorkoutBundle }>()
);

export const removeLastWorkout = createAction(
  REMOVE_LAST_WORKOUT
);

export const createNewFolder = createAction(
  CREATE_NEW_FOLDER,
  props<{ bundle: Partial<WorkoutBundle>, userEmail: string }>()
);

export const duplicateFolder = createAction(
  DUPLICATE_NEW_FOLDER,
  props<{ bundle: WorkoutBundle }>()
);

export const moveWorkoutToRoot = createAction(
  MOVE_WORKOUT_TO_ROOT,
  props<{ workout: WorkoutView; bundleId: string; }>()
);

export const moveWorkoutToBundle = createAction(
  MOVE_WORKOUT_TO_BUNDLE,
  props<{ workout: WorkoutView; bundleId: string; bundleForSaveId: string; }>()
);

export const createCustomWorkout = createAction(
  CREATE_CUSTOM_WORKOUT,
  props<{ workout: CustomWorkoutView; }>()
);

export const editCustomWorkout = createAction(
  EDIT_CUSTOM_WORKOUT,
  props<{ workout: CustomWorkoutView; }>()
);

export const getCustomWorkoutById = createAction(
  GET_CUSTOM_WORKOUT_BY_ID,
  props<{ id: string; }>()
);

export const setCurrentCustomWorkout = createAction(
  SET_CURRENT_CUSTOM_WORKOUT,
  props<{ customWorkout: CustomWorkoutView; }>()
);

export const resetCurrentCustomWorkout = createAction(
  RESET_CURRENT_CUSTOM_WORKOUT
);
export const getWodBySearch = createAction(
  GET_WOD_BY_SEARCH,
  props<{ workoutOSLimited: WorkoutOSLimited; }>()
);

export const getWodBySearchSuccess = createAction(
  GET_WOD_BY_SEARCH_SUCCESS,
  props<{ workoutView: WorkoutView; }>()
);
