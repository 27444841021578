import { Pipe, PipeTransform } from '@angular/core';
import { TwelveHourFormat } from '../utils/twelveHourFormat';

@Pipe({
  name: 'militaryTimeToAmPm'
})
export class MilitaryTimeToAmPmPipe implements PipeTransform {
  public transform(value: string): any {
    const timeValue = TwelveHourFormat(value);

    return `${timeValue.hours}:${timeValue.minutes} ${timeValue.suffix}`;
  }
}
