import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromExerciseActions from '@app/store/exercise/exercise.actions';
import { map, switchMap } from 'rxjs/operators';
import { ExerciseService } from '@shared/services/exercise.service';

@Injectable()
export class ExerciseEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly exerciseService: ExerciseService
  ) {}

  public loadStoryList = createEffect(() => this.actions$.pipe(
    ofType(fromExerciseActions.getExerciseTags),
    switchMap(({ text, limit }) => {
      return this.exerciseService.getExerciseTabs(text, limit)
        .pipe(
          map((tabs) => {
            return fromExerciseActions.getExerciseTagsSuccess({ tabs });
          })
        );
    })
  ));
}
