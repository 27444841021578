import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationModel } from '@shared/models/pagination.model';
import { OnlineWorkout } from '@shared/models/workout.model';
import { environment } from '@environments/environment';
import { setFilterQuery } from '@shared/utils/utils';
import { BundleStatus, WorkoutBundle } from '@app/store/workout/workout.model';

@Injectable({
  providedIn: 'root'
})
export class AdminWorkoutsListService {
  constructor(private http: HttpClient) {}

  public getAdminWorkoutList(pageSize: number, pageNumber: number): Observable<PaginationModel<OnlineWorkout>> {
    return this.http
      .get<PaginationModel<OnlineWorkout>>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin?size=${pageSize}&page=${pageNumber}&status=ACTIVE,INACTIVE`);
  }

  public getAllAdminWorkoutList(): Observable<Array<{ theme: string; id: string }>> {
    return this.http
      .get<Array<{ theme: string; id: string }>>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin/all`);
  }

  public getOnlineWorkout(id: string): Observable<OnlineWorkout> {
    return this.http.get<OnlineWorkout>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/${id}`);
  }

  public deleteOnlineWorkout(id: string): Observable<OnlineWorkout> {
    return this.http.delete<OnlineWorkout>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin/${id}`);
  }

  public editOnlineWorkout(value: OnlineWorkout, id: string): Observable<OnlineWorkout> {
    return this.http.put<OnlineWorkout>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin/${id}`, value);
  }

  public changeOnlineWorkoutIndex(workouts): Observable<unknown> {
    return this.http.post<unknown>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin/change-order`, workouts);
  }

  public createOnlineWorkout(value: OnlineWorkout): Observable<OnlineWorkout> {
    return this.http.post<OnlineWorkout>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin`, value);
  }

  public getAdminWorkoutsCriteria(
    pageSize: number,
    pageNumber: number,
    filterObject?: any
  ): Observable<PaginationModel<OnlineWorkout>> {
    const filterQuery = filterObject ? setFilterQuery(filterObject) : null;

    return this.http
      .get<PaginationModel<OnlineWorkout>>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin?size=${pageSize}&page=${pageNumber}${filterQuery ? filterQuery : ''}`);
  }

  public searchAdminWorkouts(prefix: string, pageSize: number, pageNumber: number): Observable<PaginationModel<OnlineWorkout>> {
    return this.http
      .get<PaginationModel<OnlineWorkout>>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/admin/search?size=${pageSize}&page=${pageNumber}&prefix=${prefix}` );
  }

  public getWorkoutBundles(
    pageSize: number,
    pageNumber: number
  ): Observable<PaginationModel<WorkoutBundle>> {
    return this.http
      .get<PaginationModel<WorkoutBundle>>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles?size=${pageSize}&page=${pageNumber}`);
  }

  public createWorkout(bundle: Partial<WorkoutBundle>): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles`, bundle);
  }

  public getUserBundles(userEmail: string): any { // Observable<PaginationModel<WorkoutBundle>>
    return this.http
      .get<PaginationModel<WorkoutBundle>>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/user`,
        { params: { userEmail, status: 'ACTIVE' } }
      );
  }

  public getUserBundleById(id: string): Observable<WorkoutBundle> { // Observable<PaginationModel<WorkoutBundle>>
    return this.http
      .get<WorkoutBundle>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/${id}`
      );
  }

  public removeUserBundles(id: string): Observable<number> {
    return this.http
      .delete<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/${id}`);
  }

  public editUserBundles(bundle: WorkoutBundle): Observable<number> {
    return this.http
      .put<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/${bundle.id}`, bundle);
  }

  public changeBundleStatus(id: string, status: BundleStatus): Observable<number> {
    return this.http
      .post<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/change-status/${id}`, null, { params: { status } });
  }

  public createNewFolder(bundle: Partial<WorkoutBundle>): Observable<WorkoutBundle> {
    return this.http.post<WorkoutBundle>(
      `${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles`,
      {...bundle}
    );
  }
}
