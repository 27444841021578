import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogService {
  private privateDialog: MatDialogRef<any> = null;
  private dialogUnsubscriber$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.closeOpenedDialog());
  }

  public openConfirmDialog(data: Partial<ConfirmDialogModel> = {}, matDialogConfig?: MatDialogConfig): Observable<boolean> {
    return this.openDialog<Partial<ConfirmDialogModel>, boolean>(ConfirmDialogComponent, {
      applyButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      applyButtonColorPallet: 'primary',
      ...data
    }, matDialogConfig);
  }

  public openDialog<T = unknown, J = unknown>(dialogClass: any, data?: T, matDialogConfig?: MatDialogConfig): Observable<J> {
    if (this.openedDialog) {
      if (this.openedDialog.componentInstance instanceof dialogClass) {
        return EMPTY;
      } else {
        this.openedDialog.close();
        this.dialogUnsubscriber$.next();
      }
    }

    this.setOpenedDialog(this.dialog.open(dialogClass, {data, ...matDialogConfig}));

    return this.openedDialog
      .afterClosed()
      .pipe(
        takeUntil(this.dialogUnsubscriber$),
        filter((response) => !(response === undefined || (typeof response === 'string' && response.length === 0))),
        finalize(() => this.setOpenedDialog(null))
      );
  }

  public get openedDialog(): MatDialogRef<any> {
    return this.privateDialog;
  }

  public closeOpenedDialog(): void {
    if (this.openedDialog) {
      this.openedDialog.close();
    }
  }

  private setOpenedDialog(dialog: MatDialogRef<any>) {
    this.privateDialog = dialog;
  }
}
