import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DocumentService } from '@shared/services/document.service';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PaginationModel } from '@shared/models/pagination.model';
import { switchMap } from 'rxjs/operators';
import { LoadingPageComponent } from '@shared/models/loading-page.component';
import { Document } from '@app/people/_shared/document.model';
import { SnackbarService } from '@shared/services/snackbar.service';
import { Certificates } from '@shared/models/web-profile.model';
import { AdminUsersService } from '@app/admin-users/_shared/admin-users.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent extends LoadingPageComponent implements OnInit {
  @Input() public isEditMode: boolean;
  @Input() public documents: PaginationModel<Document>;
  @Input() public documentsSaveEntityId: string;
  @Input() public emptyText: string;

  @Input() public certificates: Certificates[];

  @Input() public saveFunc: (id: string) => void;
  @Input() public deleteFunc: (id: string) => void;
  @Input() public getFunc: (id: string) => void;
  public documentForm: UntypedFormArray;

  constructor(
    public documentService: DocumentService,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private snackbarService: SnackbarService,
    private adminUsersService: AdminUsersService,
  ) {
    super(changeDetectorRef);
  }

  public ngOnInit() {
    this.documentForm = this.formBuilder.array([]);
    this.initForm(this.documents ? this.documents.objects : this.certificates);

    this.documentForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  public getDocumentForm(value?: Document): UntypedFormGroup {
    return this.formBuilder.group({
      id: [value.id || null],
      fileName: [value.fileName || null],
      ext: [value.ext || null],
      link: [value.link || null],
      placeholder: [value.placeholder || null]
    });
  }

  public getCertificateForm(value?: Certificates): UntypedFormGroup {
    return this.formBuilder.group({
      id: [value.id || null],
      name: [value.name || null],
      link: [value.link || null],
      userId: [value.userId || null]
    });
  }

  public deleteDoc({ id }: Document, index: number): void {
    this.documentService.deleteDoc(id)
      .pipe(switchMap(() => this.documentService.getUserDocs(this.documentsSaveEntityId)))
      .subscribe(() => {
        this.snackbarService.openSnackbar('Your document has been deleted');
        this.documentForm.removeAt(index);
      });
  }

  public updateDoc(formControl: AbstractControl): void {
    this.documentService.updateDoc(this.deleteDocPlaceholder(formControl.value))
      .pipe(switchMap(() => this.documentService.getUserDocs(this.documentsSaveEntityId)))
      .subscribe(() => this.snackbarService.openSnackbar('Your document has been saved'));
  }

  public addNewDocument(doc: Document): void {
    this.documentService.saveDoc(this.deleteDocPlaceholder({...doc, peopleId: doc.peopleId ? doc.peopleId : this.documentsSaveEntityId}))
      .pipe(switchMap(() => this.documentService.getUserDocs(this.documentsSaveEntityId)))
      .subscribe((data) => this.initForm(data.objects));
  }

  public addNewCertificate(doc: Certificates): void {
    const saveEntity: Certificates = {
      link: doc.link,
      name: doc.fileName,
      userId: this.documentsSaveEntityId
    };

    this.adminUsersService.saveDoc(saveEntity)
      .pipe(switchMap(() => this.adminUsersService.getAdminUserCertificates(this.documentsSaveEntityId)))
      .subscribe((data) => this.initForm(data));
  }

  public deleteCertificate(doc: Certificates, index: number): void {
    this.adminUsersService.removeDoc(doc)
      .pipe(switchMap(() => this.adminUsersService.getAdminUserCertificates(this.documentsSaveEntityId)))
      .subscribe(() => {
        this.snackbarService.openSnackbar('Your document has been deleted');
        this.documentForm.removeAt(index);
      });
  }

  private deleteDocPlaceholder(doc: Document): Document {
    if (doc.placeholder) {
      delete doc.placeholder;
    }

    return doc;
  }

  private initForm(documents: Document[] | Certificates[]) {
    this.documentForm.clear();

    documents.forEach((doc) => {
      if (this.documents) {
        this.documentForm.push(this.getDocumentForm(this.mapDocumentPlaceholder(doc)));
      } else {
        this.documentForm.push(this.getCertificateForm(doc));
      }
    });
  }

  private mapDocumentPlaceholder(doc: Document): Document {
    return ['jpeg', 'jpg', 'png', 'gif', 'bmp'].includes(doc.ext) ? doc : {...doc, placeholder: `assets/img/file-placeholders/${doc.ext}.svg`};
  }
}
