import { AppUiStateModel } from '@app/store/app-ui-state/app-ui-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromAppUIActions from '@app/store/app-ui-state/app-ui-state.actions';
import { deleteObjectProp } from '@shared/utils/utils';

export const initialAppState: AppUiStateModel = {
  view: '',
  adminWorkoutFilter: null,
  adminTrainingFilter: null,
  adminUsersFilter: null,
  tribeMemberFilter: null
};

export const appReducer = createReducer(
  initialAppState,
  on(fromAppUIActions.appRemoveTableFilter, (state, { propsName, objectKeys }) => {
    const newPropsValue = {};

    if (state[propsName] && state[propsName].filter) {
      Object.keys(state[propsName].filter).forEach((key) => {
        if (!objectKeys.includes(key)) {
          newPropsValue[key] = state[propsName].filter[key];
        }
      });
    }

    const newState = {
      ...state,
      [propsName]: Object.keys(newPropsValue).length
        ? newPropsValue
        : state[propsName] && state[propsName].tableSearch ? { tableSearch: state[propsName].tableSearch } : null
    };

    return {
      ...newState
    };
  }),

  on(fromAppUIActions.appSetTableFilter, (state, { filter, propsName }) => {
    const filterObject = setFilterObject(state, filter, propsName);

    return {
      ...state,
      [propsName]: Object.keys(filterObject).length
        ? {...state[propsName], value: filterObject}
        : state[propsName].tableSearch ? { tableSearch : state[propsName].tableSearch} : null
    };
  }),

  on(fromAppUIActions.appSetTableSearch, (state, { tableSearch, propsName }) => {
    return {
      ...state,
      [propsName]: tableSearch.searchKey ? {...state[propsName], tableSearch} : null
    };
  }),

  on(fromAppUIActions.appRemoveTableSearch, (state, { propsName }) => {
    return {
      ...state,
      [propsName]: {...state[propsName], tableSearch: null}
    };
  }),

  on(fromAppUIActions.appRemoveWholeTable, (state, { propsName }) => {
    const newState = {
      ...state
    };
    delete newState[propsName];

    return {
      ...newState
    };
  })
);

export function reducer(state: AppUiStateModel | undefined, action: Action) {
  return appReducer(state, action);
}

function setFilterObject(state: AppUiStateModel, filter: { [x: string]: any; }, propsName) {
  let statePart = state[propsName] && state[propsName].filter ? { ...state[propsName].filter } : {};

  const key = Object.keys(filter)[0];

  if (filter && filter[key].length) {
    statePart = {
      ...statePart,
      [key]: filter[key]
    };
  } else {
    deleteObjectProp(statePart, key);
  }

  return statePart;
}
