import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[totalfitPricing]'
})
export class PricingDirective {
  @HostListener('input', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    const targetInput = event.target as HTMLInputElement;
    const trimmedValue = targetInput.value.replace(/\s+/g, '');
    targetInput.value = trimmedValue.replace(/,/g, '.').replace(/\.(\d{2})\d+/, '.$1');
  }
}
