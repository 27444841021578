<div class="mat-typography form-group form-group_control-prefix form-group_full-width" [totalfitDropdownToggle]="select">
  <input class="form-group__control mat-typography"
         placeholder="Search movements, workouts, etc."
         [formControl]="searchControl"
         id="workout"
         type="text">

  <div class="form-group__prefix">
    <i class="icon-search"></i>
  </div>
</div>


<ng-template #select>
  <div class="workout-select mat-typography" *ngIf="{
    isLoading: isLoading$ | async,
    isBundleLoading: isBundleLoading$ | async,
    selectedBundle: selectedBundle$ | async,
    selectedSearchBundle: selectedSearchBundle$ | async,
    exerciseTabs: exerciseTabs$ | async,
    isLoadingExerciseTabs: isLoadingExerciseTabs$ | async,
    searchedBundles: searchedBundles$ | async} as uiState"
  >
    <ng-container *ngIf="searchControl.value">
      <ul>
        <ng-container *ngIf="!uiState.isLoadingExerciseTabs && uiState.exerciseTabs && searchControl.value.length >= 3">
          <li *ngIf="uiState.exerciseTabs.length" class="folder tabs workout-select__item">
            <div class="tabs__item" *ngFor="let exerciseTab of uiState.exerciseTabs">
              {{ exerciseTab.name }}
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="(!uiState.isLoading && !uiState.isBundleLoading) && (searchControl.value.length !== 0 && searchControl.value.length < 2) && !uiState.searchedBundles?.length">
          <div class="short-type">
            For a more precise search, we need at least 2 characters.
          </div>
        </ng-container>


        <ng-container *ngIf="!uiState.selectedSearchBundle">
          <li *ngFor="let folder of uiState.searchedBundles" (click)="setSearchBundle(folder)">
            <div class="folder workout-select__item">
              <div class="folder__name">
                <img src="assets/img/library/folder.svg" class="folder__img">
                {{ folder.name }}
              </div>

              <div class="folder__size">
                ({{ folder.size }})
              </div>
            </div>

            <div class="expanded-folder">
              <div class="workout-select__item wod" *ngFor="let workout of folder.workouts" (click)="getSearchedWod(workout)">
                <div class="wod__name" [innerHTML]="workout.name | highlight:searchControl.value">
                </div>

                <div class="wod__body text-ellipsis" [totalfitTooltip]="workout.content" [innerHTML]="workout.content | highlight:searchControl.value">
                </div>
              </div>
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="uiState.selectedSearchBundle">
          <li (click)="returnToMainFolderFroSearch()" class="folder folder--line-bot workout-select__item">
            <div class="folder__name">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_12131_957)">
                  <path d="M8 15H11.75C13.1424 15 14.4777 14.4469 15.4623 13.4623C16.4469 12.4777 17 11.1424 17 9.75C17 8.35761 16.4469 7.02226 15.4623 6.03769C14.4777 5.05312 13.1424 4.5 11.75 4.5H2M4.5 1L1 4.5L4.5 8" stroke="#7D7DA1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_12131_957">
                    <rect width="18" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <img src="assets/img/library/folder.svg" class="folder__img">
              {{ uiState.selectedSearchBundle.name }}
            </div>

            <div class="folder__size">
              ({{ uiState.selectedSearchBundle.size }})
            </div>
          </li>
          <li *ngFor="let wod of uiState.selectedSearchBundle.workouts" class="workout-select__item wod totalfitInfiniteScroller" (click)="getSearchedWod(wod)">
            <div class="wod__name">
              {{ wod.name }}
            </div>

            <div class="wod__body text-ellipsis">
              {{ wod.content }}
            </div>
          </li>
        </ng-container>

      </ul>
    </ng-container>

    <ng-container *ngIf="!searchControl.value">
      <ng-container *ngIf="!uiState.selectedBundle">
        <ul *ngIf="dropDownList$ | async as dropDownList"
            (totalfitInfiniteScroller)="infiniteScrollerTrigger(uiState.isLoading)"
            [viewInitTrigger]="false"
            [allItemsLength]="dropDownList.offlineWorkouts.content.length"
            [totalPagination]="totalPaginationOfflineWorkoutList"
        >
          <li class="folder workout-select__item" (click)="recentTrigger()">
            <div class="folder__name">
              <img src="assets/img/library/folder.svg" class="folder__img">
              {{ dropDownList.recentOfflineWorkouts.name }}
            </div>

            <div class="folder__size">
              ({{ dropDownList.recentOfflineWorkouts.size }})
            </div>
          </li>

          <li *ngFor="let folder of dropDownList.workoutBundleTemplates" class="folder workout-select__item" (click)="bundleScrollerTrigger(folder)">
            <div class="folder__name">
              <img src="assets/img/library/folder.svg" class="folder__img">
              {{ folder.name }}
            </div>

            <div class="folder__size">
              ({{ folder.size }})
            </div>
          </li>

          <li *ngFor="let folder of dropDownList.workoutBundles" class="folder workout-select__item"  (click)="userBundleScrollerTrigger(folder)">
            <div class="folder__name">
              <img src="assets/img/library/folder.svg" class="folder__img">
              {{ folder.name }}
            </div>

            <div class="folder__size">
              ({{ folder.size }})
            </div>
          </li>

          <li *ngFor="let wod of (offlineWorkoutList$ | async)?.objects" class="workout-select__item wod totalfitInfiniteScroller" (click)="selectWodTemplate(wod)">
            <div class="wod__name">
              {{ wod.theme }}
            </div>

            <div class="wod__body text-ellipsis">
              {{ (wod.customOfflineWorkoutExtensions | selectExtension: CustomOfflineWorkoutBlocks.wod).content }}
            </div>
          </li>
        </ul>
      </ng-container>

      <ng-container *ngIf="uiState.selectedBundle">
        <ul *ngIf="bundleList$ | async as bundleList"
            (totalfitInfiniteScroller)="bundleInfiniteScrollerTrigger(uiState.selectedBundle, uiState.isLoading)"
            [viewInitTrigger]="false"
            [allItemsLength]="bundleList.objects.length"
            [totalPagination]="bundleList.totalElements"
        >
          <li (click)="returnToMainFolder()" class="folder folder--line-bot workout-select__item">
            <div class="folder__name">
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_12131_957)">
                  <path d="M8 15H11.75C13.1424 15 14.4777 14.4469 15.4623 13.4623C16.4469 12.4777 17 11.1424 17 9.75C17 8.35761 16.4469 7.02226 15.4623 6.03769C14.4777 5.05312 13.1424 4.5 11.75 4.5H2M4.5 1L1 4.5L4.5 8" stroke="#7D7DA1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_12131_957">
                    <rect width="18" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <img src="assets/img/library/folder.svg" class="folder__img">
              {{ uiState.selectedBundle.name }}
            </div>

            <div class="folder__size">
              ({{ uiState.selectedBundle.size }})
            </div>
          </li>
          <li *ngFor="let wod of bundleList.objects" class="workout-select__item wod totalfitInfiniteScroller" (click)="selectWodTemplate(wod)">
            <div class="wod__name">
              {{ wod.theme }}
            </div>

            <div class="wod__body text-ellipsis">
              {{ (wod.customOfflineWorkoutExtensions | selectExtension: CustomOfflineWorkoutBlocks.wod).content }}
            </div>
          </li>
        </ul>
      </ng-container>
    </ng-container>


    <totalfit-totalfit-spinner [diameter]="30" [isShown]="uiState.isLoading || uiState.isBundleLoading"></totalfit-totalfit-spinner>

    <ng-container *ngIf="(!uiState.isLoading && !uiState.isBundleLoading) && searchControl.value.length >= 2 && !uiState.searchedBundles?.length">
      <div class="no-result">
        <img src="assets/img/no-workout-search-result.svg" alt="No search results">
        No search results
      </div>
    </ng-container>
  </div>
</ng-template>
