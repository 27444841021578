import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, Provider } from '@angular/core';
import { CustomSnackBarDataType, SnackbarService } from '@shared/services/snackbar.service';
import { MESSAGES } from '@shared/messages';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
      private snackBarService: SnackbarService
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const errorCodes = request.params.get('handledErrorCodes');
    const requestClone = request.clone({
      params: errorCodes ? request.params.delete('handledErrorCodes') : request.params
    });

    return next.handle(requestClone)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!(errorCodes && errorCodes.split(',').includes(String(error.status)))) {
            if (error.error && error.error.message) {
              this.snackBarService.openNotificationComponent({
                data: {
                  message: error.error.message,
                  title: MESSAGES.internalErrorTitle,
                  type: CustomSnackBarDataType.ERROR
                }
              });
            }
          }

          return throwError(error.error);
        })
      );
  }
}

export const ErrorHandlingInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorHandlingInterceptor,
  multi: true
};
