<div class="form-group form-group_full-width">
  <i *ngIf="showSearchIcon" class="icon-search"></i>
  <input [formControl]="searchControl"
         [placeholder]="placeholder"
         [matAutocomplete]="autoComplete"
         [matAutocompleteDisabled]="!autocompleteItemTemplate"
         [ngClass]="inputClass"
         [totalfitInputAutofocus]="isAutoSelected"
         [class.has-search]="showSearchIcon"
         (change)="searchFill.emit($event.target.value)"
         type="text"
         class="form-group__control"
         #searchInput>
  <button *ngIf="model && model.length > 0"
          (click)="clearSearchInput()"
          mat-button>
    <i class="icon-close"></i>
  </button>
</div>

<mat-autocomplete #autoComplete="matAutocomplete">
  <ng-container *ngIf="autocompleteItems">
    <mat-option *ngFor="let item of autocompleteItems"
                [value]="item[autocompleteValueProperty]"
                [disabled]="autocompleteItemDisabledFn && autocompleteItemDisabledFn(item)"
                (click)="!matOption.disabled && autocompleteOptionSelected.emit(item)"
                (onSelectionChange)="!matOption.disabled && autocompleteOptionSelected.emit(item)"
                (mousewheel)="$event.stopPropagation()"
                #matOption>
      <ng-container *ngTemplateOutlet="autocompleteItemTemplate; context: {item: item}"></ng-container>
    </mat-option>
    <mat-option *ngIf="autocompleteItems.length === 0"
                disabled>
      {{ 'Result not found' }}
    </mat-option>
  </ng-container>
</mat-autocomplete>
