import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'totalfit-avatar-profile',
  templateUrl: './avatar-profile.component.html',
  styleUrls: ['./avatar-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarProfileComponent implements OnInit {
  @Input() public isNewSms = false;
  @Input() public userForm: UntypedFormGroup;
  @Input() public hasSocialNet = true;
  @Input() public avatarSize: 'small' | 'middle';
  @Input() public showStatus = true;
  @Input() public placeholderImage: string;
  @Input() public socialNetLocation: 'default' | 'bottom' | null = 'default';
  @Input() public hasTitle = true;
  @Input() public hasDate = true;
  @Input() public date: string;
  @Input() public status: string;
  // TODO refactor name and meaning;
  @Input() public hasEmailBtn = false;
  @Input() public isEditable = false;

  // TODO: Refactor is edit & is text with mess service;
  @Input() public isText = false;
  @Input() public isLocation = false;
  @Input() public subtitle = '';
  @Output() public getImage = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit() {
    this.userForm.valueChanges.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public getDateArgs(): string {
    return this.avatarSize === 'middle' ? 'MM/dd/yyyy h:mm a' : 'MM/dd/yyyy';
  }

  public getUserClass(userStatus: any): string {
    return userStatus?.toLocaleLowerCase();
  }
}
