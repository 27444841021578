<div *ngIf="profileFormService.isFormEdit$ | async else previewTemplate"
     class="totalfit-form">
  <form [formGroup]="profileFormService.peopleForm"
        class="mat-typography">
    <h2 class="mat-title">General Information</h2>

    <div class="form-group form-group_default-width-1">
      <label class="form-group__label">Status</label>
      <totalfit-select formControlName="status"
                       outputOptionField="id"
                       [options]="sharedFormService.userStatus">
      </totalfit-select>
    </div>

    <div *ngIf="profileFormService.isNewProfile$.value"
         class="form-group form-group_default-width-1">
      <label class="form-group__label" for="name">Name</label>
      <input formControlName="firstName"
             class="form-group__control"
             id="name"
             type="text">
    </div>

    <div *ngIf="profileFormService.isNewProfile$.value" class="form-group form-group_default-width-1">
      <label class="form-group__label" for="last_name">Last name</label>
      <input formControlName="lastName"
             class="form-group__control"
             id="last_name"
             type="text">
    </div>

    <div *ngIf="profileFormService.peopleForm.value.status !== 'LEAD'"
         [totalfitFormGroupValidator]="profileFormService.peopleForm.get('locationParticipation.gymId')"
         class="form-group form-group_default-width-1"
         formGroupName="locationParticipation">
      <label class="form-group__label">Location</label>
      <totalfit-select formControlName="gymId"
                       outputOptionField="id"
                       [hasFilter]="true"
                       [options]="locationListService.locations.objects">
      </totalfit-select>
    </div>

    <div *ngIf="!profileFormService.isNewProfile$.value
          && profileFormService.peopleForm.value.status === 'COACH'
          || profileFormService.peopleForm.value.status === 'ASSISTANT'"
          class="form-group form-group_default-width-1">
      <label class="form-group__label">Totalfit direction</label>
      <totalfit-select formControlName="direction"
                       [options]="sharedFormService.totalfitDirection">
      </totalfit-select>
    </div>

    <div class="form-group form-group_default-width-1">
      <label class="form-group__label">Gender</label>
      <totalfit-select formControlName="gender"
                       outputOptionField="id"
                       [options]="sharedFormService.userGender">
      </totalfit-select>
    </div>

    <div *ngIf="!profileFormService.isNewProfile$.value && profileFormService.peopleForm.value.status !== 'LEAD'" class="form-group form-group_default-width-1">
      <totalfit-date-input label="Date of birth" formControlName="birthday"></totalfit-date-input>
    </div>

    <div *ngFor="let item of sharedFormService.getFormArray('email', profileFormService.peopleForm).controls; let i = index"
         class="form-group form-group_default-width-1 close-container">
      <label *ngIf="i === 0"
             class="form-group__label" for="email">
        {{ sharedFormService.getFormArray('email', profileFormService.peopleForm).controls.length > 1 ? 'Emails': 'Email' }}
      </label>
      <input [formControl]="item"
             class="form-group__control"
             id="email"
             type="text">

      <button *ngIf="sharedFormService.getFormArray('email', profileFormService.peopleForm).controls.length > 1"
              mat-flat-button
              (click)="sharedFormService.removeFormControl('email', i,  profileFormService.peopleForm)"
              class="icon-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="form-group form-group_default-width-1">
      <button mat-button
              [disabled]="sharedFormService.getFormArray('email', profileFormService.peopleForm).controls.length === 2"
              color="primary"
              class="add-entity-btn"
              (click)="sharedFormService.addNewFormControlToFormArray('email', profileFormService.peopleForm)">
        + Add email
      </button>
    </div>

    <div *ngFor="let item of sharedFormService.getFormArray('phone', profileFormService.peopleForm).controls; let i = index"
         class="form-group form-group_default-width-1 close-container">
      <label *ngIf="i === 0"
             class="form-group__label">
        {{ sharedFormService.getFormArray('phone', profileFormService.peopleForm).controls.length > 1 ? 'Phones': 'Phone' }}
      </label>

      <mat-form-field appearance="outline" class="tell-selector">
        <ngx-mat-intl-tel-input [formControl]="item"
                                [preferredCountries]="['us']"
                                [enableSearch]="true"
                                name="phone">
        </ngx-mat-intl-tel-input>
      </mat-form-field>

      <button *ngIf="sharedFormService.getFormArray('phone', profileFormService.peopleForm).controls.length > 1"
              mat-flat-button
              (click)="sharedFormService.removeFormControl('phone', i,  profileFormService.peopleForm)"
              class="icon-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="form-group">
      <button mat-button
              [disabled]="sharedFormService.getFormArray('phone', profileFormService.peopleForm).controls.length === 2"
              color="primary"
              class="add-entity-btn"
              (click)="sharedFormService.addNewFormControlToFormArray('phone', profileFormService.peopleForm)">
        + Add phone
      </button>
    </div>

    <div *ngIf="!profileFormService.isNewProfile$.value && profileFormService.peopleForm.value.status !== 'LEAD'"
         formGroupName="address"
         class="form-group form-group_default-width-1">
      <label class="form-group__label">Address</label>
      <textarea formControlName="address"
                rows="2"
                class="form-group__text-control">
      </textarea>
    </div>
  </form>

  <button mat-raised-button
          color="primary"
          type="submit"
          class="submit save-btn"
          (click)="profileFormService.saveChanges(route.snapshot.data.people)">
    save
  </button>
</div>

<ng-template #previewTemplate>
  <div class="preview-information">
    <div class="preview-information__general">
      <h2 class="mat-subheading-1">Status:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.status }}</p>
    </div>

    <div *ngIf="profileFormService.isNewProfile$.value" class="preview-information__general">
      <h2 class="mat-subheading-1">Name:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.firstName }}</p>
    </div>

    <div *ngIf="profileFormService.isNewProfile$.value" class="preview-information__general">
      <h2 class="mat-subheading-1">Last name:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.lastName }}</p>
    </div>
    <div *ngIf="profileFormService.peopleForm.value.status !== 'LEAD'" class="preview-information__general">
      <h2 class="mat-subheading-1">Locations:</h2>
      <p class="mat-body-2">
        {{ locationListService.getLocationFromLocationList(profileFormService.peopleForm.value.gymId)?.name }}
      </p>
    </div>

    <div *ngIf="!profileFormService.isNewProfile$.value && profileFormService.peopleForm.value.status !== 'LEAD'" class="preview-information__general">
      <h2 class="mat-subheading-1">Date of birth:</h2>
      <p class="mat-body-2">
        {{ profileFormService.peopleForm.value.birthday | date: 'MMM dd, yyyy' }}
      </p>
    </div>

    <div *ngIf="!profileFormService.isNewProfile$.value
          && profileFormService.peopleForm.value.status === 'COACH'
          || profileFormService.peopleForm.value.status === 'ASSISTANT'"
         class="preview-information__general">
      <h2 class="mat-subheading-1">Totalfit direction:</h2>
      <p class="mat-body-2">
        {{ profileFormService.peopleForm.value.direction }}
      </p>
    </div>

    <div class="preview-information__general">
      <h2 class="mat-subheading-1">Gender:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.gender }}</p>
    </div>

    <div class="preview-information__general">
      <h2 class="mat-subheading-1">Email:</h2>
      <div class="preview-information__list">
        <p *ngFor="let email of profileFormService.peopleForm.value.email"
           class="mat-body-2">{{ email }}
        </p>
      </div>
    </div>

    <div class="preview-information__general">
      <h2 class="mat-subheading-1">Phone:</h2>
      <div class="preview-information__list">
        <p *ngFor="let phone of profileFormService.peopleForm.value.phone"
           class="mat-body-2">
          {{ phone }}
        </p>
      </div>
    </div>

    <div *ngIf="!profileFormService.isNewProfile$.value && profileFormService.peopleForm.value.status !== 'LEAD'" class="preview-information__general">
      <h2 class="mat-subheading-1">Address:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.address.address }}</p>
    </div>
  </div>
</ng-template>
