import { trigger, style, animate, transition } from '@angular/animations';

export const slideInAnimation = trigger('slideInAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
      right: '-100%'
    }),
    animate('.3s', style({
      opacity: 1,
      right: 0
    }))
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      right: 0
    }),
    animate('.3s', style({
      opacity: 0,
      right: '-100%'
    }))
  ])
]);
