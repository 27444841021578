import { Injectable } from '@angular/core';
import { Address } from '@shared/models/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationNormalizerService {
  public addressNormalize(gR: any): Address {
    const locality = gR.address_components.find((addressComponent) => {
      return addressComponent.types.includes('locality')
        || addressComponent.types.includes('postal_town')
        || addressComponent.types.includes('administrative_area_level_1');
    });

    const country = gR.address_components.find((addressComponent) => {
      return addressComponent.types.includes('country');
    });

    return {
      addressValue: gR.formatted_address,
      location: {
        city: locality?.long_name ?? '',
        country: country?.long_name ?? ''
      }
    };
  }
}
