import { Action, createReducer, on } from '@ngrx/store';
import * as fromExerciseActions from '@app/store/exercise/exercise.actions';
import { ExerciseState } from '@store/exercise/exercise.model';

export const exerciseInitialState: ExerciseState = {
  exerciseTabs: null,
  isLoading: false
};

const exerciseReducer = createReducer(
  exerciseInitialState,
  on(fromExerciseActions.getExerciseTags,
    (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(fromExerciseActions.getExerciseTagsSuccess, (state,  { tabs }) => {
    return {
      ...state,
      exerciseTabs: tabs,
      isLoading: false
    };
  }),
  on(fromExerciseActions.resetExerciseTags, (state) => {
    return {
      ...state,
      exerciseTabs: null
    };
  })
);

export function reducer(state: ExerciseState | undefined, action: Action) {
  return exerciseReducer(state, action);
}
