import { PaginationModel } from '@shared/models/pagination.model';
import { createAction, props } from '@ngrx/store';
import { WorkoutView } from '@shared/models/workout.model';
import { WorkoutBundle } from '@store/workout/workout.model';

export const GET_USER_BUNDLE_WORKOUTS = '[BUNDLE] GET USER BUNDLE WORKOUTS';
export const RESET_SELECTED_BUNDLE = '[BUNDLE] RESET SELECTED BUNDLE';
export const GET_USER_BUNDLE_WORKOUTS_SUCCESS = '[BUNDLE] GET USER BUNDLE WORKOUTS SUCCESS';
export const GET_TF_BUNDLE_WORKOUTS = '[BUNDLE] GET TF BUNDLE WORKOUTS';
export const GET_TF_BUNDLE_WORKOUTS_SUCCESS = '[BUNDLE] GET TF BUNDLE WORKOUTS SUCCESS';
export const GET_RECENT_WORKOUTS = '[BUNDLE] GET RECENT_WORKOUTS';
export const GET_RECENT_WORKOUTS_SUCCESS = '[BUNDLE] GET RECENT_WORKOUTS_SUCCESS';

export const getUserBundleWorkouts = createAction(
  GET_USER_BUNDLE_WORKOUTS,
  props<{ folder: Partial<WorkoutBundle> }>()
);

export const getUserBundleWorkoutsSuccess = createAction(
  GET_USER_BUNDLE_WORKOUTS_SUCCESS,
  props<{ templateList: PaginationModel<WorkoutView>, folder: Partial<WorkoutBundle> }>()
);

export const getTfBundleWorkouts = createAction(
  GET_TF_BUNDLE_WORKOUTS,
  props<{ folder: Partial<WorkoutBundle> }>()
);

export const getTfBundleWorkoutsSuccess = createAction(
  GET_TF_BUNDLE_WORKOUTS_SUCCESS,
  props<{ templateList: PaginationModel<WorkoutView>, folder: Partial<WorkoutBundle> }>()
);

export const getRecentWorkouts = createAction(
  GET_RECENT_WORKOUTS
);

export const getRecentWorkoutsSuccess = createAction(
  GET_RECENT_WORKOUTS_SUCCESS,
  props<{ workoutList: WorkoutView[] }>()
);

export const resetSelectedBundle = createAction(
  RESET_SELECTED_BUNDLE
);
