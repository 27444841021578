import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const workout = (state: AppState) => state.workout;

export const selectOfflineWorkout = createSelector(
  workout,
  (state) => state.offlineWorkout
);

export const selectDropDownList = createSelector(
  workout,
  (state) => state.dropDownList
);

export const selectAdminTemplateList = createSelector(
  workout,
  (state) => state.adminTemplateList
);

export const selectCurrentTemplateForPreview = createSelector(
  workout,
  (state) => state.currentTemplateForPreview
);

export const selectTemplateWorkoutBundle = createSelector(
  workout,
  (state) => state.templateWorkoutBundle
);

export const selectAdminOfflineWorkout = createSelector(
  workout,
  (state) => state.adminOfflineWorkout
);

export const selectTemplateWorkoutBundleList = createSelector(
  workout,
  (state) => state.templateWorkoutBundleList
);

export const selectWorkoutIsLoading = createSelector(
  workout,
  (state) => state.workoutIsLoading
);

export const selectWorkoutList = createSelector(
  workout,
  (state) => state.adminWorkoutList
);

export const selectWorkoutBundles = createSelector(
  workout,
  (state) => state.workoutBundles
);

export const selectOfflineWorkoutList = createSelector(
  workout,
  (state) => state.offlineWorkoutList
);

export const selectBundleWorkout = createSelector(
  workout,
  (state) => state.selectedBundleWorkout
);

export const selectLastWorkout = createSelector(
  workout,
  (state) => state.selectedLastWorkout
);

export const selectUserWorkoutBundles = createSelector(
  workout,
  (state) => state.userWorkoutBundles
);

export const selectWorkoutBundlesInTheStore = createSelector(
  selectWorkoutBundles,
  (bundles) => !!(bundles && bundles.objects && bundles.objects.length)
);

export const selectUserBundles = createSelector(
  workout,
  (workoutStore) => workoutStore.selectedUserBundle
);

export const selectUserShopBundle = createSelector(
  workout,
  (workoutStore) => workoutStore.selectedUserShopBundle
);

export const selectCustomWorkoutList = createSelector(
  workout,
  (workoutStore) => workoutStore.customWorkoutList
);

export const selectIsCurrentCustomWorkoutLoading = createSelector(
  workout,
  (workoutStore) => workoutStore.currentCustomWorkoutLoading
);

export const selectCurrentCustomWorkout = createSelector(
  workout,
  (workoutStore) => workoutStore.currentCustomWorkout
);

export const selectWorkoutListViewType = createSelector(
  workout,
  (workoutStore) => workoutStore.workoutTypeView
);

export const selectAllOnlineExercise = createSelector(
  workout,
  (workoutStore) => workoutStore.onlineExercise
);

export const selectWorkoutBundleById = createSelector(
  selectWorkoutBundles,
  (bundles, props) => {
    return bundles.objects.find((i) => i.id === props.id);
  }
);
