import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'totalfit-manuals',
  templateUrl: './manuals.component.html',
  styleUrls: ['./manuals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualsComponent {
  @Input() public icon: string;
  @Input() public description: string;
}
