import { Address } from '@shared/models/location.model';
import { Location } from '@app/location/_shared/location.model';
import { Document } from '@app/people/_shared/document.model';

export interface People {
  id?: string;
  address: Address;
  comment: string;
  birthday: Date;
  dateOfCreation: Date;
  dateOfTrial: Date;
  direction: string;
  documents: Document[];
  email: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  lastContact: Date;
  lastPayment: Date;
  lastWorkout: Date;
  gymId: string;
  gym?: Location;
  nextPayment: Date;
  phone: string;
  photo: string;
  status: PeopleStatus;
  checked: boolean;
  name?: string;
  locationParticipation?: any;
  participations?: any;
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum PeopleStatus {
  Lead = 'LEAD',
  Trial = 'TRIAL',
  Athlete = 'ATHLETE',
  Coach = 'COACH',
  Assistant = 'ASSISTANT'
}

export enum TotalfitDirection {
  Performance = 'PERFORMANCE',
  Defence = 'DEFENCE',
  Youth = 'YOUTH',
  General = 'GENERAL'
}

export enum TShirtSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL'
}

export enum TotalfitExperience {
  New = 'New',
  'Less than 1 year' = 'Less than 1 year',
  '1-3 years' = '1-3 years',
  '3+ years' = '3+ years'
}

export enum TotalfitExamLang {
  English = 'English',
  Spanish = 'Spanish',
  Portugese = 'Portugese',
  Russia = 'Russia',
  Chinese = 'Chinese',
  Italian = 'Italian',
  Tamil = 'Tamil'
}
