import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public static isItemMatched(field: string | number, searchValue: string): boolean {
    return searchValue ? String(field).toLowerCase().includes(searchValue.trim().toLowerCase()) : true;
  }

  private static getDeepValue<T>(object: T, path: string): string | number {
    return path.split('.').reduce((prev, current) => prev[current], object);
  }

  public transform<T>(items: T[], searchInputValue: string, routes: keyof T | Array<keyof T>): T[] {
    if (!searchInputValue) {
      return items;
    }

    const fieldRoutes = Array.isArray(routes) ? routes : [routes];

    return items.filter((item) => fieldRoutes.some(
      (fieldRoute) => SearchPipe.isItemMatched(SearchPipe.getDeepValue<T>(item, fieldRoute as string), searchInputValue)
    ));
  }
}
