import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalSpinnerService } from '@shared/services/global-spinner.service';
import { Store } from '@ngrx/store';
import { AuthService } from '@core/auth/auth.service';
import { setUser } from '@app/store/user/user.actions';

@Component({
  selector: 'totalfit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  constructor(
    public globalSpinnerService: GlobalSpinnerService,
    private readonly store: Store,
    private readonly authService: AuthService
  ) {
    if (this.authService.currentUser) {
      this.store.dispatch(setUser({ user: this.authService.currentUser }));
    }
  }
}
