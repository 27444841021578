import { createAction, props } from '@ngrx/store';
import { PaginationModel } from '@shared/models/pagination.model';
import { Location } from '@app/location/_shared/location.model';
import {
  InviteLink,
  LinkFileDuration, LocationPushPayload,
  MainActiveMember,
  MainCompletedWorkout, MainCompletedWorkoutDetails,
  MainLocation, MainLocationEvent, MainReaction
} from '@store/location/location.model';

export const GET_LOCATIONS = '[LOCATION] GET LOCATIONS';
export const GET_LOCATIONS_SUCCESS = '[LOCATION] GET LOCATIONS SUCCESS';

export const GET_LOCATION_BY_ID = '[LOCATION] GET_LOCATION_BY_ID';
export const GET_LOCATION_BY_ID_SUCCESS = '[LOCATION] GET_LOCATION_BY_ID_SUCCESS';
export const CLEAR_CURRENT_LOCATION = '[LOCATION] CLEAR_CURRENT_LOCATION';

export const MOST_ACTIVE_MEMBERS = '[LOCATION] MOST_ACTIVE_MEMBERS';
export const MOST_ACTIVE_MEMBERS_SUCCESS = '[LOCATION] MOST_ACTIVE_MEMBERS_SUCCESS';

export const LOCATION_MEMBERS = '[LOCATION] LOCATION_MEMBERS';
export const LOCATION_MEMBERS_SUCCESS = '[LOCATION] LOCATION_MEMBERS_SUCCESS';

export const GET_MAIN_LOCATION = '[LOCATION] GET_MAIN_LOCATION';
export const GET_MAIN_LOCATION_SUCCESS = '[LOCATION] GET_MAIN_LOCATION_SUCCESS';

export const GET_MAIN_LOCATION_EVENTS = '[LOCATION] GET_MAIN_LOCATION_EVENTS';
export const GET_MAIN_LOCATION_EVENTS_SUCCESS = '[LOCATION] GET_MAIN_LOCATION_EVENTS_SUCCESS';

export const GET_MAIN_LOCATION_EVENTS_FOR_TODAY = '[LOCATION] GET_MAIN_LOCATION_EVENTS_FOR_TODAY';
export const GET_MAIN_LOCATION_EVENTS_FOR_TODAY_SUCCESS = '[LOCATION] GET_MAIN_LOCATION_EVENTS_FOR_TODAY_SUCCESS';

export const GET_MAIN_LOCATION_LIST = '[LOCATION] GET_MAIN_LOCATION_LIST';
export const GET_MAIN_LOCATION_LIST_SUCCESS = '[LOCATION] GET_MAIN_LOCATION_LIST_SUCCESS';

export const CREATE_MAIN_LOCATION = '[LOCATION] CREATE_MAIN_LOCATION';
export const CREATE_MAIN_LOCATION_SUCCESS = '[LOCATION] CREATE_MAIN_LOCATION_SUCCESS';

export const DELETE_MAIN_LOCATION = '[LOCATION] DELETE_MAIN_LOCATION';
export const DELETE_MAIN_LOCATION_SUCCESS = '[LOCATION] DELETE_MAIN_LOCATION_SUCCESS';

export const EDIT_MAIN_LOCATION = '[LOCATION] EDIT_MAIN_LOCATION';
export const EDIT_MAIN_LOCATION_SUCCESS = '[LOCATION] EDIT_MAIN_LOCATION_SUCCESS';

export const GET_TRIBE_MEMBERS = '[LOCATION] GET_TRIBE_MEMBERS';
export const GET_TRIBE_MEMBERS_SUCCESS = '[LOCATION] GET_TRIBE_MEMBERS_SUCCESS';

export const DELETE_TRIBE_MEMBER = '[LOCATION] DELETE_TRIBE_MEMBER';
export const DELETE_TRIBE_MEMBER_SUCCESS = '[LOCATION] DELETE_TRIBE_MEMBER_SUCCESS';

export const GET_INVITE_LINKS = '[LOCATION] GET_INVITE_LINKS';
export const GET_INVITE_LINKS_SUCCESS = '[LOCATION] GET_INVITE_LINKS_SUCCESS';

export const CREATE_INVITE_LINK = '[LOCATION] CREATE_INVITE_LINK';
export const CREATE_INVITE_LINK_SUCCESS = '[LOCATION] CREATE_INVITE_LINK_SUCCESS';

export const RESET_NEW_INVITE_LINK = '[LOCATION] RESET_NEW_INVITE_LINK';
export const RESET_NEW_INVITE_LINK_LIST = '[LOCATION] RESET_NEW_INVITE_LINK_LIST';

export const DELETE_INVITE_LINK = '[LOCATION] DELETE_INVITE_LINK';
export const DELETE_INVITE_LINK_SUCCESS = '[LOCATION] DELETE_INVITE_LINK_SUCCESS';

export const GET_COMPLETED_WORKOUTS = '[LOCATION] GET_COMPLETED_WORKOUTS';
export const GET_COMPLETED_WORKOUTS_SUCCESS = '[LOCATION] GET_COMPLETED_WORKOUTS_SUCCESS';

export const GET_COMPLETED_WORKOUT_DETAIL = '[LOCATION] GET_COMPLETED_WORKOUT_DETAIL';
export const GET_COMPLETED_WORKOUT_DETAIL_SUCCESS = '[LOCATION] GET_COMPLETED_WORKOUT_DETAIL_SUCCESS';

export const GET_WAITING_FOR_REACTION = '[LOCATION] GET_WAITING_FOR_REACTION';
export const GET_WAITING_FOR_REACTION_SUCCESS = '[LOCATION] GET_WAITING_FOR_REACTION_SUCCESS';

export const CREATE_LOCATION_EVENT = '[LOCATION] CREATE_LOCATION_EVENT';
export const CREATE_LOCATION_EVENT_SUCCESS = '[LOCATION] CREATE_LOCATION_EVENT_SUCCESS';

export const EDIT_LOCATION_EVENT = '[LOCATION] EDIT_LOCATION_EVENT';
export const EDIT_LOCATION_EVENT_SUCCESS = '[LOCATION] EDIT_LOCATION_EVENT_SUCCESS';

export const DELETE_LOCATION_EVENT = '[LOCATION] DELETE_LOCATION_EVENT';
export const DELETE_LOCATION_EVENT_SUCCESS = '[LOCATION] DELETE_LOCATION_EVENT_SUCCESS';

export const SET_LOCATION_EVENT_FOR_EDIT = '[LOCATION] SET_LOCATION_EVENT_FOR_EDIT';
export const SET_CURRENT_WORKOUT_FOR_EDIT = '[LOCATION] SET_CURRENT_WORKOUT_FOR_EDIT';

export const UPDATE_USER_LOG_RESULT = '[LOCATION] UPDATE_USER_LOG_RESULT';
export const UPDATE_USER_LOG_RESULT_SUCCESS = '[LOCATION] UPDATE_USER_LOG_RESULT_SUCCESS';

export const UPDATE_COMPLETED_WORKOUT_RESULT_SUCCESS = '[LOCATION] UPDATE_COMPLETED_WORKOUT_RESULT_SUCCESS';
export const SEND_NOTIFICATION = '[LOCATION] SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = '[LOCATION] SEND_NOTIFICATION_SUCCESS';

export const getLocations = createAction(
  GET_LOCATIONS
);

export const clearCurrentLocation = createAction(
  CLEAR_CURRENT_LOCATION
);

export const getLocationsSuccess = createAction(
  GET_LOCATIONS_SUCCESS,
  props<{ locations: PaginationModel<Location> }>()
);

export const getLocationsById = createAction(
  GET_LOCATION_BY_ID,
  props<{ id: string }>()
);

export const getLocationsByIdSuccess = createAction(
  GET_LOCATION_BY_ID_SUCCESS,
  props<{ location: Location }>()
);

export const getMostActiveMembers = createAction(
  MOST_ACTIVE_MEMBERS,
  props<{ zone_id: string }>()
);

export const getMostActiveMembersSuccess = createAction(
  MOST_ACTIVE_MEMBERS_SUCCESS,
  props<{ members: PaginationModel<MainActiveMember> }>()
);

export const getLocationMembers = createAction(
  LOCATION_MEMBERS,
  props<{ zone_id: string }>()
);

export const getLocationMembersSuccess = createAction(
  LOCATION_MEMBERS_SUCCESS,
  props<{ location: Location }>()
);

export const getMainLocation = createAction(
  GET_MAIN_LOCATION,
  props<{ id: string, from: string, to: string }>()
);

export const getMainLocationSuccess = createAction(
  GET_MAIN_LOCATION_SUCCESS,
  props<{ location: MainLocation }>()
);

export const getMainLocationEvents = createAction(
  GET_MAIN_LOCATION_EVENTS,
  props<{ location_id: string, from: string, to: string }>()
);

export const getMainLocationEventsSuccess = createAction(
  GET_MAIN_LOCATION_EVENTS_SUCCESS,
  props<{ events: Record<string, MainLocationEvent[]> }>()
);

export const getMainLocationForTodayEvents = createAction(
  GET_MAIN_LOCATION_EVENTS_FOR_TODAY,
  props<{ location_id: string, today: string }>()
);

export const getMainLocationForTodayEventsSuccess = createAction(
  GET_MAIN_LOCATION_EVENTS_FOR_TODAY_SUCCESS,
  props<{ events: Record<string, MainLocationEvent[]> }>()
);

export const getMainLocationList = createAction(
  GET_MAIN_LOCATION_LIST
);

export const getMainLocationListSuccess = createAction(
  GET_MAIN_LOCATION_LIST_SUCCESS,
  props<{ locations: MainLocation[] }>()
);

export const createMainLocation = createAction(
  CREATE_MAIN_LOCATION,
  props<{ name: string }>()
);

export const createMainLocationSuccess = createAction(
  CREATE_MAIN_LOCATION_SUCCESS,
  props<{ location: MainLocation }>()
);

export const editMainLocation = createAction(
  EDIT_MAIN_LOCATION,
  props<{ location: MainLocation }>()
);

export const editMainLocationSuccess = createAction(
  EDIT_MAIN_LOCATION_SUCCESS
);

export const getTribeMembers = createAction(
  GET_TRIBE_MEMBERS,
  props<{ page: number, size: number; id: string }>()
);

export const getTribeMembersSuccess = createAction(
  GET_TRIBE_MEMBERS_SUCCESS,
  props<{ tribeMembers: PaginationModel<MainActiveMember> }>()
);

export const getInviteLinks = createAction(
  GET_INVITE_LINKS,
  props<{ pageSize: number, pageNumber: number; id: string }>()
);

export const getInviteLinksSuccess = createAction(
  GET_INVITE_LINKS_SUCCESS,
  props<{ inviteLinks: PaginationModel<InviteLink> }>()
);

export const createInviteLink = createAction(
  CREATE_INVITE_LINK,
  props<{ id: string; linkLife: LinkFileDuration }>()
);

export const deleteInviteLink = createAction(
  DELETE_INVITE_LINK,
  props<{ link: InviteLink }>()
);

export const deleteInviteLinkSuccess = createAction(
  DELETE_INVITE_LINK_SUCCESS,
  props<{ link: InviteLink }>()
);

export const resetNewInviteLink = createAction(
  RESET_NEW_INVITE_LINK
);

export const resetNewInviteLinkList = createAction(
  RESET_NEW_INVITE_LINK_LIST
);

export const createInviteLinkSuccess = createAction(
  CREATE_INVITE_LINK_SUCCESS,
  props<{ newInviteLink: InviteLink }>()
);

export const getCompletedWorkouts = createAction(
  GET_COMPLETED_WORKOUTS,
  props<{ pageSize: number, pageNumber: number; id: string }>()
);

export const getCompletedWorkoutsSuccess = createAction(
  GET_COMPLETED_WORKOUTS_SUCCESS,
  props<{ completedWorkouts: PaginationModel<MainCompletedWorkout> }>()
);

//////

export const getCompletedWorkoutDetail = createAction(
  GET_COMPLETED_WORKOUT_DETAIL,
  props<{ id: string, eventId: string }>()
);

export const getCompletedWorkoutDetailSuccess = createAction(
  GET_COMPLETED_WORKOUT_DETAIL_SUCCESS,
  props<{ completedWorkout: MainCompletedWorkoutDetails }>()
);

export const getWaitingForReaction = createAction(
  GET_WAITING_FOR_REACTION,
  props<{ id: string }>()
);

export const getWaitingForReactionSuccess = createAction(
  GET_WAITING_FOR_REACTION_SUCCESS,
  props<{ waitingForReaction: PaginationModel<MainReaction> }>()
);

export const createLocationEvent = createAction(
  CREATE_LOCATION_EVENT,
  props<{ event: MainLocationEvent & { offlineWorkoutId: string } }>()
);

export const createLocationEventSuccess = createAction(
  CREATE_LOCATION_EVENT_SUCCESS,
  props<{ event: MainLocationEvent }>()
);

export const editLocationEvent = createAction(
  EDIT_LOCATION_EVENT,
  props<{ event: MainLocationEvent, oldDate?: string }>()
);

export const editLocationEventSuccess = createAction(
  EDIT_LOCATION_EVENT_SUCCESS,
  props<{ event: MainLocationEvent, oldDate: string }>()
);

export const deleteMainLocation = createAction(
  DELETE_MAIN_LOCATION,
  props<{ locationId: number }>()
);

export const deleteMainLocationSuccess = createAction(
  DELETE_MAIN_LOCATION_SUCCESS,
  props<{ locationId: string }>()
);

export const deleteLocationEvent = createAction(
  DELETE_LOCATION_EVENT,
  props<{ event: MainLocationEvent }>()
);

export const deleteLocationEventSuccess = createAction(
  DELETE_LOCATION_EVENT_SUCCESS,
  props<{ event: MainLocationEvent }>()
);

export const deleteTribeMember = createAction(
  DELETE_TRIBE_MEMBER,
  props<{ tribe: MainActiveMember }>()
);

export const deleteTribeMemberSuccess = createAction(
  DELETE_TRIBE_MEMBER_SUCCESS,
  props<{ id: string }>()
);

export const setLocationEventForEdit = createAction(
  SET_LOCATION_EVENT_FOR_EDIT,
  props<{ event: MainLocationEvent }>()
);

export const setLocationWeek = createAction(
  SET_LOCATION_EVENT_FOR_EDIT,
  props<{ week }>()
);

export const setCurrentWorkoutForEdit = createAction(
  SET_CURRENT_WORKOUT_FOR_EDIT,
  props<{ currentWorkoutForEdit }>()
);

export const updateUserLogResult = createAction(
  UPDATE_USER_LOG_RESULT,
  props<{ logResult: MainReaction, eventType: 'wfr' | 'details' }>()
);

export const updateUserLogResultSuccess = createAction(
  UPDATE_USER_LOG_RESULT_SUCCESS,
  props<{ logResult }>()
);

export const updateCompletedWorkoutDetails = createAction(
  UPDATE_COMPLETED_WORKOUT_RESULT_SUCCESS,
  props<{ logResult }>()
);

export const sendNotification = createAction(
  SEND_NOTIFICATION,
  props<{ notification: LocationPushPayload, eventType: 'preview' | 'dashboard' }>()
);
