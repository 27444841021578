import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const exercise = (state: AppState) => state.exercise;
export const isLoading = createSelector(
  exercise,
  (state) => state.isLoading
);

export const selectExerciseTabs = createSelector(
  exercise,
  (state) => state.exerciseTabs
);
