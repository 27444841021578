import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer, StoreModule
} from '@ngrx/store';
import { UserState } from '@app/store/user/user.model';
import { environment } from '@environments/environment';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromUserState from '@app/store/user/user.reducer';
import * as fromAppUIState from '@app/store/app-ui-state/app-ui-state.reducer';
import * as fromLocationState from '@app/store/location/location.reducer';
import * as fromWorkoutsState from '@app/store/workout/workout.reducer';
import * as fromOfflineWorkoutsState from '@app/store/offline-workout/offline-workout.reducer';
import * as fromBundleState from '@app/store/bundle/bundle.reducer';
import * as fromLibraryState from '@app/store/library/library.reducer';
import * as fromStoryState from '@app/store/story/story.reducer';
import * as fromExerciseState from '@app/store/exercise/exercise.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '@app/store/user/user.effects';
import { AppUiStateModel } from '@app/store/app-ui-state/app-ui-state.model';
import { storeFreeze } from 'ngrx-store-freeze';
import { AppUiStateEffects } from '@app/store/app-ui-state/app-ui-state.effects';
import { LocationState } from '@app/store/location/location.model';
import { LocationEffects } from '@app/store/location/location.effects';
import { WorkoutState } from '@app/store/workout/workout.model';
import { WorkoutEffects } from '@app/store/workout/workout.effects';
import { LibraryState } from '@app/store/library/library.model';
import { routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { LibraryEffects } from '@app/store/library/library.effects';
import { StoryState } from '@store/story/story.model';
import { StoryEffects } from '@store/story/story.effects';
import { OfflineWorkoutState } from '@store/offline-workout/offline-workout.model';
import { OfflineWorkoutEffects } from '@store/offline-workout/offline-workout.effects';
import { BundleEffects } from '@store/bundle/bundle.effects';
import { BundleState } from '@store/bundle/bundle.model';
import { ExerciseState } from '@store/exercise/exercise.model';
import { ExerciseEffects } from '@store/exercise/exercise.effects';

export interface AppState {
  user?: UserState;
  appUI?: AppUiStateModel;
  location: LocationState;
  workout: WorkoutState;
  offlineWorkout: OfflineWorkoutState;
  bundle: BundleState;
  library: LibraryState;
  router: RouterState;
  story: StoryState;
  exercise: ExerciseState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromUserState.reducer,
  appUI: fromAppUIState.reducer,
  location: fromLocationState.reducer,
  workout: fromWorkoutsState.reducer,
  offlineWorkout: fromOfflineWorkoutsState.reducer,
  bundle: fromBundleState.reducer,
  library: fromLibraryState.reducer,
  router: routerReducer,
  story: fromStoryState.reducer,
  exercise: fromExerciseState.reducer
};

export function storeClear(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action): AppState => {
    let nextState = state;
    if (action.type === '') {
      nextState = undefined;
    }
    return reducer(nextState, action);
  };
}

const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [ storeFreeze, storeClear ] : [storeClear];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([
      UserEffects,
      AppUiStateEffects,
      LocationEffects,
      LibraryEffects,
      WorkoutEffects,
      StoryEffects,
      OfflineWorkoutEffects,
      BundleEffects,
      ExerciseEffects
    ]),
    StoreRouterConnectingModule.forRoot(),
  ]
})

export class AppStateModule {
  public static forRoot(): ModuleWithProviders<AppStateModule> {
    return {
      ngModule: AppStateModule,
      providers: []
    };
  }
}
