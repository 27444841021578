import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { catchError, map, scan } from 'rxjs/operators';
import { DownloadFileService } from '@shared/services/download-file.service';
import { of } from 'rxjs';
import { isMediaFile } from '@app/library/_shared/library.utils';
import { Store } from '@ngrx/store';
import * as fromLibraryAction from '@app/store/library/library.actions';
import { isHttpProgressEvent, isHttpResponse } from '@shared/utils/utils';
import { Download } from '@shared/models/download.model';

@Directive({
  selector: '[totalfitDownloadFile]'
})
export class DownloadFileDirective {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly downloadFileService: DownloadFileService,
    private readonly store$: Store
  ) {}

  private downloadUrl = '';

  @Input('totalfitDownloadFile')
  public set url(url: string) {
    this.downloadUrl = url;
  }

  @Input() public loadType: 'download' | 'view';
  @Input() public entityDownloadName: string;
  @Input() public eventTriggerName: 'click' | 'dblClick' = 'click';
  @Input() public entity: any;
  @Output() public startDownload: EventEmitter<any> = new EventEmitter();
  @Output() public downloadProgress: EventEmitter<any> = new EventEmitter();

  @HostListener('click')
  public onClick() {
    if (this.eventTriggerName === 'click') {
      this.startDownload.emit({entity: this.entity, loadType: this.loadType});
      this.onClickTrigger();
    }
  }

  @HostListener('dblclick')
  public onDblClick() {
    if (this.eventTriggerName === 'dblClick') {
      this.startDownload.emit({entity: this.entity, loadType: this.loadType});
      this.onClickTrigger();
    }
  }

  private onClickTrigger(): Promise<void> {
    if (this.downloadUrl === null) {
      return;
    }

    this.httpClient.get(
      this.downloadUrl,
      { responseType: 'blob', observe: 'events', reportProgress: true, }
    ).pipe(
      scan((previous: Download, event: HttpEvent<Blob>): Download => {
          if (isHttpProgressEvent(event)) {
            const item: Download = {
              progress: Math.round((100 * event.loaded) / +this.entity.size),
              state: 'IN_PROGRESS',
              content: null,
              entity: this.entity
            };
            this.downloadProgress.emit(item);
            return item;
          }
          if (isHttpResponse(event)) {
            const item: Download = {
              progress: 100,
              state: 'DONE',
              content: event,
              entity: this.entity
            };
            this.downloadProgress.emit(item);
            return item;
          }
          return previous;
        },
        {state: 'PENDING', progress: 0, content: null}
      ),
      map((responseItem) => {
        if (responseItem.state === 'DONE') {
          const url = URL.createObjectURL(responseItem.content.body);

          if (isMediaFile(this.entity.name) && this.loadType === 'view') {
            this.store$.dispatch(fromLibraryAction.streamSetName({ name: this.entity.name }));
            this.store$.dispatch(fromLibraryAction.streamSet({ stream: url }));
            this.downloadFileService.entityForDelete.next(this.entity);

            return;
          }

          const anchor = document.createElement('a');
          anchor.href = url;

          if (this.loadType === 'download') {
            anchor.download = this.entityDownloadName
              ? this.entityDownloadName
              : this.getFilenameFromHeaders(responseItem.content.headers) || 'file';
          }

          if (this.loadType === 'view') {
            anchor.target = '_blank';
          }

          this.downloadFileService.entityForDelete.next(this.entity);

          anchor.click();

          URL.revokeObjectURL(url);
        }
      }),
      catchError(() => {
        this.downloadFileService.entityForDelete.next(this.entity);
        return of(null);
      }))
      .subscribe();
  }

  private getFilenameFromHeaders(headers: HttpHeaders) {
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
      return null;
    }

    const leadIn = 'filename=';
    const start = contentDisposition.search(leadIn);
    if (start < 0) {
      return null;
    }

    const value = contentDisposition.substring(start + leadIn.length).trim();
    if (value.length === 0) {
      return null;
    }

    const firstCharacter = value[0];
    if (firstCharacter !== '\"' && firstCharacter !== '\'') {
      return value;
    }

    if (value.length < 2) {
      return null;
    }

    const lastCharacter = value[value.length - 1];
    if (lastCharacter !== firstCharacter) {
      return null;
    }

    return value.substring(1, value.length - 1);
  }
}
