import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';

@Component({
  selector: 'totalfit-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiPickerComponent implements OnInit {
  public reactionList = ['1f44d', '1f525', '1f60e', '270c', '1f3af'];

  public classMapForReaction = {
    '1f44d': '128077',
    '1f525': '128293',
    '1f60e': '128526',
    '270c': '9996',
    '1f3af': '127919'
  };
  @Input() public currentEmoji;
  @Output() public selectEmoji: EventEmitter<string> = new EventEmitter();
  @ViewChild(DropdownToggleDirective, { static: false }) private dropdownToggle: DropdownToggleDirective;
  public emoji;

  public ngOnInit(): void {
    this.emoji = this.currentEmoji;
  }

  public selectEmojiEvent(emoji: string) {
    this.selectEmoji.emit(emoji);
    this.dropdownToggle.hideDropdown();
    this.emoji = emoji;
  }

  public getUnicodeItem(code: string): string {
    return `&#${this.classMapForReaction[code]}`;
  }
}
