import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserRole } from '@app/store/user/user.model';

@Component({
  selector: 'totalfit-redirect-component',
  template: '<router-outlet></router-outlet>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectComponentComponent {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    // if (window.location.href.indexOf('admin.') >= 0) {
    //   this.router.navigate(['/admin-root']);
    // } else {
    //   this.router.navigate(['/locations']);
    // }
    if (this.authService.currentUser && this.authService.currentUser.roles.includes(UserRole.Admin)) {
      this.router.navigate(['/admin-trainings']);
    } else {
      this.router.navigate(['/tribe']);
    }
  }
}
