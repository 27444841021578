import { ForSaveOnly, libRegExp, MediaFileExt } from '@app/library/_shared/library.model';

export function isMediaFile(filename: string): boolean {
  const fileName = filename.replace(/[{()}]/g, '');
  return MediaFileExt.includes(libRegExp.exec(fileName)[1] ? libRegExp.exec(fileName)[1].toLowerCase() : '');
}

export function isForSaveOnlyFile(file): boolean {
  const fileName = file.name.replace(/[{()}]/g, '');
  return ForSaveOnly.includes(libRegExp.exec(fileName)[1] ? libRegExp.exec(fileName)[1].toLowerCase() : '');
}
