<div class="folder">
  <button class="folder-close" type="button" mat-icon-button [mat-dialog-close]="false">
    <i class="icon-close"></i>
  </button>
  <h2>
    {{ data && data.title ? data.title : 'New folder' }}
  </h2>

  <div [formGroup]="nameForm" class="form-group form-group_full-width">
    <input formControlName="name" [totalfitInputAutofocus]="true" (keyup.enter)="onKeyPressEnter()" class="form-group__control form-group_full-width" placeholder="Untitled folder" type="text">
  </div>

  <div class="folder__action">
    <button mat-button [mat-dialog-close]="false">
      Cancel
    </button>

    <button [mat-dialog-close]="nameForm.value"
            mat-raised-button
            color="primary"
            [disabled]="nameForm.invalid">
      {{ data && data.buttonText ? data.buttonText : 'CREATE' }}
    </button>
  </div>
</div>
