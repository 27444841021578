import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExerciseOSLimit } from '@store/exercise/exercise.model';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {

  public exerciseTabsStorage: Map< string, ExerciseOSLimit[] > = new Map();
  constructor(private http: HttpClient) {}

  public getExerciseTabs(text: string, limit: number): Observable<ExerciseOSLimit[]> {
    return this.exerciseTabsStorage.has(`${text}, ${limit}`)
      ? of(this.exerciseTabsStorage.get(`${text}, ${limit}`))
      : this.http.post<ExerciseOSLimit[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/search/exercises`, { text, limit })
          .pipe(tap((value) => {
            this.exerciseTabsStorage.set(`${text}, ${limit}`, value);
          }));
  }
}
