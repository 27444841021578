<div *ngIf="form" class="social-networks d-flex">
  <a [class.active]="form.get('twitter').value"
     [href]="form.get('twitter').value"
     (click)="!form.get('twitter').value ? $event.preventDefault() : null"
     target="_blank">
    <i class="icon-Twitter"></i>
  </a>

  <a [href]="form.get('instagram').value"
     [class.active]="form.get('instagram').value"
     (click)="!form.get('instagram').value ? $event.preventDefault() : null"
     target="_blank">
    <i class="icon-Instagram"></i>
  </a>

  <a [href]="form.get('facebook').value"
     [class.active]="form.get('facebook').value"
     (click)="!form.get('facebook').value ? $event.preventDefault() : null"
     target="_blank">
    <i class="icon-Facebook"></i>
  </a>

  <a [href]="form.get('linkedin').value"
     [class.active]="form.get('linkedin').value"
     (click)="!form.get('linkedin').value ? $event.preventDefault() : null"
     target="_blank">
    <i class="icon-Linkedin"></i>
  </a>

  <button *ngIf="!hideEdit"
          mat-flat-button
          (click)="openEditDialog()"
          class="social-networks__edit-icon">
    <i class="icon-edit-filled"></i>
  </button>
</div>
