import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Address } from '@shared/models/location.model';
import { LocationNormalizerService } from '@shared/services/location.normalizer.service';

@Component({
  selector: 'totalfit-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationSelectorComponent implements OnInit {
  @Output() public loadedLocation = new EventEmitter<Address>();
  @Output() public isLoading = new EventEmitter<boolean>();
  @ViewChild('pacInput', { static: false }) public pacInput: ElementRef<HTMLInputElement>;
  private autocomplete: google.maps.places.Autocomplete;
  public options = {
    fields: ['ALL'],
    strictBounds: false,
  };

  constructor(private readonly locationNormalizer: LocationNormalizerService) {}
  public ngOnInit(): void {
    window.setTimeout(() => {
      this.autocomplete = new google.maps.places.Autocomplete(this.pacInput.nativeElement, this.options);
      this.autocomplete.addListener('place_changed', () => {
        this.isLoading.emit(true);
        const place = this.autocomplete.getPlace();
        this.loadedLocation.emit(this.locationNormalizer.addressNormalize(place));
        this.isLoading.emit(false);
      });

      const inputElement = this.pacInput.nativeElement;

      inputElement.addEventListener('keydown', () => {
        this.isLoading.emit(true);
      });
    });
  }
}
