import { ComponentType } from '@angular/cdk/portal';
import { EmbeddedViewRef, Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { CustomNotificationComponent } from '../components/notification/notification.component';

export interface CustomSnackBarData {
  message: string;
  title: string;
  type: CustomSnackBarDataType;
}

export enum CustomSnackBarDataType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackbar(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, config);
  }

  public openTemplate(template: TemplateRef<any>, config?: MatSnackBarConfig):  MatSnackBarRef<EmbeddedViewRef<any>> {
    return this.snackBar.openFromTemplate(template, config);
  }

  public openComponent<T, D = CustomSnackBarData>(component: ComponentType<T>, config?: MatSnackBarConfig<D>): MatSnackBarRef<T> {
    return this.snackBar.openFromComponent(component, config);
  }

  public openNotificationComponent<D = CustomSnackBarData>(config?: MatSnackBarConfig<D>): MatSnackBarRef<CustomNotificationComponent> {
    return this.snackBar.openFromComponent(CustomNotificationComponent, { panelClass: ['custom-notification-container'], horizontalPosition: 'right', verticalPosition: 'top', duration: 5000, ...config});
  }
}
