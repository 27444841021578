import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[totalfitViewMode]'
})
export class ViewModeDirective {

  constructor(public tpl: TemplateRef<any>) {
  }

}
