import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'totalfit-form-control',
  template: ''
})
export abstract class FormControlComponent<T> implements ControlValueAccessor {

  public get model(): T {
    return this.privateModel;
  }
  private privateModel: T;
  @Input() public isDisabled: boolean;
  protected onChange: (model: T) => void  = () => {};
  protected onTouched: () => void  = () => {};

  public updateModel(value: T) {
    this.privateModel = value;
    this.emitChanges();
  }

  public writeValue(value: T) {
    this.privateModel = value;
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouched = fn;
  }

  protected emitChanges() {
    this.onChange(this.model);
    this.onTouched();
  }
}

export function getValueAccessor(componentClass: unknown): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => componentClass),
    multi: true
  };
}

export function getValidator(componentClass: unknown): Provider {
  return {
    provide: NG_VALIDATORS,
    useExisting: componentClass,
    multi: true
  };
}
