import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

import {
  TooltipPlacement,
  TooltipPosition,
  TooltipPositionConfig,
  TooltipPositionRect,
  TooltipStyleSet,
  TooltipTrianglePositionConfig
} from '@shared/models/tooltip.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[totalfitTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input() public totalfitTooltip: string;
  @Input() public totalfitTooltipPlacement: TooltipPlacement = TooltipPlacement.top;
  private readonly truncatedTextClassName = 'text-ellipsis';
  private readonly iconButtonClassName = 'btn_icon';
  private readonly tooltipIndent = 4;
  private readonly defaultIndent = 16;
  private readonly tooltipPositionDefaults: TooltipPosition<'auto'> = {
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
  };
  private tooltipContainerElement: HTMLDivElement = null;
  private tooltipBodyElement: HTMLDivElement;
  private tooltipTriangleElement: HTMLSpanElement;
  private timeoutKey: number = null;
  private isTooltipVisible = false;
  private isAlive = true;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  public ngOnDestroy(): void {
    this.isAlive = false;
    if (this.isTooltipVisible) {
      this.renderer.removeChild(document.body, this.tooltipContainerElement);
    }
  }

  @HostListener('mouseenter')
  public showTooltip(): void {
    if (!this.isTooltipCanBeVisible) {
      return;
    }

    if (this.tooltipContainerElement === null) {
      this.initTooltipElement();
    }

    if (this.totalfitTooltip) {
      this.tooltipBodyElement.innerText = this.totalfitTooltip;
      this.toggleTooltipVisibility(true);
    }
  }

  @HostListener('mouseleave')
  public hideTooltip(): void {
    if (!this.isTooltipCanBeVisible) {
      return;
    }

    this.toggleTooltipVisibility(false);
  }

  private get isTooltipCanBeVisible(): boolean {
    const nativeElement = this.elementRef.nativeElement;

    if (this.hasClassName(nativeElement, this.iconButtonClassName)) {
      return true;
    }

    const tooltipElement = (!this.hasClassName(nativeElement) && this.hasClassName(nativeElement.parentElement))
      ? nativeElement.parentElement
      : nativeElement;

    return !this.hasClassName(tooltipElement) || tooltipElement.offsetWidth < tooltipElement.scrollWidth;
  }

  private hasClassName(element: HTMLElement, className = this.truncatedTextClassName): boolean {
    return element.classList.contains(className);
  }

  private initTooltipElement(): void {
    this.tooltipContainerElement = this.renderer.createElement('div');
    this.tooltipBodyElement = this.renderer.createElement('div');
    this.tooltipTriangleElement = this.renderer.createElement('span');

    this.renderer.addClass(this.tooltipContainerElement, 'totalfit-tooltip');
    this.renderer.addClass(this.tooltipBodyElement, 'totalfit-tooltip__body');
    this.renderer.addClass(this.tooltipBodyElement, 'mat-body-1');
    this.renderer.addClass(this.tooltipTriangleElement, 'totalfit-tooltip__triangle');

    this.renderer.appendChild(this.tooltipContainerElement, this.tooltipBodyElement);
    this.renderer.appendChild(this.tooltipContainerElement, this.tooltipTriangleElement);
  }

  private updateTooltipPosition(placement = this.totalfitTooltipPlacement): void {
    const domRect = this.getPositionRect(this.elementRef.nativeElement);
    let triangleConfig: TooltipTrianglePositionConfig;
    let bodyConfig: TooltipPositionConfig;

    switch (placement) {
      case TooltipPlacement.top: {
        triangleConfig = {
          firstBorderPropertyName: 'border-left',
          secondBorderPropertyName: 'border-right',
          firstPositionPropertyName: 'bottom',
          firstPositionValue: domRect.bottom + domRect.height + this.tooltipIndent,
          secondPositionPropertyName: 'left',
          secondPositionValue: domRect.left + domRect.width / 2 - this.tooltipIndent,
        };
        bodyConfig = {
          firstPositionValue: triangleConfig.firstPositionValue + this.tooltipIndent,
          secondPositionValue: triangleConfig.secondPositionValue - this.tooltipBodyElement.offsetWidth / 2 + this.tooltipIndent / 2,
        };
        break;
      }
      case TooltipPlacement.bottom: {
        triangleConfig = {
          firstBorderPropertyName: 'border-left',
          secondBorderPropertyName: 'border-right',
          firstPositionPropertyName: 'top',
          firstPositionValue: domRect.top + domRect.height + this.tooltipIndent,
          secondPositionPropertyName: 'left',
          secondPositionValue: domRect.left + domRect.width / 2 - this.tooltipIndent,
        };
        bodyConfig = {
          firstPositionValue: triangleConfig.firstPositionValue + this.tooltipIndent,
          secondPositionValue: triangleConfig.secondPositionValue - this.tooltipBodyElement.offsetWidth / 2 + this.tooltipIndent / 2,
        };
        break;
      }
      case TooltipPlacement.left: {
        triangleConfig = {
          firstBorderPropertyName: 'border-top',
          secondBorderPropertyName: 'border-bottom',
          firstPositionPropertyName: 'top',
          firstPositionValue: domRect.top + domRect.height / 2 - this.tooltipIndent,
          secondPositionPropertyName: 'right',
          secondPositionValue: domRect.right + domRect.width + this.tooltipIndent,
        };
        bodyConfig = {
          firstPositionValue: triangleConfig.firstPositionValue - this.tooltipBodyElement.offsetHeight / 2 + this.tooltipIndent,
          secondPositionValue: triangleConfig.secondPositionValue + this.tooltipIndent,
        };
        break;
      }
      case TooltipPlacement.right: {
        triangleConfig = {
          firstBorderPropertyName: 'border-top',
          secondBorderPropertyName: 'border-bottom',
          firstPositionPropertyName: 'top',
          firstPositionValue: domRect.top + domRect.height / 2 - this.tooltipIndent,
          secondPositionPropertyName: 'left',
          secondPositionValue: domRect.left + domRect.width + this.tooltipIndent,
        };
        bodyConfig = {
          firstPositionValue: triangleConfig.firstPositionValue - this.tooltipBodyElement.offsetHeight / 2 + this.tooltipIndent,
          secondPositionValue: triangleConfig.secondPositionValue + this.tooltipIndent,
        };
        break;
      }
    }

    bodyConfig.firstPositionPropertyName = triangleConfig.firstPositionPropertyName;
    bodyConfig.secondPositionPropertyName = triangleConfig.secondPositionPropertyName;
    this.setTooltipBodyPosition(bodyConfig);
    this.setTooltipTrianglePosition(triangleConfig, placement);
  }

  private setTooltipBodyPosition(config: TooltipPositionConfig): void {
    this.setStyles(this.tooltipBodyElement, {
      ...this.tooltipPositionDefaults,
      [config.firstPositionPropertyName]: `${config.firstPositionValue}px`,
      [config.secondPositionPropertyName]: `${config.secondPositionValue}px`,
    });

    const domRect = this.getPositionRect(this.tooltipBodyElement);

    Object.keys(TooltipPlacement).forEach((placement) => {
      if (domRect[placement] < this.defaultIndent) {
        this.renderer.setStyle(this.tooltipBodyElement, placement, `${this.defaultIndent}px`);
      }
    });
  }

  private setTooltipTrianglePosition(config: TooltipTrianglePositionConfig, placement: TooltipPlacement): void {
    const triangleBorderStyle = `${this.tooltipIndent}px solid transparent`;

    this.setStyles(this.tooltipTriangleElement, {
      ...this.tooltipPositionDefaults,
      border: 'none',
      [config.firstPositionPropertyName]: `${config.firstPositionValue}px`,
      [config.firstBorderPropertyName]: triangleBorderStyle,
      [config.secondPositionPropertyName]: `${config.secondPositionValue}px`,
      [config.secondBorderPropertyName]: triangleBorderStyle,
      [`border-${placement}`]: `${this.tooltipIndent}px solid #000000`,
    });
  }

  private getPositionRect(element: HTMLElement): TooltipPositionRect {
    const {top, left, width, height} = element.getBoundingClientRect();

    return {
      top,
      left,
      width,
      height,
      right: window.innerWidth - left - width,
      bottom: window.innerHeight - top - height
    };
  }

  private setStyles(element: HTMLElement, styles: TooltipStyleSet): void {
    Object.keys(styles).forEach((key) => this.renderer.setStyle(element, key, styles[key]));
  }

  private toggleTooltipVisibility(isVisible: boolean): void {
    if (this.timeoutKey !== null) {
      window.clearInterval(this.timeoutKey);
    }

    if (isVisible) {
      this.timeoutKey = window.setTimeout(() => {
        if (!this.isAlive) {
          return;
        }

        this.renderer.appendChild(document.body, this.tooltipContainerElement);
        this.updateTooltipPosition();

        switch (this.totalfitTooltipPlacement) {
          case TooltipPlacement.top: {
            if (this.tooltipBodyElement.offsetHeight < this.tooltipBodyElement.scrollHeight) {
              this.updateTooltipPosition(TooltipPlacement.bottom);
            }
            break;
          }
          case TooltipPlacement.bottom: {
            if (this.tooltipBodyElement.offsetHeight < this.tooltipBodyElement.scrollHeight) {
              this.updateTooltipPosition(TooltipPlacement.top);
            }
            break;
          }
          case TooltipPlacement.left: {
            if (this.tooltipBodyElement.offsetWidth < this.tooltipBodyElement.scrollWidth) {
              this.updateTooltipPosition(TooltipPlacement.right);
            }
            break;
          }
          case TooltipPlacement.right: {
            if (this.tooltipBodyElement.offsetWidth < this.tooltipBodyElement.scrollWidth) {
              this.updateTooltipPosition(TooltipPlacement.left);
            }
            break;
          }
        }

        window.setTimeout(() => this.renderer.setStyle(this.tooltipContainerElement, 'opacity', 1));
        this.timeoutKey = null;
        this.isTooltipVisible = true;
        merge(
          fromEvent(document, 'wheel'),
          fromEvent(document, 'mousemove').pipe(
            filter(() => this.elementRef.nativeElement.attributes.getNamedItem('disabled') !== null)
          )
        )
          .pipe(
            untilDestroyed(this),
            takeWhile(() => this.isTooltipVisible)
          )
          .subscribe(() => this.hideTooltip());
      }, 300);
    } else {
      this.isTooltipVisible = false;

      if (this.tooltipContainerElement !== null) {
        this.renderer.setStyle(this.tooltipContainerElement, 'opacity', 0);
        window.setTimeout(() => this.renderer.removeChild(document.body, this.tooltipContainerElement), 100);
      }
    }
  }
}
