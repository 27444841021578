import { Action, createReducer, on } from '@ngrx/store';
import * as fromLibraryActions from '@app/store/library/library.actions';
import { LibraryState } from '@app/store/library/library.model';

export const libraryInitialState: LibraryState = {
  currentStreamTime: null,
  playStatus: null,
  streamDuration: null,
  streamSrc: null,
  streamName: null,
  lastAddedLibraryItem: null,
  failedFile: null
};

const libraryReducer = createReducer(
  libraryInitialState,
  on(fromLibraryActions.streamSet, (state, { stream }) => {
    return {
      ...state,
      streamSrc: stream
    };
  }),

  on(fromLibraryActions.streamSetName, (state, { name }) => {
    return {
      ...state,
      streamName: name
    };
  }),

  on(fromLibraryActions.streamClose, (state) => {
    return {
      ...state,
      streamSrc: null,
      currentStreamTime: null,
      playStatus: null,
      streamDuration: null
    };
  }),

  on(fromLibraryActions.startPlay, (state) => {
    return {
      ...state,
      playStatus: 'play'
    };
  }),

  on(fromLibraryActions.stopPlay, (state) => {
    return {
      ...state,
      playStatus: 'pause'
    };
  }),

  on(fromLibraryActions.addFileSuccess, (state, { file }) => {
    return {
      ...state,
      lastAddedLibraryItem: file,
      failedFile: null
    };
  }),

  on(fromLibraryActions.removeLastFile, (state) => {
    return {
      ...state,
      lastAddedLibraryItem: null
    };
  }),

  on(fromLibraryActions.addFileFailed, (state, { file }) => {
    return {
      ...state,
      lastAddedLibraryItem: null,
      failedFile: file
    };
  })
);

export function reducer(state: LibraryState | undefined, action: Action) {
  return libraryReducer(state, action);
}
