import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { WorkoutService } from '@app/workout/_shared/workout.service';
import { LoadingPageComponent } from '@shared/models/loading-page.component';
import { ActivatedRoute } from '@angular/router';
import { WorkoutFormService } from '@app/workout/_shared/workout-form.service';
import { WorkoutTemplates } from '@shared/models/workoutTemplates.model';
import { Autofill, RepresentationMetricLabels, Story, WorkoutView } from '@shared/models/workout.model';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { WorkoutStoryService } from '@shared/services/workout-story.service';
import { Store } from '@ngrx/store';
import { getStoryList, searchStoryList } from '@store/story/story.actions';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import * as fromStorySelector from '@store/story/story.selector';
import * as fromExerciseActions from '@store/exercise/exercise.actions';
import { map, tap } from 'rxjs/operators';
import { ExerciseOSLimit } from '@store/exercise/exercise.model';
import * as fromExerciseSelector from '@store/exercise/exercise.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-edit-workout-form',
  templateUrl: './edit-workout-form.component.html',
  styleUrls: ['./edit-workout-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditWorkoutFormComponent extends LoadingPageComponent implements OnInit {
  @Input() set workout(workout) {
    this.store$.dispatch(fromExerciseActions.resetExerciseTags());
    this.initForm(workout);
  };
  @Input() showSelectWod = true;
  @Input() workoutList;
  @Output() selectWorkout = new EventEmitter<WorkoutView>;

  public templateType: WorkoutTemplates;
  public recommendedWarmUp = [
    {
      name: 'General Warm-up #1',
      reqName: 'General Warm-up 1'
    },
    {
      name: 'General Warm-up #2',
      reqName: 'General Warm-up 2'
    },
    {
      name: 'General Warm-up #3',
      reqName: 'General Warm-up 3'
    },
    {
      name: 'General Warm-up #4',
      reqName: 'General Warm-up 4'
    },
    {
      name: 'General Warm-up #5',
      reqName: 'General Warm-up 5'
    }
  ];
  public recommendedCooldown = [
    {
      name: 'Upper Body Cooldown'
    },
    {
      name: 'Trunk Cooldown'
    },
    {
      name: 'Total Body Cooldown'
    },
    {
      name: 'Lower Body Cooldown'
    },
    {
      name: 'Full Body Cooldown'
    }
  ];
  private bundleId: string;

  public isStoryLoading$: Observable<boolean>;
  public storyList$: Observable<Story[]>;
  public storySearchType: BehaviorSubject<string> = new BehaviorSubject('');
  public exerciseTabs$: Observable<ExerciseOSLimit[]>;

  constructor(
    public workoutService: WorkoutService,
    public workoutStoryService: WorkoutStoryService,
    public workoutFormService: WorkoutFormService,
    public route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store$: Store
  ) {
    super(changeDetectorRef);
  }

  public ngOnInit() {
    this.exerciseTabs$ = this.store$.select(fromExerciseSelector.selectExerciseTabs)
      .pipe(tap((value) => {
        this.workoutFormService.workoutForm.get('exerciseIds').patchValue(value?.map((v) => v.id));
      }));

    this.isStoryLoading$ = this.store$.select(fromStorySelector.selectStoryIsLoading);
    this.storyList$ = combineLatest(
      [this.store$.select(fromStorySelector.selectStoryList),
        this.store$.select(fromStorySelector.selectSearchedStories),
        this.storySearchType
      ]
    ).pipe(map(([storyList, searchList, search]) => {
      if (search) {
        return searchList;
      } else {
        return storyList?.objects;
      }
    }));

    this.workoutFormService.customWorkoutForm.get('wod').valueChanges.pipe(untilDestroyed(this), tap((value) => {
      this.store$.dispatch(fromExerciseActions.getExerciseTags({ text: value, limit: 50 }));
    })).subscribe();
  }

  public initForm(workout: WorkoutView): void {
    this.workoutFormService.workoutForm = this.workoutFormService.getWorkoutForm();
    this.workoutFormService.customWorkoutForm = this.workoutFormService.getCustomWorkoutForm();
    this.workoutFormService.resetWorkoutSelectValues();
    this.bundleId = this.route.snapshot.queryParams.bundleId;

    if (workout) {
      this.workoutFormService.initWorkoutForm(workout);

      this.workoutFormService.setHabits(this.workoutService.habitList.find((hlh) => {
        return hlh.id === workout.healthyLifestyleHabit;
      }));

      this.workoutFormService.setStory(workout.story);
      this.selectWorkout.emit(workout);
    }

    if (workout?.customOfflineWorkoutExtensions) {
      this.workoutFormService
        .patchCustomWorkoutForm(workout ? workout.customOfflineWorkoutExtensions : null);
    }

    if(window.sessionStorage.getItem('workoutPreviewSingle')) {
      this.workoutFormService.initWorkoutForm(JSON.parse(window.sessionStorage.getItem('workoutPreviewSingle')));

      window.sessionStorage.removeItem('workoutPreviewSingle');
    }

    if(window.sessionStorage.getItem('customWorkoutPreviewSingle')) {
      this.workoutFormService.patchCustomWorkoutForm(JSON.parse(window.sessionStorage.getItem('customWorkoutPreviewSingle')));

      window.sessionStorage.removeItem('customWorkoutPreviewSingle');
    }

    this.templateType = this.route.snapshot.queryParams.tmplType;

    if (this.bundleId) {
      this.workoutFormService.workoutForm.get('bundleId').patchValue(this.bundleId);
    }

    this.workoutFormService.setWorkoutFormService();
    this.workoutFormService.setCustomWorkoutFormService();

    const wodValue = this.workoutFormService.customWorkoutForm.get('wod').value;

    if (wodValue) {
      this.store$.dispatch(
        fromExerciseActions.getExerciseTags({ text: wodValue, limit: 50 })
      );
    }
  }

  public updateAutofill(autofill: Autofill, formControl: AbstractControl) {
    formControl.patchValue(autofill.exercises.reduce((exString, exercise) => {
      return exString.concat(`${exercise.quantity} ${RepresentationMetricLabels[exercise.metrics]} ${exercise.name} \n`);
    }, ''));
  }

  public toggleChange(event: MatSlideToggleChange, fieldName: string): void {
    this.workoutFormService.workoutSelectValues[fieldName] = event.checked;
    this.changeDetectorRef.markForCheck();
  }

  public get isProgramTemplate(): boolean {
    return +this.templateType === WorkoutTemplates.program;
  }

  public loadStories() {
    this.store$.dispatch(getStoryList(null));
  }

  public searchStories(name: string) {
    this.store$.dispatch(searchStoryList({ name }));
  }
}
