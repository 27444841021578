import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamic'
})
export class DynamicPipe implements PipeTransform {
  public transform(value: unknown, pipeInstance: PipeTransform, pipeArgs: unknown[] = []): unknown {
    return pipeInstance
      ? pipeInstance.transform(value, ...pipeArgs)
      : value;
  }
}
