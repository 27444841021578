import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedFormService } from '@shared/services/shared-form-service';
import { BehaviorSubject, of } from 'rxjs';
import { FormGroupValidatorDirective } from '@shared/directives/form-group-validator.directive';
import { CustomSnackBarDataType, SnackbarService } from '@shared/services/snackbar.service';
import { Training, TrainingStatus } from '@app/trainings/_shared/trainings.model';
import { TrainingsService } from '@shared/services/trainings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/store/user/user.model';
import { DialogService } from '@shared/services/dialog.service';
import { PublishNotificationComponent } from '@shared/components/training-edit/publish-notification/publish-notification.component';
import { switchMap } from 'rxjs/operators';
import { AmplitudeActions, AmplitudeService } from '@shared/services/amplitude.service';
import { MESSAGES } from '@shared/messages';

@Component({
  selector: 'totalfit-training-edit',
  templateUrl: './training-edit.component.html',
  styleUrls: ['./training-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainingEditComponent implements OnInit {
  @Input() public isAdmin = false;
  public trainingForm: UntypedFormGroup;
  public training: Training = null;
  public hasLimit: UntypedFormGroup;
  public instructors: Array<User & { firstNameAndLastName: string, isDisabled?: boolean, disabledLabel?: string }> = [];
  public commentControl: UntypedFormControl;
  public linkObs: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public saveType: string;

  constructor(
    public sharedFormService: SharedFormService,
    private trainingsService: TrainingsService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private readonly amplitudeService: AmplitudeService
  ) {}

  public ngOnInit() {
    [this.training, this.instructors] = this.route.snapshot.data.data;
    this.instructors = this.instructors.map((instructor) => {
      return {
        ...instructor,
        disabledLabel: 'Instructor selected.',
        isDisabled: this.getDisabledInstructorOption(this.training, instructor),
        firstNameAndLastName: `${instructor.firstName} ${instructor.lastName}`
      };
    });

    this.trainingForm = this.fb.group({
      address: this.fb.group({
        location: this.fb.group({
          country: [null, [Validators.required]],
          city: [null, [Validators.required]],
        }),
        address: [null, Validators.required],
      }),
      index: [],
      cost: [null],
      direction: [null, Validators.required],
      level: [null, Validators.required],
      hostPartner: this.fb.group({
        name:	[null],
        email: this.fb.array([
          this.fb.control([null])
        ]),
        phone: this.fb.array([
          this.fb.control(null)
        ]),
      }),
      leadInstructor: this.fb.group({
        id:	[],
        trainingId:	[this.training ? this.training.id : 0],
        type:	['LEAD_INSTRUCTOR', Validators.required],
        userId: [null, Validators.required]
      }),
      instructors: this.fb.array([
        this.fb.group({
          id:	[],
          trainingId:	[this.training ? this.training.id : 0],
          type:	['INSTRUCTOR', Validators.required],
          userId: [null, Validators.required]
        })
      ]),
      startDate: [null, Validators.required],
      finishDate: [null, Validators.required],
      participantLimit: [null],
      participants: this.fb.array([]),
      photo: [null],
      registrationLink: [null, Validators.required],
      status: [],
      zoneId: [Intl.DateTimeFormat().resolvedOptions().timeZone],
    });

    if (this.training && this.training.registrationLink) {
      this.linkObs.next(this.getFullLinkValue(this.training.registrationLink));
    }

    this.hasLimit = this.fb.group({ value: [null, Validators.required]});

    if (this.training) {
      if (!!this.training.participantLimit) {
        this.hasLimit.get('value').patchValue('Yes');
      } else {
        this.hasLimit.get('value').patchValue('No');
      }

      const { instructors, ...training } = this.training;
      const instructorsForm = (this.trainingForm.get('instructors') as UntypedFormArray);

      instructorsForm.clear();

      instructors.forEach((instructor, index) => {
        instructorsForm.push(this.newInstructorForm);

        instructorsForm.at(index).patchValue(instructor);
      });

      let parsedEmail;
      let parsedPhone;
      if (this.training.hostPartner) {
        parsedEmail = this.training.hostPartner.email ? this.training.hostPartner.email.split(' ') : null;
        parsedPhone = this.training.hostPartner.phone ? this.training.hostPartner.phone.split(' ') : null;

        const emailControler = this.trainingForm.get('hostPartner.email') as UntypedFormArray;
        const phoneControler = this.trainingForm.get('hostPartner.phone') as UntypedFormArray;

        if (parsedEmail || parsedPhone) {
          emailControler.clear();
          phoneControler.clear();

          if (parsedEmail) {
            parsedEmail.forEach((email) => {
              emailControler.push(this.fb.control([email]));
            });
          }

          if (parsedPhone) {
            parsedPhone.forEach((phone) => {
              phoneControler.push(this.fb.control([phone]));
            });
          }
        }
      }

      this.trainingForm.patchValue({
        ...training,
        hostPartner: {
          name: training.hostPartner && training.hostPartner.name ? training.hostPartner.name : null,
          email: parsedEmail || [null],
          phone: parsedPhone || [null],
        },
        address: {
          location: {
            country: training.address && training.address.location ? training.address.location.country : null,
            city: training.address && training.address.location ? training.address.location.city : null,
          },
          address: training.address ? training.address.address : null,
        },
        leadInstructor: this.training.leadInstructor ? this.training.leadInstructor : {}
      });

      if (this.training.address.location.country === 'United States') {
        this.setIndexValidation('US');
      }
    }

    this.commentControl = this.fb.control(null);
  }

  public getFilteredValue(value: string) {
    const filterValue = value.toLowerCase();
    return of(this.sharedFormService.countries.filter(
      ({ name }) => (String(name).toLowerCase().startsWith(filterValue))
    ));
  }

  public generateLink(): void {
    this.trainingsService.getRegistrationLink()
      .subscribe((data) => {
        this.linkObs.next(this.getFullLinkValue(data.link));
        return this.trainingForm.get('registrationLink').patchValue(data.link);
      });

    this.changeDetector.markForCheck();
  }

  public getFullLinkValue(link: string): string {
    return link ? `${window.location.origin}/training/${link ? link : 'Your Value'}/registration` : '';
  }

  public get newInstructorForm(): UntypedFormGroup {
    return this.fb.group({
      id:	[],
      trainingId:	[this.training ? this.training.id : 0],
      type:	['INSTRUCTOR', Validators.required],
      userId: [null, Validators.required]});
  }

  public saveChanges(type: string): void {
    this.saveType = type;
    if (type === 'LOCAL_SAVE') {
      FormGroupValidatorDirective.removeValidators(this.trainingForm.get('address.location.country'));
      FormGroupValidatorDirective.removeValidators(this.trainingForm.get('address.location.city'));
      FormGroupValidatorDirective.removeValidators(this.trainingForm.get('address.address'));
      FormGroupValidatorDirective.removeValidators(this.trainingForm.get('registrationLink'));
      FormGroupValidatorDirective.removeValidators(this.trainingForm.get('participantLimit'));
      FormGroupValidatorDirective.removeValidators(this.hasLimit);
    }

    if ((!FormGroupValidatorDirective.updateFormValidity(this.trainingForm) &&
          !FormGroupValidatorDirective.updateFormValidity(this.hasLimit)) || this.trainingForm.invalid) {
      this.snackbarService.openNotificationComponent({
        data: {
          message: MESSAGES.formError,
          title: MESSAGES.formErrorTitle,
          type: CustomSnackBarDataType.WARNING
        }
      });
      return;
    }

    const emailJoined = this.trainingForm.value.hostPartner.email.join(' ');
    const phoneJoined = this.trainingForm.value.hostPartner.phone.join(' ');

    (() => {
      const itemForSave = {
        ...this.trainingForm.value,
        hostPartner: {
          ...this.trainingForm.value.hostPartner,
          email: emailJoined ? emailJoined : null,
          phone: phoneJoined ? phoneJoined : null
        },
        status: type
      };

      if (type === 'LOCAL_SAVE') {
        if (this.training && this.training.id) {
          return this.trainingsService.editTraining(itemForSave, this.training.id);
        } else {
          return this.trainingsService.createTraining(itemForSave);
        }
      } else {
        return this.dialogService.openDialog(PublishNotificationComponent)
          .pipe(switchMap((data) => {
            if (data) {
              if (this.training && this.training.id) {
                return this.trainingsService.editTraining(itemForSave, this.training.id);
              } else {
                return this.trainingsService.createTraining(itemForSave);
              }
            } else {
              return of(null);
            }
          }));
      }
    })().subscribe((data: Training) => {
      if (data) {
        if (this.saveType === TrainingStatus.LocalSave) {
          this.pingAmplitude(AmplitudeActions.TRAINING_LOCAL_SAVE, data);
        } else {
          this.pingAmplitude(AmplitudeActions.TRAINING_PUBLISH, data);
        }
        this.router.navigate([this.router.url.includes('admin-trainings') ? 'admin-trainings' : '/trainings']);
      }
    });
  }

  public adminSave(status: any): void {
    this.trainingsService.approveTraining({
      status,
      text: this.commentControl.value,
      receiver: this.training.leadInstructor.email,
      trainingId: this.training.id
    })
      .subscribe(() => this.router.navigate(['/admin-trainings']));
  }

  public setParticipantsLimitValidator(event: string): void {
    if (event === 'Yes') {
      this.trainingForm.get('participantLimit').setValidators(Validators.required);
      this.hasLimit.get('value').setValidators(Validators.required);
    } else {
      this.trainingForm.get('participantLimit').clearValidators();
      this.hasLimit.get('value').clearValidators();
    }
  }

  public setLeadValue(instructor: User): void {
    this.trainingForm.get('leadInstructor.userId').patchValue(instructor.id);

    this.setDisabledInstructorOptions();
  }

  public setInstructor(formItem: AbstractControl, instructor: User): void {
    formItem.get('userId').patchValue(instructor.id);

    this.setDisabledInstructorOptions();
  }

  public getTrainingCost(country: { name: string; id: string }): void {
    this.setIndexValidation(country.id);
    this.trainingsService.getTrainingCost(country.name)
      .subscribe((value: any) => this.trainingForm.get('cost').patchValue(value.cost));
  }

  public getCurrentTimeStamp(): string {
    return new Date().toISOString();
  }

  public get saveString(): string {
    return 'You can\'t save your changes';
  }

  private setDisabledInstructorOptions(): void {
    this.instructors = this.instructors.map((instructor) => {
      return { ...instructor, isDisabled: this.getDisabledInstructorOption(this.trainingForm.value, instructor) };
    });

    this.changeDetector.markForCheck();
  }

  private setIndexValidation(id: string) {
    if (id === 'US') {
      this.trainingForm.get('index').setValidators(Validators.required);
    } else {
      this.trainingForm.get('index').clearValidators();
    }
  }

  private getDisabledInstructorOption(trainingValue, selectedInstructor): boolean {
    if (trainingValue) {
      return trainingValue.leadInstructor && trainingValue.leadInstructor.userId === selectedInstructor.id
        || !!trainingValue.instructors.find((instructor) => instructor.userId === selectedInstructor.id);
    }
  }

  private pingAmplitude(action: AmplitudeActions, data): void {
    this.amplitudeService.triggerEvent(action, {
      training_address: data.address.address,
      training_city: data.address.location.city,
      training_country: data.address.location.country,
      training_lead_email: data.leadInstructor.email
    });
  }
}
