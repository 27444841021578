export interface ParsedTime {
  hours: string;
  minutes: string;
  timeFormat: string | 'am' | 'pm';
}

export interface CalendarEventDay {
  day: string;
  events?: CalendarEventV1Out[];
}

export interface CalendarEvent {
  title: string;
  timeStart: string;
  timeEnd: string;
  coach: string;
  id?: number;
  color?: string;
}

export type HourTime = Omit<ParsedTime, 'minutes'>;

export const AllHours = ['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

export type ColorPalette = ['red', 'orange', 'dark-green', 'green', 'dark-blue', 'blue', 'purple', 'grey'];

export const ColorPalette: ColorPalette = ['red', 'orange', 'dark-green', 'green', 'dark-blue', 'blue', 'purple', 'grey'];

export enum RepeatEvents {
  NEVER = 'NEVER',
  EVERY_DAY = 'EVERY_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  SUNDAY = 'SUN',
  MONDAY = 'MON',
  TUESDAY = 'TUE',
  WEDNESDAY = 'WED',
  THURSDAY = 'THU',
  FRIDAY = 'FRI',
  SATURDAY = 'SAT'
}

// Каждую неделю и + пятницу (изначальный ивент в пятницу)
// Каждый день, + повторение в дни (выходят дубликаты воркаутов)

export enum CalendarScope {
  DAY = 'DAY',
  WEEK = 'WEEK'
}

export interface CalendarEventV1 {
  color: keyof ColorPalette;
  date: string;
  direction: string;
  duration: number;
  name: string;
  programId?: string;
  workoutId?: string;
  repeat?: string;
  zoneId: string;
  gymId: string;
}

export interface CalendarEventV1Out {
  coachId: string;
  color: keyof ColorPalette;
  direction: string;
  eventTime: { date: string, duration: number; zoneId: string, time: string };
  gymId: string;
  id: string;
  name: string;
  programId: string;
  repeat: RepeatEvents;
}

export interface CalendarEventV1OutList {
  events: CalendarEventV1Out[];
}

export interface CalendarEventList {
  events: CalendarEventV1[];
}

// start time
// location Id
// time: string
