import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FileUploadService } from '@shared/services/file-upload.service';
import { FormControlComponent, getValueAccessor } from '@shared/models/form-control.component';
import { finalize } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { Document } from '@app/people/_shared/document.model';
import { CustomSnackBarDataType, SnackbarService } from '@shared/services/snackbar.service';
import { environment } from '@environments/environment';
import { Certificates } from '@shared/models/web-profile.model';
import { MESSAGES } from '@shared/messages';

@Component({
  selector: 'totalfit-photo-drop',
  templateUrl: './photo-drop.component.html',
  styleUrls: ['./photo-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getValueAccessor(PhotoDropComponent)]
})
export class PhotoDropComponent extends FormControlComponent<string> implements OnInit {
  @Input() public set defaultImage(value: string) {
    if (value) {
      this.updateModel(value);
      this.fileSrc = this.model;
    }
  }
  @Input() public isDropDisable = false;
  @Input() public template: 'square' | 'circle' = 'square';
  @Input() public placeholderImage: string = null;
  @Input() public showClose = true;
  @Input() public emptyText;
  @Input() public clearModelAfterGetImage = false;
  @Input() public dropWithEmit = false;
  @Input() public acceptType = '';
  @Output() public getImageSrc = new EventEmitter<string>();
  @Output() public closePhoto = new EventEmitter<boolean>();
  @Output() public getImageWithName = new EventEmitter<Document | Certificates>();
  @Output() public fileEmmit = new EventEmitter();
  public fileSrc: string;
  public progress = 0;

  constructor(
    private fileUploadService: FileUploadService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  public ngOnInit(): void {
    if(this.placeholderImage) {
      this.updateModel(this.placeholderImage);
    }

    this.fileSrc = this.model;
    this.changeDetectorRef.markForCheck();
  }

  public onFileChange(event: HTMLInputElement) {
    if (event.files.length > 0) {
      const file = event.files[0];
      const fileName = file.name;
      const fileType = fileName.split('.').pop();

      if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'png' && fileType !== 'gif') {
        this.snackbarService.openNotificationComponent({
          data: {
            message: MESSAGES.invalidFileTypeMessage,
            title: MESSAGES.invalidFileTypeTitle,
            type: CustomSnackBarDataType.WARNING
          }
        });
        return;
      }

      const reader = new FileReader();
      let url;

      reader.onload = (readerEvent: any) => {
        url = readerEvent.target.result;

        if (this.dropWithEmit) {
          this.fileEmmit.emit({file, fileName, fileType, url});
        }
      };

      reader.readAsDataURL(file);

      this.clearModel();
      if (!this.dropWithEmit) {
        this.saveChanges(event, file, fileName, fileType);
      }
    }
    else {
      this.updateModel('');
    }
  }

  public clearModel() {
    this.fileSrc = null;
    this.updateModel('');
    this.progress = 0;

    this.closePhoto.emit(true);
  }

  public saveChanges(event, file, fileName, fileType, img?) {
    this.fileUploadService.uploadParticipantsCsv(img ? img : file)
      .pipe(
        finalize(() => {
          if (event.value) {
            event.value = null;
          }
          this.changeDetectorRef.markForCheck();
        }))
      .subscribe((responseEvent: any) => {
        if (responseEvent.type === HttpEventType.UploadProgress) {
          this.progress += Math.round(100 * Number(responseEvent.loaded) / Number(responseEvent.total));

          this.changeDetectorRef.markForCheck();
        }
        else if (responseEvent.type === HttpEventType.Response) {
          if(responseEvent.body.name) {
            this.fileSrc = `${environment.amazonFilePath}/${responseEvent.body.name}`;
            const fileValue: {
              fileName: string;
              link: string;
              ext: string;
              placeholder?: string;
            } = {
              fileName,
              link: this.fileSrc,
              ext: fileType
            };

            if (!img) {
              fileValue.placeholder = `assets/img/file-placeholders/${fileType}.svg`;
            }
            this.getImageWithName.emit(fileValue);

            if (this.clearModelAfterGetImage) {
              this.clearModel();
            }

            this.updateModel(this.fileSrc);
          } else {
            this.fileSrc = null;
            this.updateModel('');
          }
        }
      }, () => {
        this.clearModel();
        this.snackbarService.openNotificationComponent({
          data: {
            message: 'We cannot process your file, please choose another one or try again later',
            title: MESSAGES.internalErrorTitle,
            type: CustomSnackBarDataType.WARNING
          }
        });
        this.snackbarService.openSnackbar('');
      });
  }
}
