import { Action, createReducer, on } from '@ngrx/store';
import * as fromLocationActions from '@app/store/location/location.actions';
import { LocationState } from '@app/store/location/location.model';

export const locationInitialState: LocationState = {
  locations: null,
  currentLocation: null,
  currentLocationList: null,
  tribeMembers: null,
  inviteLinks: null,
  newInviteLink: null,
  completedWorkouts: null,
  completedWorkout: null,
  waitingForReaction: null,
  mainLocationEvents: null,
  eventForEdit: null,
  mainLocationEventsForToday: null
};

const locationReducer = createReducer(
  locationInitialState,
  on(fromLocationActions.getLocationsSuccess, (state, { locations }) => {
    return {
      ...state,
      locations
    };
  }),

  on(fromLocationActions.clearCurrentLocation, (state) => {
    return {
      ...state,
      currentLocation: null
    };
  }),

  on(fromLocationActions.getMainLocationSuccess, (state, { location }) => {
    return {
      ...state,
      currentLocation: location
    };
  }),

  on(fromLocationActions.getMainLocationListSuccess, (state, { locations }) => {
    return {
      ...state,
      currentLocationList: locations
    };
  }),

  on(fromLocationActions.createMainLocationSuccess, (state, { location }) => {
    return {
      ...state,
      currentLocationList: [...state.currentLocationList, location]
    };
  }),

  on(fromLocationActions.getTribeMembersSuccess, (state, { tribeMembers }) => {
    return {
      ...state,
      tribeMembers
    };
  }),
  on(fromLocationActions.getInviteLinksSuccess, (state, { inviteLinks }) => {
    return {
      ...state,
      inviteLinks
    };
  }),
  on(fromLocationActions.createInviteLinkSuccess, (state, { newInviteLink }) => {
    return {
      ...state,
      newInviteLink
    };
  }),

  on(fromLocationActions.getCompletedWorkoutsSuccess, (state, { completedWorkouts }) => {
    return {
      ...state,
      completedWorkouts
    };
  }),

  on(fromLocationActions.getCompletedWorkoutDetailSuccess, (state, { completedWorkout }) => {
    return {
      ...state,
      completedWorkout
    };
  }),

  /// Updating only reaction for now. BE side also will update only reaction

  on(fromLocationActions.updateUserLogResultSuccess, (state, { logResult }) => {
    return {
      ...state,
      waitingForReaction: { ...state.waitingForReaction, objects: state.waitingForReaction.objects.map((wfr) => (wfr.id === logResult.id
            ? logResult
            : wfr
        ))}
    };
  }),

  on(fromLocationActions.updateCompletedWorkoutDetails, (state, { logResult }) => {
    return {
      ...state,
      completedWorkout: {
        ...state.completedWorkout,
        logResults: state.completedWorkout.logResults.map((result) => (
          result.id === logResult.id ? { ...result, reaction: logResult.reaction } : result)
        )
      }
    };
  }),

  on(fromLocationActions.getWaitingForReactionSuccess, (state, { waitingForReaction }) => {
    return {
      ...state,
      waitingForReaction
    };
  }),

  on(fromLocationActions.getMainLocationEventsSuccess, (state, { events }) => {
    const eventList = {...events};
    Object.keys(eventList).forEach((key) => {
      eventList[key] = getSortedByDate([...eventList[key]]);
    });

    return {
      ...state,
      mainLocationEvents: eventList
    };
  }),

  on(fromLocationActions.getMainLocationForTodayEventsSuccess, (state, { events }) => {
    const eventList = {...events};
    Object.keys(eventList).forEach((key) => {
      eventList[key] = getSortedByDate([...eventList[key]]);
    });

    return {
      ...state,
      mainLocationEventsForToday: eventList
    };
  }),

  on(fromLocationActions.createLocationEventSuccess, (state, { event }) => {
    const eventList = [
      ...(state.mainLocationEvents[event.date] || []),
      event
    ];

    return {
      ...state,
      mainLocationEvents: {
        ...state.mainLocationEvents,
        [event.date]: getSortedByDate(eventList)
      }
    };
  }),

  on(fromLocationActions.deleteLocationEventSuccess, (state, { event }) => {
    return {
      ...state,
      mainLocationEvents: {
        ...state.mainLocationEvents,
        [event.date]: [
          ...state.mainLocationEvents[event.date].filter((i) => {
            return i.id !== event.id;
          })
        ]
      }
    };
  }),

  on(fromLocationActions.deleteMainLocationSuccess, (state, { locationId }) => {
    return {
      ...state,
      currentLocationList: [...state.currentLocationList.filter((i) => {
        return +i.id !== +locationId;
      })]
    };
  }),

  on(fromLocationActions.deleteTribeMemberSuccess, (state, { id }) => {
    return {
      ...state,
      tribeMembers: {
        ...state.tribeMembers,
        objects: state.tribeMembers.objects.filter((i) => {
          return i.id !== id;
        })
      }
    };
  }),

  on(fromLocationActions.setLocationEventForEdit, (state, { event }) => {
    return {
      ...state,
      eventForEdit: event
    };
  }),

  on(fromLocationActions.setLocationWeek, (state, { week }) => {
    return {
      ...state,
      currentLocationWeek: week
    };
  }),

  on(fromLocationActions.setCurrentWorkoutForEdit, (state, { currentWorkoutForEdit }) => {
    return {
      ...state,
      currentWorkoutForEdit
    };
  }),
  // edit
  on(fromLocationActions.editLocationEventSuccess, (state, { event, oldDate }) => {
    let newEventMap;
    if (oldDate && (oldDate !== event.date)) {
      newEventMap = {
        ...state.mainLocationEvents,
        [oldDate]: [
          ...state.mainLocationEvents[oldDate].filter((i) => {
            return i.id !== event.id;
          })
        ],
        [event.date]: [
          ...(state.mainLocationEvents[event.date] || []), event
        ]
      };
    } else {
      newEventMap = {
        ...state.mainLocationEvents,
        [event.date]: [
          ...state.mainLocationEvents[event.date].map((i) => (i.id === event.id ? event : i)),
        ]
      };
    }

    newEventMap[event.date] = getSortedByDate(newEventMap[event.date]);

    return {
      ...state,
      mainLocationEvents: newEventMap,
      eventForEdit: null
    };
  }),

  on(fromLocationActions.resetNewInviteLink, (state) => {
    return {
      ...state,
      newInviteLink: null
    };
  }),

  on(fromLocationActions.deleteInviteLinkSuccess, (state, { link }) => {
    return {
      ...state,
      inviteLinks: { ...state.inviteLinks, objects: state.inviteLinks.objects.filter((l) => l.id !== link.id) }
    };
  }),

  on(fromLocationActions.resetNewInviteLinkList, (state) => {
    return {
      ...state,
      inviteLinks: null
    };
  })
);

export function reducer(state: LocationState | undefined, action: Action) {
  return locationReducer(state, action);
}

function getSortedByDate(list) {
  if (!list) {
    return;
  }

  return list.sort((a, b) => {
    const aTime = a.start.split(":");
    const bTime = b.start.split(":");
    const aHours = parseInt(aTime[0]);
    const bHours = parseInt(bTime[0]);
    const aMinutes = parseInt(aTime[1]);
    const bMinutes = parseInt(bTime[1]);
    if (aHours !== bHours) {
      return aHours - bHours;
    } else {
      return aMinutes - bMinutes;
    }
  });
}
