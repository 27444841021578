<div class="custom-notification mat-typography">
    <div class="custom-notification__header">
        <mat-icon *ngIf="data.type === 'success'" [ngClass]="data.type" aria-hidden="false" aria-label="checked">done</mat-icon>
        <mat-icon *ngIf="data.type === 'info'" [ngClass]="data.type" aria-hidden="false" aria-label="checked">priority_high</mat-icon>
        <mat-icon *ngIf="data.type === 'warning'" [ngClass]="data.type" aria-hidden="false" aria-label="checked">warning</mat-icon>
        <mat-icon *ngIf="data.type === 'error'" [ngClass]="data.type" aria-hidden="false" aria-label="checked">report</mat-icon>

        <h4>{{ data.title }}</h4>
        <button (click)="snackBarRef.dismiss()" class="icon-button">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="custom-notification__body">
        {{ data.message }}
    </div>
</div>
