<div class="update-plan mat-typography">
  <div class="update-plan__header">
    <button mat-flat-button class="icon-button" [mat-dialog-close]="true"><mat-icon>keyboard_backspace</mat-icon></button>

      <h2 class="update-plan__title">FAQ</h2>

    <button mat-flat-button class="icon-button" [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
  </div>
  <div class="update-plan__body">
    <ng-container>
      <div class="info">
        <div class="info__title">
          Google Maps longitude latitude
        </div>

        <p>1. Open maps.google.com on your computer. If simple mode (indicated by the lightning bolt icon at the bottom of the screen), you can't find out the place's coordinates. </p>
        <img src="assets/img/location-placeholders/1.svg" alt="">
        <p>2. Right-click on the desired area of the map.</p>
        <img src="assets/img/location-placeholders/2.svg" alt="">
        <p>3. Write or copy (left click on the selected area) coordinates in the first place in the drop-down list.</p>
        <img src="assets/img/location-placeholders/3.svg" alt="">
        <p>4. Fill in the required fields. Geo X is highlighted in red, Geo Y is highlighted in blue.</p>
        <img src="assets/img/location-placeholders/4.svg" alt="">
      </div>
    </ng-container>
  </div>
</div>
