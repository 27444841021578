<form (ngSubmit)="createStripeToken()" class="checkout">
  <label>Card Info</label>
  <div id="form-field" class="form-group form-group_default-width-1">
    <div id="card-number" class="form-group__control" #cardNumber></div>
    <div class="card-items-row">
      <div id="card-cvc" class="form-group__control" #cardCvc></div>
      <div id="card-cardExpiry" class="form-group__control" #cardExpiry></div>
    </div>
    <mat-error id="card-errors" role="alert" *ngIf="cardError">
      <mat-icon style="color: #f44336">cancel</mat-icon>
      &nbsp;{{ cardError }}
    </mat-error>
  </div>
  <totalfit-totalfit-spinner [isShown]="isLoading" spinnerClass="global-spinner"></totalfit-totalfit-spinner>
</form>
