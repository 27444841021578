<div *ngIf="documents" class="list">
  <div class="list__row">
    <div *ngFor="let doc of documentForm.controls; let i = index" class="document d-flex-center-y">
<!--                <totalfit-dots-menu [config]="documentDotsConfig"-->
<!--                                    class="document__selector d-flex-center">-->
<!--                </totalfit-dots-menu>-->
      <div class="document__img">
        <a *ngIf="!isEditMode" target="_blank" [href]="doc.value.link">
          <totalfit-photo-drop [showClose]="isEditMode"
                               [defaultImage]="doc.value.placeholder || doc.value.link"
                               (closePhoto)="deleteDoc(doc.value, i)">
          </totalfit-photo-drop>
        </a>

        <totalfit-photo-drop *ngIf="isEditMode"
                             [showClose]="isEditMode"
                             [defaultImage]="doc.value.placeholder || doc.value.link"
                             (closePhoto)="deleteDoc(doc.value, i)">
        </totalfit-photo-drop>
      </div>
      <div [class.document__text_edit]="isEditMode" class="document__text d-flex-center-center">
        <div *ngIf="!isEditMode"
           [totalfitTooltip]="doc.value.fileName"
           class="mat-body-1 text-ellipsis">
          {{ doc.value.fileName }}
        </div>
        <div *ngIf="isEditMode"
             class="form-group form-group_full-width">
          <input [totalfitNgModelChangeDebounceTime]="1000"
                 [formControl]="doc.get('fileName')"
                 [control]="doc.get('fileName')"
                 (ngModelChangeDebounced)="updateDoc(doc)"
                 class="form-group__control form-group_full-width">
        </div>
      </div>
    </div>
    <totalfit-photo-drop *ngIf="isEditMode"
                         [clearModelAfterGetImage]="true"
                         [emptyText]="emptyText"
                         (getImageWithName)="addNewDocument($event)"
                         class="document__drop">
    </totalfit-photo-drop>
  </div>
</div>


<div *ngIf="certificates" class="list">
  <div class="list__row">
    <div *ngFor="let doc of documentForm.controls; let i = index" class="document d-flex-center-y">
      <div class="document__img">
        <totalfit-photo-drop [showClose]="true"
                             [defaultImage]="doc.value.placeholder || doc.value.link"
                             (closePhoto)="deleteCertificate(doc.value, i)">
        </totalfit-photo-drop>
      </div>
    </div>
    <totalfit-photo-drop [clearModelAfterGetImage]="true"
                         [emptyText]="emptyText"
                         (getImageWithName)="addNewCertificate($event)"
                         class="document__drop">
    </totalfit-photo-drop>
  </div>
</div>
