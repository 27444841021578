import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef, EventEmitter,
  HostListener,
  Input,
  OnDestroy, Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[totalfitDropdownToggle]'
})
export class DropdownToggleDirective implements OnDestroy {
  @Input('totalfitDropdownToggle') public dropdownTemplate: TemplateRef<unknown>;
  @Input() public isPanelAlignedToRight = false;
  @Input() public isPanelHasFlexibleWidth = false;
  @Input() public isCloseOnWheelAction = true;
  @Input() public backdropClass: string;
  // tslint:disable-next-line:no-output-native
  @Output() public showDropDown = new EventEmitter<boolean>();
  @Input() public hostListener = 'click';
  public isDropdownOpened = false;
  private overlayRef: OverlayRef;
  private readonly dropdownPanelOffset = 3;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private renderer: Renderer2,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  public ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  @HostListener('click')
  public toggleDropdownVisibility(template?: TemplateRef<unknown>) {
    if (this.elementRef.nativeElement.hasAttribute('disabled')) {
      return;
    }

    if (this.isDropdownOpened) {
      this.hideDropdown();
    } else {
      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create({
          [this.isPanelHasFlexibleWidth ? 'minWidth' : 'width']: this.elementRef.nativeElement.offsetWidth,
          maxHeight: '100vh',
          hasBackdrop: true,
          backdropClass: `${this.backdropClass}`
        });
      }
      this.toggleButtonElementStyles();
      this.alignOverlay();
      this.overlayRef.attach(new TemplatePortal(template || this.dropdownTemplate, this.viewContainerRef));
      this.isDropdownOpened = true;

      const hideEvents: Array<Observable<Event>> = [this.overlayRef.backdropClick()];
      if (!this.isCloseOnWheelAction) {
        hideEvents.push(fromEvent(document, 'wheel'));
      }
      merge(...hideEvents)
      .pipe(take(1))
      .subscribe(() => {
        this.hideDropdown();
        this.changeDetector.markForCheck();
      });
    }

    this.showDropDown.emit(true);
    this.changeDetector.markForCheck();
  }

  @HostListener('wheel', ['$event'])
  public onMouseWheel(event: WheelEvent) {
    if (this.isDropdownOpened) {
      event.preventDefault();
    }
  }

  public hideDropdown() {
    if (!this.isDropdownOpened) {
      return;
    }

    this.overlayRef.detach();
    this.isDropdownOpened = false;
    this.toggleButtonElementStyles(true);
    this.showDropDown.emit(false);
  }

  private toggleButtonElementStyles(isRemoval = false) {
    const element = this.elementRef.nativeElement;

    if (isRemoval) {
      this.renderer.removeStyle(element, 'zIndex');
      this.renderer.removeStyle(element, 'position');
    } else {
      this.renderer.setStyle(element, 'zIndex', '1002');
      this.renderer.setStyle(element, 'position', 'relative');
    }
  }

  private alignOverlay(): void {
    const positionStrategy = this.overlayPositionBuilder
    .flexibleConnectedTo(this.elementRef)
    .withDefaultOffsetY(this.dropdownPanelOffset)
    .withFlexibleDimensions(false)
    .withPositions(this.isPanelAlignedToRight
      ? [
        {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
        {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: -this.dropdownPanelOffset}
      ]
      : [
        {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
        {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -this.dropdownPanelOffset}
      ]
    );

    this.overlayRef.updatePositionStrategy(positionStrategy);
  }
}
