import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'totalfit-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentCardComponent {

  constructor() {}
  @Input() public title: string;
  @Input() public secondTitle: string;
  @Input() public subtitle: string;
  @Input() public description: string;
  @Input() public cardLabel = '';
  // @Input() public cardLabel: { key: string, label: string };
  @Input() public hasImage = false;
  @Input() public hasFooter = false;
  @Input() public entity: any;
  @Input() public isFavourite: boolean;
  @Input() public isSelected: boolean;
  @Input() public showWhiteboard = false;
  @Input() public showAlias = false;
  @Input() public entityImageField = 'image';
  @Input() public cardLabelClass: string;
  @Input() public authorPhoto: string;
  @Input() public author: string;
  @Input() public authorOnImgPlaceholder: { img: string, name: string };
  @Input() public disabledCard: boolean;
  @Input() public showOnlyHeaderAction = false;
  @Output() public dblBodyClick: EventEmitter<any> = new EventEmitter();
  @Output() public bodyClick: EventEmitter<any> = new EventEmitter();
  @Output() public favorite: EventEmitter<boolean> = new EventEmitter();
  @Output() public whiteBoard: EventEmitter<boolean> = new EventEmitter();

  @ContentChild('customFooter', { static: false })
  public customFooterRef: TemplateRef<any>;

  @ContentChild('customBody', { static: false })
  public customBody: TemplateRef<any>;

  public matChipConfig = {
    author: {
      className: 'author',
      label: this.cardLabel
    },
    totalfitCreated :{
      className: 'totalfit',
      label: 'Totalfit Created'
    },
    directionHome : {
      className: 'directionHome',
      label: 'Home'
    },
    directionGo : {
      className: 'directionHome',
      label: 'Home'
    }
  };
}
