import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  AdminWorkoutView,
  CustomWorkoutView,
  Habit, OnlineExercise,
  OnlineMetric,
  OnlineMetricLabels, OnlineWorkout,
  RepresentationMetricLabels,
  WodType, WorkoutTypes,
  WorkoutView
} from '@shared/models/workout.model';
import { environment } from '@environments/environment';
import { tap } from 'rxjs/operators';
import { PaginationModel } from '@shared/models/pagination.model';
import { createLabelsFromEnum } from '@shared/utils/utils';
import {
  CustomOfflineWorkoutBlocks,
  CustomOfflineWorkoutExtensions,
  TemplateWorkoutBundle, UserComplexOfflineWorkouts
} from '@store/workout/workout.model';
import { ScrollConfig } from '@shared/models/scroll-pagination.model';

@Injectable({
  providedIn: 'root'
})
export class WorkoutService {
  public habitList: Habit[] = null;
  public templateExercisesList: any = null;
  public exerciseAvailableMetrics: Map<string, Array<{ name: string, id: string }>> = new Map();
  public workoutList: WorkoutView[];
  public metric = createLabelsFromEnum(OnlineMetric, OnlineMetricLabels);
  public stashWorkout: OnlineWorkout = null;
  public stashWorkoutPath;

  public lastSelectedWorkoutsPagination = new Set();
  public loadedWorkouts: WorkoutView[] = [];
  private scrollConfig: ScrollConfig = null;
  private lastLoadedPage = 0;

  constructor(
    private http: HttpClient
  ) {}

  public getAllTemplates(
    pageSize: number,
    pageNumber: number
  ): Observable<PaginationModel<AdminWorkoutView>> {
    return this.http
      .get<PaginationModel<AdminWorkoutView>>(`${environment.apiUrl}/${environment.apiV.apiV1}/templates/offline-workouts?size=${pageSize}&page=${pageNumber}`);
  }

  public getAllTemplatesAdmin(
    pageSize: number,
    pageNumber: number
  ): Observable<PaginationModel<AdminWorkoutView>> {
    return this.http
      .get<PaginationModel<AdminWorkoutView>>(`${environment.apiUrl}/${environment.apiV.apiV1}/templates/offline-workouts/admin?size=${pageSize}&page=${pageNumber}`);
  }

  public getAllTemplatesBundleAdmin(
      pageSize: number,
      pageNumber: number
  ): Observable<PaginationModel<TemplateWorkoutBundle>> {
    return this.http
        .get<PaginationModel<TemplateWorkoutBundle>>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/templates/admin?size=${pageSize}&page=${pageNumber}`);
  }

  public getWorkoutBundleTemplateById(
      id: string
  ): Observable<TemplateWorkoutBundle> {
    return this.http
        .get<TemplateWorkoutBundle>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/templates/${id}`);
  }

  public createTemplate(
    template: AdminWorkoutView
  ): Observable<AdminWorkoutView> {
    return this.http
      .post<AdminWorkoutView>(`${environment.apiUrl}/${environment.apiV.apiV1}/templates/offline-workouts`, template);
  }

  public editWorkoutBundleTemplate(
      templateWorkoutBundle: TemplateWorkoutBundle
  ): Observable<TemplateWorkoutBundle> {
    return this.http
        .put<TemplateWorkoutBundle>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/templates/${templateWorkoutBundle.id}`, templateWorkoutBundle);
  }

  public createWorkoutBundleTemplate(
      templateWorkoutBundle: Partial<TemplateWorkoutBundle>
  ): Observable<TemplateWorkoutBundle> {
    return this.http
        .post<TemplateWorkoutBundle>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/templates`, templateWorkoutBundle);
  }

  public getTemplateById(
    id: string
  ): Observable<AdminWorkoutView> {
    return this.http
      .get<AdminWorkoutView>(`${environment.apiUrl}/${environment.apiV.apiV1}/templates/offline-workouts/${id}`);
  }

  public getDropDownList(): Observable<UserComplexOfflineWorkouts> {
    return this.http
      .get<UserComplexOfflineWorkouts>(`${environment.apiUrl}/${environment.apiV.apiV1}/managements/offline-workouts/drop-down-list`);
  }

  public editTemplateById(
    template: AdminWorkoutView
  ): Observable<AdminWorkoutView> {
    return this.http
      .put<AdminWorkoutView>(`${environment.apiUrl}/${environment.apiV.apiV1}/templates/offline-workouts/${template.id}`, template);
  }

  public removeTemplateById(
    id: string
  ): Observable<number> {
    return this.http
      .delete<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/templates/offline-workouts/${id}`);
  }

  public removeWorkoutBundleTemplateById(
      id: string
  ): Observable<number> {
    return this.http
        .delete<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/workout-bundles/${id}`);
  }

  public getWorkouts(pageSize: number, pageNumber: number): Observable<PaginationModel<WorkoutView>> {
    return this.lastSelectedWorkoutsPagination.has(`${pageSize}, ${pageNumber}`)
      ?
        of({ objects: this.loadedWorkouts, pagesCount: pageNumber } as PaginationModel<WorkoutView>)
      :
        this.http
          .get<PaginationModel<WorkoutView>>(`${environment.apiUrl}/${environment.apiV.apiV1}/offline-workouts?size=${pageSize}&page=${pageNumber}&favorite=${false}`)
          .pipe(
            tap((res) => {
              this.workoutList = res.objects;
              this.loadedWorkouts = [...this.loadedWorkouts, ...res.objects];
              this.lastSelectedWorkoutsPagination.add(`${pageSize}, ${pageNumber}`);
            })
          );
  }

  public getOfflineWorkoutList(pageSize: number, pageNumber: number): Observable<PaginationModel<WorkoutView>> {
    return this.http
      .get<PaginationModel<WorkoutView>>(`${environment.apiUrl}/${environment.apiV.apiV1}/offline-workouts?size=${pageSize}&page=${pageNumber}&favorite=${false}`);
  }

  public createCustomWorkout(workout: CustomWorkoutView): Observable<CustomWorkoutView> {
    return this.http.post<CustomWorkoutView>(`${environment.apiUrl}/${environment.apiV.apiV1}/custom-offline-workout`, workout);
  }

  public editCustomWorkout(workout: CustomWorkoutView): Observable<CustomWorkoutView> {
    return this.http
      .put<CustomWorkoutView>(`${environment.apiUrl}/${environment.apiV.apiV1}/custom-offline-workout/${workout.id}`, workout);
  }

  public getCustomWorkoutById(id: string): Observable<CustomWorkoutView> {
    return this.http.get<CustomWorkoutView>(`${environment.apiUrl}/${environment.apiV.apiV1}/custom-offline-workout/${id}`);
  }

  // TODO
  public editWorkout(data: any, id: string): Observable<WorkoutView> {
    let dataForSave = { ...data };

    if (data.healthyLifestyleHabit) {
      dataForSave
        = {...data, healthyLifestyleHabit: data.healthyLifestyleHabit.id ? data.healthyLifestyleHabit.id : data.healthyLifestyleHabit };
    }

    return this.http.put<WorkoutView>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/${id}`, dataForSave);
  }

  public getWorkout(id: string, bundleId?: string): Observable<WorkoutView> {
    return bundleId ? this.http.get<WorkoutView & { healthyLifestyleHabit: any }>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/bundle/${id}`, { params: { bundleId } })
      : this.http.get<WorkoutView & { healthyLifestyleHabit: any }>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/${id}`);
  }

  public removeWorkout(id: string): Observable<unknown> {
    return this.http.delete(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/${id}`);
  }

  public saveWorkout(data?: WorkoutView): Observable<WorkoutView>  {
    let dataForSave = { ...data, wodTime: 1 };

    if (data.healthyLifestyleHabit) {
      dataForSave
        = {...data, healthyLifestyleHabit: data.healthyLifestyleHabit.id ? data.healthyLifestyleHabit.id : data.healthyLifestyleHabit };
    }

    return this.http.post<WorkoutView>(
      `${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts`,
      dataForSave
    );
  }

  public getCustomWorkoutList(
    pageSize: number,
    pageNumber: number
  ): Observable<PaginationModel<CustomWorkoutView>> {
    return this.http
      .get<PaginationModel<CustomWorkoutView>>(`${environment.apiUrl}/${environment.apiV.apiV1}/custom-offline-workout?size=${pageSize}&page=${pageNumber}`);
  }

  public getAllOnlineExercise(
    pageSize: number,
    pageNumber: number
  ): Observable<PaginationModel<OnlineExercise>> {
    return this.http
      .get<PaginationModel<OnlineExercise>>(`${environment.apiUrl}/${environment.apiV.apiV2}/online-workouts/exercises?size=${pageSize}&page=${pageNumber}`);
  }

  public removeCustomWorkout(id: string): Observable<unknown> {
    return this.http.delete(`${ environment.apiUrl }/${environment.apiV.apiV1}/custom-offline-workout/${id}`);
  }

  // TODO: For now, req with { params: { size: '10', page: '1' } } - return error. Set params after back ready
  public getAllHabits(): Observable<PaginationModel<Habit>> {
    return this.http
      .get<PaginationModel<Habit>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/habits`)
      .pipe(
        tap((res) => {
          this.habitList = res.objects;
        })
      );
  }

  public getAllTemplateExercises(url: string, apiVersion?: string): Observable<PaginationModel<any>> {
    return this.http
      .get<PaginationModel<any>>(`${ environment.apiUrl }/${apiVersion ? apiVersion : environment.apiV.apiV1}/${url}?size=10000`)
      .pipe(
        tap((res) => {
          const exerciseAvailableMetricsExist = !!this.exerciseAvailableMetrics.size;
          this.templateExercisesList = res.objects.map((item) => {
            if (!exerciseAvailableMetricsExist) {
              const filteredMetrics = this.metric.filter((metric) => {
                return item && item.availableMetric ? item.availableMetric.includes(metric.id) : false;
              });

              this.exerciseAvailableMetrics.set(String(item.id), filteredMetrics ? filteredMetrics : this.metric);
            }
            item.exerciseId = String(item.id);
            delete item.id;
            return item;
          });
        })
      );
  }

  public getAutofill(autofillName: string): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/${environment.apiV.apiV1}/exercises/autofill`, { params: { autofillName } });
  }

  public getQuantityMetricDesignation(complexType: string): string {
    switch (complexType) {
      case 'FOR_TIME' :
        return 'min';
      case 'DEATH_BY_REPS':
        return '(rest between rounds, sec)';
      default:
        return 'min';
    }
  }

  public getWorkoutTitle(workout: WorkoutView): string {
    if (workout) {
      const complexExts = workout.complexExts[0];

      if (!complexExts) {
        return;
      }

      switch (complexExts.wodType) {
        case WodType.FOR_TIME:
          return `${complexExts.roundsCount > 1 ? complexExts.roundsCount + ' rounds' : complexExts.roundsCount + ' round'}, ${WorkoutTypes[complexExts.wodType]}`;
        case WodType.AMRAP:
        case WodType.EMOM:
        case WodType.TABATA:
        case WodType.MAX_EFFORT:
          return `${complexExts.quantity} min, ${WorkoutTypes[complexExts.wodType]}`;
        case WodType.DEATH_BY_REPS:
          return `${WorkoutTypes[complexExts.wodType]}`;
      }
    }
  }

  public getOnlineWorkoutTitle(workout: OnlineWorkout): string {
    if (workout && workout.stages) {
      const stage = workout.stages.find((s) => s.stageName === 'WOD');

      if (!stage) {
        return;
      }

      switch (stage.stageType) {
        case WodType.FOR_TIME:
          return `${stage.stageOption.metricQuantity > 1 ? stage.stageOption.metricQuantity + ' rounds' : stage.stageOption.metricQuantity + ' round'}, ${WorkoutTypes[stage.stageType]}`;
        case WodType.AMRAP:
        case WodType.EMOM:
        case WodType.TABATA:
        case WodType.MAX_EFFORT:
          return `${stage.stageOption.metricQuantity} min, ${WorkoutTypes[stage.stageType]}`;
        case WodType.DEATH_BY_REPS:
          return `${WorkoutTypes[stage.stageType]}`;
      }
    }
  }

  public getWorkoutExercise(exercise): string {
    if (exercise) {
      return `${exercise.quantity}${RepresentationMetricLabels[exercise.metrics]} ${exercise.name}`;
    }
  }

  public getWorkoutCustomWod(customOfflineWorkoutExtensions: CustomOfflineWorkoutExtensions[]): CustomOfflineWorkoutExtensions  {
    if (!customOfflineWorkoutExtensions) {
      return;
    }

    customOfflineWorkoutExtensions.find((extension) => {
      return extension.extensionType === CustomOfflineWorkoutBlocks.wod;
    });

    return customOfflineWorkoutExtensions.find((extension) => {
      return extension.extensionType === CustomOfflineWorkoutBlocks.wod;
    });
  }

  public splitWithSpace(equipArr: string[]): string {
    return equipArr.reduce((string, item, index) => `${string}${index === 0 ? '' : ','} ${item}`, '');
  }

  public updateCachedList(workoutResponse: WorkoutView) {
    const wodIndex = this.loadedWorkouts.findIndex((w) => w.id === workoutResponse.id);
    if (wodIndex === -1 && this.scrollConfig) {
      if (this.isLastPage(this.scrollConfig)) {
        this.loadedWorkouts = [...this.loadedWorkouts, workoutResponse];
      }
    } else {
      this.loadedWorkouts[wodIndex] = workoutResponse;
    }
  }

  public removeFromCache(id): void {
    this.loadedWorkouts.splice(this.loadedWorkouts.findIndex((w) => w.id === id), 1);
  }

  public dropCache(): void {
    this.loadedWorkouts = [];
    this.scrollConfig = null;
    this.lastLoadedPage = Number.MAX_SAFE_INTEGER;
    this.lastSelectedWorkoutsPagination.clear();
  }

  public setScrollConfig(scrollConfig: ScrollConfig): void {
    this.scrollConfig = scrollConfig;
  }

  public setLastLoadedPage(page: number): void {
    this.lastLoadedPage = Math.max(this.lastLoadedPage, page);
  }

  private isLastPage(config: ScrollConfig): boolean {
    const totalPages = Math.ceil(Number(config.totalElements) / config.pageSize);
    return this.lastLoadedPage >= totalPages - 1;
  }
}
