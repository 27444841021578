<div class="autofill">
  <div class="autofill__label">{{ label }}</div>
  <div class="autofill__row">
    <button *ngFor="let item of options"
            mat-stroked-button
            [ngClass]="{ active: selectedAutofillName === (item.reqName ? item.reqName : item.name) }"
            (click)="getExercises(item.reqName ? item.reqName : item.name)"
            class="btn-outline">
      {{ item.name }}
    </button>
  </div>
</div>
