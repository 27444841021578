import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { MyProgram, OfflineWeek, ProgramWorkout } from '@app/my-program/_shared/my.program.model';
import { map, tap } from 'rxjs/operators';
import { WorkoutService } from '@app/workout/_shared/workout.service';
import { PaginationModel } from '@shared/models/pagination.model';
import { setFilterQuery } from '@shared/utils/utils';

@Injectable()
export class MyProgramService {
  public currentProgram: MyProgram;

  constructor(
    private http: HttpClient,
    private workoutService: WorkoutService
  ) {}

  // tslint:disable-next-line:max-line-length
  public getMyPrograms(pageSize: number, pageNumber: number, isFavorite = false, filterObject?: any): Observable<PaginationModel<MyProgram>> {
    const filterQuery = filterObject ? setFilterQuery(filterObject) : null;

    return this.http
      .get<PaginationModel<MyProgram>>(`${environment.apiUrl}/${environment.apiV.apiV1}/offline-program${filterQuery ? '/filter' : ''}?size=${pageSize}&page=${pageNumber}&favorite=${isFavorite}${filterQuery ? filterQuery : ''}`);
  }

  public getProgram(id): Observable<MyProgram> {
    return this.http.get<MyProgram>(`${environment.apiUrl}/${environment.apiV.apiV1}/offline-program/${id}`)
      .pipe(map((data) => {
        return {
          ...data,
          offlineWeeks: data.offlineWeeks.map((week) => {
            return {
              ...week,
              workouts: week.workouts.map((programWorkout) => this.patchWorkout(programWorkout))
            };
          }),
          healthyLifestyleHabits: this.weekHlh(data.offlineWeeks),
        };
      }), tap((data) => {
        this.currentProgram = data;
      }));
  }

  public saveMyProgram(data: any, hlhList: string[], id?: number): Observable<unknown> {
    const now = new Date();
    const secondStep = data.offlineWeeks.map((week, weekIndex) => {
      return {
        theme: week.theme,
        id: week.id ? week.id : null,
        workouts: week.workouts.map((workout) => {
          const workoutForSave: any = {
            ...workout.workout,
          };

          if (workout.workout) {
            workoutForSave.story = week.story;
            workoutForSave.healthyLifestyleHabit = hlhList[
                Math.ceil(weekIndex / 3)
                  ? Math.ceil(weekIndex / 3) > hlhList.length
                  ? hlhList.length - 1
                  : Math.ceil(weekIndex / 3) - 1
                  : 0
                ];
            workoutForSave.theme = week.theme;
          }

          return {
            zoneId: workout.zoneId,
            localDateTime: now.toISOString(),
            number: workout.number,
            dayType: workout.dayType,
            dayOfWeek: workout.dayOfWeek,
            workout: Object.keys(workoutForSave).length === 0 ? null : workoutForSave,
            id: workout.id
          };
        })
      };
    });

    if (id) {
      return this.http
        .put<number>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-program/${id}`, {...data, offlineWeeks: secondStep});
    } else {
      return this.http
        .post<number>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-program`, {...data, offlineWeeks: secondStep});
    }
  }
  public removeMyProgram(id: number): Observable<unknown> {
    return this.http.delete(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-program/${id}`);
  }

  private patchWorkout(programWorkout: ProgramWorkout): ProgramWorkout {
    const workout: any = {};
    if (programWorkout.workout) {
      workout.workout = programWorkout.workout
        ? {...programWorkout.workout, healthyLifestyleHabit: programWorkout.workout.healthyLifestyleHabit}
        : null;
      workout.number =  programWorkout.workout.complexExts[0].exercises.length > 4
        ? 5
        : programWorkout.workout.complexExts[0].exercises.length;
      workout.time = Math.ceil(programWorkout.workout.complexExts[0].quantity / 5)
        ? Math.ceil(programWorkout.workout.complexExts[0].quantity / 5) > 3
          ? 4
          : Math.ceil(programWorkout.workout.complexExts[0].quantity / 5)
        : 1;
      workout.wodType = programWorkout.workout.complexExts[0].wodType;
    }
    return {
      ...programWorkout,
      ...workout
    };
  }

  public weekHlh(offlineWeeks: OfflineWeek[]): string[] {
    const hlhs = [];

    offlineWeeks.forEach((week) => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < week.workouts.length; i++) {
        if (week.workouts[i].workout && !hlhs.includes(week.workouts[i].workout.healthyLifestyleHabit)) {
          if (this.workoutService.habitList) {
            hlhs.push(this.workoutService.habitList.find((hlh) => hlh.id === week.workouts[i].workout.healthyLifestyleHabit).id);
          }
        }
      }
    });

    return hlhs;
  }
}
