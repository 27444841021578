<div class="certificate">
  <div class="certificate__img">
    <img [src]="photo" alt="avatar">
    <div class="btn-container">
      <button (click)="preview()"
              class="d-flex-center-center">
        <i class="icon-search"></i>
      </button>

      <button [totalfitDownloadFile]="getSaveLink(photo)"
              [entityDownloadName]="text"
              [entity]="{ name: text, size: 100 }"
              loadType="download"
              class="d-flex-center-center">
        <i class="icon-Download"></i>
      </button>
    </div>
  </div>
  <div class="certificate__info d-flex-center-center">
    <span class="mat-body-2">Certificate TF</span>
    <p class="mat-body-2">{{ text }}</p>
  </div>
</div>
