import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromWokoutActions from '@app/store/workout/workout.actions';
import * as fromUIActions from '@app/store/app-ui-state/app-ui-state.actions';
import { catchError, filter, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectAdmitTableFilterState } from '@app/store/app-ui-state/app-ui-state.selector';
import { appShowSnackBar, TablePropNames } from '@app/store/app-ui-state/app-ui-state.actions';
import { Store } from '@ngrx/store';
import { AdminWorkoutsListService } from '@app/admin-workouts/_share/admin-workouts-list.service';
import { selectUser } from '@app/store/user/user.selector';
import { GlobalSpinnerService } from '@shared/services/global-spinner.service';
import { WorkoutService } from '@app/workout/_shared/workout.service';
import { forkJoin, of } from 'rxjs';
import { WorkoutView } from '@shared/models/workout.model';
import { BundleService } from '@shared/services/bundle.service';
import { OfflineWorkoutService } from '@shared/services/offline-workout.service';

@Injectable()
export class WorkoutEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly adminWorkoutsListService: AdminWorkoutsListService,
    private readonly globalSpinnerService: GlobalSpinnerService,
    private readonly workoutService: WorkoutService,
    private readonly offlineWorkoutService: OfflineWorkoutService,
    private readonly bundleService: BundleService
  ) {}

  public getAllTemplates = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getAllTemplates),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutService.getAllTemplates(pageSize, pageNumber)
        .pipe(
          map((workoutList) => {
            return fromWokoutActions.getAllTemplatesSuccess({ workoutList });
          })
        );
    })
  ));

  public getAllTemplatesAdmin = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getAllTemplatesAdmin),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutService.getAllTemplatesAdmin(pageSize, pageNumber)
        .pipe(
          map((workoutList) => {
            return fromWokoutActions.getAllTemplatesAdminSuccess({ workoutList });
          })
        );
    })
  ));

  public getAllTemplatesBundleAdmin = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getAllTemplatesBundleAdmin),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutService.getAllTemplatesBundleAdmin(pageSize, pageNumber)
        .pipe(
          map((templateWorkoutBundleList) => {
            return fromWokoutActions.getAllTemplatesBundleAdminSuccess({ templateWorkoutBundleList });
          })
        );
    })
  ));

  public getWorkoutBundleTemplateById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getWorkoutBundleTemplateById),
    switchMap(({ id }) => {
      return this.workoutService.getWorkoutBundleTemplateById(id)
        .pipe(
          map((templateWorkoutBundle) => {
            return fromWokoutActions.getWorkoutBundleTemplateByIdSuccess({ templateWorkoutBundle });
          })
        );
    })
  ));

  public createWorkoutBundleTemplate = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.createWorkoutBundleTemplate),
    switchMap(({ templateWorkoutBundle }) => {
      return this.workoutService.createWorkoutBundleTemplate(templateWorkoutBundle)
        .pipe(
          switchMap((templateWorkoutBundle) => {
            return [fromWokoutActions.createWorkoutBundleTemplateSuccess({ templateWorkoutBundle }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));

  public createTemplate = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.createTemplate),
    switchMap(({ workout }) => {
      return this.workoutService.createTemplate(workout)
        .pipe(
          switchMap((workout) => {
            return [fromWokoutActions.createTemplatesSuccess({ workout }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));

  public editWorkoutBundleTemplate = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.editWorkoutBundleTemplate),
    switchMap(({ templateWorkoutBundle }) => {
      return this.workoutService.editWorkoutBundleTemplate(templateWorkoutBundle)
        .pipe(
          switchMap((templateWorkoutBundle) => {
            return [fromWokoutActions.editWorkoutBundleTemplateSuccess({ templateWorkoutBundle }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));

  public editTemplateById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.editTemplateById),
    switchMap(({ workout }) => {
      return this.workoutService.editTemplateById(workout)
        .pipe(
          switchMap((workout) => {
            return [fromWokoutActions.editTemplateByIdSuccess({ workout }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));

  public moveTemplateToBundle = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.moveTemplateToBundle),
    switchMap(({ workout, isBundle }) => {
      return this.workoutService.editTemplateById(workout)
        .pipe(
          switchMap((workout) => {
            return [fromWokoutActions.removeTemplateByIdSuccess({ id: String(workout.id), isBundle }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));

  public removeTemplateById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.removeTemplateById),
    switchMap(({ id, isBundle }) => {
      return this.workoutService.removeTemplateById(id)
        .pipe(
          switchMap(() => {
            return [fromWokoutActions.removeTemplateByIdSuccess({ id, isBundle }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));

  public removeWorkoutBundleTemplateById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.removeWorkoutBundleTemplateById),
    switchMap(({ id }) => {
      return this.workoutService.removeWorkoutBundleTemplateById(id)
        .pipe(
          switchMap(() => {
            return [fromWokoutActions.removeWorkoutBundleTemplateByIdSuccess({ id }), fromWokoutActions.setWorkoutIsLoading({ workoutIsLoading: false })];
          })
        );
    })
  ));


  public getDropDownList = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getDropDownList),
    switchMap(() => {
      return this.workoutService.getDropDownList()
        .pipe(
          map((list) => {
            return fromWokoutActions.getDropDownListSuccess({ list });
          })
        );
    })
  ));

  public getTemplateById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getTemplateById),
    switchMap(({id}) => {
      return forkJoin([
        id
          ? this.workoutService.getTemplateById(id)
          : of(null),
        this.workoutService.getAllHabits(),
      ]).pipe(
        map(([adminOfflineWorkout]) => {
          return fromWokoutActions.getTemplateByIdSuccess({ adminOfflineWorkout });
        })
      );
    })
  ));

  public loadAdminWorkouts = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getAdminWorkouts),
    withLatestFrom(
      this.store$.select(selectAdmitTableFilterState, { key: TablePropNames.ADMIN_WORKOUT_TABLE })
    ),
    switchMap(([{ pageSize, pageNumber, tabFilter }, filterI]: any) => {
      return this.adminWorkoutsListService.getAdminWorkoutsCriteria(pageSize, pageNumber, {...tabFilter, ...filterI})
        .pipe(
          map((adminWorkouts) => {
            return fromWokoutActions.getAdminWorkoutsSuccess({adminWorkouts});
          })
        );
    })
  ));

  public getWorkout = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getWorkout),
    switchMap(({id, bundleId}) => {
      return forkJoin([
        id
          ? this.workoutService.getWorkout(id, bundleId)
          : of(null),
        this.workoutService.getAllHabits(),
      ]).pipe(
          map(([offlineWorkout]) => {
            return fromWokoutActions.getWorkoutSuccess({ offlineWorkout });
          })
        );
    })
  ));

  public changeAdminWorkoutOrders = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.changeAdminWorkoutOrders),
    switchMap(({workouts}) => {
      return this.adminWorkoutsListService.changeOnlineWorkoutIndex(workouts)
        .pipe(
          map(() => {
            return fromWokoutActions.changeAdminWorkoutOrdersSuccess({ updateState: true });
          })
        );
    })
  ));

  public loadCustomWorkout = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getCustomWorkoutList),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutService.getCustomWorkoutList(pageSize, pageNumber)
        .pipe(
          map((workouts) => {
            return fromWokoutActions.getCustomWorkoutListSuccess({ workouts });
          }),
          catchError(() => {
            return of(fromWokoutActions.getCustomWorkoutListSuccess({ workouts: null }));
          })
        );
    })
  ));

  public loadAllOnlineExercise = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getAllOnlineExercise),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutService.getAllOnlineExercise(pageSize, pageNumber)
        .pipe(
          map((exercise) => {
            return fromWokoutActions.getAllOnlineExerciseSuccess({ exercise });
          })
        );
    })
  ));

  public getWodBySearch = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getWodBySearch),
    switchMap(({ workoutOSLimited }) => {
      return (workoutOSLimited.creationType === 'TEMPLATE' ? this.workoutService.getTemplateById(String(workoutOSLimited.id))
        : this.workoutService.getWorkout(String(workoutOSLimited.id))).pipe(
        map((workoutView: WorkoutView) => {
          return fromWokoutActions.getWodBySearchSuccess({ workoutView });
        })
      );

    })
  ));

  public loadWorkoutBundles = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getBundles),
    switchMap(({ pageSize, pageNumber }) => {
      return this.adminWorkoutsListService.getWorkoutBundles(pageSize, pageNumber)
        .pipe(
          map((bundles) => {
            return fromWokoutActions.getBundlesSuccess({ bundles });
          })
        );
    })
  ));

  public getOfflineWorkoutList = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getOfflineWorkoutList),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutService.getWorkouts(pageSize, pageNumber)
        .pipe(
          map((workouts) => {
            return fromWokoutActions.getOfflineWorkoutListSuccess({ workouts });
          })
        );
    })
  ));

  // @ts-ignore
  public loadUserWorkoutBundles = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getUserBundles),
    switchMap(({ userEmail }) => {
      return this.adminWorkoutsListService.getUserBundles(userEmail)
        .pipe(
          map((bundles) => {
            this.globalSpinnerService.isShown.next(false);
            return fromWokoutActions.getUserBundlesSuccess({ bundles });
          })
        );
    })
  ));

  public getUserBundleById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getUserBundleById),
    switchMap(({ id }) => {
      this.globalSpinnerService.isShown.next(true);
      return this.adminWorkoutsListService.getUserBundleById(id)
        .pipe(
          map((bundle) => {
            this.globalSpinnerService.isShown.next(false);
            return fromWokoutActions.getUserBundleByIdSuccess({ bundle });
          })
        );
    })
  ));

  public moveWorkoutToRoot = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.moveWorkoutToRoot),
    switchMap(({ workout, bundleId }) => {
      this.globalSpinnerService.isShown.next(true);
      return this.workoutService.editWorkout({...workout, bundleId: null}, workout.id)
        .pipe(
          map(() => {
            this.globalSpinnerService.isShown.next(false);
            return fromWokoutActions.getUserBundleById({ id: bundleId });
          })
        );
    })
  ));

  public moveWorkoutToBundle = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.moveWorkoutToBundle),
    switchMap(({ workout, bundleId, bundleForSaveId }) => {
      this.globalSpinnerService.isShown.next(true);
      return this.workoutService.editWorkout({...workout, bundleId: bundleForSaveId }, workout.id)
        .pipe(
          filter(() => !!bundleId),
          map(() => {
            return fromWokoutActions.getUserBundleById({ id: bundleId });
          }),
          finalize(() => {
            this.globalSpinnerService.isShown.next(false);
          })
        );
    })
  ));

  // @ts-ignore
  public removeUserWorkoutBundles = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.removeUserBundles),
    withLatestFrom(this.store$.select(selectUser)),
    switchMap(([{ id }, user]) => {
      this.globalSpinnerService.isShown.next(true);
      return this.adminWorkoutsListService.removeUserBundles(id)
        .pipe(
          map(() => {
            return fromWokoutActions.getUserBundles({ userEmail: user.email });
          })
        );
    })
  ));

  public createNewFolder = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.createNewFolder),
    switchMap(({ bundle, userEmail }) => {
      this.globalSpinnerService.isShown.next(true);
      return this.adminWorkoutsListService.createNewFolder(bundle)
        .pipe(
          map(() => {
            return fromWokoutActions.getUserBundles({ userEmail });
          })
        );
    })
  ));

  public editFolder = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.editUserBundles),
    withLatestFrom(this.store$.select(selectUser)),
    switchMap(([{ bundle }, user]) => {
      this.globalSpinnerService.isShown.next(true);
      return this.adminWorkoutsListService.editUserBundles(bundle)
        .pipe(
          map(() => {
            return fromWokoutActions.getUserBundles({ userEmail: user.email });
          })
        );
    })
  ));

  public changeBundlesActivity = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.changeBundlesActivity),
    switchMap(({ id, status, userEmail }) => {
      this.globalSpinnerService.isShown.next(true);
      return this.adminWorkoutsListService.changeBundleStatus(id, status)
        .pipe(
          map(() => {
            if (userEmail) {
              return fromWokoutActions.getUserBundles({ userEmail });
            } else {
              this.globalSpinnerService.isShown.next(false);
              return appShowSnackBar({ message: 'We\'ve added a workout set to your workout list.' });
            }
          })
        );
    })
  ));

  public createCustomWorkout = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.createCustomWorkout),
    this.globalSpinnerService.wrapRequest(({ workout }) => {
      return this.workoutService.createCustomWorkout(workout)
        .pipe(
          map(() => {
            return fromUIActions.appNavigate({ route: '/workouts' });
          })
        );
    })
  ));

  public editCustomWorkout = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.editCustomWorkout),
    this.globalSpinnerService.wrapRequest(({ workout }) => {
      return this.workoutService.editCustomWorkout(workout)
        .pipe(
          map(() => {
            return fromUIActions.appNavigate({ route: '/workouts' });
          })
        );
    })
  ));

  public getCustomWorkoutById = createEffect(() => this.actions$.pipe(
    ofType(fromWokoutActions.getCustomWorkoutById),
    this.globalSpinnerService.wrapRequest(({ id }) => {
      return this.workoutService.getCustomWorkoutById(id)
        .pipe(
          map((customWorkout) => {
            return fromWokoutActions.setCurrentCustomWorkout({ customWorkout });
          })
        );
    })
  ));

  public cacheClearOnSuccess = createEffect(() => this.actions$.pipe(
    ofType(
      fromWokoutActions.moveTemplateToBundle,
      fromWokoutActions.moveWorkoutToRoot,
      fromWokoutActions.moveWorkoutToBundle,
      fromWokoutActions.changeBundlesActivity,
    ),
    tap(() => {
      this.bundleService.dropCache();
      this.offlineWorkoutService.dropCache();
    })
  ), { dispatch: false });
}
