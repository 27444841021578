import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter, HostListener, ViewChildren, QueryList, ElementRef
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';
import { FormControlComponent, getValueAccessor } from '@shared/models/form-control.component';
import { SelectOption, SelectValue } from '@shared/components/select/select.model';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { ScrollConfig } from '@shared/models/scroll-pagination.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getValueAccessor(SelectComponent)]
})
export class SelectComponent extends FormControlComponent<SelectValue | SelectValue[]> implements OnInit {
  @ViewChild(DropdownToggleDirective, { static: false }) public dropdownToggle: DropdownToggleDirective;
  @ViewChildren('focusItem') private focusItems: QueryList<ElementRef>;
  @ViewChild('button', { static: false }) private button: MatButton;
  @Input() public options: SelectOption[];
  @Input() public hasImage = false;
  @Input() public searchOptions: SelectOption[];
  @Input() public viewInitTrigger = true;
  @Input() public hasFilter = false;
  @Input() public hasSearch = false;
  @Input() public displayType: 'dropdown' | 'radio' = 'dropdown';
  @Input() public isMultiple = false;
  @Input() public selectorPlaceholder: string;
  @Input() public selectClass: string;
  @Input() public dynamicPipeOption: {
    instance: any;
    arguments?: Array<string | number>;
  };
  @Input() public dynamicPipeLabel: {
    instance: any;
    arguments?: Array<string | number>;
  };
  @Input() public notFoundPlaceholder = 'Not Found';
  @Input() public selectIcon: string;
  @Input() public selectListClass: string;
  // TODO REFACTOR backdropClass
  @Input() public backdropClass: string;
  @Input() public hasNoLabel = false;
  @Input() public optionUniqueIdentificator = 'id';
  @Input() public outputOptionField = 'id';
  @Input() public nameKey: any = 'name';
  @Input() public labelKey: string;
  @Input() public disabledLabel: string;

  @Input() public selectType: 'dot' | 'checkbox' = 'dot';
  @Input() public allItemsLength: number;
  @Input() public totalPagination: number;
  @Input() public isLoading: boolean;

  @Output() public selectEvent: EventEmitter<any> = new EventEmitter();
  @Output() public filter: EventEmitter<any> = new EventEmitter();
  @Output() public triggerDropDown: EventEmitter<any> = new EventEmitter();
  @Output() public loadPagination: EventEmitter<number> = new EventEmitter();
  @Output() public search: EventEmitter<string> = new EventEmitter();
  @Output() public searchType: EventEmitter<string> = new EventEmitter();
  public label: string;
  public filterControl: UntypedFormControl;
  public searchControl: UntypedFormControl;
  public selectedOptions: SelectOption[];
  public selectedIndex = 0;

  public scrollConfig: ScrollConfig = {
    isLoading: false,
    pageNumber: 0,
    pageSize: null
  };

  public showSearchOptions: false;

  private selectionModel: SelectionModel<SelectValue>;
  private isShow: boolean;
  private focusItem: ElementRef;

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent) {
    if (this.isShow) {
      const activeEle = document.activeElement;
      if (event.code === 'ArrowDown' && this.selectedIndex <= this.options.length) {
        event.preventDefault();
        if (!activeEle.classList.contains('focus-item')) {
          this.focusItems.first.nativeElement.focus();
          this.focusItem = this.focusItems.first.nativeElement;
          this.selectedIndex = 0;

          return;
        }

        this.selectedIndex++;
      }

      if (event.code === 'ArrowUp' && this.selectedIndex > 0) {
        event.preventDefault();
        this.selectedIndex--;
      }

      if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
        this.focusItem = this.focusItems.find((item, index) =>  {
          if (index === this.selectedIndex) {
            item.nativeElement.focus();
          }

          return index === this.selectedIndex;
        });
      }

      if (event.code === 'Enter' && this.focusItem) {
        event.preventDefault();
        this.selectOption(this.options.find((item) => {
          if (this.focusItem.nativeElement && this.focusItem.nativeElement.id && this.focusItem.nativeElement.id !== 'null') {
            return item[this.outputOptionField] === this.focusItem.nativeElement.id;
          } else {
            return this.options[0];
          }
        }));
        this.button.focus();
      }

      this.changeDetector.markForCheck();
    }
  }

  public ngOnInit(): void {
    this.selectionModel = new SelectionModel<SelectValue>(this.isMultiple, null);
    this.updateLabel();
    this.filterControl = new UntypedFormControl('');
    this.searchControl = new UntypedFormControl('');

    this.filterControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((value) => value.length > 2),
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe((value) => this.filter.emit(value));

    this.searchControl.valueChanges
      .pipe(
        tap((value) => { this.searchType.emit(value); }),
        untilDestroyed(this),
        filter((value) => value.length > 2),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value) => this.search.emit(value));
  }

  public selectOption(event: SelectOption): void {
    this.selectionModel[this.isMultiple ? 'toggle' : 'select'](
      event[this.optionUniqueIdentificator] ? event[this.optionUniqueIdentificator] : event[this.nameKey]
    );
    this.updateLabel();
    this.updateModel(this.isMultiple ? this.selectionModel.selected : event[this.outputOptionField]);
    // this.filterControl.setValue('');
    this.selectEvent.emit(this.isMultiple ? this.selectionModel.selected : event);

    if (!this.isMultiple && this.dropdownToggle) {
      this.dropdownToggle.hideDropdown();
    }
  }

  public isOptionSelected(option: SelectOption): boolean {
    return this.selectionModel.isSelected(
      option[this.optionUniqueIdentificator] ? option[this.optionUniqueIdentificator] : option[this.nameKey]
    );
  }

  public writeValue(value: SelectValue | SelectValue[]) {
    if (this.selectionModel.selected.join(',') !== value) {
      this.selectionModel.select(...(Array.isArray(value) ? value : [value]));
    }

    this.updateLabel();
    this.changeDetector.markForCheck();
  }

  public get isDropdownType(): boolean {
    return this.displayType === 'dropdown';
  }

  private updateLabel(): void {
    if (!this.isDropdownType) {
      return;
    }

    if (this.isMultiple ? this.selectionModel.hasValue() : this.selectionModel.selected[0]) {
      if (this.isMultiple) {
        this.selectedOptions = this.selectionModel.selected.map(
          (selectedId) => this.options.find((option) => (
            option[this.optionUniqueIdentificator] ? option[this.optionUniqueIdentificator] : option[this.nameKey]) === selectedId
          )
        );

        this.label = this.selectedOptions.map((option) => {
          return option ? option[this.labelKey || this.nameKey] : '';
        }).join(',');
      } else {
        const option = this.options?.find((selectedOption) => {
          return (
              selectedOption[this.optionUniqueIdentificator]
                ? selectedOption[this.optionUniqueIdentificator]
                : selectedOption[this.nameKey])  === this.selectionModel.selected[0];
          }
        );

        this.label = option && option[this.labelKey || this.nameKey] || this.selectorPlaceholder;
      }
    } else {
      this.selectedOptions = [];
      this.label = this.selectorPlaceholder;
    }
  }

  public detectIsElementShow(isShow: boolean): void {
    this.isShow = isShow;
  }

  public emitScrollPagination(pageSize: number, pageNumber = this.scrollConfig.pageNumber): void {
    Object.assign(this.scrollConfig, {pageSize, pageNumber});

    if (!this.scrollConfig.isLoading) {
      this.scrollConfig.isLoading = true;

      this.changeDetector.markForCheck();
    }
  }

  public infiniteScrollerTrigger(event): void {
    if (!this.isLoading) {
      this.loadPagination.emit(event);
    }
  }

  public setOptionType(): any {
    this.showSearchOptions = false;
  }
}
