import { Injectable } from '@angular/core';
import { Training, TrainingLevelsName, TrainingStatus } from '@app/trainings/_shared/trainings.model';
import { Direction } from '@shared/models/direction.model';
import { getTrainingDay } from '../../trainings/_shared/training-utils';

@Injectable()
export class TrainingUtilsService {
  public getTrainingTitle(training: Training): string {
    return `${training.address && training.address.location ? training.address.location.city : ''},
            ${training.address && training.address.location ? training.address.location.country : ''}
    `;
  }

  public getTrainingDirection(training: Training): string {
    return `${ Direction[training.direction] === Direction.DEFENCE ? 'Defense' : Direction[training.direction] }`;
  }

  public getTrainingDirectionClass(training: Training): string {
    return training.direction.toLowerCase();
  }

  public getLevel(training: Training): string {
    return `${ TrainingLevelsName[training.level] }`;
  }

  public getTrainingStatus(training: Training): { class: string; label: string } {
    switch (training.status) {
      case TrainingStatus.LocalSave:
        return { class: 'draft', label: 'Local save' };
      case TrainingStatus.WaitingForReview:
        return { class: 'pending', label: 'Waiting for review' };
      case TrainingStatus.Published:
        return { class: 'published', label: 'Published' };
      case TrainingStatus.Rejected:
        return { class: 'rejected', label: 'Decline' };
    }
  }

  public getTrainingDay(training: Training): string {
    return getTrainingDay(training);
  }
}
