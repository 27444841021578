import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackBarData } from '@app/_shared/services/snackbar.service';

@Component({
  selector: 'totalfit-profile-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomNotificationComponent implements OnInit {
  public snackBarRef = inject(MatSnackBarRef);
  public data: CustomSnackBarData;

  constructor() { }

  ngOnInit(): void {
    this.data = this.snackBarRef.containerInstance.snackBarConfig.data;
  }

}
