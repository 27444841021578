<div class="activate mat-typography">
  <mat-card *ngIf="isLoaded && !isLoading" class="activate__item">
    <span class="mat-title">
      Your account has been successfully activated.
    </span>
    <div class="route-btn">
      <button mat-button routerLink="/">Start using Totalfit!</button>
    </div>
  </mat-card>

  <mat-card *ngIf="!isLoaded && isLoading" class="activate__item">
    <span class="mat-title">
      Activating your account, please wait
    </span>
    <mat-spinner></mat-spinner>
  </mat-card>

  <mat-card *ngIf="!isLoaded && !isLoading" class="activate__item">
    <span class="mat-title">
      Something went wrong. Please check your data and try again.
    </span>
    <div class="route-btn">
      <button mat-button (click)="activate()">Reactivate</button>
    </div>
  </mat-card>
</div>

