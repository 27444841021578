import { AfterViewInit, Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[totalfitNgModelChangeDebounceTime]',
  providers: [NgModel]
})
export class NgModelChangeDebouncedDirective implements AfterViewInit {
  @Input() public totalfitNgModelChangeDebounceTime = 500;
  @Input() public control: UntypedFormControl;
  @Output() public ngModelChangeDebounced = new EventEmitter<any>();

  public ngAfterViewInit() {
    this.control.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      debounceTime(this.totalfitNgModelChangeDebounceTime)
    ).subscribe((value) => this.ngModelChangeDebounced.emit(value));
  }
}
