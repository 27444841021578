import { UserRole } from '@app/store/user/user.model';

export interface SideNavigationItem {
  label: string;
  iconClass?: string;
  router?: string;
  children?: SideNavigationItem[];
  isDisabled?: boolean;
  parentRouter?: string[];
  svgPlaceholder?: string;
  roles?: UserRole[];
  subDomain?: string | false;
  guestRoute?: boolean;
  routerChildUrlForActiveState?: string[];
}

export type SideNavigation = SideNavigationItem[];

export const SideNavigationItems: SideNavigation = [
  // {
  //   label: 'Calendar',
  //   iconClass: 'icon-calendar',
  //   router: '/calendar',
  //   roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
  //   subDomain: false,
  //   guestRoute: true
  // },
  {
    label: 'Tribe',
    iconClass: 'icon-Find-gym-filled',
    router: '/tribe',
    roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
    subDomain: false,
    guestRoute: true
  },
  // {
  //   label: 'People',
  //   iconClass: 'icon-group-24px',
  //   router: '/people',
  //   roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
  //   subDomain: false,
  //   guestRoute: true
  // },
  {
    label: 'Library',
    svgPlaceholder: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M21 4C19.89 3.65 18.67 3.5 17.5 3.5C15.55 3.5 13.45 3.9 12 5C10.55 3.9 8.45 3.5 6.5 3.5C4.55 3.5 2.45 3.9 1 5V19.65C1 19.9 1.25 20.15 1.5 20.15C1.6 20.15 1.65 20.1 1.75 20.1C3.1 19.45 5.05 19 6.5 19C8.45 19 10.55 19.4 12 20.5C13.35 19.65 15.8 19 17.5 19C19.15 19 20.85 19.3 22.25 20.05C22.35 20.1 22.4 20.1 22.5 20.1C22.75 20.1 23 19.85 23 19.6V5C22.4 4.55 21.75 4.25 21 4ZM21 17.5C19.9 17.15 18.7 17 17.5 17C15.8 17 14.35 17.1913 13 18.0413V7C14.35 6.15 15.8 5.5 17.5 5.5C18.7 5.5 19.9 5.65 21 6V17.5Z" fill="#7E8283"/>\n' +
      '<path d="M6.5 17C5.3 17 4.1 17.15 3 17.5V6C4.1 5.65 5.3 5.5 6.5 5.5C8.2 5.5 9.65 6.15 11 7V18.0527C9.65 17.2027 8.2 17 6.5 17Z" fill="white"/>\n' +
      '</svg>\n',
    router: '/library',
    roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
    subDomain: false,
    guestRoute: true
  },
  // {
  //   label: 'Locations',
  //   iconClass: 'icon-Find-gym-filled',
  //   router: '/locations',
  //   roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
  //   subDomain: false,
  //   guestRoute: true
  // },
  {
    label: 'Programming',
    iconClass: 'icon-Progress-filled',
    svgPlaceholder: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M4.33975 7.57735L12 3.1547L19.6603 7.57735V16.4227L12 20.8453L4.33975 16.4227V7.57735Z" stroke="#7E8283" stroke-width="2"/>\n' +
      '</svg>\n',
    parentRouter: ['/program', '/workouts'],
    guestRoute: true,
    roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
    children: [
      {
        label: 'Single workouts',
        router: '/workouts',
        roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
        subDomain: false,
        routerChildUrlForActiveState: ['add-new-workout', 'edit', 'view', 'workouts'],
      },
      // {
      //   label: 'Programs',
      //   router: '/program',
      //   roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
      //   subDomain: false,
      //   routerChildUrlForActiveState: ['add-new-program', 'edit', 'view', 'program'],
      // }
    ]
  },
  // {
  //   label: 'Resources',
  //   iconClass: 'icon-calendar',
  //   router: '/resources',
  //   roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
  //   subDomain: false,
  //   guestRoute: true
  // },
  {
    label: 'Trainings',
    iconClass: 'icon-assignment-24px',
    router: '/trainings',
    roles: [UserRole.Instructor],
    subDomain: false,
    guestRoute: true
  },
  // {
  //   label: 'Apprenticeship',
  //   iconClass: 'icon-assigned',
  //   router: '/apprenticeship',
  //   roles: [UserRole.Athlete, UserRole.Trainer, UserRole.Instructor],
  //   subDomain: false,
  //   guestRoute: true
  // },
  // Admin Routes
  {
    label: 'Admin Trainings',
    iconClass: 'icon-assignment-24px',
    router: '/admin-trainings',
    roles: [UserRole.Admin],
    subDomain: false,
    guestRoute: false
  },
  {
    label: 'Admin Workouts',
    iconClass: 'icon-assignment-24px',
    router: '/admin-workouts',
    roles: [UserRole.Admin],
    subDomain: false,
    guestRoute: false
  },
  {
    label: 'Admin Workout Manager',
    iconClass: 'icon-assignment-24px',
    router: '/admin-workout-manager',
    roles: [UserRole.Admin],
    subDomain: false,
    guestRoute: false
  },
  {
    label: 'Admin Users',
    iconClass: 'icon-group-24px',
    router: '/admin-users',
    roles: [UserRole.Admin],
    subDomain: false,
    guestRoute: false
  },
  {
    label: 'Admin Library',
    svgPlaceholder: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M21 4C19.89 3.65 18.67 3.5 17.5 3.5C15.55 3.5 13.45 3.9 12 5C10.55 3.9 8.45 3.5 6.5 3.5C4.55 3.5 2.45 3.9 1 5V19.65C1 19.9 1.25 20.15 1.5 20.15C1.6 20.15 1.65 20.1 1.75 20.1C3.1 19.45 5.05 19 6.5 19C8.45 19 10.55 19.4 12 20.5C13.35 19.65 15.8 19 17.5 19C19.15 19 20.85 19.3 22.25 20.05C22.35 20.1 22.4 20.1 22.5 20.1C22.75 20.1 23 19.85 23 19.6V5C22.4 4.55 21.75 4.25 21 4ZM21 17.5C19.9 17.15 18.7 17 17.5 17C15.8 17 14.35 17.1913 13 18.0413V7C14.35 6.15 15.8 5.5 17.5 5.5C18.7 5.5 19.9 5.65 21 6V17.5Z" fill="#7E8283"/>\n' +
      '<path d="M6.5 17C5.3 17 4.1 17.15 3 17.5V6C4.1 5.65 5.3 5.5 6.5 5.5C8.2 5.5 9.65 6.15 11 7V18.0527C9.65 17.2027 8.2 17 6.5 17Z" fill="white"/>\n' +
      '</svg>\n',
    router: '/admin-library',
    roles: [UserRole.Admin],
    subDomain: false,
    guestRoute: false
  },
  {
    label: 'Admin Collection',
    iconClass: 'icon-Progress-filled',
    router: '/admin-collection',
    svgPlaceholder: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M4.33975 7.57735L12 3.1547L19.6603 7.57735V16.4227L12 20.8453L4.33975 16.4227V7.57735Z" stroke="#7E8283" stroke-width="2"/>\n' +
      '</svg>\n',
    guestRoute: false,
    roles: [UserRole.Admin]
  }
];
