import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'totalfit-people-table-filter',
  templateUrl: './people-table-filter.component.html',
  styleUrls: ['./people-table-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleTableFilterComponent implements OnInit {

  @Input() public selectedFilter: string | string[];
  @Input() public entities: string[] | object[];
  @Input() public keyControl: string;
  @Output() public changeFilter = new EventEmitter<any>();
  public changedFilter;

  public ngOnInit(): void {
    this.changedFilter = {
      controlKey: this.keyControl
    };
    if (this.selectedFilter instanceof Array) {
      this.changedFilter.values = this.selectedFilter;
    } else {
      this.changedFilter.value = this.selectedFilter;
    }
  }

  public selectEntity(event: Event, value: string, triggerUpdate: boolean = false) {
    this.changedFilter = {
      event, value, controlKey: this.keyControl
    };
    if (triggerUpdate) {
      this.updateEntity();
    }
  }

  public updateEntity() {
    this.changeFilter.emit(this.changedFilter);
  }
}
