import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { SideNavigation, SideNavigationItem, SideNavigationItems } from '@shared/models/side-navigation.model';
import { ActivatedRoute, ActivationEnd, Data, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ResizeService } from '@shared/services/resize.service';
import { fromEvent, Observable } from 'rxjs';
import { Permission, RoleCheckDirective } from '../../directives/role-check.directive';
import { AuthService } from '@core/auth/auth.service';
import { selectStream } from '@app/store/library/library.selector';
import { Store } from '@ngrx/store';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-navigation-page-container',
  templateUrl: './navigation-page-container.component.html',
  styleUrls: ['./navigation-page-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationPageContainerComponent implements OnInit {
  public isSideNavOpened = document.body.clientWidth >= this.resizeService.ResponsiveBreakpointsWidth.m;
  public sideNavigationConfig: SideNavigation;
  public title: string;
  public routeBack: boolean;
  public optionalParams: any;
  public activeStream$: Observable<string>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public resizeService: ResizeService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private store$: Store,
    private readonly sanitizer: DomSanitizer
  ) {}

  public ngOnInit() {
    this.sideNavigationConfig = SideNavigationItems;

    this.handleRouterNavigation(this.getRouterNavigation(this.route.snapshot.queryParams, this.route.snapshot.data));
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof ActivationEnd)
      )
      .subscribe((activation: ActivationEnd) => {
        this.handleRouterNavigation(this.getRouterNavigation(activation.snapshot.queryParams, activation.snapshot.data));
      });

    fromEvent(window, 'resize').pipe(untilDestroyed(this)).subscribe(() => {
      this.isSideNavOpened = document.body.clientWidth >= this.resizeService.ResponsiveBreakpointsWidth.m;
      this.changeDetectorRef.markForCheck();
    });

    this.activeStream$ = this.store$.select(selectStream)
      .pipe(
        untilDestroyed(this)
      );
  }

  public hasChildRouterActive(navigationItem: SideNavigationItem): boolean {
    if (navigationItem.parentRouter) {
      let hasCoincidence = false;
      navigationItem.parentRouter.forEach((parentRoute) => {
        if (this.router.url.split('/').includes(parentRoute.replace('/', ''))) {
          hasCoincidence = true;

          return;
        }
      });

      return hasCoincidence;
    } else {
      return false;
    }
  }

  public hasRouterChildUrlForActiveState(navigationItem: SideNavigationItem): boolean {
    const route = this.router.url;

    if (navigationItem.routerChildUrlForActiveState) {
      let hasCoincidence = false;

      navigationItem.routerChildUrlForActiveState.forEach((routerChildUrlForActiveState) => {
        if (route.includes(routerChildUrlForActiveState) && route.includes(navigationItem.router)) {
          hasCoincidence = true;
        }
      });

      return hasCoincidence;
    } else {
      return false;
    }
  }

  public checkRole(permission: Permission) {
    return RoleCheckDirective.checkDisabledState(permission, this.authService.currentUser);
  }

  public getSvgPlaceholder(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  private handleRouterNavigation({ title, routeBack, optionalParams }: Data) {
    this.title = title;
    this.routeBack = routeBack;
    this.optionalParams = JSON.parse(optionalParams ? optionalParams : '{}');
  }

  private getRouterNavigation(routerParams, routeData) {
    const title = routerParams.title || routeData.title;
    const routeBack = routerParams.routeBack || routeData.routeBack;
    const optionalParams = routerParams.optionalParams || routeData.optionalParams;

    return { title, routeBack, optionalParams };
  }
}
