import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  public canActivate(): boolean {
    const currentUser = this.authService.currentUser;

    if (currentUser && this.router.url.includes('')) {
      this.router.navigate(['/']);

      return false;
    } else {
      return true;
    }
  }
}
