export const MESSAGES = {
  internalErrorTitle: 'Oops! Something went wrong.',
  internalError: 'We stumbled upon an internal error. No worries! Our team of tech ninjas are on it!',
  formErrorTitle: 'Something Went Haywire',
  formError: 'Please double-check your data and give it another go',
  passwordMatch: 'The new password and recovery password must match.',
  copiedTitle: 'Copy to Clipboard',
  copiedMessage: 'The value has been successfully copied to your clipboard. You can now paste it wherever you need it.',
  notificationTitle: 'Notification sent',
  notificationMessage: 'Your message has been successfully sent',
  invalidFileTypeTitle: 'Invalid File Type',
  invalidFileTypeMessage: 'The selected file is not a valid image file. Please select an image file with a .jpg, .jpeg, .png, or .gif extension.'
};
