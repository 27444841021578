import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Participant, TrainingsLetter } from '@app/trainings/_shared/trainings.model';

@Injectable()
export class ParticipantService {
  constructor(private http: HttpClient) {}

  public registerParticipant(participant: Participant): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/participant`, participant);
  }

  public editParticipant(participant: Participant): Observable<unknown> {
    return this.http.put(`${environment.apiUrl}/${environment.apiV.apiV1}/participant/${participant.id}`, participant);
  }

  public deleteParticipant(participant: Participant): Observable<unknown> {
    return this.http.delete(`${environment.apiUrl}/${environment.apiV.apiV1}/participant/${participant.id}`);
  }

  public exportParticipants(participants: Participant[]): any {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/participant/export`, participants, { responseType: 'blob'});
  }

  public changeExamStatus(participant: Participant): Observable<Participant> {
    return this.http
      .get<Participant>(`${environment.apiUrl}/${environment.apiV.apiV1}/participant/exam-status/${participant.id}`, { params: {
          examStatus: participant.examStatus
        }});
  }

  public getLetterHistory(participantEmail): Observable<TrainingsLetter[]> {
    return this.http.get<TrainingsLetter[]>(
      `${environment.apiUrl}/${environment.apiV.apiV1}/participant/messages`,
      {
        params: {
          participantEmail
        }
      }
    );
  }
}
