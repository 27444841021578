<div class="totalfit-select"
     [ngClass]="selectClass">
  <button *ngIf="isDropdownType else radioOptionsTemplate"
          [totalfitDropdownToggle]="optionsTemplateRef"
          [backdropClass]="backdropClass"
          [disabled]="isDisabled"
          (showDropDown)="detectIsElementShow($event)"
          mat-button
          (click)="triggerDropDown.emit(true)"
          class="trigger" #button>
    <span [totalfitTooltip]="label" class="trigger__label text-ellipsis">
      <span *ngIf="!hasNoLabel">{{ label | dynamic: dynamicPipeLabel?.instance: dynamicPipeLabel?.arguments }}</span>
    </span>
    <mat-icon *ngIf="!selectIcon" class="trigger__icon">arrow_drop_down</mat-icon>
    <i *ngIf="selectIcon" [ngClass]="selectIcon"></i>
  </button>



  <ng-template #optionsTemplateRef>
    <ul (mousewheel)="$event.stopPropagation()"
        [ngClass]="selectListClass"
        [class.has-search]="hasFilter"

        (totalfitInfiniteScroller)="infiniteScrollerTrigger($event)"
        [viewInitTrigger]="viewInitTrigger"
        [allItemsLength]="allItemsLength || 0"
        [totalPagination]="totalPagination || 0"
        viewType="table"
        itemHeight="36"

        class="select-menu">
      <li *ngIf="hasFilter"
          class="select-menu__search">
        <totalfit-search-input [formControl]="filterControl"
                               inputClass="slim"
                               isAutoSelected="true"
                               placeholder=""
                               border="bottom">
        </totalfit-search-input>
      </li>

      <li *ngIf="hasSearch"
          class="select-menu__search">
        <div  class="form-group form-group_full-width">
          <input [formControl]="searchControl"
                 placeholder="Story Name"
                 class="form-group__control">
        </div>
      </li>

      <ng-container *ngIf="options | search: filterControl.value: nameKey as filteredOptions">
        <li [tabindex]="i" *ngFor="let option of filteredOptions; let i = index" class="focus-item totalfitInfiniteScroller" [id]="option[optionUniqueIdentificator]" #focusItem>
          <button [disabled]="option.isDisabled"
                  (click)="selectOption(option)"
                  mat-button>
            <img *ngIf="hasImage" [src]="option.img ? option.img : 'assets/img/user/empty_photo.svg'"
                 class="user-icon"
                 alt="user photo">
          <span *ngIf="option[nameKey] | dynamic: dynamicPipeOption?.instance: dynamicPipeOption?.arguments as value"
                [innerHTML]="value"
                [totalfitTooltip]="value"
                [ngClass]="{ label: !option[outputOptionField] || option[outputOptionField] === '0' }"
                class="text-ellipsis content">
          </span>

          <span *ngIf="option.isDisabled && (option.disabledLabel || disabledLabel)"
                class="disabled-label">
            {{ option.disabledLabel ? option.disabledLabel : disabledLabel }}
          </span>

          <ng-container *ngIf="selectType === 'dot'; else checkbox">
            <i *ngIf="isOptionSelected(option)" class="icon-check2"></i>
          </ng-container>

          <ng-template #checkbox>
            <mat-checkbox [checked]="isOptionSelected(option)"
                          [disableRipple]="true"
                          color="primary"
                          class="checkbox">
            </mat-checkbox>
          </ng-template>
          </button>
        </li>

        <li *ngIf="filteredOptions.length === 0 && filterControl.value.length > 2 || !options.length">
          <button mat-button>Not Found</button>
        </li>

        <li class="select-menu__failed-search" *ngIf="filteredOptions.length === 0 && filterControl.value.length <= 2 && hasFilter">
          <button mat-button>{{ notFoundPlaceholder }}</button>
        </li>
      </ng-container>
    </ul>

    <totalfit-totalfit-spinner [diameter]="30" [isShown]="isLoading"></totalfit-totalfit-spinner>
  </ng-template>

  <ng-template #radioOptionsTemplate>
    <ul class="options-radio">
      <li *ngFor="let option of options">
        <button [disabled]="isDisabled || option.isDisabled"
                (click)="selectOption(option)"
                type="button"
                class="btn btn_plain">
        <span [class.radio-icon_selected]="isOptionSelected(option)"
              class="radio-icon">
        </span>
          {{option.name}}
        </button>
      </li>
    </ul>
  </ng-template>
</div>
