import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

export interface FileUpload {
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  public uploadReq: HttpRequest<FormData>;

  constructor(private http: HttpClient) { }

  public uploadParticipantsCsv(file: File): Observable<HttpEvent<FileUpload>> {
    const formData = new FormData();
    formData.append('file', file);

    this.uploadReq = new HttpRequest(
      'POST',
      `${environment.apiUrl}/${environment.apiV.apiV1}/files/upload`, formData, {reportProgress: true}
      );

    return this.http.request(this.uploadReq);
  }
}
