import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'totalfit-update-plan-info',
  templateUrl: './update-plan-info.component.html',
  styleUrls: ['./update-plan-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePlanInfoComponent {
  constructor(private readonly dialogRef: MatDialogRef<any>) {}

  public upgrade() {
    this.dialogRef.close('upgrade');
  }
}
