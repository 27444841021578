import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'totalfit-time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeRangeSelectorComponent implements OnInit, AfterViewInit  {
  @Input() public label = 'Time Range';
  @Input() public startTimeControl: FormControl<string>;
  @Input() public endTimeControl: FormControl<string>;
  @Input() public fromToDateValidationStatus: boolean;

  @Output() public timeRangeChange = new EventEmitter<{ startTime: string; endTime: string }>();

  @ViewChild('startTimeTrigger', { read: MatAutocompleteTrigger }) public startTimeTrigger: MatAutocompleteTrigger;
  @ViewChild('endTimeTrigger', { read: MatAutocompleteTrigger }) public endTimeTrigger: MatAutocompleteTrigger;

  public timeSlots: string[] = [];

  public filteredStartTimeSlots: string[] = [];
  public filteredEndTimeSlots: string[] = [];

  constructor() {}

  public ngOnInit() {
    this.generateTimeSlots();
    this.filteredStartTimeSlots = this.timeSlots;
    this.filteredEndTimeSlots = this.timeSlots;

    this.startTimeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        distinctUntilChanged(),
        debounceTime(200))
      .subscribe(() => {
      this.onStartTimeChange();
    });

    this.endTimeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        distinctUntilChanged(),
        debounceTime(200))
      .subscribe(() => {
      this.onEndTimeChange();
    });
  }

  public ngAfterViewInit(): void {
    this.startTimeControl.valueChanges.subscribe(() => {
      this.focusOnBestOption(this.startTimeControl.value, this.startTimeTrigger, this.filteredStartTimeSlots);
    });

    this.endTimeControl.valueChanges.subscribe(() => {
      this.focusOnBestOption(this.endTimeControl.value, this.endTimeTrigger, this.filteredEndTimeSlots);
    });
  }

  public generateTimeSlots(): void {
    const start = moment('00:00', 'HH:mm');
    const end = moment('23:45', 'HH:mm');

    while (start.isSameOrBefore(end)) {
      this.timeSlots.push(start.format('hh:mm A'));
      start.add(15, 'minutes');
    }
  }

  public onStartTimeChange(): void {
    const startTimeMoment = moment(this.startTimeControl.value, 'hh:mm A');
    const endTimeMoment = this.endTimeControl.value ? moment(this.endTimeControl.value, 'hh:mm A') : null;

    if (startTimeMoment.isSameOrAfter(moment('11:00 PM', 'hh:mm A'))) {
      this.endTimeControl.setValue('', { emitEvent: false });
    } else if (!endTimeMoment || startTimeMoment.isAfter(endTimeMoment)) {
      this.endTimeControl.setValue(startTimeMoment.clone().add(1, 'hour').format('hh:mm A'), { emitEvent: false });
    }

    this.filteredEndTimeSlots = this.timeSlots.filter((time) => {
      return moment(time, 'hh:mm A').isAfter(startTimeMoment);
    });

    this.emitTimeRangeChange();
  }

  public onEndTimeChange(): void {
    this.emitTimeRangeChange();
  }

  private findClosestTime(inputTime: string, slots: string[]): string | null {
    const inputMoment = moment(inputTime.replace(/\s+/g, '').toUpperCase(), 'hh:mmA', true);

    if (!inputMoment.isValid()) {
      return null;
    }

    let closestTime: string | null = null;
    let minDiff = Number.MAX_VALUE;

    slots.forEach((time) => {
      const timeMoment = moment(time, 'hh:mm A'); // Change the format here
      const diff = Math.abs(inputMoment.diff(timeMoment, 'minutes'));

      if (diff < minDiff) {
        minDiff = diff;
        closestTime = time;
      }
    });

    return closestTime;
  }

  public onStartTimeBlur(): void {
    const inputTime = this.startTimeControl.value;
    const inputMoment = this.formatInputTime(inputTime);

    if (inputMoment) {
      const formattedInputTime = inputMoment.format('hh:mm A');
      this.startTimeControl.setValue(formattedInputTime, { emitEvent: true });
    }
    this.setValidatorsAndUpdate(this.startTimeControl);
  }

  public onEndTimeBlur(): void {
    const inputTime = this.endTimeControl.value;
    if (!inputTime) {
      return;
    }

    const inputMoment = this.formatInputTime(inputTime);

    if (inputMoment) {
      const formattedInputTime = inputMoment.format('hh:mm A');
      this.endTimeControl.setValue(formattedInputTime, { emitEvent: true });
    }

    this.setValidatorsAndUpdate(this.endTimeControl);
  }

  public focusOnBestOption(value: string, trigger: MatAutocompleteTrigger, slots: string[]): void {
    const formattedInputTime = this.formatInputTime(value);

    if (formattedInputTime) {
      const inputTime = formattedInputTime.format('hh:mm A');
      const closestTime = this.findClosestTime(inputTime, slots);

      if (closestTime) {
        const index = slots.findIndex((time) => time === closestTime);

        if (index >= 0) {
          setTimeout(() => {
            const options = trigger.autocomplete.options.toArray();
            options.forEach((op) => op._getHostElement().classList.remove('focused'));
            const option = options[index]?._getHostElement();
            option?.scrollIntoView({ behavior: 'auto', block: 'center' });
            option?.classList.add('focused');
          }, 100);
        }
      }
    }
  }

  private formatInputTime(inputTime: string): moment.Moment | null {
    if (!inputTime) {
      return null;
    }

    const cleanedInput = inputTime.replace(/[^0-9]/g, '');
    const len = cleanedInput.length;

    if (len < 1 || len > 4) {
      return null;
    }

    let hour: number;
    let minute: number;

    if (len === 1) {
      hour = parseInt(cleanedInput);
      minute = 0;
    } else if (len === 2) {
      hour = parseInt(cleanedInput);
      minute = 0;
    } else if (len === 3) {
      hour = parseInt(cleanedInput.substring(0, 1));
      minute = parseInt(cleanedInput.substring(1));
    } else {
      hour = parseInt(cleanedInput.substring(0, 2));
      minute = parseInt(cleanedInput.substring(2));
    }

    if (isNaN(hour) || isNaN(minute)) {
      return null;
    }

    hour = Math.min(23, hour);
    minute = Math.min(59, minute);

    const inputMoment = moment({ hour, minute });

    // Determine if the input is in AM or PM format
    const isPM = inputTime.toLowerCase().includes('pm');

    if (hour < 12 && isPM) {
      inputMoment.add(12, 'hours');
    } else if (hour === 12 && !isPM) {
      inputMoment.subtract(12, 'hours');
    }

    return inputMoment;
  }

  private emitTimeRangeChange(): void {
    this.timeRangeChange.emit({
      startTime: this.startTimeControl.value,
      endTime: this.endTimeControl.value
    });
  }

  private timeFormatValidator(): ValidatorFn  {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const isValid = moment(value, 'hh:mm A', true).isValid();
      return isValid ? null : { 'invalidTimeFormat': { value } };
    };
  }

  private setValidatorsAndUpdate(control: FormControl): void {
    control.setValidators([this.timeFormatValidator(), Validators.required]);
    control.updateValueAndValidity();
  }
}
