<form [formGroup]="userForm"
      class="avatar-profile"
      [class.avatar-profile__card]="isLocation">
  <!--  <ng-content></ng-content>-->
  <div [class.avatar-photo_has-photo]="userForm?.value.photo"
       [class.avatar-photo_small]="avatarSize === 'small'"
       [class.avatar-photo_middle]="avatarSize === 'middle'"
       class="avatar-photo">
    <totalfit-photo-drop [defaultImage]="userForm?.value.photo"
                         [placeholderImage]="userForm?.value.photo ? '' : (placeholderImage || 'assets/img/user/ant-design_camera-outlined.svg')"
                         [isDropDisable]="!isEditable"
                         formControlName="photo"
                         (getImageWithName)="getImage.emit($event)"
                         [showClose]="false"
                         template="circle">
    </totalfit-photo-drop>

    <a *ngIf="hasEmailBtn"
            mat-flat-button
            [href]="'mailto:' + userForm.value.email"
            class="d-flex-center-center">
      <!--      TODO replace icon-->
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="white"/>
      </svg>
    </a>
  </div>
  <!--    <div *ngIf="user?.photo else noPhotoTemplate"-->
  <!--         [class.avatar-photo__photo&#45;&#45;small]="avatarSize === 'small'"-->
  <!--         [class.avatar-photo__photo&#45;&#45;middle]="avatarSize === 'middle'"-->
  <!--         class="avatar-photo__photo">-->
  <!--&lt;!&ndash;   TODO: Replace this part with mess service   &ndash;&gt;-->
  <!--&lt;!&ndash;      <div *ngIf="isNewSms" class="avatar-photo__new-sms mat-title d-flex-center-center">&ndash;&gt;-->
  <!--&lt;!&ndash;        1&ndash;&gt;-->
  <!--&lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    </div>-->

  <div class="avatar-photo__social">
    <ng-container *ngIf="socialNetLocation === 'bottom'">
      <ng-content></ng-content>
    </ng-container>
  </div>
  <div class="profile-information">
    <div [class.profile-information__middle]="avatarSize === 'middle'">
      <div *ngIf="hasDate" class="profile-information__date">
        <span>{{ (userForm?.value.dateOfCreation || date) | date: getDateArgs() }}</span>
        <span *ngIf="avatarSize === 'middle'">5:06 PM</span>
      </div>
      <div *ngIf="hasTitle"
           [class.mat-subheading-1]="avatarSize === 'small'"
           class="profile-information__name mat-title">
        {{ userForm?.value.firstName }} {{ userForm?.value.lastName }}
      </div>
      <div *ngIf="subtitle" class="profile-information__subtitle">
        {{ subtitle }}
      </div>
    </div>

    <div *ngIf="showStatus" class="profile-information__coach">
      <i class="icon-point" [ngClass]="getUserClass((userForm?.value.status || status))"></i>
      <span class="mat-body-2">{{ (userForm?.value.status || status) }}</span>
    </div>
    <ng-container *ngIf="socialNetLocation === 'default'">
      <ng-content></ng-content>
    </ng-container>
    <!--  TODO: Replace isText with mess service last message -->
    <ng-container *ngIf="isText">
      <div *ngIf="!isLocation" class="profile-information__text">
        <p>
          About Instructor non deserunt ullamco
          est sit aliqua dolor do amet sint.
          Velit officia consequat duis enim
          velit mollit. Exercitation veniam
          consequat sunt ostrud
        </p>
      </div>

      <div *ngIf="isLocation">
        <p class="mat-caption profile-information__location">Level 1 Coach, Los Angeles, USA</p>
        <p class="mat-caption"><b class="profile-information__bold">Homework:</b> 0/4 tasks completed</p>
      </div>
      <div class="profile-information__send"
           [class.profile-information__empty]="isLocation">
        <button mat-raised-button
                class="mat-body-2"
                color="primary">
          <!--   Todo replace icon-->
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#F2F2F2"/>
          </svg>
          Send message
        </button>
      </div>
    </ng-container>
  </div>
</form>
