<div [formGroup]="data.form" class="edit-social dialog mat-typography">
  <button mat-button
          mat-dialog-close
          class="edit-social__close icon-button">
    <i class="icon-close"></i>
  </button>
  <div class="dialog__body edit-social__body">
    <div class="edit-social__item">
      <div class="form-group form-group_full-width">
        <label class="form-group__label">twitter</label>
        <input formControlName="twitter"
               class="form-group__control form-group_full-width"
               type="text">
      </div>
    </div>
    <div class="edit-social__item">
      <div class="form-group form-group_full-width">
        <label class="form-group__label">linkedin</label>
        <input formControlName="linkedin"
               class="form-group__control form-group_full-width"
               type="text">
      </div>
    </div>
    <div class="edit-social__item">
      <div class="form-group form-group_full-width">
        <label class="form-group__label">facebook</label>
        <input formControlName="facebook"
               class="form-group__control form-group_full-width"
               type="text">
      </div>
    </div>
    <div class="edit-social__item">
      <div class="form-group form-group_full-width">
        <label class="form-group__label">instagram</label>
        <input formControlName="instagram"
               class="form-group__control form-group_full-width"
               type="text">
      </div>
    </div>
  </div>

  <div class="dialog__footer">
    <button mat-button
            mat-dialog-close>
      cancel
    </button>
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="true">
      save
    </button>
  </div>
</div>
