<form *ngIf="profileFormService.isFormEdit$ | async else previewTemplate"
      [formGroup]="profileFormService.peopleForm"
      class="totalfit-form activity">
  <totalfit-date-input *ngIf="profileFormService.peopleForm.value.status === 'ATHLETE'"
                       formControlName="lastWorkout"
                       label="Last workout">
  </totalfit-date-input>

  <totalfit-date-input *ngIf="profileFormService.peopleForm.value.status === 'TRIAL'"
                       formControlName="dateOfTrial"
                       label="Date of trial">
  </totalfit-date-input>

  <totalfit-date-input *ngIf="profileFormService.peopleForm.value.status === 'LEAD'
                              || profileFormService.peopleForm.value.status === 'TRIAL'"
                       formControlName="lastContact"
                       label="Last contact">
  </totalfit-date-input>

  <div *ngIf="profileFormService.peopleForm.value.status !== 'COACH'
                && profileFormService.peopleForm.value.status !== 'ASSISTANT'"
       class="form-group form-group_default-width-1">
    <label class="form-group__label">Comment</label>
    <textarea formControlName="comment"
      class="form-group__text-control mat-typography"
      rows="4"
      id="comment_people">
    </textarea>
  </div>

  <totalfit-date-input *ngIf="profileFormService.peopleForm.value.status === 'ATHLETE'"
                       formControlName="lastPayment"
                       label="Last payment">
  </totalfit-date-input>

  <totalfit-date-input *ngIf="profileFormService.peopleForm.value.status === 'ATHLETE'"
                       formControlName="nextPayment"
                       label="Next payment">
  </totalfit-date-input>

  <button *ngIf="profileFormService.peopleForm.value.status !== 'COACH'
                && profileFormService.peopleForm.value.status !== 'ASSISTANT'"
          mat-raised-button
          color="primary"
          type="submit"
          class="submit save-btn"
          (click)="profileFormService.saveChanges(route.snapshot.data.people)">
    save
  </button>
  <!--        TODO: after user fill activity -->
</form>

<ng-template #previewTemplate>
  <div class="preview-information">
    <div *ngIf="profileFormService.peopleForm.value.status === 'ATHLETE'" class="preview-information__general d-flex-center-y">
      <h2 class="mat-subheading-1">Next payment:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.nextPayment | date: 'MMM dd, yyyy' }}</p>
    </div>
    <div *ngIf="profileFormService.peopleForm.value.status === 'ATHLETE'" class="preview-information__general d-flex-center-y">
      <h2 class="mat-subheading-1">Last payment:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.lastPayment | date: 'MMM dd, yyyy' }}</p>
    </div>

    <div *ngIf="profileFormService.peopleForm.value.status === 'ATHLETE'" class="preview-information__general d-flex-center-y">
      <h2 class="mat-subheading-1">Last workout:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.lastWorkout | date: 'MMM dd, yyyy' }}</p>
    </div>

    <div *ngIf="profileFormService.peopleForm.value.status === 'TRIAL'" class="preview-information__general d-flex-center-y">
      <h2 class="mat-subheading-1">Date of trial:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.dateOfTrial | date: 'MMM dd, yyyy' }}</p>
    </div>

    <div *ngIf="profileFormService.peopleForm.value.status === 'LEAD'
                || profileFormService.peopleForm.value.status === 'TRIAL'" class="preview-information__general d-flex-center-y">
      <h2 class="mat-subheading-1">Last contact:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.lastContact | date: 'MMM dd, yyyy' }}</p>
    </div>

    <div *ngIf="profileFormService.peopleForm.value.status !== 'COACH'
                && profileFormService.peopleForm.value.status !== 'ASSISTANT'"
         class="people-content__comment">
      <h2 class="mat-subheading-1">Comment:</h2>
      <p class="mat-body-2">{{ profileFormService.peopleForm.value.comment }}</p>
    </div>
  </div>
</ng-template>
