import { Injectable } from '@angular/core';
import { NEVER, Observable, Subject } from 'rxjs';
import { Action } from '@ngrx/store';
import { delay, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GlobalSpinnerService {
  public isShown: Subject<boolean> = new Subject<boolean>();

  public wrapRequest<T, R>(
    project: (value: T) => Observable<R>,
    cancelAction: Observable<Action> = NEVER): (source: Observable<T>) => Observable<R> {
    return (source: Observable<T>) => {
      return source.pipe(
        tap(() => this.isShown.next(true)),
        switchMap((value: T) => {
          return project(value).pipe(
            takeUntil(cancelAction),
            delay(500),
            finalize(() => this.isShown.next(false))
          );
        }));
    };
  }
}
