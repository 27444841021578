import { Action, createReducer, on } from '@ngrx/store';
import * as fromOfflineWorkoutActions from '@app/store/offline-workout/offline-workout.actions';
import { OfflineWorkoutState } from '@store/offline-workout/offline-workout.model';
import * as fromWorkoutActions from '@store/workout/workout.actions';

export const offlineWorkoutInitialState: OfflineWorkoutState = {
  searchFolders: null,
  offlineWorkoutList: null,
  isLoading: false
};

const offlineWorkoutReducer = createReducer(
  offlineWorkoutInitialState,
  on(
    fromOfflineWorkoutActions.getOfflineWorkoutList,
    fromOfflineWorkoutActions.searchOfflineWorkoutList,
    (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(fromOfflineWorkoutActions.getOfflineWorkoutListSuccess, (state,  { offlineWorkoutList }) => {
    return {
      ...state,
      offlineWorkoutList,
      isLoading: false
    };
  }),
  on(fromOfflineWorkoutActions.setOfflineWorkoutList, (state,  { offlineWorkoutList }) => {
    return {
      ...state,
      offlineWorkoutList,
      isLoading: false
    };
  }),

  on(fromOfflineWorkoutActions.searchOfflineWorkoutListSuccess, (state,  { budgeOS }) => {
    return {
      ...state,
      isLoading: false,
      searchFolders: budgeOS
    };
  }),
  on(fromWorkoutActions.getWodBySearch, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(fromWorkoutActions.getWodBySearchSuccess, (state) => {
    return {
      ...state,
      isLoading: false
    };
  }),
  on(fromOfflineWorkoutActions.resetOfflineWorkout, () => {
    return {
      searchFolders: null,
      offlineWorkoutList: null,
      isLoading: false
    };
  }),
  // on(fromStoryActions.searchStoryListSuccess, (state,  { searchedStories }) => {
  //   return {
  //     ...state,
  //     searchedStories,
  //     isLoading: false
  //   };
  // }),
);

export function reducer(state: OfflineWorkoutState | undefined, action: Action) {
  return offlineWorkoutReducer(state, action);
}
