import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { People } from '@app/people/_shared/people.model';
import { Router } from '@angular/router';
import { PeopleService } from '@app/people/_shared/people.service';
import { BehaviorSubject } from 'rxjs';
import { ErrorKeys } from '@shared/models/http-error-keys.model';
import { SnackbarService } from '@shared/services/snackbar.service';

@Injectable()
export class ProfileFormService {
  public peopleForm: UntypedFormGroup;
  public isFormEdit$ = new BehaviorSubject<boolean>(false);
  public isSkipRelocate$ = new BehaviorSubject<boolean>(false);
  public isNewProfile$ = new BehaviorSubject<boolean>(false);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private peopleService: PeopleService,
    private snackbarService: SnackbarService
  ) {
    this.peopleForm = this.getPeopleForm();
  }

  public patchForm(people: People) {
    if (people) {
      const parsedEmail = people.email ? people.email.split(' ') : null;
      const parsedPhone = people.phone ? people.phone.split(' ') : null;

      const emailControler = this.peopleForm.get('email') as UntypedFormArray;
      const phoneControler = this.peopleForm.get('phone') as UntypedFormArray;

      emailControler.clear();
      phoneControler.clear();

      if (parsedEmail) {
        parsedEmail.forEach((email) => {
          emailControler.push(this.formBuilder.control([email]));
        });
      }

      if (parsedPhone) {
        parsedPhone.forEach((phone) => {
          phoneControler.push(this.formBuilder.control([phone]));
        });
      }

      this.peopleForm.patchValue({
        ...people,
        email: parsedEmail || [''],
        phone: parsedPhone || [''],
        address: people.address ? people.address : {
          address: '',
          location: {}
        }
      });
    } else {
      this.peopleForm = this.getPeopleForm();
    }
  }

  public getPeopleForm(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [null],
      status: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: [''],
      locationParticipation: this.formBuilder.group({
        gymId: ['', Validators.required],
        status: ['']
      }),
      gender: [null],
      email: this.formBuilder.array([
        this.formBuilder.control(null)
      ]),
      phone: this.formBuilder.array([
        this.formBuilder.control(null)
      ]),
      photo: [''],
      lastContact: [''],
      lastPayment: [''],
      lastWorkout: [''],
      nextPayment: [''],
      comment: [''],
      dateOfCreation: [''],
      dateOfTrial: [''],
      twitter: [''],
      instagram: [''],
      facebook: [''],
      linkedin: [''],
      address: this.formBuilder.group({
        location: this.formBuilder.group({
          country: [''],
          city: ['']
        }),
        address: ['']
      }),
      direction: [''],
      birthday: ['']
    });
  }

  public startEditForm(): void {
    this.isFormEdit$.next(true);
  }

  public saveChanges(people: People, ignoreEdit = false): void {
    const emailJoined = this.peopleForm.value.email[0] ? this.peopleForm.value.email.join(' ') : null;
    const phoneJoined = this.peopleForm.value.phone.join(' ');
    // TODO document: this.peopleForm.value.document[0] - delete after back implementaton;
    const joinedValue = {
      ...this.peopleForm.value,
      email: emailJoined,
      phone: phoneJoined,
      address: this.peopleForm.value.address.address ? this.peopleForm.value.address : null,
    };

    (people
      ? this.peopleService.editPeople(joinedValue, people.id)
      : this.peopleService.savePeople(joinedValue))
      .subscribe(() => {
        if (!this.isSkipRelocate$.value) {
          this.router.navigate([this.router.url.includes('admin-users') ? 'admin-users' : '/people'])
            .then(() => {
              // REFACTOR
              if (!ignoreEdit) {
                this.isFormEdit$.next(false);
              }
            });
        } else {
          if (!ignoreEdit) {
            this.isFormEdit$.next(false);
          }
        }
      }, ((error) => {
          if (error.toLowerCase().includes(ErrorKeys.DUPLICATE_ENTRY)) {
            this.snackbarService.openSnackbar('Current email is already exists.');
          }
      }));
  }
}
