import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[totalfitInputAutofocus]'
})
export class InputAutofocusDirective implements AfterViewInit {
  @Input('totalfitInputAutofocus') public hasAutofocus: boolean | string;

  constructor(private element: ElementRef<HTMLInputElement | HTMLTextAreaElement>) {}

  public ngAfterViewInit(): void {
    if (this.hasAutofocus === '' || this.hasAutofocus) {
      window.setTimeout(() => {
        const inputElement = this.element.nativeElement;
        const typeValue = this.element.nativeElement.attributes.getNamedItem('type').value;
        inputElement.focus();

        if (typeValue === 'button' || typeValue === 'submit') {
          return;
        }

        if (typeValue !== 'number') {
          const valueLength = (inputElement.value || '').length;
          inputElement.setSelectionRange(valueLength, valueLength);
        }
      }, 500);
    }
  }
}
