<button mat-button
        [totalfitDropdownToggle]="filterRef"
        [isPanelAlignedToRight]="isPanelAlignedToRight"
        [isPanelHasFlexibleWidth]="true"
        [color]="hasFilterValue ? 'primary' : ''"
        (click)="$event.stopPropagation()"
        [ngClass]="{ isSmallDrop: configValue.isSmallDrop }"
        class="filter__icon icon-button icon-button__mute">
  <i *ngIf="!configValue.matIconType"
     [ngClass]="iconClass"
     [class.primary]="hasFilterValue">
  </i>
  <mat-icon *ngIf="configValue.matIconType">
    {{ configValue.matIconType }}
  </mat-icon>
</button>

<ng-template #filterRef>
  <div class="filter mat-typography" [ngClass]="{ isSmallDrop: configValue.isSmallDrop }">
    <div class="filter__body">
      <div *ngFor="let filterCategory of configValue.categories"
           [ngClass]="filterCategory.filterType"
           class="filter__category">
        <button mat-button disabled class="filter__category-head">{{ filterCategory.filterCategoryHead }}</button>

<!--    refactor    -->
        <ng-container *ngIf="filterCategory.filterType !== 'search'">
          <ng-container *ngTemplateOutlet="listRef; context: { $implicit: filterCategory }"></ng-container>
        </ng-container>

        <ng-container *ngIf="filterCategory.filterType === 'search'">
          <ng-container *ngTemplateOutlet="searchRef; context: { $implicit: filterCategory }"></ng-container>
        </ng-container>
      </div>
    </div>

    <div class="filter__actions">
      <button mat-button
              class="clear-all"
              (click)="clearFilter()">
        Clear
      </button>
<!--      [disabled]="!hasFilterValue"-->
      <button type="submit"
              form="form-item"
              mat-raised-button
              color="primary"
              [disabled]="!hasFilterValue"
              (click)="filterEntity()">
        Apply
      </button>
    </div>
  </div>
</ng-template>

<ng-template #listRef let-filters>
  <button  *ngFor="let filterItem of filters.filters"
           mat-button
           [ngClass]="{ selected: filterItem.isSelected && filters.filterType === 'button' }"
           (click)="toggleFilterKey(filters, filterItem, filters.multipleSelection)"
           class="filter__list-item">
          <span [totalfitTooltip]="filterItem.filterLabel" class="text-ellipsis">
            {{ filterItem.filterLabel }}
          </span>

    <span *ngIf="filterItem.isSelected && filters.filterType !== 'button'" class="custom-badge">
    </span>
  </button>
</ng-template>

<ng-template #searchRef let-filterCategory>
  <form [formGroup]="filterCategory.filterController"
        id="form-item"
        (input)="registerSearchChange(filterCategory)">
    <div [totalfitFormGroupValidator]="filterCategory.filterController.get(filterCategory.filterKey)"
         [errorMessages]="filterCategory.filterControllerErrors || {required: 'This field is required'}"
         class="form-group form-group_full-width">
      <input [formControl]="filterCategory.filterController.get(filterCategory.filterKey)"
             [type]="filterCategory.inputType || 'text'"
             [mask]="filterCategory.filterMask || ''"
             [placeholder]="filterCategory.filterPlaceholder || ''"
             [dropSpecialCharacters]="false"
             [validation]="false"
             placeHolderCharacter="0"
             class="form-group__control form-group_full-width">
    </div>
  </form>
</ng-template>
