import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromStoryActions from '@app/store/story/story.actions';
import { map, switchMap } from 'rxjs/operators';
import { WorkoutStoryService } from '@shared/services/workout-story.service';

@Injectable()
export class StoryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly workoutStoryService: WorkoutStoryService
  ) {}

  public loadStoryList = createEffect(() => this.actions$.pipe(
    ofType(fromStoryActions.getStoryList),
    switchMap(({ pageSize, pageNumber }) => {
      return this.workoutStoryService.getAllStories(pageSize, pageNumber)
        .pipe(
          map((storyList) => {
            return fromStoryActions.getStoryListSuccess({ storyList });
          })
        );
    })
  ));

  public searchStoryList = createEffect(() => this.actions$.pipe(
    ofType(fromStoryActions.searchStoryList),
    switchMap(({ name }) => {
      return this.workoutStoryService.searchStories(name)
        .pipe(
          map((searchedStories) => {
            return fromStoryActions.searchStoryListSuccess({ searchedStories });
          })
        );
    })
  ));
}
