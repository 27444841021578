import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { tap } from 'rxjs/operators';
import { PaginationModel } from '@shared/models/pagination.model';
import { Document } from '@app/people/_shared/document.model';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient) {}
  public peopleDocuments: PaginationModel<Document> = null;

  public saveDoc(doc: Document): Observable<Document> {
    return this.http.post<Document>(`${environment.apiUrl}/${environment.apiV.apiV1}/files/documents`, doc);
  }

  public deleteDoc(docId: number): Observable<string> {
    return this.http.delete<string>(`${environment.apiUrl}/${environment.apiV.apiV1}/files/documents/${docId}`);
  }

  public updateDoc(doc: Document): Observable<string> {
    return this.http.put<string>(`${environment.apiUrl}/${environment.apiV.apiV1}/files/documents/${doc.id}`, doc);
  }

  public getUserDocs(peopleId: string): Observable<PaginationModel<Document>> {
    return this.http.get<PaginationModel<Document>>(`${environment.apiUrl}/${environment.apiV.apiV1}/files/documents/people/${peopleId}`)
      .pipe(tap((peopleDocs) => this.peopleDocuments = peopleDocs));
  }

  public getDocumentsByPeopleId(peopleId: string): Observable<PaginationModel<Document>> {
    return this.http.get<PaginationModel<Document>>(`${environment.apiUrl}/${environment.apiV.apiV1}/files/documents/people/${peopleId}`);
  }
}
