import { createAction, props } from '@ngrx/store';
import { PaginationModel } from '@shared/models/pagination.model';
import { Story } from '@shared/models/workout.model';

export const GET_STORY_LIST = '[LOCATION] GET STORY LIST';
export const GET_STORY_LIST_SUCCESS = '[LOCATION] GET STORY LIST SUCCESS';

export const SEARCH_STORY_LIST = '[LOCATION] SEARCH STORY LIST';
export const SEARCH_STORY_LIST_SUCCESS = '[LOCATION] SEARCH STORY LIST SUCCESS';

export const getStoryList = createAction(
  GET_STORY_LIST,
  props<{ pageSize?: number, pageNumber?: number, name?: string }>()
);

export const getStoryListSuccess = createAction(
  GET_STORY_LIST_SUCCESS,
  props<{ storyList: PaginationModel<Story> }>()
);

export const searchStoryList = createAction(
  SEARCH_STORY_LIST,
  props<{ name?: string }>()
);

export const searchStoryListSuccess = createAction(
  SEARCH_STORY_LIST_SUCCESS,
  props<{ searchedStories: Story[] }>()
);
