<div class="manuals-block d-flex-center-y">
  <i [ngClass]="'icon-' + icon">
    <span class="path1"></span>
    <span class="path2"></span>
    <span class="path3"></span>
    <span class="path4"></span>
    <span class="path5"></span>
    <span class="path6"></span>
    <span class="path7"></span>
    <span class="path8"></span>
  </i>
  <p class="mat-caption">{{ description }}</p>
</div>
