import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, pairwise } from 'rxjs/operators';
import { ScrollPosition } from '@shared/models/scroll-pagination.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[totalfitInfiniteScroller]'
})
export class ScrollPaginationDirective implements AfterViewInit {
  @Input() public heightDeductionComponents: HTMLElement[];
  @Input() public totalPagination: number;
  @Input() public allItemsLength: number;
  @Input() private itemHeight = 260;
  @Input() private viewType: 'table' | 'list' = window.localStorage.getItem('lastWorkoutTemplateType') as 'table' | 'list';

  @Input() private viewInitTrigger = true;
  @Output() public totalfitInfiniteScroller: EventEmitter<number> = new EventEmitter();
  @Input() private clientHeightGap: number = null;
  private target: HTMLElement = null;
  private itemRerScreen: number;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    this.setTarget(this.elementRef.nativeElement);
    queueMicrotask(() => {
      this.calculateClientHeightGap();
      this.calculateItemsAmount();

      if (this.viewInitTrigger) {
        this.totalfitInfiniteScroller.emit(this.itemRerScreen);
      }
    });

    fromEvent(this.target, 'scroll')
      .pipe(
        untilDestroyed(this),
        map((event): ScrollPosition => {
          const target = event.target as HTMLElement;

          return {
            scrollFromTop: target.scrollTop,
            scrollHeight: target.scrollHeight,
            clientHeight: target.clientHeight
          };
        }),
        pairwise(),
        filter((positions ) => (
          this.isUserScrollingDown(positions)
          && this.isScrollExpectedPercent
          && this.allItemsLength < this.totalPagination
        )),
        debounceTime(300),
      )
      .subscribe(() => this.totalfitInfiniteScroller.emit(this.itemRerScreen));
  }

  private isUserScrollingDown(positions: ScrollPosition[]): boolean {
    return positions[0].scrollFromTop < positions[1].scrollFromTop;
  }

  private get isScrollExpectedPercent(): boolean {
    const scrollItems = this.elementRef.nativeElement.getElementsByClassName('totalfitInfiniteScroller');
    return(scrollItems[scrollItems.length - 1].getBoundingClientRect().bottom - (scrollItems[scrollItems.length - 1].clientHeight / 2))
      < window.innerHeight;
  }

  private calculateClientHeightGap(): void {
    if (this.heightDeductionComponents && this.heightDeductionComponents.length) {
      this.clientHeightGap = this.heightDeductionComponents.reduce(
        (previousValue, element) => previousValue + element.offsetHeight,
        0
      );
    } else {
      this.clientHeightGap = 0;
    }
  }

  private calculateItemsAmount(): void {
    this.itemRerScreen
      = this.viewType === 'list'
      ? (Math.round((this.target.clientHeight - this.clientHeightGap) / this.itemHeight) + 1) * Math.round(this.target.clientWidth / this.itemHeight)
      : Math.round((this.target.clientHeight - this.clientHeightGap) / this.itemHeight) + 1;
  }

  private setTarget(node: HTMLElement): void {
    const nodeStyles = window.getComputedStyle(node);
    if (node) {
      if (nodeStyles.getPropertyValue('overflow') === 'auto' || nodeStyles.getPropertyValue('overflow-y') === 'auto') {
        this.target = node;
      } else {
        this.setTarget(node.parentNode as HTMLElement);
      }
    }
  }
}
