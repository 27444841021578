import { Directive, ElementRef, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[totalfitForceZero]'
})
export class ForceZeroDirective {
  @Input() private totalfitForceZero: AbstractControl;

  constructor(public el: ElementRef) {
    setTimeout(() => {
      if (!this.totalfitForceZero.value && !this.totalfitForceZero.dirty) {
        this.totalfitForceZero.patchValue('00');
      }

      if (String(this.totalfitForceZero.value).length === 1) {
        this.totalfitForceZero.patchValue(`0${this.totalfitForceZero.value}`);
      }
    });
  }
}
