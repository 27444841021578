import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'totalfit-create-new-folder',
  templateUrl: './create-new-folder.component.html',
  styleUrls: ['./create-new-folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewFolderComponent implements OnInit {
  public nameForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; title: string; buttonText: string; },
    private dialogRef: MatDialogRef<CreateNewFolderComponent>
  ) {}

  public ngOnInit(): void {
    this.nameForm = this.fb.group({
      name: ['', Validators.required]
    });

    if (this.data && this.data.name) {
      this.nameForm.get('name').patchValue(this.data.name);
    }
  }

  public onKeyPressEnter(): void {
    if (this.nameForm.invalid) {
      return;
    }

    this.dialogRef.close(this.nameForm.value);
  }
}
