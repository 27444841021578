<div class="library-file">
  <div class="library-list">
    <h2 class="library-list__title">File list</h2>

    <div *ngFor="let file of fileList; let i = index" class="library-list__item">
      <img [src]="getFilePlaceholder(file.fileName)">

      <div class="name">
        {{ file.fileName }}
      </div>

      <button mat-button (click)="removeFileFromList(i)" class="icon-button"><i class="icon-close"></i></button>
    </div>
  </div>

  <totalfit-photo-drop *ngIf="fileList && !fileList.length"
                       [dropWithEmit]="true"
                       [clearModelAfterGetImage]="true"
                       [emptyText]="'Drop file or '"
                       (fileEmmit)="addFile($event)"
                       class="document__drop">
  </totalfit-photo-drop>

  <div class="library-file__actions">
    <button
      [mat-dialog-close]="false"
      mat-stroked-button
      color="primary">
      Cancel
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="addFiles()"
      [disabled]="!fileList.length">
      Save
    </button>
  </div>
</div>
