import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'totalfit-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyComponent {
}
