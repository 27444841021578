import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { ViewModeDirective } from '@shared/directives/editable/view-mode.directive';
import { EditModeDirective } from '@shared/directives/editable/edit-mode.directive';
import { AbstractControl } from '@angular/forms';
import { SnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'totalfit-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableComponent implements OnInit, OnDestroy {
  @ContentChild(ViewModeDirective) private viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) private editModeTpl: EditModeDirective;
  @Input() public idForEdit: string | number;
  @Input() public editingId: string | number;
  @Output() public editingIdChange = new EventEmitter<string | number>();
  @Output() private update = new EventEmitter();
  @Input() public controlForEditing: AbstractControl;

  private editMode = new Subject();
  private editMode$ = this.editMode.asObservable();
  private mode: 'view' | 'edit' = 'view';
  private cachedValue: string;

  private destroySub = new Subject();

  constructor(private host: ElementRef, private detectorRef: ChangeDetectorRef, private readonly snackBar: SnackbarService) {}

  public ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();

    fromEvent(document, 'keyup')
      .pipe(
        filter((e: KeyboardEvent) => {
          return e.keyCode === 13 && this.mode === 'edit';
        }),
        tap(() => {
          this.editingIdChange.emit(null);
          this.toViewMode();
          this.detectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  public toViewMode() {
    const hasError = this.controlForEditing && this.controlForEditing.hasError('required');
    this.mode = 'view';

    if (hasError) {
      this.snackBar.openSnackbar('This value is required and can\'t be saved as empty.');
      if (this.cachedValue) {
        this.controlForEditing.patchValue(this.cachedValue);
      }
      return;
    }

    this.update.next();
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, 'click').pipe(
      takeUntil(this.destroySub)
    ).subscribe(() => {
      if (!this.editingId || this.editingId === this.idForEdit) {
        this.editingIdChange.emit(this.idForEdit);
        this.mode = 'edit';
        this.editMode.next(true);
        this.detectorRef.markForCheck();

        if (this.controlForEditing && this.controlForEditing.value) {
          this.cachedValue = this.controlForEditing.value;
        }
      }
    });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({target}: any) => !this.element.contains(target) && target.tagName !== 'BUTTON' && !target.classList.contains('edit')),
      tap(() => this.editingIdChange.emit(null)),
      take(1)
    );

    this.editMode$.pipe(
      switchMapTo(clickOutside$),
      takeUntil(this.destroySub)
    ).subscribe(() => this.toViewMode());
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  public ngOnDestroy() {
    this.destroySub.next();
    this.destroySub.complete();
  }

}
