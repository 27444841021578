<div [ngClass]="formGroupClass"
     class="form-group form-group_default-width-1 form-group_control-postfix">
  <label class="form-group__label">{{ label }}</label>
  <input [matDatepicker]="picker"
         (dateChange)="updateModel($event.value)"
         [value]="model"
         class="form-group__control">
  <mat-datepicker-toggle matSuffix
                         [for]="picker"
                         class="form-group__postfix">
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
