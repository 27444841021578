import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { SnackbarService } from '@shared/services/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { libRegExp } from '@app/library/_shared/library.model';
import { isMediaFile } from '@app/library/_shared/library.utils';
import { Store } from '@ngrx/store';
import * as fromLibraryActions from '@app/store/library/library.actions';

@Component({
  selector: 'totalfit-add-file-list',
  templateUrl: './add-file-list.component.html',
  styleUrls: ['./add-file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFileListComponent {
  public fileList = [];

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly snackbarService: SnackbarService,
    private readonly store$: Store,
    private dialogRef: MatDialogRef<AddFileListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  public addFile(value): void {
    if (this.fileList.some((item) => item.fileName === value.fileName)) {
      this.snackbarService.openSnackbar('File with this name is already exist in the list');
    } else {
      this.fileList.push(value);
    }

    this.cd.markForCheck();
  }

  public removeFileFromList(index) {
    this.fileList.splice(index, 1);

    this.cd.markForCheck();
  }

  public addFiles(): void {
    const libraryItem = {
      name: this.fileList[0].fileName,
      size: `${this.fileList[0].file.size}`,
      path: `${this.data}/`,
      entries: []
    };
    this.store$.dispatch(fromLibraryActions.addNewFileToLibrary({ file: this.fileList[0], path: this.data, libraryFile: libraryItem }));
    this.dialogRef.close(libraryItem);
  }

  public getFilePlaceholder(fileName: string): string {
    return `assets/img/file-ext/${libRegExp.exec(fileName)[1]
      ? isMediaFile(fileName) ? 'mp3'
        : libRegExp.exec(fileName.replace(/[{()}]/g, ''))[1].toLowerCase() : 'folder'}.svg`;
  }
}
