import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Participant, Training, TrainingRequest, TrainingsLetter } from '@app/trainings/_shared/trainings.model';
import { PaginationModel } from '@shared/models/pagination.model';
import { environment } from '@environments/environment';
import { setFilterQuery } from '@shared/utils/utils';
import { User } from '@app/store/user/user.model';

export type TrainingsStatus = 'ALL' | 'UPCOMING' | 'PAST';

@Injectable()
export class TrainingsService {
  constructor(private http: HttpClient) {}

  public getTrainings(
    pageSize: number,
    pageNumber: number,
    filterObject?: any
  ): Observable<PaginationModel<Training>> {
    const filterQuery = filterObject ? setFilterQuery(filterObject) : null;

    return this.http
      .get<PaginationModel<Training>>(`${environment.apiUrl}/${environment.apiV.apiV1}/training?size=${pageSize}&page=${pageNumber}${filterQuery ? filterQuery : ''}`);
  }

  public editTraining(training: Training, id: string): Observable<any> {
    return this.http.put<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/training/${id}`, training);
  }

  public createTraining(training: Training): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/training`, training);
  }

  public getTrainingById(trainingId: string): Observable<any>  { // Training
    return this.http.get<Training>(`${environment.apiUrl}/${environment.apiV.apiV1}/training/${trainingId}`);
  }

  public getTrainingByLink(link: string): Observable<any>  { // Training
    return this.http.get<Training>(`${environment.apiUrl}/${environment.apiV.apiV1}/training/by-link/${link}`);
  }

  public getAllInstructors(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/instructors`);
  }

  public getAllMessages(): Observable<TrainingsLetter[]> {
    return this.http.get<TrainingsLetter[]>(`${environment.apiUrl}/${environment.apiV.apiV1}/training/messages?scope=WEB`);
  }

  public getRegistrationLink(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${environment.apiV.apiV1}/training/registration-link`);
  }

  public deleteTraining(trainingId: string): Observable<number> {
    return this.http.delete<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/training/${trainingId}`);
  }

  public inviteParticipants(list: { trainingId: string, receivers: string[] }): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/training/invite-participant`, list);
  }

  public sendMessageToParticipants(array: any[]): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/training/messages`, array);
  }

  public getParticipant(id: string): Observable<Participant> {
    return this.http.get<Participant>(`${environment.apiUrl}/${environment.apiV.apiV1}/participant/${id}`);
  }

  public approveTraining(trainingRequest: TrainingRequest): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/training/admin/approve`, trainingRequest);
  }

  public getTrainingCost(country: string): Observable<unknown> {
    return this.http.get(`${environment.apiUrl}/${environment.apiV.apiV1}/training/cost`, { params: { country } });
  }

  public getTrainingLeadItems(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${environment.apiV.apiV1}/training/lead`);
  }
}
