import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AdminOnlineLabel,
  Badge,
  BadgeLable,
  Exercise, ExerciseType, ExerciseTypeLabels,
  FitnessLevel,
  GroupType,
  OnlineExercise,
  OnlineMetric,
  OnlineMetricLabels, OnlineStageOption, OnlineWorkout,
  Plan,
  PlanLabel,
  Rounds, StageMetricType, StageTypeLabels, StageTypes,
  Story,
  TypeOfWork, WodType,
  WorkoutComplex, WorkoutStatus,
  WorkoutTypes,
  WorkoutView
} from '@shared/models/workout.model';
import { SelectionList } from '@shared/models/dictionary.model';
import { WorkoutService } from '@app/workout/_shared/workout.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FormGroupValidatorDirective } from '@shared/directives/form-group-validator.directive';
import { createLabelsFromEnum } from '@shared/utils/utils';
import {
  CustomOfflineWorkoutBlocks,
  CustomOfflineWorkoutBlocksFromForm,
  CustomOfflineWorkoutExtensions
} from '@store/workout/workout.model';
import { AdminProgramRepresentation } from '@app/admin-program/_shared/admin-program.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {finalize} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {setWorkoutIsLoading} from "@store/workout/workout.actions";
import { WorkoutStoryService } from '@shared/services/workout-story.service';
import { OfflineWorkoutService } from '@shared/services/offline-workout.service';

@UntilDestroy()
@Injectable()
export class WorkoutFormService {
  public workoutForm: UntypedFormGroup;
  public customWorkoutForm: UntypedFormGroup;
  public workoutTypes: SelectionList;
  public groupType: SelectionList;
  public typeOfWork: SelectionList;
  public metric: SelectionList;
  public onlineMetric: SelectionList;
  public rounds: SelectionList;
  public plan: SelectionList;
  public fitnessLevel: SelectionList;
  public badge: SelectionList;

  public adminOnlineLabel: SelectionList;
  public exerciseType: SelectionList;
  public workoutStatus: SelectionList;
  public representationStatus: SelectionList;
  public wodType: SelectionList;
  public stageType: SelectionList;
  public stageMetricType: SelectionList;

  public selectedStory: Story;
  public triggerValidators = new Subject<boolean>();
  public wodIndex = 0;

  public workoutSelectValues = {
    cooldown: false,
    warmup: false,
    hlh: false,
    story: false,
    skill: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workoutService: WorkoutService,
    private route: ActivatedRoute,
    private store$: Store,
    private workoutStoryService: WorkoutStoryService,
    private readonly offlineWorkoutService: OfflineWorkoutService
  ) {
    this.workoutForm = this.getWorkoutForm();
    this.customWorkoutForm = this.getCustomWorkoutForm();

    this.workoutTypes = createLabelsFromEnum(WorkoutTypes, WorkoutTypes);

    this.groupType = createLabelsFromEnum(GroupType, GroupType);

    this.typeOfWork = createLabelsFromEnum(TypeOfWork, TypeOfWork);

    this.metric = createLabelsFromEnum(OnlineMetric, OnlineMetricLabels);

    this.onlineMetric = createLabelsFromEnum(OnlineMetric, OnlineMetricLabels);

    this.rounds = createLabelsFromEnum(Rounds, Rounds);

    this.plan = createLabelsFromEnum(Plan, PlanLabel);

    this.fitnessLevel = createLabelsFromEnum(FitnessLevel, FitnessLevel);

    this.badge = createLabelsFromEnum(Badge, BadgeLable);

    this.adminOnlineLabel = createLabelsFromEnum(AdminOnlineLabel, AdminOnlineLabel);

    this.exerciseType = createLabelsFromEnum(ExerciseType, ExerciseTypeLabels);

    this.workoutStatus = createLabelsFromEnum(WorkoutStatus, WorkoutStatus);
    this.representationStatus = createLabelsFromEnum(AdminProgramRepresentation, AdminProgramRepresentation);
    this.wodType = createLabelsFromEnum(WodType, WorkoutTypes);

    this.stageMetricType = createLabelsFromEnum(StageMetricType, StageMetricType);
    this.stageType = createLabelsFromEnum(StageTypes, StageTypeLabels);

    this.triggerValidators
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          FormGroupValidatorDirective.updateFormValidity(this.workoutForm);
          FormGroupValidatorDirective.updateFormValidity(this.customWorkoutForm);
        }
      });
  }
  public getCustomWorkoutForm(): UntypedFormGroup {
    return this.formBuilder.group({
      wod: [null, Validators.required],
      cooldown: [null],
      warmup: [null],
      hlh: [null],
      story: [null],
      skill: [null]
    });
  }

  public getWorkoutForm(): UntypedFormGroup {
    return this.formBuilder.group({
      complexExts: this.formBuilder.array([this.getComplexFromGroup()]),
      dateTime: [],
      equipment: [],
      favorite: [],
      bundleId: [],
      story: this.formBuilder.group({
        estimatedReadingTime: [],
        id: [null],
        image: [],
        name: [''],
        shared: [false],
        story: [''],
        truthToRemember: ['']
      }),
      theme: ['', [Validators.required]],
      wodTime: 1,
      premium: [],
      coachNotes: [null],
      id: null,
      exerciseIds: null
    });
  }

  public setStory(story: Story) {
    if (story && story.id) {
      const control = this.workoutForm.get('story');
      control.patchValue(story);
      this.selectedStory = story;
    } else {
      this.workoutForm.get('story').reset({ shared: false, truthToRemember: '' });
    }
  }

  public getComplexFromGroup(complex?: WorkoutComplex, type = 'WOD', optional = false): UntypedFormGroup {
    const exercisesFormArray: UntypedFormArray = this.formBuilder.array([]);

    if (complex) {
      complex.exercises.forEach((exercise: Exercise) => exercisesFormArray.push(this.getExercisesFromGroup(exercise, type)));
    } else {
      exercisesFormArray.push(this.getExercisesFromGroup(null, type, false, true));
    }

    return this.formBuilder.group({
      complexOrder: [complex ? complex.complexOrder : 1],
      typeOfWork: [complex ? complex.typeOfWork : null],
      metrics: [complex ? complex.metrics : 'ROUNDS'],
      wodType: [complex ? complex.wodType : null, optional ? null : null],
      groupType: [complex ? complex.groupType : null, optional ? null : null],
      quantity: [complex ? complex.quantity : null, optional ? null : null],
      roundsCount: [complex ? complex.roundsCount : null],
      exercises: exercisesFormArray,
      partnerRestExercise: this.getExercisesFromGroup(
        complex && complex.partnerRestExercise
          ? complex.partnerRestExercise
          : null, null, false, true),
      restExercise: this.getExercisesFromGroup(complex && complex.restExercise ? complex.restExercise : null, null, false, true)
    });
  }

  public getExercisesFromGroup(exercise?: Exercise, type?: string, forceType = false, optional = false): UntypedFormGroup {
    return this.formBuilder.group({
      id: [exercise ? exercise.id : null],
      exerciseId: [exercise ? exercise.exerciseId : null],
      type: [forceType ? type : exercise ? exercise.type : type],
      name: [exercise ? exercise.name : ''],
      quantity: [exercise ? exercise.quantity : '', optional ? [] : [Validators.required]],
      metrics: [exercise ? exercise.metrics : '', optional ? [] : [Validators.required]],
      tag: [exercise ? exercise.tag : ''],
      startReps: [exercise ? exercise.startReps : null],
      extraReps: [exercise ? exercise.extraReps : null]
    });
  }

  public getOnlineExercisesFromGroup(exercise?: OnlineExercise, type?: string, forceType = false, optional = false): UntypedFormGroup {
    return this.formBuilder.group({
      type: [forceType ? type : exercise ? exercise.type : type],
      name: [exercise ? exercise.name : '', optional ? [] : [Validators.required]],
      quantity: [exercise ? exercise.quantity : '', optional ? [] : [Validators.required]],
      metrics: [exercise ? exercise.metrics : '', optional ? [] : [Validators.required]],
      onlineExerciseId: [exercise ? exercise.onlineExerciseId : ''],
    });
  }

  public resetWorkoutSelectValues(): void {
    this.workoutSelectValues = {
      cooldown: false,
      warmup: false,
      hlh: false,
      story: false,
      skill: false,
    };
  }

  public setWorkoutFormService(): void {
    Object.keys(this.workoutSelectValues).forEach((key) => {
      const parsedKey = key === 'hlh' ? 'healthyLifestyleHabit' : key;
      if (!this.workoutForm.value[parsedKey]) {
        this.workoutSelectValues[key] = false;

        return;
      }

      if (Array.isArray(this.workoutForm.value[parsedKey])) {
        const hasId = this.workoutForm.value[parsedKey].some((item) => {
          return item.id;
        });

        if (hasId) {
          this.workoutSelectValues[key] = true;
        }

        return;
      } else if (
          typeof this.workoutForm.value[parsedKey] === 'object' && this.workoutForm.value[parsedKey] !== null
            ? this.workoutForm.value[parsedKey].id
            : this.workoutForm.value[parsedKey]
      ) {
        this.workoutSelectValues[key] = true;

        return;
      }

      this.workoutSelectValues[key] = false;
    });
  }

  public setCustomWorkoutFormService(): void {
    Object.keys(this.workoutSelectValues).forEach((key) => {
      if (this.customWorkoutForm.value[key]) {
        this.workoutSelectValues[key] = true;
      }
    });
  }

  public get complexExts(): AbstractControl[] {
    return (this.workoutForm.get('complexExts') as UntypedFormArray).controls;
  }

  public saveChanges(
    workout: WorkoutView,
    workoutResponseRedirect?: (workout: any) => void
  ): void {
    const valueForSave = this.buildWorkoutForSave(workout);

    if (valueForSave.story?.name && !valueForSave.story.id) {
      this.workoutStoryService.dropCache();
    }

    (workout && workout.id
      ? this.workoutService.editWorkout(valueForSave, workout.id)
      : this.workoutService.saveWorkout({...valueForSave, id: ''}))
      .pipe(finalize(() => this.store$.dispatch(setWorkoutIsLoading({ workoutIsLoading: false }))))
      .subscribe((workoutResponce) => {
        this.offlineWorkoutService.dropCache();
        window.sessionStorage.removeItem('workoutPreviewSingle');
        window.sessionStorage.removeItem('customWorkoutPreviewSingle');

        if (!workout?.bundleId) {
          this.workoutService.updateCachedList(workoutResponce);
        }

        if (workoutResponseRedirect) {
          workoutResponseRedirect(workoutResponce);
        }
      });
  }

  public buildWorkoutForSave(workout: WorkoutView, isTemplate = false) {
    const valueForSave = {...this.workoutForm.value};
    Object.keys(this.workoutSelectValues).forEach((key) => {
      const parsedKey = key === 'hlh' ? 'healthyLifestyleHabit' : key;

      if (!this.workoutSelectValues[key]) {
        valueForSave[parsedKey] = null;
      }
    });
    const customOfflineWorkoutExtensions = [];

    Object.keys(CustomOfflineWorkoutBlocksFromForm).forEach((key) => {
      if (this.customWorkoutForm.value[key] !== null) {
        let customOfflineWorkoutExtension;

        if (workout && workout.customOfflineWorkoutExtensions) {
          customOfflineWorkoutExtension
            = workout.customOfflineWorkoutExtensions.find((c) => c.extensionType === CustomOfflineWorkoutBlocks[key]);
        }

        if (CustomOfflineWorkoutBlocks[key] === 'WOD' || this.workoutSelectValues[CustomOfflineWorkoutBlocks[key].toLowerCase()]) {
          customOfflineWorkoutExtensions.push({
            content: this.customWorkoutForm.value[key],
            extensionType: CustomOfflineWorkoutBlocks[key],
            id: customOfflineWorkoutExtension ? customOfflineWorkoutExtension.id : null
          });
        }


        if (valueForSave.hasOwnProperty(key)) {
          valueForSave[key] = null;
        }

        if (key === 'wod') {
          valueForSave.complexExts = null;
        }

        if (key === 'hlh') {
          valueForSave.healthyLifestyleHabit = null;
        }
      }
    });

    valueForSave.customOfflineWorkoutExtensions = customOfflineWorkoutExtensions;

    if (
      this.selectedStory?.name && valueForSave.story?.name
      && (this.selectedStory?.name !== valueForSave.story?.name || this.selectedStory?.story !== valueForSave.story?.story)
    ) {
      valueForSave.story.id = null;
    }

    if (isTemplate && !this.selectedStory?.id) {

      valueForSave.story.shared = true;
    }

    return valueForSave;
  }

  public initWorkoutForm(workout?: WorkoutView): void {
    this.workoutForm = this.getWorkoutForm();
    if (!workout) {
      this.workoutForm.reset(
        {
          wodTime: 1,
          complexExts: [{ complexOrder: 1, metrics: 'ROUNDS' }],
          cooldown: [{ type: 'COOLDOWN' }],
          warmup: [{ type: 'WARMUP' }]
        });
      return;
    }
    if (this.route.snapshot.queryParams.partialInit) {
      this.workoutForm.reset(
        {
          wodTime: 1,
          complexExts: [{ complexOrder: 1, metrics: 'ROUNDS' }],
          cooldown: [{ type: 'COOLDOWN' }],
          warmup: [{ type: 'WARMUP' }],
          ...workout
        });
      return;
    }

    this.workoutForm.reset();

    this.workoutForm.patchValue({ ...workout, wodTime: workout.wodTime ? workout.wodTime : 1 });
  }

  public patchCustomWorkoutForm(customWorkoutValue: any): void {
    if (Array.isArray(customWorkoutValue)) {
      customWorkoutValue.forEach((workout) => {
        const extensionType = workout.extensionType.toLowerCase().trim();
        this.customWorkoutForm.get(extensionType).patchValue(workout.content);
      });
    } else {
      this.customWorkoutForm.patchValue(customWorkoutValue);
    }
  }

  public setHabits(habit: { name: string, id?: string, tag?: string}): void {
    if (habit) {
      this.customWorkoutForm.get('hlh').patchValue(habit.name);
    }
  }

  public initOnlineWorkoutForm(onlineWorkout: OnlineWorkout): UntypedFormGroup {
    const group = this.getOnlineWorkoutForm();
    const stageControl = group.get('stages') as UntypedFormArray;

    if (onlineWorkout) {
      onlineWorkout.stages.forEach((stage) => {
        stageControl.push(this.getStageFormGroup(stage));
      });
    }

    group.patchValue(onlineWorkout);
    return group;
  }

  private getOnlineWorkoutForm(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [],
      badge: ['', Validators.required],
      difficultyLevel: ['', Validators.required],
      equipment: ['any', Validators.required],
      estimatedTime: ['', Validators.required],
      image: ['', Validators.required],
      orderNumber: [''],
      plan: ['', Validators.required],
      priorityStage: ['', Validators.required],
      skillImage:	['any', Validators.required],
      theme: ['', Validators.required],
      status: ['', Validators.required],
      stages: this.formBuilder.array([]),
      metrics: ['ROUNDS', Validators.required],
      warmupRounds: ['1', Validators.required],
      quantity: ['1', Validators.required],
      wodType: ['FOR_TIME', Validators.required]
    });
  }

  public getStageFormGroup(stage?: OnlineStageOption): UntypedFormGroup {
    const exercises = this.formBuilder.array([]);
    const stageOptionRest = this.formBuilder.array([]);
    if (stage && stage.exercises) {
      stage.exercises.forEach((exercise) => {
        const group = this.getExerciseGroup(exercise);
        exercises.push(group);
      });
    }

    if (stage && stage.stageOption) {
      stage.stageOption.rests.forEach((restItem) => {
        stageOptionRest.push(this.formBuilder.group({
          ...restItem
        }));
      });
    }

    return this.formBuilder.group({
      exercises,
      stageName: [stage ? stage.stageName : null],
      stageOption: this.formBuilder.group({
        metricQuantity: [stage ? stage.stageOption.metricQuantity : null],
        metricType: [stage ? stage.stageOption.metricType : null] ,
        rests: stageOptionRest
      }),
      stageType: [stage ? stage.stageType : null]
    });
  }

  public getExerciseGroup(exercise?): UntypedFormGroup {
    return this.formBuilder.group({
      type: [exercise ? exercise.type : null],
      onlineExerciseId: [exercise ? exercise.onlineExerciseId : null],
      name: [exercise ? exercise.name : null],
      quantity : [exercise ? exercise.quantity : null],
      metrics: [exercise ? exercise.metrics : null]
    });
  }

  public checkCustomFormContentUpdated(fcc: CustomOfflineWorkoutExtensions[], scc: CustomOfflineWorkoutExtensions[]): boolean {
    if (fcc?.length !== scc?.length) {
      return true;
    }

    return !fcc.every((fccItem) => {
      return scc.find((sccItem) => sccItem.extensionType === fccItem.extensionType)?.content === fccItem.content;
    });
  }

  public checkFormContentUpdated(fc: WorkoutView, sc: WorkoutView): boolean {
    return !(
      fc.theme === sc.theme
        && fc.story?.name === sc.story?.name
        && fc.story?.story === sc.story?.story
        && fc.coachNotes === sc.coachNotes);
  }
}
