import {
  Component,
  ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef
} from '@angular/core';
import { getValueAccessor } from '@shared/models/form-control.component';
import { LocationListService } from '@app/location/_shared/location-list.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingPageComponent } from '@shared/models/loading-page.component';
import { ProfileFormService } from '@shared/services/profile-form.service';
import { SharedFormService } from '@shared/services/shared-form-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [getValueAccessor(ProfileComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProfileComponent extends LoadingPageComponent implements OnInit {
  @Input() public isEditable = false;

  constructor(
    public locationListService: LocationListService,
    public profileFormService: ProfileFormService,
    public route: ActivatedRoute,
    public sharedFormService: SharedFormService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  public ngOnInit(): void {
    this.profileFormService.patchForm(this.route.snapshot.data.people);
    this.profileFormService.peopleForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }
}
