<div class="dialog mat-typography">
  <div class="dialog__header">
    <h3> {{data.title}} </h3>
  </div>
  <div class="dialog__body">
    {{ data.description }}
  </div>
  <div class="dialog__footer">
    <button *ngIf="!data.isCancelButtonHidden"
            mat-button
            type="button"
            mat-dialog-close>
      {{ data.cancelButtonText }}
    </button>
    <button mat-raised-button
            type="button"
            cdkFocusInitial
            [mat-dialog-close]="true"
            [color]="data.applyButtonColorPallet">
      {{ data.applyButtonText }}
    </button>
  </div>
</div>
