import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'totalfit-totalfit-spinner',
  templateUrl: './totalfit-spinner.component.html',
  styleUrls: ['./totalfit-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalfitSpinnerComponent {
  @Input() public isShown: boolean;
  @Input() public spinnerClass: string;
  @Input() public spinnerContainerClass: string;
  @Input() public color: ThemePalette = 'primary';
  @Input() public diameter = 70;
}
