import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUserActions from '@app/store/user/user.actions';
import * as fromAppUIActions from '@app/store/app-ui-state/app-ui-state.actions';
import { catchError, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AdminUsersService } from '@app/admin-users/_shared/admin-users.service';
import { selectAdmitTableFilterState } from '@app/store/app-ui-state/app-ui-state.selector';
import { TablePropNames } from '@app/store/app-ui-state/app-ui-state.actions';
import { Store } from '@ngrx/store';
import { PeopleService } from '@app/people/_shared/people.service';
import { of } from 'rxjs';
import { WebProfileService } from '@shared/services/web-profile.service';
import { GlobalSpinnerService } from '@shared/services/global-spinner.service';

@Injectable()
export class UserEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly webProfileService: WebProfileService,
    private readonly adminUsersService: AdminUsersService,
    private readonly peopleService: PeopleService,
    private readonly store$: Store,
    private readonly globalSpinner: GlobalSpinnerService
  ) {}

  public loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.LOGIN),
    switchMap(({ value, keepIn }) => {
      return this.authService.getUser(value, keepIn);
    }),
    switchMap(((user) => {
      return [
        fromUserActions.setUser({ user }),
        fromAppUIActions.appNavigate({ route: '/' })
      ];
    }))
  ));

  public loadWebProfile$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.getWebProfile),
    switchMap(() => {
      return this.webProfileService.getWebProfile()
        .pipe(
          map((webProfile) => {
            return fromUserActions.getWebProfileSuccess({ webProfile });
          })
        );
    })
  ));

  public editWebProfile$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.editWebProfile),
    this.globalSpinner.wrapRequest(({ webProfile }) => {
      return this.webProfileService.updateWebProfile(webProfile)
        .pipe(
          map((webProfileResp) => {
            return fromUserActions.getWebProfileSuccess({ webProfile: webProfileResp });
          })
        );
    })
  ));

  public loadWebProfileProgress$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.getWebProfileProgress),
    this.globalSpinner.wrapRequest(() => {
      return this.webProfileService.getUserProgress()
        .pipe(
          map((progress) => {
            return fromUserActions.getWebProfileProgressSuccess({ progress });
          })
        );
    })
  ));

  public getUserByToken$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.getUserByToken),
    switchMap(({ user }) => {
      return this.webProfileService.getUserByToken(user.token)
        .pipe(
          map((userByToken) => {
            this.authService.setUpUser(userByToken, !!JSON.parse(window.localStorage.getItem('keepIn')), false);
            return fromUserActions.setUser({ user: userByToken });
          })
        );
    }),
  ));

  public addAdminUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.addAdminUser),
    switchMap(({ value }) => {
      return this.adminUsersService.addAdminUser(value)
        .pipe(
          map(() => fromAppUIActions.appNavigate({ route: '/admin-users' })),
          catchError(() => of(null))
        );
    })
  ));

  public upgradePlan$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.subscription),
    tap(() => {
      this.store$.dispatch(fromUserActions.setUpdateStatus({ updateType: 'UPDATING' }));
    }),
    switchMap(({ user, paymentMethod }) => {
      return this.adminUsersService.subscription(paymentMethod, user.email)
        .pipe(
          map(() => {
            return fromUserActions.getUserByToken({user});
          }),
          finalize(() => {
            this.store$.dispatch(fromUserActions.setUpdateStatus({ updateType: 'DONE' }));
          }),
          catchError(() => {
            return of(fromUserActions.setUpdateStatus({ updateType: 'FAILED' }));
          })
        );
    })
  ));

  public loadAdminUsers = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.GET_ADMIN_USER),
    withLatestFrom(
      this.store$.select(selectAdmitTableFilterState, { key: TablePropNames.ADMIN_USERS_TABLE })
    ),
    switchMap(([{ pageSize, pageNumber, tabFilter }, filter]: any) => {
      return this.adminUsersService.getAdminPeoplesCriteria(pageSize, pageNumber, {...tabFilter, ...filter})
        .pipe(
          map((adminUsers) => {
            return fromUserActions.getAdminUserSuccess({adminUsers});
          })
        );
    })
  ));

  public loadPeople = createEffect(() => this.actions$.pipe(
    ofType(fromUserActions.getPeople),
    withLatestFrom(
      this.store$.select(selectAdmitTableFilterState, { key: TablePropNames.PEOPLE_TABLE })
    ),
    switchMap(([{ pageSize, pageNumber, tabFilter }, filter]: any) => {
      return this.peopleService.getPeopleCriteria(pageSize, pageNumber, {...tabFilter, ...filter})
        .pipe(
          map((people) => {
            return fromUserActions.getPeopleSuccess({people});
          })
        );
    })
  ));
}
