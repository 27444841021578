import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { slideInAnimation } from '@core/animations/animations';
import { AuthService } from '@core/auth/auth.service';
import { DialogService } from '@shared/services/dialog.service';
import { UpdatePlanComponent } from '@shared/dialogs/update-plan/update-plan.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectWebProfile } from '@app/store/user/user.selector';
import * as fromUserActions from '@store/user/user.actions';
import { AmplitudeActions, AmplitudeService } from '@shared/services/amplitude.service';
import { WebProfile } from '@shared/models/web-profile.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'totalfit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [slideInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() public title: string;
  @Input() public hasBackNavigation: boolean;
  @Input() public routeBack: string;
  @Input() public optionalParams: any;
  public searchControl: UntypedFormControl;
  public user$: Observable<WebProfile>;

  constructor(
    public authService: AuthService,
    private dialogService: DialogService,
    private readonly store: Store,
    private readonly amplitudeService: AmplitudeService
  ) {}

  public ngOnInit(): void {
    this.searchControl = new UntypedFormControl(null);
    this.user$ = this.store.select(selectWebProfile).pipe(tap((value) => {
      if (!value && this.authService.authToken) {
        this.store.dispatch(fromUserActions.getWebProfile());
      }
    }));
  }

  public getUserAvatar(imageBase64: string): string {
    return `${imageBase64}`;
  }

  public openPersonalPlan(): void {
    this.dialogService.openDialog(UpdatePlanComponent);
    this.amplitudeService.triggerEvent(AmplitudeActions.USER_UPGRADE_PLAN_CLICK);
  }

  public changeSelectedRole(role: 'ADMIN' | 'USER'): void {
    this.authService.setSelectedRole(role);
  }
}
