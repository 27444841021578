import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Training } from '@app/trainings/_shared/trainings.model';
import { TrainingUtilsService } from '@shared/services/training-utils.service';

@Component({
  selector: 'totalfit-training-title',
  templateUrl: './training-title.component.html',
  styleUrls: ['./training-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainingTitleComponent implements OnInit {
  @Input() public training: Training;
  public imageContainer: string[];

  constructor(public trainingUtilsService: TrainingUtilsService) {}

  public ngOnInit(): void {
    this.imageContainer = [...this.training.instructors.map((ins) => ins.photo).reverse(), this.training.leadInstructor.photo];
  }
}
