import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const user = (state: AppState) => state.user;

export const selectUser = createSelector(
  user,
  (state) => state.user
);

export const selectWebProfile = createSelector(
  user,
  (state) => state.webProfile
);

export const selectWebProfileProgress = createSelector(
  user,
  (state) => state.progress
);

export const selectAdminUsers = createSelector(
  user,
  (state) => state.adminUsers
);

export const selectPeople = createSelector(
  user,
  (state) => state.people
);

export const selectUpdatedStatus = createSelector(
  user,
  (state) => state.updatedStatus
);
