import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromLocationActions from '@app/store/location/location.actions';
import { editLocationEventSuccess, getCompletedWorkoutDetailSuccess } from '@app/store/location/location.actions';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LocationListService } from '@app/location/_shared/location-list.service';
import { GlobalSpinnerService } from '@shared/services/global-spinner.service';
import { selectAdmitTableFilterState } from '@store/app-ui-state/app-ui-state.selector';
import { appNavigate, appShowSnackBarComponent, TablePropNames } from '@store/app-ui-state/app-ui-state.actions';
import { Store } from '@ngrx/store';
import { CustomSnackBarDataType, SnackbarService } from '@shared/services/snackbar.service';
import { selectRouteParam } from '@store/router.selectors';
import { MainLocationEvent } from '@store/location/location.model';
import { MESSAGES } from '@shared/messages';
import { EMPTY, merge, of } from 'rxjs';

@Injectable()
export class LocationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly locationListService: LocationListService,
    private readonly globalSpinner: GlobalSpinnerService,
    private readonly store$: Store,
    private readonly snackbarService: SnackbarService
  ) {}

  public loadLocations = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getLocations),
    switchMap(() => {
      return this.locationListService.getLocationList()
        .pipe(
          map((locations) => {
            return fromLocationActions.getLocationsSuccess({ locations });
          })
        );
    })
  ));

  public loadLocationById = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getMainLocation),
    this.globalSpinner.wrapRequest(({ id, from, to }) => {
      return this.locationListService.getMainLocation(id, from, to)
        .pipe(
          map((location) => {
            return fromLocationActions.getMainLocationSuccess({ location });
          })
        );
    }),
  ));

  public sendNotification = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.sendNotification),
    this.globalSpinner.wrapRequest(({ notification, eventType }) => {
      return this.locationListService.sendNotification(notification)
        .pipe(
          switchMap((res: any) => {
            return [eventType === 'preview'
              ? getCompletedWorkoutDetailSuccess({ completedWorkout: res})
              : editLocationEventSuccess(
                { event: res as MainLocationEvent, oldDate: null }),
              appShowSnackBarComponent({
                message: MESSAGES.notificationMessage,
                title: MESSAGES.notificationTitle,
                notificationType: CustomSnackBarDataType.SUCCESS
              })];
          })
        );
    }),
  ));

  public loadLocationEventsById = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getMainLocationEvents),
    switchMap(({ location_id, from, to }) => {
      return this.locationListService.getMainLocationEvents(location_id, from, to)
        .pipe(
          map((events) => {
            return fromLocationActions.getMainLocationEventsSuccess({ events });
          })
        );
    })
  ));

  public loadLocationEventsForTodayById = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getMainLocationForTodayEvents),
    switchMap(({ location_id, today }) => {
      return this.locationListService.getMainLocationEvents(location_id, today, today)
        .pipe(
          map((events) => {
            return fromLocationActions.getMainLocationForTodayEventsSuccess({ events });
          })
        );
    })
  ));

  public createLocationEvent = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.createLocationEvent),
    switchMap(({ event }) => {
      return this.locationListService.createLocationEvent(event)
        .pipe(
          map((createdEvent) => {
            return fromLocationActions.createLocationEventSuccess({ event: createdEvent });
          })
        );
    })
  ));

  public editLocationEvent = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.editLocationEvent),
    switchMap(({ event, oldDate }) => {
      return this.locationListService.editLocationEvent(event)
        .pipe(
          switchMap((createdEvent) => {
            const today = new Date().toISOString().substr(0, 10);
            // if today old or new - load
            return merge(
              of(fromLocationActions.editLocationEventSuccess({ event: createdEvent, oldDate })),
              ((oldDate && today === oldDate) || today === createdEvent.date)
                ? of(fromLocationActions.getMainLocationForTodayEvents({ location_id: createdEvent.locationId, today }))
                : EMPTY
            );
          })
        );
    })
  ));

  public editMainLocation = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.editMainLocation),
    switchMap(({ location }) => {
      return this.locationListService.editMainLocation(location)
        .pipe(
          map(() => {
            return appNavigate({ route: `tribe/${location.id}/view` });
          })
        );
    })
  ));

  public deleteLocationEvent = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.deleteLocationEvent),
    switchMap(({ event }) => {
      return this.locationListService.deleteLocationEvent(event)
        .pipe(
          map(() => {
            return fromLocationActions.deleteLocationEventSuccess({ event });
          })
        );
    })
  ));

  public deleteTribeMember = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.deleteTribeMember),
    withLatestFrom(this.store$.select(selectRouteParam('id'))),
    switchMap(([{ tribe }, id]) => {
      return this.locationListService.deleteTribeMember(tribe, id)
        .pipe(
          map(() => {
            return fromLocationActions.deleteTribeMemberSuccess({ id: tribe.id });
          })
        );
    })
  ));

  public deleteMainLocation = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.deleteMainLocation),
    switchMap(({ locationId }) => {
      return this.locationListService.deleteMainLocation(locationId)
        .pipe(
          map(() => {
            return fromLocationActions.deleteMainLocationSuccess({ locationId: String(locationId) });
          })
        );
    })
  ));

  public loadLocationList = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getMainLocationList),
    this.globalSpinner.wrapRequest(() => {
      return this.locationListService.getMainLocationList()
        .pipe(
          map((locations) => {
            return fromLocationActions.getMainLocationListSuccess({ locations });
          })
        );
    }),
  ));

  public createMainLocations = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.createMainLocation),
    switchMap(({ name }) => {
      return this.locationListService.createMainLocation(name)
        .pipe(
          map((location) => {
            return fromLocationActions.createMainLocationSuccess({ location });
          })
        );
    })
  ));

  public getTribeMembers = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getTribeMembers),
    withLatestFrom(
      this.store$.select(selectAdmitTableFilterState, { key: TablePropNames.TRIBE_MEMBER_FILTER })
    ),
    switchMap(([{ page, size, id }, filter]) => {
      return this.locationListService.getTribeMembers(size, page, id, filter)
        .pipe(
          map((tribeMembers) => {
            return fromLocationActions.getTribeMembersSuccess({ tribeMembers });
          })
        );
    })
  ));

  public getLocationLinks = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getInviteLinks),
    switchMap(({ pageSize, pageNumber, id }) => {
      return this.locationListService.getInviteLinks(pageSize, pageNumber, id)
        .pipe(
          map((inviteLinks) => {
            return fromLocationActions.getInviteLinksSuccess({ inviteLinks });
          })
        );
    })
  ));


  public deleteInviteLink = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.deleteInviteLink),
    withLatestFrom(this.store$.select(selectRouteParam('id'))),
    switchMap(([{ link }, id]) => {
      return this.locationListService.deleteInviteLinks(link, id)
        .pipe(
          map(() => {
            return fromLocationActions.deleteInviteLinkSuccess({ link });
          })
        );
    })
  ));

  public getCompletedWorkouts = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getCompletedWorkouts),
    withLatestFrom(
      this.store$.select(selectAdmitTableFilterState, { key: TablePropNames.LOCATION_COMPLETED_WORKOUT })
    ),
    switchMap(([{ pageSize, pageNumber, id }, filter]) => {
      return this.locationListService.getCompletedWorkouts(pageSize, pageNumber, id, filter)
        .pipe(
          map((completedWorkouts) => {
            return fromLocationActions.getCompletedWorkoutsSuccess({ completedWorkouts });
          })
        );
    })
  ));

  public getCompletedWorkout = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getCompletedWorkoutDetail),
    switchMap(({ id, eventId }) => {
      return this.locationListService.getCompletedWorkoutDetails(id, eventId)
        .pipe(
          map((completedWorkout) => {
            return fromLocationActions.getCompletedWorkoutDetailSuccess({ completedWorkout });
          })
        );
    })
  ));

  public getWaitingForReaction = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.getWaitingForReaction),
    switchMap(({ id }) => {
      return this.locationListService.getWaitingForReaction(id)
        .pipe(
          map((waitingForReaction) => {
            return fromLocationActions.getWaitingForReactionSuccess({ waitingForReaction });
          })
        );
    })
  ));

  public updateUserLogResult = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.updateUserLogResult),
    switchMap(({ logResult, eventType }) => {
      return this.locationListService.updateUserLogResult(logResult)
        .pipe(
          map(() => {
            this.snackbarService.openNotificationComponent({
              data: {
                message: 'Your reaction has been successfully sent.',
                title: 'Reaction sent',
                type: CustomSnackBarDataType.SUCCESS
              }
            });
            return eventType === 'wfr'
              ? fromLocationActions.updateUserLogResultSuccess({ logResult })
              : fromLocationActions.updateCompletedWorkoutDetails({ logResult });
          })
        );
    })
  ));

  public createInviteLink = createEffect(() => this.actions$.pipe(
    ofType(fromLocationActions.createInviteLink),
    switchMap(({ id, linkLife }) => {
      return this.locationListService.createInviteLink(id, linkLife)
        .pipe(
          map((newInviteLink) => {
            return fromLocationActions.createInviteLinkSuccess({ newInviteLink });
          })
        );
    })
  ));
}
