import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[totalfitFormGroupValidator]'
})
export class FormGroupValidatorDirective implements OnInit {
  @Input('totalfitFormGroupValidator') public formControl: AbstractControl;
  @Input() public errorMessages: {[key: string]: string} = { required: 'This field is required', max: 'Invalid value' };
  private errorElements: HTMLElement[] = [];

  public static updateFormValidity(abstractControl: AbstractControl): boolean {
    const updateValidity = (formControl: AbstractControl) => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    };

    abstractControl.markAllAsTouched();

    if (abstractControl instanceof UntypedFormGroup) {
      Object.keys(abstractControl.controls).forEach((controlName) => {
        updateValidity(abstractControl.get(controlName));
        this.updateFormValidity(abstractControl.get(controlName));
      });
    } else if (abstractControl instanceof UntypedFormArray) {
      abstractControl.controls.forEach((control) => {
        if (control instanceof UntypedFormGroup) {
          this.updateFormValidity(control);
        }

        return updateValidity(control);
      });
    } else {
      updateValidity(abstractControl);
    }

    return abstractControl.valid;
  }

  public static removeValidators(abstractControl: AbstractControl): void {
    if (abstractControl instanceof UntypedFormGroup) {
      Object.keys(abstractControl.controls).forEach((controlName) => {
        this.removeValidators(abstractControl.get(controlName));
      });
    } else if (abstractControl instanceof UntypedFormArray) {
      abstractControl.controls.forEach((control) => {
        if (control instanceof UntypedFormGroup) {
          this.removeValidators(control);
        }
      });
    } else {
      abstractControl.clearValidators();
      abstractControl.updateValueAndValidity();
    }
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    if (this.formControl) {
      this.formControl.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.formControl.dirty) {
            const errors = this.formControl.errors;

            if (errors?.max) {
              this.errorMessages.max = `Invalid value. Max value is ${errors.max.max}`;
            }

            this.errorElements.forEach((element) => this.renderer.removeChild(this.elementRef.nativeElement, element));
            this.errorElements.length = 0;

            if (errors !== null && Object.keys(errors).some((er) => errors[er])) {
              this.renderer.addClass(this.elementRef.nativeElement, 'form-group_invalid');

              Object.keys(errors).forEach((key) => {
                if (errors[key]) {
                  const message = this.errorMessages[key];

                  if (message) {
                    const errorElement = this.renderer.createElement('div');

                    this.renderer.addClass(errorElement, 'invalid-feedback');
                    this.renderer.setProperty(errorElement, 'innerText', message);
                    this.errorElements.push(errorElement);
                  }
                }
              });
              this.errorElements.forEach((element) => this.renderer.appendChild(this.elementRef.nativeElement, element));
            } else {
              this.renderer.removeClass(this.elementRef.nativeElement, 'form-group_invalid');
            }
          }
        });
    }
  }
}
