import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { PaginationModel } from '@shared/models/pagination.model';
import { User, UserPermissionsId } from '@app/store/user/user.model';
import { setFilterQuery } from '@shared/utils/utils';
import { Certificates } from '@shared/models/web-profile.model';

@Injectable()
export class AdminUsersService {
  constructor(private http: HttpClient) {}

  public getAdminUsers(): Observable<PaginationModel<User>> {
    return this.http.get<PaginationModel<User>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/users/admin`);
  }

  public getAdminUser(id: string): Observable<User> {
    return this.http.get<User>(`${ environment.apiUrl }/${environment.apiV.apiV1}/users/admin/${id}`);
  }

  public getAdminUserCertificates(id: string): Observable<Certificates[]> {
    return this.http.get<Certificates[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/certificate/admin/certificate/${id}`);
  }

  public saveDoc(doc: Certificates): Observable<Certificates[]> {
    return this.http.post<Certificates[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/certificate/admin/certificate`, doc);
  }

  public removeDoc({ id }: Certificates): Observable<Certificates[]> {
    return this.http.delete<Certificates[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/certificate/admin/certificate/${id}`);
  }

  public getAdminUserRemove(id: string): Observable<any[]> {
    return this.http.delete<any[]>(`${ environment.apiUrl }/${environment.apiV.apiV1}/certificate/admin/certificate/${id}`);
  }

  public editAdminUser(model: any): Observable<any> {
    return this.http.put<User>(`${ environment.apiUrl }/${environment.apiV.apiV1}/users/admin/${model.id}`, model);
  }

  public changeAccountType(model: { accountType: UserPermissionsId; userId: string }): Observable<any> {
    return this.http.post<User>(`${ environment.apiUrl }/${environment.apiV.apiV1}/users/admin/account-type`, model);
  }

  public changeAdminUserPassword(value, id: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }/${environment.apiV.apiV1}/users/admin/change-password/${id}`,
      { params: value });
  }

  public searchAdminUsers(prefix: string, pageSize: number, pageNumber: number): Observable<PaginationModel<User>> {
    return this.http
      .get<PaginationModel<User>>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/admin/search?size=${pageSize}&page=${pageNumber}&prefix=${prefix}`);
  }

  public getAdminPeoplesCriteria(
    pageSize: number,
    pageNumber: number,
    filterObject?: any
  ): Observable<PaginationModel<User>> {
    const filterQuery = filterObject ? setFilterQuery(filterObject) : null;

    return this.http
      .get<PaginationModel<User>>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/admin?size=${pageSize}&page=${pageNumber}${filterQuery ? filterQuery : ''}`);
  }

  public addAdminUser(value: { email: string; firstName: string; lastName: string }): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/users/admin`, value);
  }

  public subscription(paymentMethod: string, userEmail: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/payment/subscription`, { paymentMethod, userEmail });
  }
}
