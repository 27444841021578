<ng-container *ngIf="!config.triggerType">
  <button *ngIf="!isMenuDisabled else disabledButton"
          [totalfitDropdownToggle]="buttonsTemplateRef"
          [isPanelAlignedToRight]="true"
          [isPanelHasFlexibleWidth]="true"
          (click)="$event.stopPropagation()"
          mat-button
          class="trigger">
    <mat-icon>more_horiz</mat-icon>
  </button>
</ng-container>
<!-- TODO Refactor -->
<ng-container *ngIf="config.triggerType">
  <button [totalfitDropdownToggle]="buttonsTemplateRef"
          [isPanelAlignedToRight]="true"
          [isPanelHasFlexibleWidth]="true"
          [ngClass]="config.triggerType.class"
          mat-raised-button
          color="primary"
          (click)="$event.stopPropagation()">
    <i [ngClass]="config.triggerType.iconClass"></i>
  </button>
</ng-container>

<ng-template #disabledButton>
  <button (click)="$event.stopPropagation()">
    disabled
  </button>
</ng-template>

<ng-template #buttonsTemplateRef>
  <div class="dots-menu">
    <button *ngFor="let button of config.buttons" [class.disabled]="isButtonDisabled(button)"
            mat-button
            (click)="triggerCallbackFn(button, $event)">
      {{ button.label }}
    </button>
  </div>
</ng-template>
