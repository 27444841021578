<div class="modal mat-typography">
  <button mat-flat-button class="icon-button" [mat-dialog-close]="false"><mat-icon>close</mat-icon></button>
  <img class="modal__img" src="assets/img/plan.svg">

  <div class="modal__mess">
    Sorry, this action is unavailable on current plan.
  </div>

  <div class="modal__action">
    <button mat-raised-button color="primary" class="upgrade" (click)="upgrade()">Upgrade your plan</button>
    <button mat-button class="not-now" [mat-dialog-close]="false">Not now</button>
  </div>
</div>
