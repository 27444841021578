// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://staging-01-totalfit.team',
  amazonFilePath: 'https://totalfit-users-uploads.s3-eu-west-1.amazonaws.com',
  amazonLibraryFilePath: 'https://totalfit-library-bucket.s3-eu-west-1.amazonaws.com',
  stripePublishableKey: 'pk_test_51Hht7JCP7r7nAiREkhzAuVLz6aIwkI5Z2kpYCXUw7wowAwuMYBdduX1b3QpIjtc7ZKRmZoKy0J4QbRsr7DQhBgbd007dXDM8CG',
  apiV: {
    apiV1: 'api/v1',
    apiV2: 'api/v2'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
