import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DialogService } from '@shared/services/dialog.service';
import { PhotoPreviewComponent } from '@shared/dialogs/photo-preview/photo-preview.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'totalfit-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificateComponent {
  @Input() public text: string;
  @Input() public photo: string;

  constructor(private dialogService: DialogService, private readonly http: HttpClient) {}

  public preview() {
    this.dialogService.openDialog(PhotoPreviewComponent, this.photo);
  }

  public load(url) {
    this.http.get(url).subscribe();
  }

  public getSaveLink(path: string): string {
    const spitedPath = path.split('/');
    return `${environment.apiUrl}/${environment.apiV.apiV1}/certificate/download?name=${spitedPath[spitedPath.length - 1]}`;
  }
}
