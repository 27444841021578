import { ChangeDetectorRef, Component } from '@angular/core';

// @ts-ignore
@Component({
  template: ''
})
export abstract class LoadingPageComponent {
  private _isLoading = false;

  protected constructor(private cd: ChangeDetectorRef) {}

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }
}
