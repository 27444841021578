import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as workoutActions from '@store/workout/workout.actions';
import * as bundleSelector from '@store/bundle/bundle.selector';
import { combineLatest, Observable, Subject } from 'rxjs';
import { BundleTypes, CustomOfflineWorkoutBlocks, UserComplexOfflineWorkouts, WorkoutBundle } from '@store/workout/workout.model';
import { selectDropDownList } from '@store/workout/workout.selector';
import * as fromOfflineWorkoutActions from '@store/offline-workout/offline-workout.actions';
import * as fromBundleActions from '@store/bundle/bundle.actions';
import * as fromExerciseActions from '@store/exercise/exercise.actions';
import * as fromExerciseSelector from '@store/exercise/exercise.selector';
import * as fromOfflineWorkoutSelector from '@store/offline-workout/offline-workout.selector';

import { debounceTime, distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { WorkoutView } from '@shared/models/workout.model';
import { PaginationModel } from '@shared/models/pagination.model';
import { selectOfflineWorkoutIsLoading, selectOfflineWorkoutList } from '@store/offline-workout/offline-workout.selector';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExerciseOSLimit } from '@store/exercise/exercise.model';
import { BudgeOS, SearchWorkout, WorkoutOSLimited } from '@store/offline-workout/offline-workout.model';
import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';
import { Actions, ofType } from '@ngrx/effects';

@UntilDestroy()
@Component({
  selector: 'totalfit-workout-search-dropdown',
  templateUrl: './workout-search-dropdown.component.html',
  styleUrls: ['./workout-search-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkoutSearchDropdownComponent implements OnInit {

  public dropDownList$: Observable<UserComplexOfflineWorkouts>;
  public isLoading$: Observable<boolean>;
  public isBundleLoading$: Observable<boolean>;
  public bundleList$: Observable<PaginationModel<WorkoutView>>;
  public selectedBundle$: Observable<Partial<WorkoutBundle>>;
  public CustomOfflineWorkoutBlocks = CustomOfflineWorkoutBlocks;
  public offlineWorkoutList$: Observable<PaginationModel<WorkoutView>>;
  public exerciseTabs$: Observable<ExerciseOSLimit[]>;
  public isLoadingExerciseTabs$: Observable<boolean>;
  public totalPaginationOfflineWorkoutList: number;

  public searchedBundles$: Observable<BudgeOS[]>;

  public selectedSearchBundle$: Subject<BudgeOS> = new Subject();

  public searchControl = new FormControl('');
  @Output() public selectWod: EventEmitter<WorkoutView> = new EventEmitter();
  @ViewChild(DropdownToggleDirective, { static: false }) private dropdownToggle: DropdownToggleDirective;

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
  ) { }

  public ngOnInit(): void {
    this.selectedBundle$ = this.store$.select(bundleSelector.selectSelectedBundleId);
    this.exerciseTabs$ = this.store$.select(fromExerciseSelector.selectExerciseTabs);
    this.isLoadingExerciseTabs$ = this.store$.select(fromExerciseSelector.isLoading);
    this.searchedBundles$ = this.store$.select(fromOfflineWorkoutSelector.selectSearchFolders);
    this.offlineWorkoutList$ = this.store$.select(selectOfflineWorkoutList);
    this.isLoading$ = this.store$.select(selectOfflineWorkoutIsLoading);
    this.isBundleLoading$ = this.store$.select(bundleSelector.isLoading);

    this.bundleList$ = combineLatest([
      this.store$.select(bundleSelector.selectBundleTemplateList),
      this.store$.select(bundleSelector.selectRecentBundle),
      this.selectedBundle$
    ]).pipe(map(([bundleList, recentList, selectedFolder]) => {
      if (selectedFolder?.id === null && selectedFolder?.name === 'Recent') {
        return {objects: recentList, totalElements: 0, pagesCount: 0} as unknown as PaginationModel<WorkoutView>;
      } else {
        return bundleList;
      }
    }));

    this.store$.dispatch(workoutActions.getDropDownList());
    this.dropDownList$ = this.store$.select(selectDropDownList)
      .pipe(
        withLatestFrom(this.offlineWorkoutList$),
        map(([ ddl, ow ]) => {
          if (!ow) {
            this.store$.dispatch(fromOfflineWorkoutActions.setOfflineWorkoutList({
              offlineWorkoutList: {
                objects: ddl.offlineWorkouts.content,
                pagesCount: ddl.offlineWorkouts.totalPages,
                totalElements: String(ddl.offlineWorkouts.totalElements)
              },
              pageNumber: ddl.offlineWorkouts.pageable.pageNumber,
              pageSize: ddl.offlineWorkouts.pageable.pageSize
            }));

            this.totalPaginationOfflineWorkoutList = ddl.offlineWorkouts.totalElements;
          }
          return ddl;
        })
      );

    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      debounceTime(300),
      filter((value) => value.length > 1),
      tap((text) => {
      this.store$.dispatch(fromExerciseActions.getExerciseTags({ text, limit: 70 }));
    })).subscribe();

    combineLatest([this.exerciseTabs$, this.searchControl.valueChanges, this.isLoadingExerciseTabs$]).pipe(
      untilDestroyed(this)
    ).subscribe(([exTabs, searchText, isTabsLoading]) => {
      if (searchText?.length > 1 && !isTabsLoading && exTabs) {
        const searchTextElements = searchText.split(/[.,/]+/).map(s => s.trim());
        const exNamings = new Map();

        searchTextElements.forEach((value) => {
          exNamings.set(value, null);

          for (const ex of exTabs) {
            if (ex.name.toLowerCase() === value.toLowerCase() || ex.synonyms?.find((e) => e.toLowerCase() === value.toLowerCase())) {
              exNamings.set(value, ex.id);
            }
          }
        });

        const searchReq: SearchWorkout = {
          exerciseIds: [],
          limit: 50,
          tags: []
        };

        for (const [key, value] of exNamings) {
          if (value) {
            searchReq.exerciseIds.push(value);
          } else {
            searchReq.tags.push(key);
          }
        }

        this.store$.dispatch(fromOfflineWorkoutActions.searchOfflineWorkoutList({ searchReq }));
      }
    });

    this.actions$.pipe(ofType(workoutActions.getWodBySearchSuccess)).pipe(
      untilDestroyed(this),
      tap(({ workoutView }) => {
        this.selectWodTemplate(workoutView);
    })).subscribe();
  }

  public infiniteScrollerTrigger(isLoading: boolean): void {
    if (!isLoading) {
      this.store$.dispatch(fromOfflineWorkoutActions.getOfflineWorkoutList({}));
    }
  }

  public bundleInfiniteScrollerTrigger(folder: Partial<WorkoutBundle>, isLoading: boolean): void {
    if (isLoading) {
      return;
    }

    if (folder.type === BundleTypes.TS_BUNDLE) {
      this.bundleScrollerTrigger(folder);
    }

    if (folder.type === BundleTypes.USER_BUNDLE) {
      this.userBundleScrollerTrigger(folder);
    }
  }

  public bundleScrollerTrigger(folder: Partial<WorkoutBundle>): void {
    this.store$.dispatch(fromBundleActions.getTfBundleWorkouts({ folder }));
  }

  public recentTrigger(): void {
    this.store$.dispatch(fromBundleActions.getRecentWorkouts());
  }

  public userBundleScrollerTrigger(folder: Partial<WorkoutBundle>): void {
    this.store$.dispatch(fromBundleActions.getUserBundleWorkouts({ folder }));
  }

  public returnToMainFolder(): void {
    this.store$.dispatch(fromBundleActions.resetSelectedBundle());
  }

  public returnToMainFolderFroSearch(): void {
    this.store$.dispatch(fromBundleActions.resetSelectedBundle());
    this.selectedSearchBundle$.next(null);
  }

  public setSearchBundle(folder: BudgeOS): void {
    this.selectedSearchBundle$.next(folder);
  }
  public selectWodTemplate(wod: WorkoutView): void {
    if (wod.creationType === 'TEMPLATE') {
      this.selectWod.emit({...wod, id: null});

      this.dropdownToggle.hideDropdown();
      return;
    }

    this.selectWod.emit(wod);
    this.dropdownToggle.hideDropdown();
  }
  public getSearchedWod(workoutOSLimited: WorkoutOSLimited): void {
    this.store$.dispatch(workoutActions.getWodBySearch({ workoutOSLimited }));
  }
}
