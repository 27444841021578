<button mat-button
        [totalfitDropdownToggle]="colorPickerTemplate"
        [isPanelHasFlexibleWidth]="true"
        [ngClass]="'color-palette_' + currentColorPalette"
        class="icon-trigger">
  <i class="icon-check2"></i>

  <span *ngIf="label" class="label">
    {{ label }}
  </span>
</button>

<ng-template #colorPickerTemplate>
  <div class="color-palette">
    <ng-container *ngFor="let color of colorPalette">
      <button mat-button
              [ngClass]="'color-palette_' + color"
              (click)="selectColorEvent(color)"
              class="icon-trigger">
        <i class="icon-check2"></i>
      </button>
    </ng-container>
  </div>
</ng-template>

