import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { UserPermissionsId, UserRole } from '@app/store/user/user.model';

export enum AmplitudeActions {
  LAUNCH_APP = 'Launch App',

  PEOPLE_ADD_NEW_CLICK = '[People] Click On add new people',
  PEOPLE_EXPORT = '[People] Export people table',
  PEOPLE_PRINT = '[People] Print people table',
  PEOPLE_LOAD_PEOPLE = '[People] Load People',
  PEOPLE_VISIT_PAGE = '[People] Visit People Page',
  PEOPLE_LEAVE_PAGE = '[People] Leave People Page',
  PEOPLE_CANT_CREATE_PEOPLE = '[People] Can\'t Create People',

  LIBRARY_VISIT_PAGE = '[Library] Visit Library Page',
  LIBRARY_LEAVE_PAGE = '[Library] Leave Library Page',
  LIBRARY_PREVIEW_FILE = '[Library] Library Preview File',
  LIBRARY_LOAD_FILE = '[Library] Library Load File',

  LOCATIONS_VISIT_PAGE = '[Locations] Visit Locations Page',
  LOCATIONS_LEAVE_PAGE = '[Locations] Leave Locations Page',
  LOCATIONS_CANT_CREATE_NEW = '[Locations] Locations Cant Create New',
  LOCATIONS_CANT_PUBLISH = '[Locations] Locations Cant Create New',

  LOCATIONS_EDIT_LOCATION = '[Locations] Edit Location',
  LOCATIONS_SAVE_LOCATION = '[Locations] Save Location',

  SINGLE_WORKOUT_VISIT_PAGE = '[Single_Workout] Visit Single Workout Page',
  SINGLE_WORKOUT_LEAVE_PAGE = '[Single_Workout] Leave Single Workout Page',
  SINGLE_WORKOUT_CANT_CREATE_WORKOUT = '[Single_Workout] Can\'t Create Workout',
  SINGLE_WORKOUT_REMOVE = '[Single_Workout] Remove Workout',
  SINGLE_WORKOUT_ADD_NEW_SINGLE_WORKOUT = '[Single_Workout] Add New Workout',
  SINGLE_WORKOUT_EDIT_SINGLE_WORKOUT = '[Single_Workout] Edit Workout',
  SINGLE_WORKOUT_CLICK_WHITE_BOARD = '[Single_Workout] Click White Board',

  PROGRAM_VISIT_PAGE = '[Program] Visit Program Page',
  PROGRAM_LEAVE_PAGE = '[Program] Leave Program Page',
  PROGRAM_CLICK_ADD_NEW = '[Program] Program Click Add New',
  PROGRAM_CANT_CREATE_NEW = '[Program] Program Cant Create New',
  PROGRAM_REMOVE = '[Program] Remove Program',
  PROGRAM_ADD_NEW_PROGRAM = '[Program] Add New Program',
  PROGRAM_EDIT_PROGRAM = '[Program] Edit Program',

  TRAINING_VISIT_PAGE = '[Training] Visit Training Page',
  TRAINING_LOAD_UPCOMING = '[Training] Load Upcoming',
  TRAINING_LOAD_PAST = '[Training] Load Past',
  TRAINING_LEAVE_PAGE = '[Training] Leave Training Page',

  TRAINING_LOCAL_SAVE = '[Training] Local Save',
  TRAINING_PUBLISH = '[Training] Publish',
  TRAINING_INVITE_ATHLETES = '[Training] Training Invite Athletes',
  TRAINING_SEND_LETTER = '[Training] Training Send Letter',

  USER_UPGRADE_PLAN_CLICK = '[User] Click On upgrade plan',
  USER_UPGRADE_PLAN_PURCHASE = '[User] Purchase upgrade plan',

}

export interface UserTrackInfo {
  user_roles: UserRole[];
  user_account_type: UserPermissionsId;
}

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  public instance;

  public setInstance(userId: string, userTrackInfo?: UserTrackInfo): void {
    this.amplitudeInstance.init('3dbc8571865efc8c6b1e233d04fff2c8', userId);
    this.triggerEvent(AmplitudeActions.LAUNCH_APP, userTrackInfo);
  }

  public triggerEvent(event: AmplitudeActions, value?: any) {
    this.amplitudeInstance.logEvent(event, value);
  }

  // public trackPeopleStatusChange(status: PeopleStatus): void {
  //   this.triggerEvent()
  // }

  public get amplitudeInstance(): any {
    return amplitude.getInstance();
  }

  public get amplitudeInstanceSessionId(): number {
    return this.amplitudeInstance._sessionId;
  }
}
