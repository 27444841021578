import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'totalfit-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StatisticComponent {
  @Input() public color: string;
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public count: string;
}
