import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';

import { DotsMenuButton, DotsMenuConfig } from '@shared/components/dots-menu/dots-menu.model';
import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';

@Component({
  selector: 'totalfit-dots-menu',
  templateUrl: './dots-menu.component.html',
  styleUrls: ['./dots-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotsMenuComponent {
  @Input() public config: DotsMenuConfig<unknown>;
  @Input() public entity: unknown;
  @ViewChild(DropdownToggleDirective, { static: false }) public dropdownToggle: DropdownToggleDirective;

  public triggerCallbackFn(button: DotsMenuButton<unknown>, event: Event) {
    event.stopPropagation();

    if (!this.isButtonDisabled(button)) {
      button.callbackFn(this.entity);
      this.dropdownToggle.hideDropdown();
    }
  }

  public get isMenuDisabled(): boolean {
    return this.isDisabled(this.config);
  }

  public isButtonDisabled(button: DotsMenuButton<unknown>): boolean {
    return this.isDisabled(button);
  }

  private isDisabled(item: DotsMenuConfig<unknown> | DotsMenuButton<unknown>): boolean {
    return item.isDisabled && item.isDisabled(this.entity);
  }
}
