import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const story = (state: AppState) => state.story;

export const selectStoryList = createSelector(
  story,
  (storyState) => storyState.storyList
);

export const selectSearchedStories = createSelector(
  story,
  (storyState) => storyState.searchedStories
);

export const selectStoryIsLoading = createSelector(
  story,
  (storyState) => storyState.isLoading
);
