import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';

export function isNull(value: any): value is null {
  return value === null;
}

export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

export function isNumberFinite(value: any): value is number {
  return isNumber(value) && isFinite(value);
}

// Not strict positive
export function isPositive(value: number): boolean {
  return value >= 0;
}

export function isInteger(value: number): boolean {
  // No rest, is an integer
  return value % 1 === 0;
}

export function toDecimal(value: number, decimal: number): number {
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function deleteObjectProp(obj, prop) {
  if (obj[prop] && ! obj[prop].length) {
    delete obj[prop];
  }
}

export function setFilterQuery(filterObject): string {
  // @ts-ignore
  const { tableSearch, value, ...filter } = filterObject;

  let filterObjectFlatFilterObject = {
    ...filter
  };

  if (value) {
    filterObjectFlatFilterObject = {
      ...filterObjectFlatFilterObject,
      ...filterObject.value
    };
  }

  if (tableSearch) {
    filterObjectFlatFilterObject.fieldName = [tableSearch.fieldName];
    filterObjectFlatFilterObject.searchKey = [tableSearch.searchKey];
  }

  let filterQuery = '';
  if (filterObjectFlatFilterObject && Object.keys(filterObjectFlatFilterObject).length) {
    Object.keys(filterObjectFlatFilterObject).forEach((key) => filterQuery = filterQuery + (filterObjectFlatFilterObject[key].length ? `&${key}=${filterObjectFlatFilterObject[key].join(',')}` : ''));
  }

  return filterQuery;
}

export function createLabelsFromEnum(valueEnum, labelEnum): Array<{ name: string, id: string, isDisabled?: boolean }> {
  return Object.keys(valueEnum).map((key) => {
    return { name: labelEnum[key], id: key };
  });
}

export function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

export function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress
    || event.type === HttpEventType.UploadProgress;
}
