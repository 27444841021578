import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LibraryItem, Manual } from '@app/library/_shared/library.model';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class LibraryService {
  constructor(private http: HttpClient) {}

  public getLibrary(): Observable<LibraryItem> {
    return this.http.get<LibraryItem>(`${environment.apiUrl}/${environment.apiV.apiV1}/library`);
  }

  public getLibraryFile(libraryItem: LibraryItem): Observable<Blob> {
    return this.http.get(
      `${environment.apiUrl}/${environment.apiV.apiV1}/library/file`,
      { params: { name: `${libraryItem.path}${libraryItem.name}` }, responseType: 'blob' }
      );
  }

  public getLibraryManuals(level: string): Observable<Manual[]> {
    return this.http.get<Manual[]>(`${environment.apiUrl}/${environment.apiV.apiV1}/library/manual`, { params: { level } });
  }

  public saveLibFiles(body, path) {
    const formData = new FormData();
    formData.append('file', body.file);

    return this.http.post<LibraryItem>(
      `${environment.apiUrl}/${environment.apiV.apiV1}/library/admin/library`,
      formData, { params: { path: `${path}/${body.fileName}` } });
  }

  public createLibraryFolder(path: string, folderName: string): Observable<number> {
    return this.http.post<number>(
      `${environment.apiUrl}/${environment.apiV.apiV1}/library/admin/library/folder`,
      {},
      { params: { path: `${path}/${folderName}` } }
    );
  }

  public deleteLibraryFolder(path: string): Observable<number> {
    return this.http.delete<number>(
      `${environment.apiUrl}/${environment.apiV.apiV1}/library/admin/library/folder`,
      { params: { path } }
    );
  }
}
