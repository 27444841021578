import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ColorPalette } from '@app/calendar/_shared/calendar.model';
import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';

@Component({
  selector: 'totalfit-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit {
  public colorPalette: string[];
  @Input() public currentColorPalette = 'grey';
  @Input() public label = 'Select color';
  @Output() public selectColor: EventEmitter<string> = new EventEmitter();
  @ViewChild(DropdownToggleDirective, { static: false }) private dropdownToggle: DropdownToggleDirective;

  public ngOnInit(): void {
    this.colorPalette = ColorPalette;
    this.selectColor.emit(this.currentColorPalette);
  }

  public selectColorEvent(color: string) {
    this.selectColor.emit(color);
    this.dropdownToggle.hideDropdown();
  }
}
