import { OnlineWorkout, Plan, WodType } from '@shared/models/workout.model';

export enum AdminProgramRepresentation {
  MOBILE = 'MOBILE', WEB = 'WEB'
}

export interface AdminWorkoutBundle {
  id?: string;
  name: string;
  status: BundleStatus;
  workouts: OnlineWorkout[];
}

export interface AdminOnlineProgram {
  id:	string;
  equipment: string[];
  goal:	string;
  image: string;
  levels:	string[];
  maxWeekNumber: number;
  name:	string;
  session: string;
  workouts:	WorkoutLimited[];
  representation: AdminProgramRepresentation;
}

export interface PartialOnlineWorkout {
  theme: string;
  id: string;
  difficultyLevel: string;
}

export interface WorkoutLimited {
  difficultyLevel: string;
  equipment: string[];
  estimatedTime: number;
  id:	string;
  image: string;
  plan: Plan;
  skillImage:	string;
  theme: string;
  wodType: WodType;
}

export enum BundleStatus {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM'
}
