import { createAction, props } from '@ngrx/store';
import { PaginationModel } from '@shared/models/pagination.model';
import { WorkoutView } from '@shared/models/workout.model';
import { BudgeOS, SearchWorkout } from '@store/offline-workout/offline-workout.model';

export const GET_OFFLINE_WORKOUT_LIST = '[OFFLINE WORKOUT] GET OFFLINE WORKOUT LIST';
export const GET_OFFLINE_WORKOUT_LIST_SUCCESS = '[OFFLINE WORKOUT] GET OFFLINE WORKOUT LIST SUCCESS';

export const SET_OFFLINE_WORKOUT_LIST = '[OFFLINE WORKOUT] SET OFFLINE WORKOUT LIST';

export const SEARCH_OFFLINE_WORKOUT_LIST = '[OFFLINE WORKOUT] SEARCH OFFLINE WORKOUT LIST';
export const SEARCH_OFFLINE_WORKOUT_LIST_SUCCESS = '[OFFLINE WORKOUT] SEARCH OFFLINE WORKOUT LIST SUCCESS';

export const RESET_OFFLINE_WORKOUT = '[OFFLINE WORKOUT] RESET OFFLINE WORKOUT';

export const getOfflineWorkoutList = createAction(
  GET_OFFLINE_WORKOUT_LIST,
  props<{ pageSize?: number, pageNumber?: number }>()
);

export const getOfflineWorkoutListSuccess = createAction(
  GET_OFFLINE_WORKOUT_LIST_SUCCESS,
  props<{ offlineWorkoutList: PaginationModel<WorkoutView> }>()
);

export const setOfflineWorkoutList = createAction(
  SET_OFFLINE_WORKOUT_LIST,
  props<{ offlineWorkoutList: PaginationModel<WorkoutView>, pageNumber: number, pageSize: number }>()
);

// Refactor
export const searchOfflineWorkoutList = createAction(
  SEARCH_OFFLINE_WORKOUT_LIST,
  props<{ searchReq?: SearchWorkout }>()
);

export const searchOfflineWorkoutListSuccess = createAction(
  SEARCH_OFFLINE_WORKOUT_LIST_SUCCESS,
  props<{ budgeOS: BudgeOS[] }>()
);

export const resetOfflineWorkout = createAction(
  RESET_OFFLINE_WORKOUT,
);
