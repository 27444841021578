import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { LibraryItem, libRegExp } from '@app/library/_shared/library.model';
import { DropdownToggleDirective } from '@shared/directives/dropdown-toggle.directive';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTime, distinctUntilChanged, filter, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { DownloadFileService } from '@shared/services/download-file.service';
import { AmplitudeActions, AmplitudeService } from '@shared/services/amplitude.service';
import { isMediaFile, isForSaveOnlyFile } from '@app/library/_shared/library.utils';
import { Store } from '@ngrx/store';
import * as fromLibraryAction from '@app/store/library/library.actions';
import {
  selectStream,
  selectStreamName,
  streamPlayStatus
} from '@app/store/library/library.selector';
import { CreateNewFolderComponent } from '@shared/dialogs/create-new-folder/create-new-folder.component';
import { DialogService } from '@shared/services/dialog.service';
import { AddFileListComponent } from '@shared/components/add-file-list/add-file-list.component';
// @ts-ignore
import * as fromLibraryActions from '@app/store/library/library.actions';
import { ConfirmDialogComponent } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Download } from '@shared/models/download.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'totalfit-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryViewComponent implements OnInit, OnDestroy {
  @ViewChild('breadcrumbRef', { static: true }) public breadcrumbRef: ElementRef;
  @ViewChild('libraryRef', { static: true }) public libraryRef: ElementRef;
  @ViewChild(DropdownToggleDirective, { static: false }) public dropdownToggle: DropdownToggleDirective;
  @ViewChild('contextNav', { static: false }) public rightClickWorkout: TemplateRef<unknown>;
  @ViewChild(MatMenuTrigger) public contextMenu: MatMenuTrigger;
  @ViewChildren('focusItem') private focusItems: QueryList<ElementRef>;
  @Input() public withAdminControls = false;
  public breadcrumbs: LibraryItem[] = [{ name: 'root', size: '' }];
  public filesViewType: 'table' | 'list' = 'table';
  public searchControl: UntypedFormControl = new UntypedFormControl('');
  public currentView: LibraryItem[];
  public showBreadcrumbCompressed: boolean;
  public root: LibraryItem[];
  public displayedColumns: string[] = ['name', 'size'];
  public resentFiles: LibraryItem[];
  public dataSource;
  public formControl = new UntypedFormControl();
  public library: LibraryItem;
  public contextMenuPosition = { x: '0px', y: '0px' };
  public searchEntries: Array<LibraryItem & { filePath: any }> = [];
  public selectedIndex = 0;
  public loadMap: Map<LibraryItem, { text: string; progress?: number }> = new Map();
  private focusItem: ElementRef;

  public audioFile: HTMLAudioElement;
  public audioDuration: string;
  public currentTime: string;
  public playStatus: string;
  public playName: string;
  private currentPathWithIndexes;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private downloadFileService: DownloadFileService,
    private amplitudeService: AmplitudeService,
    private readonly store$: Store,
    private readonly dialogService: DialogService
  ) {
    if (window.localStorage.getItem('resentFiles')) {
      this.resentFiles = JSON.parse(window.localStorage.getItem('resentFiles'));
    }
  }

  public ngOnInit(): void {
    this.currentView = this.route.snapshot.data.library.entries;
    this.dataSource = this.route.snapshot.data.library.entries;
    this.root = this.route.snapshot.data.library.entries;

    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => this.searchEntries = []),
        filter((enteredValue: string) => enteredValue.length > 2),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchValue) => {
        this.searchInRoot(this.root, searchValue, []);
      });

    this.downloadFileService.entityForDelete
      .pipe(untilDestroyed(this))
      .subscribe((entity) => {
        if (this.loadMap.get(entity)) {
          this.loadMap.delete(entity);

          this.changeDetectorRef.markForCheck();
        }
      });

    this.store$.select(selectStream)
      .pipe(
        withLatestFrom(this.store$.select(selectStreamName)),
        untilDestroyed(this)
      )
      .subscribe(([stream, name]: any) => {
        if (stream) {
          if (this.audioFile) {
            this.resetAudioFile();
          }

          this.audioFile = new Audio();
          this.audioFile.src = stream;
          this.store$.dispatch(fromLibraryAction.startPlay());

          this.audioFile.addEventListener('timeupdate', (event: any) => {
            if (!this.audioDuration) {
              this.audioDuration = this.transformTime(event.path[0].duration);
            }

            if (name) {
              this.playName = name;
            }

            this.currentTime = this.transformTime(event.path[0].currentTime);
            this.changeDetectorRef.markForCheck();
          });
        }

        if (!stream) {
          this.resetAudioFile();
        }
      });

    this.store$.select(streamPlayStatus)
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (status === 'play') {
          this.audioFile.play();
          this.playStatus = 'play';
        }

        if (status === 'pause') {
          this.audioFile.pause();
          this.playStatus = 'pause';
        }
      });

    this.amplitudeService.triggerEvent(AmplitudeActions.LIBRARY_VISIT_PAGE);
  }

  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent) {
    if (this.searchControl.value.length) {
      const activeEle = document.activeElement;
      if (event.code === 'ArrowDown' && this.selectedIndex <= this.searchEntries.length) {
        event.preventDefault();
        if (!activeEle.classList.contains('focus-item')) {
          this.focusItems.first.nativeElement.focus();
          this.focusItem = this.focusItems.first.nativeElement;
          this.selectedIndex = 0;

          return;
        }

        this.selectedIndex++;
      }

      if (event.code === 'ArrowUp' && this.selectedIndex > 0) {
        event.preventDefault();
        this.selectedIndex--;
      }

      if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
        this.focusItem = this.focusItems.find((item, index) =>  {
          if (index === this.selectedIndex) {
            item.nativeElement.focus();
          }

          return index === this.selectedIndex;
        });
      }

      if (event.code === 'Enter' && this.focusItem) {
        event.preventDefault();

        this.focusItem = this.focusItems.find((item, index) =>  {
          if (index === this.selectedIndex) {
            item.nativeElement.focus();
          }

          return index === this.selectedIndex;
        });

        this.focusItem.nativeElement.click();
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  public setNewBreadcrumbs(reset = false): void {
    if (reset) {
      this.breadcrumbs = null;
    }
  }

  public setFolder(libraryItem: LibraryItem, setRoot?: boolean): void {
    if (setRoot) {
      this.currentView = this.root;
      this.dataSource = this.root;
      this.breadcrumbs.splice(1);
      this.showBreadcrumbCompressed = false;
      return;
    }

    this.currentView = libraryItem.entries;
    this.dataSource = libraryItem.entries;

    this.breadcrumbs.splice(this.breadcrumbs.indexOf(libraryItem) + 1);
    const isEnoughSpace = ((this.libraryRef.nativeElement.clientWidth - 270)
      - this.breadcrumbs.reduce((width , item) => {
        width += item.name.length * 10 + 30;
        return width;
      }, 0)) > 0;

    this.showBreadcrumbCompressed = !isEnoughSpace;
    this.changeDetectorRef.markForCheck();
  }

  public selectFolder(libraryItem: LibraryItem): void {
    if ((libraryItem.entries && libraryItem.entries.length) || libraryItem.size === '0') {
      this.currentView = libraryItem.entries;
      this.dataSource = libraryItem.entries;
      this.breadcrumbs.push(libraryItem);
      const isEnoughSpace = ((this.libraryRef.nativeElement.clientWidth - 270)
        - (this.breadcrumbRef.nativeElement.clientWidth + (libraryItem.name.length * 10 + 40))) > 0;

      this.showBreadcrumbCompressed = !isEnoughSpace;
    }

    this.changeDetectorRef.markForCheck();
  }

  public getBredMaxWidth(): string {
    let itemWidth = 0;
    if (this.showBreadcrumbCompressed) {
      itemWidth = (this.libraryRef.nativeElement.clientWidth / 2) + 60;
      return `${itemWidth > 240 ? 240 : itemWidth}px`;
    }
  }

  public getFilePlaceholder(libraryItem: LibraryItem): string {
    if ((libraryItem.entries && libraryItem.entries.length) || libraryItem.size === '0') {
      return 'assets/img/file-ext/folder.svg';
    }

    return `assets/img/file-ext/${libRegExp.exec(libraryItem.name)[1]
      ? isMediaFile(libraryItem.name) ? 'mp3'
        : libRegExp.exec(libraryItem.name.replace(/[{()}]/g, ''))[1].toLowerCase() : 'folder'}.svg`;
  }

  public getSaveLink(libraryItem: LibraryItem, type: 'download' | 'view'): string {
    if ((libraryItem.entries && libraryItem.entries.length) || libraryItem.size === '0') {
      return null;
    }

    return `${environment.apiUrl}/${environment.apiV.apiV1}/library/file?name=${libraryItem.path === '/' ? '' : libraryItem.path}${libraryItem.name}&response=${type === 'download' ? 'DOWNLOAD' : 'VIEW'}`;
  }

  public onContextMenu(event: MouseEvent, item: LibraryItem) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item };
    this.contextMenu.menu.backdropClass = 'library-context-nav';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  public searchItemClick(searchItem: LibraryItem & { filePath: any }): void {
    if ((searchItem.entries && searchItem.entries.length) || searchItem.size === '0') {
      this.breadcrumbs = [this.breadcrumbs[0], ...searchItem.filePath];
      this.currentView = searchItem.entries;
      this.dataSource = searchItem.entries;

      const isEnoughSpace = ((this.libraryRef.nativeElement.clientWidth - 270)
        - this.breadcrumbs.reduce((width , item) => {
          width += item.name.length * 10 + 30;
          return width;
        }, 0)) > 0;

      this.dropdownToggle.hideDropdown();

      this.showBreadcrumbCompressed = !isEnoughSpace;
    }
  }

  public onLoadProgressCheck(value: Download) {
    const loadItem = this.loadMap.get(value.entity);

    if (loadItem) {
      this.loadMap.set(value.entity, { progress: value.progress, text: loadItem.text });
      this.changeDetectorRef.markForCheck();
    }
  }

  public startDownloadFile({entity, loadType}): void {
    if ((entity.entries && entity.entries.length) || entity.size === '0') {
      return;
    }

    this.loadMap.set(entity, loadType === 'download'
      ? { text: 'We are preparing file for download. Please, wait.' }
      : { text: 'We are preparing file for preview. I would be open in separate tab. Please, wait.'});

    if (loadType === 'download') {
      this.amplitudeService.triggerEvent(AmplitudeActions.LIBRARY_LOAD_FILE, { name: entity.name });
    } else {
      this.amplitudeService.triggerEvent(AmplitudeActions.LIBRARY_PREVIEW_FILE, { name: entity.name });
    }
  }

  public startAddFile(file): void {
    this.loadMap.set(file, { text: 'Saving your file...' });
  }

  public pauseAudio(): void {
    this.store$.dispatch(fromLibraryAction.stopPlay());
  }

  public playAudio(): void {
    this.store$.dispatch(fromLibraryAction.startPlay());
  }

  public closeStream(): void {
    this.store$.dispatch(fromLibraryAction.streamClose());
  }

  public onSliderChangeEnd(event): void {
    this.audioFile.currentTime = event.value;
    this.changeDetectorRef.markForCheck();
  }

  public createNewFolder(): void {
    this.dialogService.openDialog(CreateNewFolderComponent)
      .subscribe((bundle: { name: string }) => {
        if (bundle) {
          const currentFolder = this.breadcrumbs[this.breadcrumbs.length - 1];
          const path = `${currentFolder.path ? (currentFolder.path === '/' ? '' : currentFolder.path) + currentFolder.name : ''}`;

          this.store$.dispatch(fromLibraryActions.addNewFolderToLibrary({ path, fileName: bundle.name }));
          const libFile: LibraryItem = {
            name: bundle.name,
            entries: [],
            size: '0',
            path: `${path}/`,
          };

          this.currentView = [ ...this.currentView, libFile];

          this.dataSource = this.currentView;

          this.setNewItemInFolder(this.root, libFile, `${path}/`.split('/'));
        }
      });
  }

  public addFileList(): void {
    const currentFolder = this.breadcrumbs[this.breadcrumbs.length - 1];
    const path = `${currentFolder.path ? (currentFolder.path === '/' ? '' : currentFolder.path) + currentFolder.name : ''}`;

    this.dialogService.openDialog(AddFileListComponent, path)
      .subscribe((file: any) => {
        if (file) {
          this.startAddFile(file);
          this.currentView = [ ...this.currentView, file];

          this.dataSource = this.currentView;

          this.setNewItemInFolder(this.root, file, file.path.split('/'));

          this.changeDetectorRef.markForCheck();
        }
      });
  }

  public ngOnDestroy() {
    this.amplitudeService.triggerEvent(AmplitudeActions.LIBRARY_LEAVE_PAGE);
    this.store$.dispatch(fromLibraryAction.streamClose());
  }

  public get compressedBreadcrumb(): LibraryItem[] {
    return [...this.breadcrumbs].splice(2, this.breadcrumbs.length - 3);
  }

  public getIsMedia(libraryItem: LibraryItem): boolean {
    return isMediaFile(libraryItem.name);
  }

  public isForSaveOnlyFile(libraryItem: LibraryItem): boolean {
    return isForSaveOnlyFile(libraryItem);
  }

  public delete(libItem: LibraryItem) {
    this.dialogService.openDialog(ConfirmDialogComponent,
      {
        title: 'Are you sure that you want to remove this item ?',
        cancelButtonText: 'Cancel',
        applyButtonText: 'Delete',
        applyButtonColorPallet: 'warn'
      })
      .subscribe((responce) => {
        if (responce) {
          const currentFolder = this.breadcrumbs[this.breadcrumbs.length - 1];
          const path = `${currentFolder.path ? (currentFolder.path === '/' ? '' : currentFolder.path) + currentFolder.name : ''}`;

          this.currentView = [ ...this.currentView.filter((i) => i.name !== libItem.name && i.size !== libItem.size)];

          this.dataSource = this.currentView;

          this.store$.dispatch(fromLibraryAction.deleteFileFromLibrary({ path: `${libItem.path}${libItem.name}` }));

          this.setNewItemInFolder(this.root, libItem, `${path}/`.split('/'), true);
        }
      });
  }

  private resetAudioFile(): void {
    if (this.audioFile) {
      this.audioFile.pause();
      this.audioFile.remove();
    }

    this.audioFile = null;
    this.audioDuration = null;
    this.currentTime = null;
    this.playName = null;

    this.changeDetectorRef.markForCheck();
  }
// @ts-ignore
  private setNewItemInFolder(entries: LibraryItem[], libItem: LibraryItem, path: string[], itemForDelete = false): void {
    window.setTimeout(() => {
      this.setPathWithIndex(entries, path);
      this.updateObject(entries, this.currentPathWithIndexes, libItem, itemForDelete);
      this.changeDetectorRef.markForCheck();
    });
  }

  private updateObject(obj, path, value, itemForDelete) {
    const initialObj = JSON.parse(JSON.stringify(obj));
    let parent = initialObj;
    const newBread = [];

    for (let i = 0; i < path.length - 1; i += 1) {
      parent = parent[path[i]];
      if (!Array.isArray(parent)) {
        newBread.push(parent);
      }
    }

    this.breadcrumbs = [...this.breadcrumbs.splice(0, 1), ...newBread];

    if (value.size === '0') {
      const isEnoughSpace = ((this.libraryRef.nativeElement.clientWidth - 270)
        - (this.breadcrumbRef.nativeElement.clientWidth + (value.name.length * 10 + 40))) > 0;

      this.showBreadcrumbCompressed = !isEnoughSpace;
    }

    parent[path[path.length - 1]] = itemForDelete
      ? [...parent[path[path.length - 1]].filter((i) => i.name !== value.name && i.size !== value.size)]
      : [...parent[path[path.length - 1]], value];

    this.root = [...initialObj];
  }

  private setPathWithIndex(entries: LibraryItem[], path, parsedPath = []): Array<string | number> {
    if (path.length === 1) {

      this.currentPathWithIndexes = parsedPath;
      return parsedPath;
    }
    const searchedFolder = entries.find((item) => item.name === path[0] && (item.entries && item.entries.length) || item.size === '0');

    if (searchedFolder) {
      parsedPath.push(entries.indexOf(searchedFolder));
      parsedPath.push('entries');

      this.setPathWithIndex(searchedFolder.entries, path.slice(1), parsedPath);
    }
  }

  private searchInRoot(entries: LibraryItem[], searchValue: string, path: any[]): void {
    entries.forEach((libraryItem) => {
      let parsedPath = path;
      if (libraryItem.name && libraryItem.name.toLowerCase().includes(searchValue.toLowerCase())) {
        parsedPath = [...path, libraryItem];
        this.searchEntries.push({...libraryItem, filePath: parsedPath});
      }

      if ((libraryItem.entries && libraryItem.entries.length) || libraryItem.size === '0') {
        parsedPath = parsedPath[parsedPath.length - 1] === libraryItem ? parsedPath : [...parsedPath, libraryItem];
        this.searchInRoot(libraryItem.entries, searchValue, parsedPath);
      }
    });

    this.changeDetectorRef.markForCheck();
  }

  private transformTime(time): string {
    const minutes = '0' + Math.floor( time / 60);
    // @ts-ignore
    const seconds = '0' +  Math.floor(time - minutes * 60);
    return  minutes.substr(-2) + ':' + seconds.substr(-2);
  }
}
