import { AppState } from '@app/app-state.module';
import { createSelector } from '@ngrx/store';

export const bundle = (state: AppState) => state.bundle;

export const selectBundleTemplateList = createSelector(
  bundle,
  (state) => state.bundleTemplateList
);

export const isLoading = createSelector(
  bundle,
  (state) => state.isLoading
);

export const selectSelectedBundleId = createSelector(
  bundle,
  (state) => state.selectedBundle
);

export const selectRecentBundle = createSelector(
  bundle,
  (state) => state.recentBundle
);
