import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export enum ResponsiveBreakpoints {
  xs = 'xs',
  sm = 'sm',
  m = 'm',
  l = 'l',
  xl = 'xl'
}

@Injectable({ providedIn: 'root' })
export class ResizeService {
  public sizeSubject = new BehaviorSubject<ResponsiveBreakpoints>(null);
  public windowSize = document.body.clientWidth;

  public ResponsiveBreakpointsWidth = {
    sm: 576,
    m: 768,
    l: 992,
    xl: 1200
  };

  constructor() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
      ).subscribe(() => this.getWidthAndReturnRightSize(document.body.clientWidth));

    window.setTimeout(() => this.getWidthAndReturnRightSize(document.body.clientWidth));
  }

  private getWidthAndReturnRightSize(windowSize: number): void {
    this.windowSize = windowSize;
    switch (true) {
      case windowSize < this.ResponsiveBreakpointsWidth.sm:
        this.sizeSubject.next(ResponsiveBreakpoints.xs);
        break;

      case windowSize > this.ResponsiveBreakpointsWidth.sm && windowSize < this.ResponsiveBreakpointsWidth.m:
        this.sizeSubject.next(ResponsiveBreakpoints.sm);
        break;

      case windowSize > this.ResponsiveBreakpointsWidth.m && windowSize < this.ResponsiveBreakpointsWidth.l:
        this.sizeSubject.next(ResponsiveBreakpoints.m);
        break;

      case windowSize > this.ResponsiveBreakpointsWidth.l && windowSize < this.ResponsiveBreakpointsWidth.xl:
        this.sizeSubject.next(ResponsiveBreakpoints.l);
        break;

      case windowSize > this.ResponsiveBreakpointsWidth.xl:
        this.sizeSubject.next(ResponsiveBreakpoints.xl);
        break;
    }
  }
}
