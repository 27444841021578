import { CustomOfflineWorkoutExtensions } from '@store/workout/workout.model';

export interface WorkoutView {
  complexExts: WorkoutComplex[];
  cooldown: Exercise[];
  dateTime: Date;
  equipment: string[];
  hlh: any;
  id?: string;
  skill: Exercise;
  story?: Story;
  theme: string;
  warmup: Exercise[];
  wodTime: number;
  healthyLifestyleHabit?: any;
  favorite: boolean;
  author?: string;
  authorPhoto?: string;
  premium?: boolean;
  coachNotes?: string;
  customOfflineWorkoutExtensions: CustomOfflineWorkoutExtensions[];
  status: WorkoutStatus;
  creationType?: string;

  bundleId: string;
}

export interface AdminWorkoutView extends WorkoutView {
  priorityStage: PriorityStage;
  bundleId: string;
}

export enum PriorityStage {
  FULL = 'FULL',
  WOD = 'WOD',
  WARMUP = 'WARMUP',
  SKILL = 'SKILL',
  COOLDOWN = 'COOLDOWN',
  STRETCHING_SESSION = 'STRETCHING_SESSION'
}

export interface CustomWorkoutView {
  id: string;
  theme: string;
  trainingText: string;
  trainerId: string;
  cooldown: string;
  warmup: string;
  hlh: string;
  coachNotes: string;
  story: string;
  skill: string;
}
// TODO Refactor hlh

export interface Story {
  name: string;
  id?: string;
  shared?: boolean;
  story?: string;
  truthToRemember?: string;
  estimatedReadingTime?: number;
  image?: string;
}

export interface WorkoutComplex {
  complexOrder: number;
  typeOfWork: TypeOfWork;
  groupType: GroupType;
  exercises: Exercise[];
  metrics: Rounds;
  wodType: any;
  quantity: number;
  partnerRestExercise: Exercise;
  restExercise: Exercise;
  roundsCount: number;
} // timeCap: string;

export interface Exercise {
  id?: number;
  exerciseId?: number;
  type: string;
  name?: string;
  quantity: number;
  metrics: string;
  tag?: string;
  extraReps?: string;
  startReps?: string;
  preferredMetric?: string;
}

export interface OnlineExercise {
  id?: string;
  metrics: OnlineMetric;
  name?: string;
  previewImage: string;
  quantity: number;
  tag: OnlineExerciseTag;
  type: ExerciseType;
  video1080:	string;
  video480:	string;
  video720:	string;
  videoVertical:	string;
  onlineExerciseId: string;
  exerciseId?: string;
}

export interface Habit {
  id?: string;
  name: string;
}

export interface Program {
  description: string;
  id: string;
  name: string;
}

export interface Autofill {
  exercises: Exercise[];
  id: string;
  name: string;
  type: string;
}

export interface OnlineWorkout {
  id?: string;
  badge: Badge;
  difficultyLevel: string;
  equipment: [string];
  estimatedTime: number;
  image: string;
  orderNumber: number;
  plan: Plan;
  priorityStage: string;
  skillImage:	string;
  theme: string;
  status: WorkoutStatus;
  stages: OnlineStageOption[];
  wodType?: string;
}

export interface 	OnlineStageOption {
  id?: string;
  exercises: OnlineExercise[];
  stageName: StageNames;
  stageOption: StageOption;
  stageType: WodType;
}

export interface StageOption {
  metricQuantity: number;
  metricType: OnlineMetric;
  rests: OnlineRest[];
}

export interface OnlineRest {
  order: number;
  quantity: number;
}

export enum StageNames {
  WARMUP = 'WARMUP',
  WOD = 'WOD',
  COOLDOWN = 'COOLDOWN',
  SKILL = 'SKILL',
  PARTNERS_EXERCISE = 'PARTNERS_EXERCISE',
  REST = 'REST'
}

export interface Questions {
  id: number;
  order: number;
  question: string;
  questionType: QuestionType;
  reward: number;
}

export enum WorkoutStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum WorkoutType {
  CUSTOM = 'CUSTOM',
  BUILDER = 'BUILDER'
}

export enum QuestionType {
  TEXT = 'TEXT',
  RATE = 'RATE'
}

export enum Plan {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM'
}

export enum Badge {
  NEW = 'NEW',
  EMPTY = 'EMPTY'
}

export enum PlanLabel {
  FREE = 'Free',
  PREMIUM = 'Premium'
}

export enum BadgeLable {
  NEW = 'New',
  EMPTY = 'Empty'
}

export type WorkoutEditModel = Omit<WorkoutView, 'id'>;

export enum WorkoutTypes {
  FOR_TIME = 'For time',
  AMRAP = 'AMRAP',
  EMOM = 'EMOM',
  TABATA = 'Tabata',
  MAX_EFFORT = 'Max effort',
  DEATH_BY_REPS = 'Death by reps'
}

export enum WodType {
  FOR_TIME = 'FOR_TIME',
  AMRAP = 'AMRAP',
  EMOM = 'EMOM',
  TABATA = 'TABATA',
  MAX_EFFORT = 'MAX_EFFORT',
  DEATH_BY_REPS = 'DEATH_BY_REPS'
}

export enum StageTypes {
  FOR_TIME = 'FOR_TIME',
  AMRAP = 'AMRAP',
  WARMUP = 'WARMUP',
  STRETCHING_SESSION = 'STRETCHING_SESSION'
}

export enum StageTypeLabels {
  FOR_TIME = 'For time',
  AMRAP = 'AMRAP',
  WARMUP = 'Warmup',
  STRETCHING_SESSION = 'Stretching session'
}

export enum GroupType {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group'
}

export enum TypeOfWork {
  YOU_GO_I_GO = 'You go I go',
  SHARE_AS_YOU_WANT = 'Share as you want'
}

export enum Metric {
  SEC = 'Sec',
  MIN = 'Min',
  REPS = 'Reps'
}

export enum OnlineMetric {
  SEC = 'SEC',
  MIN = 'MIN',
  REPS = 'REPS',
  METER = 'METER',
  CAL = 'CAL'
}

export enum OnlineMetricLabels {
  SEC = 'Sec',
  MIN = 'Min',
  REPS = 'Reps',
  METER = 'Meter',
  CAL = 'Cal'
}

export enum RepresentationMetricLabels {
  SEC = 'sec',
  MIN = 'min',
  REPS = 'X',
  METER = 'm',
  CAL = 'cal'
}

export enum OnlineExerciseTag {
  GYMNASTIC = 'GYMNASTIC',
  WEIGHTLIFTING = 'WEIGHTLIFTING',
  MONOSTRUCTURAL = 'MONOSTRUCTURAL',
  EMPTY = 'EMPTY',
  STRETCHING = 'STRETCHING'
}

export enum Rounds {
  ROUNDS = 'Rounds',
  MIN = 'Min'
}

export enum AdminOnlineLabel {
  ROUNDS = 'Rounds',
  MIN = 'Min'
}

export enum ExerciseType {
  WOD = 'WOD',
  WARMUP = 'WARMUP',
  COOLDOWN = 'COOLDOWN',
  SKILL = 'SKILL',
  PARTNERS_EXERCISE = 'PARTNERS_EXERCISE',
  REST = 'REST'
}

export enum ExerciseTypeLabels {
  WOD = 'WOD',
  WARMUP = 'WARMUP',
  COOLDOWN = 'COOLDOWN',
  SKILL = 'SKILL',
  PARTNERS_EXERCISE = 'PARTNERS EXERCISE',
  REST = 'REST'
}

export enum FitnessLevel {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced'
}

export enum TagNames {
  GYMNASTIC= 'Gymnastics',
  WEIGHTLIFTING = 'Weightlifting',
  MONOSTRUCTURAL = 'Monostructural ',
  EMPTY = 'Empty',
  STRETCHING = 'Static Stretching',
  DYNAMIC_STRETCHING = 'Dynamic Stretching'
}

export enum StageMetricType {
  ROUNDS = 'ROUNDS',
  MIN = 'MIN'
}
