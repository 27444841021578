import { LoginCredentials, User } from '@app/store/user/user.model';
import { createAction, props } from '@ngrx/store';
import { PaginationModel } from '@shared/models/pagination.model';
import { People } from '@app/people/_shared/people.model';
import { ProfileProgress, WebProfile } from '@shared/models/web-profile.model';

export const LOGIN = '[USER] LOGIN';
export const GET_USER_BY_TOKEN = '[USER] GET_USER_BY_TOKEN';
export const SET_USER = '[USER] SET_USER';

export const GET_WEB_PROFILE = '[USER] GET_WEB_PROFILE';
export const GET_WEB_PROFILE_SUCCESS = '[USER] GET_WEB_PROFILE_SUCCESS';
export const EDIT_WEB_PROFILE = '[USER] EDIT_WEB_PROFILE';

export const GET_WEB_PROFILE_PROGRESS = '[USER] GET_WEB_PROFILE_PROGRESS';
export const GET_WEB_PROFILE_PROGRESS_SUCCESS = '[USER] GET_WEB_PROFILE_PROGRESS_SUCCESS';

export const GET_ADMIN_USER = '[USER] GET_ADMIN_USER';
export const GET_ADMIN_USER_SUCCESS = '[USER] GET_ADMIN_USER_SUCCESS';

export const GET_PEOPLE = '[USER] GET_PEOPLE';
export const GET_PEOPLE_SUCCESS = '[USER] GET_PEOPLE_SUCCESS';

export const ADD_ADMIN_USER = '[USER] ADD_ADMIN_USER';
export const SUBSCRIPTION = '[USER] SUBSCRIPTION';

export const SET_UPDATE_STATUS = '[USER] SET_UPDATED_STATUS';

export const login = createAction(
  LOGIN,
  props<{value: LoginCredentials, keepIn: boolean}>()
);

export const getUserByToken = createAction(
  GET_USER_BY_TOKEN,
  props<{user: User}>()
);

export const setUser = createAction(
  SET_USER,
  props<{user: User}>()
);

export const getAdminUser = createAction(
  GET_ADMIN_USER,
  props<{ pageSize: number, pageNumber: number; tabFilter: string }>()
);

export const getAdminUserSuccess = createAction(
  GET_ADMIN_USER_SUCCESS,
  props<{adminUsers: PaginationModel<User>}>()
);

export const getPeople = createAction(
  GET_PEOPLE,
  props<{ pageSize: number, pageNumber: number; tabFilter: string }>()
);

export const getPeopleSuccess = createAction(
  GET_PEOPLE_SUCCESS,
  props<{people: PaginationModel<People>}>()
);

export const addAdminUser = createAction(
  ADD_ADMIN_USER,
  props<{ value: { email: string; firstName: string; lastName: string } }>()
);

export const subscription = createAction(
  SUBSCRIPTION,
  props<{ paymentMethod: string; user: User; }>()
);

export const setUpdateStatus = createAction(
  SET_UPDATE_STATUS,
  props<{ updateType: 'UPDATING' | 'DONE' | 'FAILED' }>()
);

export const getWebProfile = createAction(
  GET_WEB_PROFILE
);

export const getWebProfileSuccess = createAction(
  GET_WEB_PROFILE_SUCCESS,
  props<{webProfile: WebProfile}>()
);

export const editWebProfile = createAction(
  EDIT_WEB_PROFILE,
  props<{webProfile: WebProfile}>()
);

export const getWebProfileProgress = createAction(
  GET_WEB_PROFILE_PROGRESS
);

export const getWebProfileProgressSuccess = createAction(
  GET_WEB_PROFILE_PROGRESS_SUCCESS,
  props<{progress: ProfileProgress}>()
);
