<totalfit-navigation-page-container>
  <div class="totalfit-form">
    <form [formGroup]="trainingForm"
          class="mat-typography">
      <h2 class="mat-title">General Information</h2>

      <div [totalfitFormGroupValidator]="trainingForm.get('direction')" class="form-group form-group_default-width-1" [ngClass]="{ isAdmin: isAdmin }">
        <label class="form-group__label">Totalfit Direction</label>
        <totalfit-select formControlName="direction"
                         outputOptionField="id"
                         [options]="sharedFormService.totalfitDirection">
        </totalfit-select>
      </div>

      <div [totalfitFormGroupValidator]="trainingForm.get('level')" class="form-group form-group_default-width-1" [ngClass]="{ isAdmin: isAdmin }">
        <label class="form-group__label">Type of training</label>
        <totalfit-select formControlName="level"
                         outputOptionField="id"
                         [options]="[{ name: 'Level 1', id: 'level1' }, { name: 'Level 2', id: 'level2' }, { name: 'TOI', id: 'TOI' }]">
        </totalfit-select>
      </div>

      <div [totalfitFormGroupValidator]="trainingForm.get('leadInstructor.userId')" class="form-group form-group_default-width-1" [ngClass]="{ isAdmin: isAdmin }">
        <label class="form-group__label">Lead Instructor</label>
        <totalfit-select selectPlaceholder="Select Instructor"
                         outputOptionField="id"
                         nameKey="firstNameAndLastName"
                         [formControl]="trainingForm.get('leadInstructor.userId')"
                         (selectEvent)="setLeadValue($event)"
                         [options]="instructors">
        </totalfit-select>
      </div>

      <ng-container>
        <div *ngFor="let item of sharedFormService.getFormArray('instructors', trainingForm).controls; let i = index"
             [totalfitFormGroupValidator]="item.get('userId')"
             [ngClass]="{ isAdmin: isAdmin }"
             class="form-group form-group_default-width-1 close-container">
          <label *ngIf="i === 0"
                 class="form-group__label">
            {{ sharedFormService.getFormArray('instructors', trainingForm).controls.length > 1 ? 'Instructors': 'Instructor' }}
          </label>
          <totalfit-select selectPlaceholder="Select Instructor"
                           outputOptionField="id"
                           [formControl]="item.get('userId')"
                           nameKey="firstNameAndLastName"
                           (selectEvent)="setInstructor(item, $event)"
                           [options]="instructors">
          </totalfit-select>

          <button *ngIf="sharedFormService.getFormArray('instructors', trainingForm).controls.length > 1 && !isAdmin"
                  mat-flat-button
                  (click)="sharedFormService.removeFormControl('instructors', i,  trainingForm)"
                  class="icon-button">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </ng-container>

      <div class="form-group form-group_default-width-1">
        <button mat-button
                color="primary"
                class="add-entity-btn"
                *ngIf="!isAdmin"
                (click)="sharedFormService.addNewFormGroupToFormArray('instructors', trainingForm, newInstructorForm)">
          + Add instructor
        </button>
      </div>

      <div class="form-group form-group_row">
        <div [totalfitFormGroupValidator]="trainingForm.get('startDate')"
             [ngClass]="{ isAdmin: isAdmin }"
             class="form-group form-group_extra-small-input-width1" [ngStyle]="{ position: 'relative' }">
          <label class="form-group__label">Start date</label>
          <input [matDatepicker]="pickerStart"
                 formControlName="startDate"
                 [min]="getCurrentTimeStamp()"
                 class="form-group__control">
          <mat-datepicker-toggle matSuffix
                                 [for]="pickerStart"
                                 class="form-group__postfix">
          </mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </div>

        <div [totalfitFormGroupValidator]="trainingForm.get('finishDate')"
             [ngClass]="{ isAdmin: isAdmin }"
             class="form-group form-group_extra-small-input-width1" [ngStyle]="{ position: 'relative' }">
          <label class="form-group__label">Finish date</label>
          <input [matDatepicker]="pickerEnd"
                 [min]="trainingForm.get('startDate').value"
                 formControlName="finishDate"
                 class="form-group__control">
          <mat-datepicker-toggle matSuffix
                                 [for]="pickerEnd"
                                 class="form-group__postfix">
          </mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </div>
      </div>

      <ng-container formGroupName="address">
        <ng-container formGroupName="location">
          <div [totalfitFormGroupValidator]="trainingForm.get('address.location.country')"
               [ngClass]="{ isAdmin: isAdmin }"
               class="form-group form-group_default-width-1">
            <label class="form-group__label">Country</label>
            <totalfit-search-input formControlName="country"
                                   [autocompleteItemsFn]="getFilteredValue.bind(this)"
                                   [autocompleteItemTemplate]="countryAutoCompleteTemp"
                                   [isAutoSelected]="false"
                                   (autocompleteOptionSelected)="getTrainingCost($event)"
                                   [initialList]="sharedFormService.countries"
                                   autocompleteValueProperty="name"
                                   placeholder=" ">
            </totalfit-search-input>

            <ng-template let-country="item" #countryAutoCompleteTemp>
              <span>{{ country.name }}</span>
            </ng-template>
          </div>

          <div [totalfitFormGroupValidator]="trainingForm.get('address.location.city')"
               [ngClass]="{ isAdmin: isAdmin }"
               class="form-group form-group_default-width-1">
            <label class="form-group__label">City</label>
            <input formControlName="city"
                   class="form-group__control"
                   type="text">
          </div>
        </ng-container>

        <div *ngIf="trainingForm.get('address.location.country').value === 'United States'"
             [totalfitFormGroupValidator]="trainingForm.get('index')"
             class="form-group form-group_default-width-1">
          <label class="form-group__label">Zip code</label>
          <input [formControl]="trainingForm.get('index')"
                 class="form-group__control"
                 id="zipCode"
                 type="text">
        </div>

        <div [totalfitFormGroupValidator]="trainingForm.get('address.address')"
             [ngClass]="{ isAdmin: isAdmin }"
             class="form-group form-group_default-width-1">
          <label class="form-group__label" for="address">Address</label>
          <textarea formControlName="address"
                    class="form-group__text-control mat-typography"
                    rows="3"
                    id="address">
        </textarea>
        </div>
      </ng-container>

      <div class="form-group form-group_row form-group_default-width-1">
        <div [totalfitFormGroupValidator]="hasLimit.get('value')" class="form-group limit-size" [ngClass]="{ isAdmin: isAdmin }">
          <label class="form-group__label">Participants Limit</label>

          <totalfit-select outputOptionField="id"
                           (selectEvent)="setParticipantsLimitValidator($event.id)"
                           [formControl]="hasLimit.get('value')"
                           [options]="[{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }]">
          </totalfit-select>
        </div>

        <div *ngIf="hasLimit.value.value === 'Yes'" [totalfitFormGroupValidator]="trainingForm.get('participantLimit')" class="form-group count" [ngClass]="{ isAdmin: isAdmin }">
          <label class="form-group__label">Count</label>

          <input formControlName="participantLimit" type="text" class="form-group__control">
        </div>
      </div>

      <div [totalfitFormGroupValidator]="trainingForm.get('registrationLink')"
           [ngClass]="{ isAdmin: isAdmin }"
           class="form-group form-group_default-width-1">
        <label class="form-group__label">Registration link</label>
        <input [value]="linkObs | async"
               readonly
               class="form-group__control"
               type="text">
      </div>

      <div class="form-group form-group_default-width-1">
        <button mat-button
                color="primary"
                class="add-entity-btn"
                *ngIf="!isAdmin"
                (click)="generateLink()">
          <mat-icon>link</mat-icon> Generate link
        </button>
      </div>

      <div class="form-group form-group_default-width" [ngClass]="{ isAdmin: isAdmin }">
        <label class="form-group__label">Cost</label>
        <input formControlName="cost"
               class="form-group__control"
               readonly
               type="text">
      </div>

      <div class="form-group" [ngClass]="{ isAdmin: isAdmin }">
        <label class="form-group__label">Photo</label>
        <totalfit-photo-drop formControlName="photo"
                             [showClose]="!isAdmin"
                             [isDisabled]="isAdmin"
                             [isDropDisable]="isAdmin"
                             [defaultImage]="training?.photo" class="form-control">
        </totalfit-photo-drop>
      </div>

      <h2 class="mat-title">Host Partner</h2>

      <ng-container formGroupName="hostPartner">
        <div class="form-group form-group_default-width-1" [ngClass]="{ isAdmin: isAdmin }">
          <label class="form-group__label">Name</label>
          <input formControlName="name"
                 class="form-group__control"
                 type="text">
        </div>
      </ng-container>

      <div *ngFor="let item of sharedFormService.getFormArray('hostPartner.email', trainingForm).controls; let i = index"
           [totalfitFormGroupValidator]="sharedFormService.getFormArray('hostPartner.email', trainingForm).controls[i]"
           [ngClass]="{ isAdmin: isAdmin }"
           class="form-group form-group_default-width-1 close-container">
        <label *ngIf="i === 0"
               class="form-group__label" for="email">
          {{ sharedFormService.getFormArray('hostPartner.email', trainingForm).controls.length > 1 ? 'Emails': 'Email' }}
        </label>
        <input [formControl]="item"
               class="form-group__control"
               id="email"
               type="text">

        <button *ngIf="sharedFormService.getFormArray('hostPartner.email', trainingForm).controls.length > 1 && !isAdmin"
                mat-flat-button
                (click)="sharedFormService.removeFormControl('hostPartner.email', i,  trainingForm)"
                class="icon-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="form-group form-group_default-width-1">
        <button *ngIf="!isAdmin"
                mat-button
                [disabled]="sharedFormService.getFormArray('hostPartner.email', trainingForm).controls.length === 2"
                color="primary"
                class="add-entity-btn"
                (click)="sharedFormService.addNewFormControlToFormArray('hostPartner.email', trainingForm)">
          + Add email
        </button>
      </div>

      <div *ngFor="let item of sharedFormService.getFormArray('hostPartner.phone', trainingForm).controls; let i = index"
           class="form-group form-group_default-width-1 close-container" [ngClass]="{ isAdmin: isAdmin }">
        <label *ngIf="i === 0"
               class="form-group__label">
          {{ sharedFormService.getFormArray('hostPartner.phone', trainingForm).controls.length > 1 ? 'Phones': 'Phone' }}
        </label>
        <mat-form-field appearance="outline" class="tell-selector">
          <ngx-mat-intl-tel-input [formControl]="item"
                                  [preferredCountries]="['us']"
                                  [enableSearch]="true">
          </ngx-mat-intl-tel-input>
        </mat-form-field>

        <button *ngIf="sharedFormService.getFormArray('hostPartner.phone', trainingForm).controls.length > 1 && !isAdmin"
                mat-flat-button
                (click)="sharedFormService.removeFormControl('hostPartner.phone', i,  trainingForm)"
                class="icon-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="form-group form-group_default-width-1">
        <button *ngIf="!isAdmin"
                mat-button
                [disabled]="sharedFormService.getFormArray('hostPartner.phone', trainingForm).controls.length === 2"
                color="primary"
                class="add-entity-btn"
                (click)="sharedFormService.addNewFormControlToFormArray('hostPartner.phone', trainingForm)">
          + Add phone
        </button>
      </div>

      <ng-container *ngIf="!isAdmin">
        <span [totalfitTooltip]="training?.status && training?.status === 'WAITING_FOR_REVIEW' ? saveString : ''">
          <button mat-stroked-button
                  color="primary"
                  type="submit"
                  class="submit save-btn"
                  [disabled]="training?.status && training?.status === 'WAITING_FOR_REVIEW'"
                  (click)="saveChanges('LOCAL_SAVE')">
          save
        </button>
        </span>

        <button mat-raised-button
                color="primary"
                type="submit"
                class="submit"
                (click)="saveChanges('WAITING_FOR_REVIEW')">
          publish
        </button>
      </ng-container>

      <ng-container *ngIf="isAdmin && training.status === 'WAITING_FOR_REVIEW'">
        <div class="form-group comment-container">
          <div class="separator"></div>

          <h2 class="mat-title">Comment</h2>

          <textarea class="form-group__text-control" [formControl]="commentControl"></textarea>
        </div>

        <button mat-raised-button
                color="primary"
                type="submit"
                class="submit"
                style="margin-right: 16px"
                (click)="adminSave('ACCEPT')">
          accept
        </button>

        <button *ngIf="commentControl.value"
                mat-stroked-button
                color="primary"
                type="submit"
                class="submit save-btn"
                (click)="adminSave('DECLINE')">
          decline
        </button>
      </ng-container>

    </form>
  </div>
</totalfit-navigation-page-container>
