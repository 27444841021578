import { Gender, People } from '@app/people/_shared/people.model';
import { PaginationModel } from '@shared/models/pagination.model';
import { ProfileProgress, WebProfile } from '@shared/models/web-profile.model';

export interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
  birthday: Date;
  registerDate: Date;
  status: UserStatus;
  token: string;
  verifyEmailSent: Date;
  roles: UserRole[];
  gender: Gender;
  height: string;
  location: WebLocation;
  weight: string;
  about: string;
  phone: string;
  twitter: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  plan: string;
  accountType?: UserPermissionsId;
}

export interface ChangeUserPassword {
  confirmPassword: string;
  newPassword: string;
  oldPassword: string;
}

export enum UserRole {
  Athlete = 'ATHLETE',
  Trainer = 'TRAINER',
  TRAINER_LEVEL2 = 'TRAINER_LEVEL2',
  Admin = 'ADMIN',
  Instructor = 'INSTRUCTOR'
}

export enum UserRoleLabel {
  ATHLETE = 'Athlete',
  TRAINER = 'Level 1 Coach',
  TRAINER_LEVEL2 = 'Level 2 Coach',
  ADMIN = 'Admin',
  INSTRUCTOR = 'Instructor',
}

export enum UserRolesId {
  ATHLETE = '1',
  TRAINER = '2',
  TRAINER_LEVEL2 = '5',
  ADMIN = '4',
  INSTRUCTOR = '3',
}

export const PeopleRoles = {
  ALL: [],
  LEAD: ['LEAD'],
  TRIAL: ['TRIAL'],
  ATHLETE: ['ATHLETE'],
  TEAM: ['ASSISTANT', 'COACH']
};

export const UserRoles: Array<{ id: number, roleName: string, roleLabel: string, isDisabled?: boolean }> = [
  {
    id: 4,
    roleName: UserRole.Admin,
    roleLabel: UserRoleLabel[UserRole.Admin],
  },
  {
    id: 3,
    roleName: UserRole.Instructor,
    roleLabel: UserRoleLabel[UserRole.Instructor]
  },
  {
    id: 2,
    roleName: UserRole.Trainer,
    roleLabel: UserRoleLabel[UserRole.Trainer]
  },
  {
    id: 5,
    roleName: UserRole.TRAINER_LEVEL2,
    roleLabel: UserRoleLabel[UserRole.TRAINER_LEVEL2]
  },
  {
    id: 1,
    roleName: UserRole.Athlete,
    roleLabel: UserRoleLabel[UserRole.Athlete]
  }
];

export enum UserPermissionsId {
  ASSOCIATE = 'ASSOCIATE',
  NON_ASSOCIATE = 'NON_ASSOCIATE '
}

export enum UserPermissionsLabels {
  ASSOCIATE = 'Associate',
  NON_ASSOCIATE = 'Non associate'
}

export enum UserStatus {
  Active,
  Inactive
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface WebLocation {
  country: string;
  city: string;
}

export interface UserState {
  user: User;
  webProfile: WebProfile;
  adminUsers: PaginationModel<User>;
  people: PaginationModel<People>;
  updatedStatus: 'UPDATING' | 'DONE' | 'FAILED';
  progress: ProfileProgress;
}
