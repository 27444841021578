<div class="update-plan mat-typography">
  <div class="update-plan__header">
    <button *ngIf="step !== 0 && step !== 3" mat-flat-button class="icon-button" (click)="setPrevStep()"><mat-icon>keyboard_backspace</mat-icon></button>
    <ng-container *ngIf="step === 0">
      <h2 class="update-plan__title">Choose a plan that’s right for you</h2>
    </ng-container>

    <ng-container *ngIf="step !== 0 && step !== 3">
      <totalfit-payment-steps-header [step]="step" [stepsLabels]="stepsLabels"></totalfit-payment-steps-header>
    </ng-container>

    <button mat-flat-button class="icon-button" [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
  </div>
  <div class="update-plan__body">
    <ng-container *ngIf="step === 0">
      <div class="plan">
        <div class="plan__header">
          <h5 class="plan__title">Coach</h5>
          <p class="plan__subtitle">For beginners and small group management with limited access to Totalfit Tools.</p>
          <div class="plan__price">Free</div>
        </div>
        <div class="plan__body">
          <ul class="plan__body-list">
            <li>No license to use Totalfit Brand</li>
            <li>No license to use Totalfit Name</li>
            <li>Create 1 location</li>
            <li>Calendar and Schedule</li>
            <li>Add up to 10 peoples</li>
            <li>6 months Instructors’s support</li>
            <li>Create up to 10 workouts</li>
            <li>Whiteborad</li>
            <li>Create 1 program (4 weeks)</li>
            <li>Limited Totalfit Library Access</li>
            <li>1 workout per week from annual Totalfit 8-weeks program</li>
          </ul>
        </div>
      </div>

      <div class="plan">
        <div class="plan__header">
          <h5 class="plan__title">Premium Totalfit Coach</h5>
          <p class="plan__subtitle">
            For a full-fledged gym or a groups or gyms network with full access to Totalfit Tools.
          </p>
          <div class="plan__price">
            $250
            <div class="plan__price-subtitle">
              yearly billing only
            </div>

            <button mat-flat-button color="primary" (click)="selectPlan()">Upgrade</button>
          </div>
        </div>
        <div class="plan__body">
          <ul class="plan__body-list">
            <li class="highlighted"><mat-icon>grade</mat-icon>License to usage of Totalfit Brand</li>
            <li class="highlighted"><mat-icon>grade</mat-icon>License to usage of Totalfit Name</li>
            <li>Create up to 3 locations</li>
            <li>Calendar and Schedule for every location</li>
            <li>Add up to 500 peoples</li>
            <li>12 months Instructors’s support</li>
            <li>Create up to 100 workouts </li>
            <li>Whiteborad</li>
            <li>Create up to 100 workouts</li>
            <li>Unlimited Totalfit Library Access</li>
            <li>Full Acces to annual Totalfit 8-weeks program</li>
            <li>Publishing of your Location in Totalfit App</li>
            <li>Get subscribers from Totalfit App</li>
            <li>1 Free Workouts Stack from Totalfit Shop</li>
            <li>50% discount for 1 Workouts Stack from Totalfit Shop </li>
            <li>1 Free Program from Totalfit Shop</li>
            <li>50% discount for 1 Program from Totalfit Shop</li>
          </ul>
        </div>
      </div>
    </ng-container>

    <totalfit-payment-steps [step]="step"
                            [paymentSetUp]="paymentSetUp"
                            [purchaseSuccessfully]="purchaseSuccessfully"
                            (purchase)="purchase()"
                            (tokenReg)="registerToken($event)"
                            (confirm)="confirmPayment($event)"
                            (setPreviousStep)="setPreviousStep()"
                            (finalClickSuccess)="closeDialog()">
    </totalfit-payment-steps>
  </div>
</div>


<totalfit-totalfit-spinner [isShown]="(updatedStatus | async) === 'UPDATING'" spinnerClass="global-spinner"></totalfit-totalfit-spinner>
