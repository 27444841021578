import { Story } from '@shared/models/workout.model';
import { Observable, of } from 'rxjs';
import { PaginationModel } from '@shared/models/pagination.model';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkoutStoryService {
  public storyList: Story[] = [];
  public storyTotalSize: string;
  public paginationValue = {
    pageSize: 20,
    pageNumber: 0
  };
  public lastSelectedStoryPagination = new Set();
  public searchStorage: Map<string, Story[]> = new Map();

  constructor(
    private http: HttpClient
  ) {}

  public getAllStories(
    pageSize = this.paginationValue.pageSize,
    pageNumber = this.paginationValue.pageNumber,
  ): Observable<PaginationModel<Story>> {
    return this.lastSelectedStoryPagination.has(`${pageSize}, ${pageNumber}`) || +this.storyTotalSize === this.storyList.length - 1
      ?
      of({ objects: this.storyList, pagesCount: pageNumber } as PaginationModel<Story>)
      : this.http
        .get<PaginationModel<Story>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/stories?size=${pageSize}&page=${pageNumber}`)
        .pipe(
          map((res) => {
            this.storyTotalSize = res.totalElements;
            this.storyList = pageNumber === 0
              ? [{ name: '+ Add new', id: null, story: '', truthToRemember: '' }, ...res.objects]
              : [...this.storyList, ...res.objects];
            this.lastSelectedStoryPagination.add(`${pageSize}, ${pageNumber}`);

            if (this.paginationValue.pageNumber < res.pagesCount) {
              this.paginationValue.pageNumber++;
            }

            return { objects: this.storyList, pagesCount: res.pagesCount, totalElements: res.totalElements } ;
          })
        );
  }

  public searchStories(name: string): Observable<Story[]> {
    return this.searchStorage.has(name)
      ?
      of(this.searchStorage.get(name))
      : this.http
        .get<PaginationModel<Story>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/offline-workouts/stories?size=100&page=0&name=${name}`)
        .pipe(
          map((res) => {
            this.searchStorage.set(name, res.objects);
            return res.objects;
          })
        );
  }

  public dropCache(): void {
    this.storyList = [];
    this.storyTotalSize = null;
    this.paginationValue = {
      pageSize: 20,
      pageNumber: 0
    };
    this.lastSelectedStoryPagination.clear();
  }
}
