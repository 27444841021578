import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserRole } from '@app/store/user/user.model';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { ActivateGuardService } from '@core/auth/activate-guard.service';
import { RedirectComponentComponent } from '@core/auth/redirect-component/redirect-component.component';

@NgModule({
  imports: [RouterModule.forRoot([
    {
        path: '',
        component: RedirectComponentComponent
    },
    {
        path: 'locations',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./location/location.module').then((module) => module.LocationModule),
        data: {
            role: [UserRole.Trainer],
            title: 'Locations',
        }
    },
    {
        path: 'calendar',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./calendar/calendar.module').then((module) => module.CalendarModule),
        data: {
            role: [UserRole.Trainer],
            title: 'Calendar',
        }
    },
    // {
    //     path: 'program',
    //     canActivate: [AuthGuardService],
    //     loadChildren: () => import('./my-program/my-program.module').then((module) => module.MyProgramModule),
    //     data: {
    //         role: [UserRole.Trainer],
    //         title: 'My program',
    //     }
    // },
    {
      path: 'tribe',
      canActivate: [AuthGuardService],
      loadChildren: () => import('./location-schedule/location-schedule.module').then((module) => module.LocationScheduleModule),
      data: {
        role: [UserRole.Trainer],
        title: 'Tribe',
      }
    },
    {
        path: 'workouts',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./workout/workout.module').then((module) => module.WorkoutModule),
        data: {
            role: [UserRole.Trainer],
            title: 'Workouts',
        }
    },
    {
        path: 'profile-coach',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./profile-coach/profile-coach.module').then((module) => module.ProfileCoachModule),
        data: {
            role: [UserRole.Trainer, UserRole.Admin],
            title: 'Profile Coach'
        }
    },
    // {
    //     path: 'people',
    //     canActivate: [AuthGuardService],
    //     loadChildren: () => import('./people/people.module').then((module) => module.PeopleModule),
    //     data: {
    //         role: [UserRole.Trainer],
    //         title: 'People',
    //     }
    // },
    {
        path: 'library',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./library/library.module').then((module) => module.LibraryModule),
        data: {
            role: [UserRole.Trainer],
            title: 'Library'
        }
    },
    {
      path: 'admin-library',
      canActivate: [AuthGuardService],
      loadChildren: () => import('./admin-library/admin-library.module').then((module) => module.AdminLibraryModule),
      data: {
        role: [UserRole.Admin],
        title: 'Library'
      }
    },
    {
      path: 'admin-collection',
      canActivate: [AuthGuardService],
      loadChildren: () => import('./admin-program/admin-program.module').then((module) => module.AdminProgramModule),
      data: {
        role: [UserRole.Admin],
        title: 'Program'
      }
    },
    {
        path: 'trainings',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./trainings/trainings.module').then((module) => module.TrainingsModule),
        data: {
            role: [UserRole.Instructor],
            title: 'Trainings'
        }
    },
    {
        path: 'admin-trainings',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./admin-trainings/admin-trainings.module').then((module) => module.AdminTrainingsModule),
        data: {
            role: [UserRole.Admin],
            title: 'Trainings'
        }
    },
    {
        path: 'admin-workouts',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./admin-workouts/admin-workouts.module').then((module) => module.AdminWorkoutsModule),
        data: {
            role: [UserRole.Admin],
            title: 'Workouts'
        }
    },
    {
      path: 'admin-workout-manager',
      canActivate: [AuthGuardService],
      loadChildren: () => import('./admin-workout-manager/admin-workout-manager.module')
        .then((module) => module.AdminWorkoutManagerModule),
      data: {
        role: [UserRole.Admin],
        title: 'Workout Manager'
      }
    },
    // Change on user module when time is come, my young padavan
    {
        path: 'admin-users',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./admin-users/admin-users.module').then((module) => module.AdminUsersModule),
        data: {
            role: [UserRole.Admin],
            title: 'Users'
        }
    },
    {
        path: 'training/:id/registration',
        loadChildren: () => import('./training-registration/training-registration.module')
            .then((module) => module.TrainingRegistrationModule),
        data: {
            title: 'Trainings'
        }
    },
    {
        path: 'activate/:id',
        canActivate: [ActivateGuardService],
        loadChildren: () => import('./activate/activate.module').then((module) => module.ActivateModule),
    },
    {
        path: 'activation/:id',
        loadChildren: () => import('./activation-screen/activation-screen.module').then((module) => module.ActivationScreenModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((module) => module.LoginModule),
    },
    {
        path: 'forgot-password/:recovery-id',
        loadChildren: () => import('./login/login.module').then((module) => module.LoginModule),
    }
], { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
