import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileProgress, WebProfile } from '@shared/models/web-profile.model';
import { environment } from '@environments/environment';
import { User } from '@app/store/user/user.model';

@Injectable({ providedIn: 'root' })
export class WebProfileService {
  constructor(private http: HttpClient) {}

  public getWebProfile(): Observable<WebProfile> {
    return this.http.get<WebProfile>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/web-profile`);
  }

  public getUserByToken(token: string): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/${token}`);
  }

  public getUserProgress(): Observable<ProfileProgress> {
    return this.http.get<ProfileProgress>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/profile/progress`);
  }

  public updateWebProfile(webProfile: WebProfile): Observable<WebProfile> {
    return this.http.put<WebProfile>(`${environment.apiUrl}/${environment.apiV.apiV1}/users/web-profile`, webProfile);
  }
}
