import { Action, createReducer, on } from '@ngrx/store';
import * as fromWorkoutActions from '@app/store/workout/workout.actions';
import { WorkoutState } from '@app/store/workout/workout.model';
import { WorkoutType } from '@shared/models/workout.model';

export const workoutsInitialState: WorkoutState = {
  adminWorkoutList: null,
  workoutBundles: null,
  selectedBundleWorkout: null,
  selectedLastWorkout: null,
  userWorkoutBundles: null,
  selectedUserBundle: null,
  selectedUserShopBundle: null,
  customWorkoutList: null,
  currentCustomWorkout: null,
  currentCustomWorkoutLoading: false,
  workoutTypeView: WorkoutType.BUILDER,
  onlineExercise: null,
  offlineWorkoutList: null,
  offlineWorkout: null,
  workoutIsLoading: false,
  adminTemplateList: null,
  templateWorkoutBundleList: null,
  adminTemplateListPagination: null,
  adminOfflineWorkout: null,
  adminBundleTemplateList: null,
  templateWorkoutBundle: null,
  currentTemplateForPreview: null,
  dropDownList: null
};

const workoutReducer = createReducer(
  workoutsInitialState,
  on(fromWorkoutActions.getAllTemplatesAdminSuccess, (state, { workoutList }) => {
    return {
      ...state,
      adminTemplateList: workoutList
    };
  }),
  on(fromWorkoutActions.setTemplateForPreview, (state, { template }) => {
    return {
      ...state,
      currentTemplateForPreview: template
    };
  }),
  on(fromWorkoutActions.getAllTemplatesBundleAdminSuccess, (state, { templateWorkoutBundleList }) => {
    return {
      ...state,
      templateWorkoutBundleList
    };
  }),
  on(fromWorkoutActions.setAllTemplatesPagination, (state, { pagination }) => {
    return {
      ...state,
      adminTemplateListPagination: pagination
    };
  }),
  on(fromWorkoutActions.createWorkoutBundleTemplateSuccess, (state, { templateWorkoutBundle }) => {
    return {
      ...state,
      templateWorkoutBundleList: { ...state.templateWorkoutBundleList, objects: [ ...state.templateWorkoutBundleList.objects, templateWorkoutBundle] }
    };
  }),
  on(fromWorkoutActions.createTemplatesSuccess, (state, { workout }) => {
    return workout.bundleId ?
      {
        ...state,
        templateWorkoutBundle: { ...state.templateWorkoutBundle, workouts: [...state.templateWorkoutBundle.workouts, workout] }
      }
      : {
      ...state,
      adminTemplateList: state.adminTemplateList.objects.length < state.adminTemplateListPagination.pageSize
        ? { ...state.adminTemplateList, objects: [ ...state.adminTemplateList.objects, workout ], totalElements: String(+state.adminTemplateList.totalElements + 1) }
        : { ...state.adminTemplateList, totalElements: String(+state.adminTemplateList.totalElements + 1)  },
    };
  }),
  on(fromWorkoutActions.getTemplateByIdSuccess, (state, { adminOfflineWorkout }) => {
    return {
      ...state,
      adminOfflineWorkout
    };
  }),
  on(fromWorkoutActions.getTemplateById, (state) => {
    return {
      ...state,
      adminOfflineWorkout: null
    };
  }),
  on(fromWorkoutActions.getWorkoutBundleTemplateByIdSuccess, (state, { templateWorkoutBundle }) => {
    return {
      ...state,
      templateWorkoutBundle
    };
  }),
  on(fromWorkoutActions.editTemplateByIdSuccess, (state, { workout }) => {
    return {
      ...state,
      templateWorkoutBundle: workout.bundleId
        ? { ...state.templateWorkoutBundle, workouts: state.templateWorkoutBundle?.workouts?.map((w) => (w.id === workout.id ? workout : w)) }
        : state.templateWorkoutBundle,
      adminTemplateList: !workout?.bundleId
        ? { ...state.adminTemplateList, objects: state.adminTemplateList?.objects?.map((w) => (w.id === workout.id ? workout : w)) }
        : state.adminTemplateList,
      adminOfflineWorkout: workout
    }
  }),

  on(fromWorkoutActions.editWorkoutBundleTemplateSuccess, (state, { templateWorkoutBundle }) => {
    return {
      ...state,
      templateWorkoutBundleList: {
        ...state.templateWorkoutBundleList,
        objects: state.templateWorkoutBundleList?.objects.map((t) => (t.id === templateWorkoutBundle.id ? templateWorkoutBundle : t))
      }
    }
  }),

  on(fromWorkoutActions.removeTemplateByIdSuccess, (state, { id, isBundle }) => {
    return isBundle ?
      {
        ...state,
        templateWorkoutBundle: { ...state.templateWorkoutBundle, workouts: state.templateWorkoutBundle.workouts.filter((w) => String(w.id) !== String(id)) }
      }
      : {
      ...state,
      adminTemplateList: { ...state.adminTemplateList, objects: state.adminTemplateList.objects.filter((w) => String(w.id) !== String(id)), totalElements: String(+state.adminTemplateList.totalElements - 1) }
    };
  }),



  on(fromWorkoutActions.removeWorkoutBundleTemplateByIdSuccess, (state, { id }) => {
    return {
      ...state,
      templateWorkoutBundleList: { ...state.templateWorkoutBundleList, objects: state.templateWorkoutBundleList.objects.filter((w) => String(w.id) !== String(id)), totalElements: String(+state.adminTemplateList.totalElements - 1) }
    };
  }),
  on(fromWorkoutActions.getAllTemplatesSuccess, (state, { workoutList }) => {
    return {
      ...state,
      adminTemplateList: workoutList
    };
  }),
  on(fromWorkoutActions.setWorkoutIsLoading, (state, { workoutIsLoading }) => {
    return {
      ...state,
      workoutIsLoading
    };
  }),
  on(fromWorkoutActions.getWorkoutSuccess, (state, { offlineWorkout }) => {
    return {
      ...state,
      offlineWorkout,
      workoutIsLoading: false
    };
  }),
  on(fromWorkoutActions.getWorkout, (state) => {
    return {
      ...state,
      workoutIsLoading: true
    };
  }),
  on(fromWorkoutActions.getAdminWorkoutsSuccess, (state, { adminWorkouts }) => {
    return {
      ...state,
      adminWorkoutList: adminWorkouts
    };
  }),
  on(fromWorkoutActions.getBundlesSuccess, (state, { bundles }) => {
    return {
      ...state,
      workoutBundles: bundles
    };
  }),
  on(fromWorkoutActions.setBundleWorkout, (state, { workout }) => {
    return {
      ...state,
      selectedBundleWorkout: workout
    };
  }),

  on(fromWorkoutActions.selectLastWorkout, (state, { workout }) => {
    return {
      ...state,
      selectedLastWorkout: workout
    };
  }),

  on(fromWorkoutActions.removeLastWorkout, (state) => {
    return {
      ...state,
      selectedLastWorkout: null
    };
  }),

  on(fromWorkoutActions.getUserBundlesSuccess, (state, { bundles }) => {
    return {
      ...state,
      userWorkoutBundles: bundles.objects
    };
  }),

  on(fromWorkoutActions.getAllOnlineExerciseSuccess, (state, { exercise }) => {
    return {
      ...state,
      onlineExercise: exercise
    };
  }),

  on(fromWorkoutActions.setUserBundle, (state, { bundle }) => {
    return {
      ...state,
      selectedUserBundle: bundle
    };
  }),

  on(fromWorkoutActions.setUserShopBundle, (state, { bundle }) => {
    return {
      ...state,
      selectedUserShopBundle: bundle
    };
  }),

  on(fromWorkoutActions.getUserBundleByIdSuccess, (state, { bundle }) => {
    return {
      ...state,
      selectedUserBundle: bundle
    };
  }),

  on(fromWorkoutActions.getUserBundleById, (state) => {
    return {
      ...state,
      selectedUserBundle: null
    };
  }),

  on(fromWorkoutActions.getCustomWorkoutList, (state) => {
    return {
      ...state,
      currentCustomWorkoutLoading: true,
      customWorkoutList: null
    };
  }),

  on(fromWorkoutActions.getCustomWorkoutListSuccess, (state, { workouts }) => {
    return {
      ...state,
      currentCustomWorkoutLoading: false,
      customWorkoutList: workouts
    };
  }),

  on(fromWorkoutActions.setCurrentCustomWorkout, (state, { customWorkout }) => {
    return {
      ...state,
      currentCustomWorkout: customWorkout
    };
  }),

  on(fromWorkoutActions.resetCurrentCustomWorkout, (state) => {
    return {
      ...state,
      currentCustomWorkout: null
    };
  }),

  on(fromWorkoutActions.getOfflineWorkoutListSuccess, (state, { workouts }) => {
    return {
      ...state,
      offlineWorkoutList: workouts
    };
  }),

  on(fromWorkoutActions.getDropDownListSuccess, (state, { list }) => {
    return {
      ...state,
      dropDownList: list
    };
  }),
);

export function reducer(state: WorkoutState | undefined, action: Action) {
  return workoutReducer(state, action);
}
