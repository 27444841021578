import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[totalfitDragDrop]'
})
export class DragDropDirective {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @Output() public fileDropped = new EventEmitter<any>();
  @Input() private dragoverClass: string;
  // Dragover listener
  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.addClass(this.elementRef.nativeElement, this.dragoverClass);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.elementRef.nativeElement, this.dragoverClass);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const filesData = evt.dataTransfer;
    if (filesData.files.length > 0) {
      this.fileDropped.emit(filesData);
    }
    this.renderer.removeClass(this.elementRef.nativeElement, this.dragoverClass);
  }
}
