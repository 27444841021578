import { Training } from '@app/trainings/_shared/trainings.model';

export enum months {
   'January' = 1,
   'February',
   'March',
   'April',
   'May',
   'June',
   'July',
   'August',
   'September', 'October', 'November', 'December'
}

export function getTrainingDay(training: Training): any {
  const parsedStartDay = training.startDate.slice(8, 10);
  const parsedEndDay = training.finishDate.slice(8, 10);

  const parsedStartMonth = training.startDate.slice(5, 7);
  const parsedEndMonth = training.finishDate.slice(5, 7);

  const parsedStartYear = training.startDate.slice(0, 4);
  const parsedEndYear = training.finishDate.slice(0, 4);

  if (parsedStartYear !== parsedEndYear) {
   return `${months[Number(parsedStartMonth)]} ${parsedStartDay}, ${parsedStartYear} - ${months[Number(parsedEndMonth)]} ${parsedEndDay}, ${parsedEndYear}`;
  }

  if (parsedStartMonth !== parsedEndMonth) {
    return `${months[Number(parsedStartMonth)]} ${parsedStartDay} - ${months[Number(parsedEndMonth)]} ${parsedEndDay}, ${parsedEndYear}`;
  }

  return `${months[Number(parsedStartMonth)]} ${parsedStartDay} - ${parsedEndDay}, ${parsedEndYear}`;
}
