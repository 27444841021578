import { UserState } from '@app/store/user/user.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromUserActions from '@app/store/user/user.actions';

export const userInitialState: UserState = {
  user: {
    firstName: null,
    roles: [],
    about: null,
    location: {
      country: null,
      city: null
    },
    birthday: null,
    gender: null,
    status: null,
    lastName: null,
    registerDate: null,
    email: null,
    photo: null,
    accountType: null,
    facebook: null,
    height: null,
    instagram: null,
    linkedin: null,
    phone: null,
    plan: null,
    token: null,
    twitter: null,
    verifyEmailSent: null,
    weight: null
  },
  adminUsers: null,
  people: null,
  updatedStatus: null,
  webProfile: null,
  progress: null
};

const userReducer = createReducer(
  userInitialState,
  on(fromUserActions.setUser, (state, { user }) => {
    return {
      ...state,
      user
    };
  }),
  on(fromUserActions.getAdminUserSuccess, (state, { adminUsers }) => {
    return {
      ...state,
      adminUsers
    };
  }),
  on(fromUserActions.getPeopleSuccess, (state, { people }) => {
    return {
      ...state,
      people
    };
  }),
  on(fromUserActions.setUpdateStatus, (state, { updateType }) => {
    return {
      ...state,
      updatedStatus: updateType
    };
  }),

  on(fromUserActions.getWebProfileSuccess, (state, { webProfile }) => {
    return {
      ...state,
      webProfile
    };
  }),

  on(fromUserActions.getWebProfileProgressSuccess, (state, { progress }) => {
    return {
      ...state,
      progress
    };
  }),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
