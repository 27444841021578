import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import {catchError, map} from "rxjs/operators";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
    ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (!this.authService.authToken) {
      this.router.navigate(['/login']);
      return false;
    }

    if (!this.authService.currentUser && this.authService.authToken) {
      return this.authService.getUserByJWT()
        .pipe(map(() => {
          return this.authService.currentUser &&
                  this.authService.selectedRole.getValue().some((item) => route.data.role.includes(item));
        }), catchError(() => {
          this.router.navigate(['/login']);
          return of(false);
        }));
    }

    if (
      this.authService.currentUser &&
      this.authService.selectedRole.getValue().some((item) => route.data.role.includes(item))
    ) { return true; }

    this.router.navigate(['/login']);
    return false;
  }
}
