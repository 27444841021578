import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromLibraryActions from '@app/store/library/library.actions';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { LibraryService } from '@shared/services/library.service';
import { DownloadFileService } from '@shared/services/download-file.service';
import { addFileFailed, addFileSuccess } from '@app/store/library/library.actions';
import { SnackbarService } from '@shared/services/snackbar.service';
import { of } from 'rxjs';

@Injectable()
export class LibraryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly libraryService: LibraryService,
    private readonly downloadFileService: DownloadFileService,
    private readonly snackbarService: SnackbarService
  ) {}

  public addNewFile$ = createEffect(() => this.actions$.pipe(
    ofType(fromLibraryActions.addNewFileToLibrary),
    switchMap(({ file, path, libraryFile }) => {
      return this.libraryService.saveLibFiles(file, path)
        .pipe(
          map(() => {
            return addFileSuccess({ file: libraryFile });
          }),
          catchError(() => {
            this.snackbarService.openSnackbar('We were unable to upload your file for some reason, please try again later');
            return of(addFileFailed({ file: libraryFile }));
          }),
          finalize(() => {
            this.downloadFileService.entityForDelete.next(libraryFile);
          })
        );
    })
  ));

  public deleteFileFromLibrary$ = createEffect(() => this.actions$.pipe(
    ofType(fromLibraryActions.deleteFileFromLibrary),
    switchMap(({ path }) => {
      return this.libraryService.deleteLibraryFolder(path);
    })
  ), { dispatch: false });

  public addNewFolder$ = createEffect(() => this.actions$.pipe(
    ofType(fromLibraryActions.addNewFolderToLibrary),
    switchMap(({ fileName, path }) => {
      return this.libraryService.createLibraryFolder(path, fileName);
    })
  ), { dispatch: false });
}
