<button mat-button
        [totalfitDropdownToggle]="emojiPickerTemplate"
        [isPanelHasFlexibleWidth]="true"
        class="icon-trigger">
  <span *ngIf="emoji" [innerHTML]="getUnicodeItem(emoji)"></span>

  <svg *ngIf="!emoji" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#F3F3F6"/>
    <path d="M7.16667 10.0833C7.16667 9.39167 7.725 8.83333 8.41667 8.83333C9.10833 8.83333 9.66667 9.39167 9.66667 10.0833C9.66667 10.775 9.10833 11.3333 8.41667 11.3333C7.725 11.3333 7.16667 10.775 7.16667 10.0833ZM11.3333 16.75C13.275 16.75 14.925 15.5333 15.5917 13.8333H7.075C7.74167 15.5333 9.39167 16.75 11.3333 16.75ZM14.25 11.3333C14.9417 11.3333 15.5 10.775 15.5 10.0833C15.5 9.39167 14.9417 8.83333 14.25 8.83333C13.5583 8.83333 13 9.39167 13 10.0833C13 10.775 13.5583 11.3333 14.25 11.3333ZM19.6667 3H18V4.66667H16.3333V6.33333H18V8H19.6667V6.33333H21.3333V4.66667H19.6667V3ZM18 12.1667C18 15.85 15.0167 18.8333 11.3333 18.8333C7.65 18.8333 4.66667 15.85 4.66667 12.1667C4.66667 8.48333 7.65 5.5 11.3333 5.5C12.55 5.5 13.6833 5.83333 14.6667 6.4V4.53333C13.6136 4.07022 12.4754 3.83182 11.325 3.83333C6.725 3.83333 3 7.56667 3 12.1667C3 16.7667 6.725 20.5 11.325 20.5C15.9333 20.5 19.6667 16.7667 19.6667 12.1667C19.6667 11.2917 19.525 10.4583 19.275 9.66667H17.5C17.8167 10.4417 18 11.2833 18 12.1667Z" fill="#67678E"/>
  </svg>
</button>

<ng-template #emojiPickerTemplate>
  <div class="emoji-palette">
    <ng-container *ngFor="let emoji of reactionList">
      <button mat-button
              (click)="selectEmojiEvent(emoji)"
              class="icon-trigger">
        <span [innerHTML]="getUnicodeItem(emoji)"></span>
      </button>
    </ng-container>
  </div>
</ng-template>

