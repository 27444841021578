<header class="header">
  <div class="main-content">
    <div class="header__logo">
      <img src="assets/img/HorizontalLogoWhite.svg" alt="logo">
    </div>

    <ng-container *ngIf="user$ | async as user">
      <div class="header__search">
        <div class="header__title mat-typography">
          <a *ngIf="routeBack"
             [routerLink]="[routeBack]"
             [queryParams]="optionalParams">
            <i class="icon-icon-back"></i>

            <h2>{{ title }}</h2>
          </a>

          <h2 *ngIf="!routeBack">{{ title }}</h2>
        </div>

        <!--      <button *ngIf="!isSearchActive"-->
        <!--              (click)="isSearchActive = !isSearchActive"-->
        <!--              class="search-toggler">-->
        <!--        <i class="icon-search"></i>-->
        <!--      </button>-->

        <!--      <input *ngIf="isSearchActive"-->
        <!--             [formControl]="searchControl"-->
        <!--             class="header__input mat-typography"-->
        <!--             placeholder="Search"-->
        <!--             type="text"-->
        <!--             @slideInAnimation>-->
        <!--      <button (click)="isSearchActive = !isSearchActive"-->
        <!--              *ngIf="isSearchActive"-->
        <!--              class="search-toggler search-toggler_close">-->
        <!--        <i class="icon-close"></i>-->
        <!--      </button>-->
      </div>

      <div class="header__actions">
<!--        -->
        <div *ngIf="user?.accountType === 'NON_ASSOCIATE'" class="header__actions-panel">
          <button mat-raised-button color="primary" (click)="openPersonalPlan()">Upgrade your plan</button>
        </div>

        <!--    TODO - Create User Icon component -->
        <button *ngIf="user && (user.firstName || user.lastName)" [matMenuTriggerFor]="userTemplateRef"
                class="header__user">
          <img *ngIf="user?.photo; else namePlaceholder"
               [src]="getUserAvatar(user.photo)"
               alt="img">
          <ng-template #namePlaceholder>
            <div  class="placeholder mat-typography">
              {{(user.firstName ? user.firstName[0] : '') + (user.lastName ? user.lastName[0] : '')}}
            </div>
          </ng-template>
        </button>

        <mat-menu #userTemplateRef="matMenu"
                  xPosition="before">
          <div class="user" (click)="$event.stopPropagation()">
            <h3 class="user__name mat-typography">
              {{user.firstName}} {{user.lastName}}
            </h3>
            <div class="user__level mat-typography">
              {{user.email}} level
            </div>
          </div>
          <button mat-menu-item routerLink="/profile-coach">My Profile</button>
          <button *ngIf="authService.isUserAdmin"
                  mat-menu-item
                  (click)="changeSelectedRole(authService.isAdmin ? 'USER' : 'ADMIN')">
            {{ authService.isAdmin ? 'Switch to app' : 'Switch to admin' }}
          </button>
          <button mat-menu-item
                  (click)="authService.logout()">Log Out</button>
        </mat-menu>
      </div>
    </ng-container>
  </div>
</header>
