import { Pipe, PipeTransform } from '@angular/core';
import { CustomOfflineWorkoutBlocks, CustomOfflineWorkoutExtensions } from '@store/workout/workout.model';

@Pipe({
  name: 'selectExtension',
  pure: true
})
export class SelectExtensionPipe implements PipeTransform {

  public transform(extensions: CustomOfflineWorkoutExtensions[], block: CustomOfflineWorkoutBlocks): CustomOfflineWorkoutExtensions {
    return extensions.find((extension) => extension.extensionType === block);
  }

}
