import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedFormService } from '@shared/services/shared-form-service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AmplitudeActions, AmplitudeService } from '@shared/services/amplitude.service';
import { PaymentStep } from '@shared/dialogs/dialogs.model';
import { Store } from '@ngrx/store';
import { subscription } from '@app/store/user/user.actions';
import { User } from '@app/store/user/user.model';
import { selectUpdatedStatus, selectUser } from '@app/store/user/user.selector';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestConfirm } from '@shared/services/stripe.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'totalfit-update-plan',
  templateUrl: './update-plan.component.html',
  styleUrls: ['./update-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePlanComponent implements OnInit {
  public step = 0;
  // Should be private ts issue
  public token: any = null;
  public countryControl: UntypedFormControl;
  public stepsLabels: PaymentStep[];
  public paymentSetUp: any;
  public currentUser: User;
  public updatedStatus: Observable<any>;
  public purchaseSuccessfully: boolean;

  constructor(
    public sharedFormService: SharedFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private readonly amplitudeService: AmplitudeService,
    private readonly store$: Store,
    private dialogRef: MatDialogRef<UpdatePlanComponent>
  ) { }

  public ngOnInit(): void {
    this.countryControl = this.fb.control('');

    this.stepsLabels = [
      {
        label: '1.Pick a plan',
      },
      {
        label: '2.Check',
        matIconClass: 'arrow_right_alt'
      },
      {
        label: '3.Pay',
        matIconClass: 'arrow_right_alt'
      }
    ];

    this.paymentSetUp = {
      actionName: 'You are upgrading',
      entityName: 'Premium Totalfit Coach',
      actionDescription: `You’ll be charged $250 yearly until you cancel the subscription. To learn more, see FAQ or submit a question.`,
      price: 250
    };

    this.store$.select(selectUser)
      .subscribe((user) => {
        this.currentUser = user;

        this.changeDetectorRef.markForCheck();
      });

    this.updatedStatus = this.store$.select(selectUpdatedStatus)
      .pipe(
        tap((status) => {
          // Refactor
          if (status === 'DONE') {
            this.purchaseSuccessfully = true;
            this.step = 3;
          }

          if (status === 'FAILED') {
            this.purchaseSuccessfully = false;
            this.step = 3;
          }

          this.changeDetectorRef.markForCheck();
        })
      );
  }

  public selectPlan(): void {
    this.step = 1;

    // Remove
    this.changeDetectorRef.markForCheck();
  }

  public purchase(): void {
    this.step = 2;
    this.amplitudeService.triggerEvent(AmplitudeActions.USER_UPGRADE_PLAN_PURCHASE);
    this.changeDetectorRef.markForCheck();
  }

  public setPrevStep(): void {
    this.step--;

    this.changeDetectorRef.markForCheck();
  }

  public registerToken(token): void {
    // tslint:disable-next-line:no-console
    if (token) {
      this.token = token;
    } else {
      this.token = null;
    }
    // tslint:disable-next-line:no-console
    this.changeDetectorRef.detectChanges();
  }

  public confirmPayment(confirmValue: RequestConfirm): void {
    this.store$.dispatch(subscription({ user: this.currentUser, paymentMethod: confirmValue.paymentMethod }));
    this.changeDetectorRef.markForCheck();
  }

  public setPreviousStep(): void {
    this.step--;
    this.changeDetectorRef.markForCheck();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
