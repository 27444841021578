export interface LibraryItem {
  name: string;
  size: string;
  path?: string;
  entries?: LibraryItem[];
}

export const libRegExp: RegExp = /(?:\.([^.]+))?$/;

export interface Manual {
  id: number;
  name: string;
  link: string;
  level: string;
}

export const MediaFileExt = ['wav', 'aif', 'mp3', 'mp4'];
export const ForSaveOnly = ['ai', 'otf', 'mov', 'ttf', 'zip', 'aif', 'doc', 'docx', 'pptx'];
