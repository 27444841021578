import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output, EventEmitter, Input, OnInit
} from '@angular/core';
import { combineLatest, fromEvent } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { StripeService } from '@shared/services/stripe.service';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const stripe   = Stripe(environment.stripePublishableKey);
const elements = stripe.elements();

@UntilDestroy()
@Component({
  selector: 'totalfit-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StripePaymentComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('cardNumber') public cardNumberRef: ElementRef;
  @ViewChild('cardCvc') public cardCvcRef: ElementRef;
  @ViewChild('cardExpiry') public cardExpiryRef: ElementRef;
  @Output() public createToken = new EventEmitter();
  @Output() public confirmPayment = new EventEmitter();
  @Output() public paymentInfo = new EventEmitter();
  @Input() public userForm: UntypedFormGroup;
  @Input() private amount: number;
  @Input() private chargeData: any;

  public _totalAmount: number;
  public cardNumber: any;
  public cardExpiry: any;
  public cardCvc: any;
  public cardHandler = this.onChange.bind(this);
  public cardError: string;
  public isLoading = false;
  private lastValue;
  private paymentId: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private stripeService: StripeService
  ) {}

  public ngOnInit() {
    if (this.userForm) {
      this.userForm.valueChanges.pipe(untilDestroyed(this), debounceTime(700)).subscribe(() => {
        this.onChange(this.lastValue);
      });
    }

    if (this.amount) {
      this.stripeService.charge(this.amount * 100, this.chargeData)
        .subscribe((req: any) => {
          this.paymentId = req.paymentId;
        });
    }
  }

  public ngOnDestroy() {
    if (this.cardNumber) {
      // We remove event listener here to keep memory clean
      this.cardNumber.removeEventListener('change', this.cardHandler);
      this.cardExpiry.removeEventListener('change', this.cardHandler);
      this.cardCvc.removeEventListener('change', this.cardHandler);

      this.cardNumber.destroy();
      this.cardExpiry.destroy();
      this.cardCvc.destroy();
    }
  }
  public ngAfterViewInit() {
    this.initiateCardElement();
  }

  public initiateCardElement() {
    // Giving a base style here, but most of the style is in scss file
    this.cardNumber = elements.create('cardNumber', { showIcon: true });
    this.cardExpiry = elements.create('cardExpiry');
    this.cardCvc = elements.create('cardCvc');

    this.cardNumber.mount(this.cardNumberRef.nativeElement);
    this.cardExpiry.mount(this.cardExpiryRef.nativeElement);
    this.cardCvc.mount(this.cardCvcRef.nativeElement);

    combineLatest([
      fromEvent(this.cardNumber, 'change').pipe(startWith(null)),
      fromEvent(this.cardExpiry, 'change').pipe(startWith(null)),
      fromEvent(this.cardCvc, 'change').pipe(startWith(null))
    ]).subscribe(([s1, s2, s3]) => {
      this.lastValue = [s1, s2, s3];
      this.onChange([s1, s2, s3]);
    });
  }

  public onChange(arrS: any[]) {
    const hasError = arrS.some((i) => {
      if (i && i.error) {
        this.cardError = i.error.message;
      } else {
        this.cardError = '';
      }

      this.changeDetectorRef.detectChanges();
      return i ? i.error : i;
    });

    if (hasError) {
      return;
    } else {
      this.cardError = '';
    }

    if (arrS.every((i) => i && i.complete) && this.userForm.valid) {
      this.createStripeToken();
      this.changeDetectorRef.detectChanges();
    }
  }

  public async createStripeToken() {
    const {token, error} = await stripe.createToken(this.cardNumber, this.userForm.value);
    this.isLoading = false;

    if (token) {
      this.createToken.emit(token.id);
      this.paymentInfo.emit(token);
      if (this.amount) {
        const confirm = await stripe.createPaymentMethod({
          type: 'card',
          card: this.cardNumber,
          billing_details: {
            name: this.userForm.value.name
          }
        });

        this.confirmPayment.emit({ paymentId: this.paymentId, paymentMethod: confirm.paymentMethod.id });
      }

    } else {
      this.onError(error);
      this.createToken.emit(null);
    }

    this.changeDetectorRef.detectChanges();
  }

  public onError(error) {
    if (error.message) {
      this.cardError = error.message;
    }
  }
}
