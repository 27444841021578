import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { finalize, timeout } from 'rxjs/operators';
import { LoadingPageComponent } from '@shared/models/loading-page.component';

@Component({
  selector: 'totalfit-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivateComponent extends LoadingPageComponent implements OnInit {
  public isLoaded = false;

  constructor(
    private httpModule: HttpClient,
    private route: ActivatedRoute,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  public ngOnInit(): void {
    this.activate();
  }

  public activate(): void {
    this.isLoaded = false;
    this.isLoading = true;

    this.httpModule.get(`${environment.apiUrl}/activation/${this.route.snapshot.params.id}`)
      .pipe(
        timeout(2000),
        finalize(() => this.isLoading = false))
      .subscribe(() => this.isLoaded = true, () => this.isLoaded = false);
  }

}
