<totalfit-header [title]="title" [routeBack]="routeBack" [optionalParams]="optionalParams"></totalfit-header>
<div class="main-content mat-typography">
  <mat-sidenav-container>
    <mat-sidenav mode="side" [opened]="isSideNavOpened">
      <mat-nav-list>
        <ng-container *ngFor="let navItem of sideNavigationConfig">
          <ng-container *ngIf="!navItem.children">
            <ng-container *totalfitRoleCheck="{ roles: navItem.roles, subDomain: navItem.subDomain, guestRoute: navItem.guestRoute }">
              <mat-list-item [routerLink]="navItem.router"
                             [routerLinkActiveOptions]="{exact: true}"
                             [ngClass]="{ disabled: checkRole({ roles: navItem.roles, subDomain: navItem.subDomain, guestRoute: navItem.guestRoute }) }"
                             routerLinkActive="mat-list-item_active"
                             [attr.data-test-id]="navItem.label + '_' + 'path'"
                             class="navigation-item">
                <ng-template #icon>
                  <i *ngIf="navItem.iconClass"
                     [ngClass]="navItem.iconClass"></i>
                </ng-template>
                <ng-container *ngIf="navItem.svgPlaceholder else icon">
                  <div class="img-placeholder" [class.img-placeholder_active-first-path]="navItem.router === '/library' || navItem.router === '/admin-library'" [innerHTML]="getSvgPlaceholder(navItem.svgPlaceholder)"></div>
                </ng-container>
                {{ navItem.label }}
              </mat-list-item>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="navItem.children">
            <ng-container *totalfitRoleCheck="{ roles: navItem.roles, subDomain: navItem.subDomain, guestRoute: navItem.guestRoute }">
              <mat-accordion>
                <mat-expansion-panel [disabled]="checkRole({ roles: navItem.roles, subDomain: navItem.subDomain, guestRoute: navItem.guestRoute })"
                                     [expanded]="hasChildRouterActive(navItem)" hideToggle="true" class="nav-panel">
                  <mat-expansion-panel-header>
                    <mat-panel-title [class.activated]="hasChildRouterActive(navItem)"
                                     class="navigation-item"
                                     [ngClass]="{ disabled: checkRole({ roles: navItem.roles, subDomain: navItem.subDomain, guestRoute: navItem.guestRoute }) }">
                      <ng-template #icon>
                        <i *ngIf="navItem.iconClass"
                           [ngClass]="navItem.iconClass"></i>
                      </ng-template>

                      <ng-container *ngIf="navItem.svgPlaceholder else icon">
                        <div class="img-placeholder" [innerHTML]="getSvgPlaceholder(navItem.svgPlaceholder)"></div>
                      </ng-container>
                      {{ navItem.label }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <ng-container *ngFor="let childNav of navItem.children">
                    <mat-list-item [routerLink]="childNav.router"
                                   [ngClass]="{
                                      disabled: checkRole({ roles: navItem.roles, subDomain: navItem.subDomain, guestRoute: navItem.guestRoute }),
                                      'mat-list-item_active': hasRouterChildUrlForActiveState(childNav)
                                   }"
                                   [routerLinkActiveOptions]="{exact: true}"
                                   routerLinkActive="mat-list-item_active"
                                   class="navigation-item">
                      <i [ngClass]="childNav.iconClass"></i>
                      {{ childNav.label }}
                    </mat-list-item>
                  </ng-container>

                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <main id="main-container" [class.with-active-stream]="activeStream$ | async">
        <ng-content></ng-content>
      </main>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>
