<totalfit-navigation-page-container>
  <div class="library mat-typography" #libraryRef>
    <div *ngIf="withAdminControls" class="library__actions">
      <button *ngIf="breadcrumbs.length <= 1" [totalfitDropdownToggle]="nameExplanation"
              mat-icon-button>
        <mat-icon>
          help_outline
        </mat-icon>
      </button>

      <button
        mat-stroked-button
        color="primary"
        [disabled]="breadcrumbs.length <= 1"
        (click)="createNewFolder()">
        Add new folder
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="breadcrumbs.length <= 1"
        (click)="addFileList()">
        Add new file
      </button>
    </div>

    <div class="library__header">
      <div class="breadcrumb" #breadcrumbRef>
        <ng-container *ngIf="!showBreadcrumbCompressed">
          <div *ngFor="let breadcrumb of breadcrumbs; let i = index" class="breadcrumb__item">
            <div *ngIf="breadcrumb.name === 'root'" (click)="setFolder(breadcrumb, true)">
              <img src="assets/img/small-logo-group.svg" alt="">
            </div>
            <mat-icon *ngIf="breadcrumb.name !== 'root'">navigate_next</mat-icon>
            <div *ngIf="breadcrumb.name !== 'root'" [totalfitTooltip]="breadcrumb.name" class="breadcrumb text-ellipsis" [ngClass]="{active: i + 1 === breadcrumbs.length}" (click)="setFolder(breadcrumb)">{{ breadcrumb.name }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="showBreadcrumbCompressed">
          <div class="breadcrumb__item">
            <div (click)="setFolder(breadcrumbs[0], true)">
              <img src="assets/img/small-logo-group.svg" alt="">
            </div>
            <mat-icon>navigate_next</mat-icon>
            <div class="breadcrumb text-ellipsis" [ngStyle]="{ maxWidth: getBredMaxWidth() }" [totalfitTooltip]="breadcrumbs[1]?.name" (click)="setFolder(breadcrumbs[1])">{{ breadcrumbs[1]?.name }}</div>
            <mat-icon *ngIf="compressedBreadcrumb.length" [totalfitDropdownToggle]="breadcrumbItemsRef">more_horiz</mat-icon>
            <mat-icon *ngIf="!compressedBreadcrumb.length">navigate_next</mat-icon>
            <div [totalfitTooltip]="breadcrumbs[breadcrumbs.length - 1]?.name" [ngStyle]="{ maxWidth: getBredMaxWidth() }" class="breadcrumb text-ellipsis active">{{ breadcrumbs[breadcrumbs.length - 1]?.name }}</div>
          </div>
        </ng-container>
      </div>
      <div class="search">
        <i class="icon-search"></i>
        <input type="text" [formControl]="searchControl" [totalfitDropdownToggle]="optionsTemplateRef">

        <div class="search-results">
          <ng-template #optionsTemplateRef>
            <ul class="library-search mat-typography" *ngIf="searchControl.value.length">
              <li *ngIf="searchControl.value.length < 3">
                Type at least three characters to search
              </li>
              <li *ngFor="let searchEntry of searchEntries; let i = index"
                  [tabindex]="i"
                  (click)="searchItemClick(searchEntry)"
                  class="focus-item"

                  [totalfitDownloadFile]="getSaveLink(searchEntry, 'view')"
                  [entityDownloadName]="searchEntry.name"
                  [entity]="searchEntry"
                  [ngClass]="{ disabled: loadMap.get(searchEntry) }"
                  [loadType]="getIsMedia(searchEntry) || isForSaveOnlyFile(searchEntry) ? 'download' : 'view'"
                  eventTriggerName="click"
                  (startDownload)="startDownloadFile($event)"
                  (downloadProgress)="onLoadProgressCheck($event)"
                  #focusItem>
                <img [src]="getFilePlaceholder(searchEntry)">
                <span>
                {{ searchEntry.name }}
              </span>

                <div *ngIf="loadMap.get(searchEntry) as loadItem" class="load-container">
                  <mat-progress-bar  [value]="loadItem.progress" color="primary"></mat-progress-bar>
                </div>
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="library__files" [class.library__files_with-active-player]="audioFile">
      <div class="library__view-control">
        <h3>Files</h3>
        <div class="view-control">
          <button mat-button (click)="filesViewType = 'table'" class="icon-button" [ngClass]="{ 'active': filesViewType === 'table' }">
            <i [ngClass]="{ 'active': filesViewType === 'table' }" class="icon-List-view"></i>
          </button>
          <button mat-button (click)="filesViewType = 'list'" class="icon-button" [ngClass]="{ 'active': filesViewType === 'list' }">
            <i [ngClass]="{ 'active': filesViewType === 'list' }" class="icon-Grid-view active"></i>
          </button>
        </div>
      </div>

      <div *ngIf="filesViewType === 'list'"
           class="file-item-container">
        <div *ngFor="let libraryItem of currentView"
             [totalfitDownloadFile]="getSaveLink(libraryItem, 'view')"
             [entityDownloadName]="libraryItem.name"
             [entity]="libraryItem"
             [ngClass]="{ disabled: loadMap.get(libraryItem) }"
             [loadType]="isForSaveOnlyFile(libraryItem) ? 'download' : 'view'"
             eventTriggerName="dblClick"
             (startDownload)="startDownloadFile($event)"
             (dblclick)="selectFolder(libraryItem)"
             (contextmenu)="onContextMenu($event, libraryItem)"
             (downloadProgress)="onLoadProgressCheck($event)"
             class="file-item">
          <img [src]="getFilePlaceholder(libraryItem)">
          <span [totalfitTooltip]="libraryItem.name" class="text-ellipsis">{{ libraryItem.name }}</span>

          <button *ngIf="this.breadcrumbs.length > 1 && withAdminControls" mat-icon-button (click)="delete(libraryItem)" class="delete-button">
            <mat-icon>
              delete_forever
            </mat-icon>
          </button>

          <div *ngIf="loadMap.get(libraryItem) as loadItem" class="load-container">
            <div class="load-container__message">
              {{ loadItem.text }}
            </div>

            <mat-progress-bar [value]="loadItem.progress" color="primary"></mat-progress-bar>
          </div>
        </div>
      </div>

      <div *ngIf="filesViewType === 'table'"
           class="files-table table-block">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
              <span class="name-folder">
                <img [src]="getFilePlaceholder(element)">
                {{element.name}}

                <button *ngIf="this.breadcrumbs.length > 1 && withAdminControls" class="library-delete-button" mat-icon-button (click)="delete(element)">
                  <mat-icon>
                    delete_forever
                  </mat-icon>
                </button>
              </span>

              <div *ngIf="loadMap.get(element) as loadItem" class="load-container">
                <div class="load-container__message">
                  {{ loadItem.text }}
                </div>

                <mat-progress-bar [value]="loadItem.progress" color="primary"></mat-progress-bar>
              </div>
            </td>
          </ng-container>

          <!-- Bytes Column -->
          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef> Bytes </th>
            <td mat-cell *matCellDef="let element" class="bytes-cell text-ellipsis"> {{element.size | bytes:3:'B': 'MB'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="'table-header'"></tr>
          <tr mat-row
              *matRowDef="let row; columns: displayedColumns;"
              [totalfitDownloadFile]="getSaveLink(row, 'view')"
              [entityDownloadName]="row.name"
              [entity]="row"
              [ngClass]="{ disabled: loadMap.get(row) }"
              [loadType]="isForSaveOnlyFile(row) ? 'download' : 'view'"
              eventTriggerName="dblClick"
              (startDownload)="startDownloadFile($event)"
              (contextmenu)="onContextMenu($event, row)"
              (downloadProgress)="onLoadProgressCheck($event)"
              (dblclick)="selectFolder(row)">
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="audioFile" class="library__player-container">
      <div class="name">
        <div>
          {{ playName }}
        </div>

        <button (click)="closeStream()" mat-icon-button>
          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>
      <div class="library__player">
        <div *ngIf="currentTime">
          {{ currentTime }}
        </div>

        <button *ngIf="playStatus === 'play'" mat-icon-button (click)="pauseAudio()">
          <mat-icon>
            pause
          </mat-icon>
        </button>
        <button *ngIf="playStatus === 'pause'" mat-icon-button (click)="playAudio()">
          <mat-icon>
            play_arrow
          </mat-icon>
        </button>
        <div class="time-slider">
          <mat-slider [max]="audioFile?.duration"
                      [value]="audioFile?.currentTime"
                      [step]="1"
                      min="0"
                      color="primary"
                      (input)="onSliderChangeEnd($event)"
                      class="slider">
          </mat-slider>
        </div>

        <div *ngIf="audioDuration">
          {{ audioDuration }}
        </div>
      </div>
    </div>
  </div>
</totalfit-navigation-page-container>


<ng-template #breadcrumbItemsRef>
  <div class="breadcrumbItems mat-typography">
    <ul class="select-menu">
      <li [tabindex]="i"
          *ngFor="let option of compressedBreadcrumb; let i = index">
        <button (click)="setFolder(option)"
                mat-button
                [totalfitTooltip]="option.name">
          {{ option.name }}
        </button>
      </li>
    </ul>
  </div>
</ng-template>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngIf="item.entries.length || item.size === '0'">
      <button mat-menu-item (click)="selectFolder(item)">Open</button>
    </ng-container>

    <ng-container *ngIf="!item.entries.length && item.size !== '0'">
      <button mat-menu-item
              [totalfitDownloadFile]="getSaveLink(item, 'download')"
              [entityDownloadName]="item.name"
              (startDownload)="startDownloadFile($event)"
              (downloadProgress)="onLoadProgressCheck($event)"
              [entity]="item"
              loadType="download">
        Download
      </button>
      <button *ngIf="!isForSaveOnlyFile(item)"
              mat-menu-item
              [totalfitDownloadFile]="getSaveLink(item, 'view')"
              [entityDownloadName]="item.name"
              (startDownload)="startDownloadFile($event)"
              [entity]="item"
              loadType="view">
        View
      </button>
    </ng-container>
  </ng-template>
</mat-menu>


<ng-template #nameExplanation>
  <div class="drop-down-name">
    You can't create file or folder in the root.
  </div>
</ng-template>
