import {
  ChangeDetectionStrategy,
  Component, Input,
} from '@angular/core';
import { FormControlComponent, getValueAccessor } from '@shared/models/form-control.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'totalfit-date-input',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getValueAccessor(InputDateComponent)]
})

export class InputDateComponent extends FormControlComponent<MatDatepickerInputEvent<unknown>> {
  @Input() public label = 'Select date';
  @Input() public formGroupClass: string;

  constructor() {
    super();
  }
}
