import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedFormService } from '@shared/services/shared-form-service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { AmplitudeActions, AmplitudeService } from '@shared/services/amplitude.service';
import { PaymentSetUp } from '@shared/dialogs/dialogs.model';
import { RequestConfirm } from '@shared/services/stripe.service';

@Component({
  selector: 'totalfit-payment-steps',
  templateUrl: './payment-steps.component.html',
  styleUrls: ['./payment-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentStepsComponent implements OnInit {
  @Input() public step;
  @Input() public paymentSetUp: PaymentSetUp<any>;
  @Input() public chargeData: any;
  @Input() public purchaseSuccessfully: boolean;
  @Output() public purchase = new EventEmitter();
  @Output() public confirm: EventEmitter<RequestConfirm> = new EventEmitter();
  @Output() public finalClickSuccess = new EventEmitter();
  @Output() public setPreviousStep = new EventEmitter();
  @Output() public tokenReg = new EventEmitter();
  // Should be private ts issue
  public token: string = null;
  public userForm: UntypedFormGroup;
  public countryControl: UntypedFormControl;
  public confirmValue: RequestConfirm = null;

  constructor(
    public sharedFormService: SharedFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private readonly amplitudeService: AmplitudeService
  ) { }

  public ngOnInit(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      address_zip: ['', Validators.required],
      address_country: ['', Validators.required]
    });

    this.countryControl = this.fb.control('');

    this.userForm.get('name').patchValue(`${this.authService.currentUser.firstName} ${this.authService.currentUser.lastName}`);
  }

  public purchaseTrigger(): void {
    this.step = 2;

    this.purchase.emit('');
    this.amplitudeService.triggerEvent(AmplitudeActions.USER_UPGRADE_PLAN_PURCHASE);
  }

  public registerToken(token): void {
    // tslint:disable-next-line:no-console
    if (token) {
      this.token = token;
    } else {
      this.token = null;
    }
    // tslint:disable-next-line:no-console
    this.changeDetectorRef.detectChanges();
  }

  public getFilteredValue(value: string) {
    const filterValue = value.toLowerCase();
    return of(this.sharedFormService.countries.filter(
      ({ name }) => (String(name).toLowerCase().startsWith(filterValue))
    ));
  }

  public setIndexValidation({ id }: { id: string }) {
    this.userForm.get('address_country').patchValue(id);
  }

  public registerConfirmValue(confirm: RequestConfirm): void {
    this.confirmValue = confirm;

    this.changeDetectorRef.detectChanges();
  }

  public confirmTrigger(): void {
    this.confirm.emit(this.confirmValue);
    this.confirmValue = null;
  }
}
