import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[totalfitAddPlus]'
})
export class AddPlusInInputDirective {
  @Input() private totalfitAddPlus: AbstractControl;
  constructor() {
    setTimeout(() => {
      this.totalfitAddPlus.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe((value: string) => {
          if (!value.startsWith('+')) {
            this.totalfitAddPlus.patchValue(`+${value}`);
          }
        });
    });
  }
}
