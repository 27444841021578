import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { People, PeopleStatus } from '@app/people/_shared/people.model';
import { tap } from 'rxjs/operators';
import { PaginationModel } from '@shared/models/pagination.model';
import { setFilterQuery } from '@shared/utils/utils';

@Injectable()
export class PeopleService {
  public peopleList: People[] = null;

  constructor(private http: HttpClient) {}

  public static getCoachAssistantPeopleList(peopleList: People[]): People[] {
    if (!peopleList.length) {
      return [];
    }

    return peopleList.filter((people) => people.status === PeopleStatus.Coach || people.status === PeopleStatus.Assistant);
  }

  public getPeople(): Observable<PaginationModel<People>> {
    return this.http.get<PaginationModel<People>>(`${environment.apiUrl}/${environment.apiV.apiV1}/people?size=100&page=0`)
      .pipe(
        tap((peopleList) => this.peopleList = peopleList.objects)
      );
  }

  public getPeopleCriteria(
    pageSize: number,
    pageNumber: number,
    filterObject?: any
  ): Observable<PaginationModel<People>> {
    const filterQuery = filterObject ? setFilterQuery(filterObject) : null;

    return this.http
      .get<PaginationModel<People>>(`${environment.apiUrl}/${environment.apiV.apiV1}/people?size=${pageSize}&page=${pageNumber}${filterQuery ? filterQuery : ''}`);
  }

  public removePeople(id: string): Observable<unknown> {
    return this.http.delete(`${environment.apiUrl}/${environment.apiV.apiV1}/people/${id}`);
  }

  public editPeople(data: People, id: string): Observable<number> {
    return this.http.put<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/people/${id}`, data);
  }

  public savePeople(data: People): Observable<any> {
    return this.http.post<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/people`, data);
  }

  public getPeopleById(id: string): Observable<People> {
    return this.http.get<People>(`${ environment.apiUrl }/${environment.apiV.apiV1}/people/${id}`);
  }

  public exportPeople(peopleArray: People[]): any {
    return this.http.post(`${environment.apiUrl}/${environment.apiV.apiV1}/people/export`, peopleArray, { responseType: 'blob'});
  }

  public getByPeopleFirstNameStartingWith(prefix: string): Observable<People[]> {
    return this.http.get<People[]>(
      `${environment.apiUrl}/${environment.apiV.apiV1}/people/by-people-firstname-starts-with`,
      {
        params: {
          prefix
        }
      }
    );
  }
}
