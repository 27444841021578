import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { City, Country, Location, LocationEditModel } from '@app/location/_shared/location.model';
import { environment } from '@environments/environment';
import { tap } from 'rxjs/operators';
import { PaginationModel } from '@shared/models/pagination.model';
import { Dictionary } from '@shared/models/dictionary.model';
import { AmplitudeActions, AmplitudeService } from '@shared/services/amplitude.service';
import {
  InviteLink,
  LinkFileDuration, LocationPushPayload,
  MainActiveMember,
  MainCompletedWorkout, MainCompletedWorkoutDetails,
  MainLocation, MainLocationEvent, MainReaction
} from '@store/location/location.model';
import { Filter } from '@store/app-ui-state/app-ui-state.model';

@Injectable()
export class LocationListService {
  public selectedLocation$ = new BehaviorSubject<LocationEditModel>(null);
  public locations: PaginationModel<Location> = null;
  public countryList: Dictionary<string> = [];
  public cityList: Dictionary<string> = [];
  public selectedLocation: Location;

  constructor(
    private http: HttpClient,
    private readonly amplitudeService: AmplitudeService
  ) {}

  public getLocationList(): Observable<PaginationModel<Location>> {
    return this.http.get<PaginationModel<Location>>(`${ environment.apiUrl }/${environment.apiV.apiV1}/gyms?size=${100}`)
      .pipe(tap((data) => this.locations = data));
  }

  public createMainLocation(name: string): Observable<MainLocation> {
    return this.http.post<MainLocation>(`${ environment.apiUrl }/${environment.apiV.apiV1}/admin/locations`, { name });
  }

  public getLocations(): Observable<PaginationModel<Location> | Location[]> {
    return this.locations
      ? of(this.locations)
      : this.getLocationList()
        .pipe((tap(() => {
          return this.locations;
        })));
  }

  public getLocationFromLocationList(id: string): Location {
    return this.locations.objects.find((location) => location.id === id);
  }

  public editLocation(data: LocationEditModel, id: string): Observable<Location> {
    const dataWithOutSchedule = data;
    delete dataWithOutSchedule.schedule;

    const isForProfit = data.costModel.profitModel === 'FOR_PROFIT';
    this.amplitudeService.triggerEvent(AmplitudeActions.LOCATIONS_EDIT_LOCATION, {
      location_one_time_price: isForProfit ? data.costModel.pricing.oneTime : 'no-set',
      location_one_month_price: isForProfit ? data.costModel.pricing.oneMonth : 'no-set',
      location_six_months_price: isForProfit ? data.costModel.pricing.sixMonths : 'no-set',
      location_currency: isForProfit ? data.costModel.currencyCode : 'no-set',
      location_photo: data.image ? data.image : 'default'
    });

    return this.http.put<Location>(`${ environment.apiUrl }/${environment.apiV.apiV1}/gyms/${id}`, dataWithOutSchedule);
  }

  public saveLocation(data: LocationEditModel): Observable<any> {
    const dataWithOutSchedule = data;
    delete dataWithOutSchedule.schedule;

    const isForProfit = data.costModel.profitModel === 'FOR_PROFIT';
    this.amplitudeService.triggerEvent(AmplitudeActions.LOCATIONS_SAVE_LOCATION, {
      location_one_time_price: isForProfit ? data.costModel.pricing.oneTime : 'no-set',
      location_one_month_price: isForProfit ? data.costModel.pricing.oneMonth : 'no-set',
      location_six_months_price: isForProfit ? data.costModel.pricing.sixMonths : 'no-set',
      location_currency: isForProfit ? data.costModel.currencyCode : 'no-set',
      location_photo: data.image ? data.image : 'default'
    });

    return this.http.post<Location>(`${ environment.apiUrl }/${environment.apiV.apiV1}/gyms`, dataWithOutSchedule);
  }

  public publicLocation(locationId: string): Observable<any> {
    return this.http.get(`${ environment.apiUrl }/${environment.apiV.apiV1}/gyms/public/${locationId}`);
  }

  public saveDraftLocation(location: Location): Observable<any> {
    return this.http.put(`${ environment.apiUrl }/${environment.apiV.apiV1}/gyms/temporal/${location.id}`, location);
  }

  public removeLocation(id: string): Observable<unknown> {
    return this.http.delete(`${ environment.apiUrl }/${environment.apiV.apiV1}/gyms/${id}`);
  }

  public getLocation(id: string): Observable<Location> {
    return this.http.get<Location>(`${environment.apiUrl}/${environment.apiV.apiV1}/gyms/${id}`);
  }

  public sendNotification(notification: LocationPushPayload): Observable<MainCompletedWorkoutDetails> {
    return this.http.put<MainCompletedWorkoutDetails>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/schedule-location-events/pushes`, notification);
  }

  public getMainLocation(id: string, from: string, to: string): Observable<MainLocation> {
    return this.http.get<MainLocation>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}`, {
      params: {
        from,
        to
      }
    });
  }

  public getMainLocationEvents(locationId: string, from: string, to: string): Observable<Record<string, MainLocationEvent[]>> {
    return this.http
      .get<Record<string, MainLocationEvent[]>>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/schedule-location-events/period/${locationId}`, {
      params: {
        from,
        to
      }
    });
  }

  public createLocationEvent(event: MainLocationEvent): Observable<MainLocationEvent> {
    return this.http.post<MainLocationEvent>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/schedule-location-events`, event);
  }

  public editLocationEvent(event: MainLocationEvent): Observable<MainLocationEvent> {
    return this.http
      .put<MainLocationEvent>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/schedule-location-events/${event.id}`, event);
  }

  public editMainLocation(location: MainLocation): Observable<MainLocation> {
    return this.http
      .put<MainLocation>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations`,
        {...location, onlineWorkouts: [1, 2, 5, 7, 364, 369, 371, 373]}
        );
  }

  public deleteTribeMember(tribe: MainActiveMember, locationId: string): Observable<unknown> {
    return this.http
      .delete<unknown>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${locationId}/location-members/${tribe.user.id}`);
  }

  public deleteLocationEvent(event: MainLocationEvent): Observable<unknown> {
    return this.http
      .delete<unknown>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/schedule-location-events/${event.id}`);
  }

  public deleteMainLocation(locationId: number): Observable<unknown> {
    return this.http
      .delete<unknown>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${locationId}`);
  }

  public getMainLocationList(): Observable<MainLocation[]> {
    return this.http.get<MainLocation[]>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/list`);
  }

  public getTribeMembers(size: number, page: number, id: string, filter: Filter): Observable<PaginationModel<MainActiveMember>> {
    return this.http.get<PaginationModel<MainActiveMember>>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}/location-members?page=${page}&size=${size}${getSearchParams(filter).join('')}`, {
    });
  }

  public getInviteLinks(size: number, page: number, id: string): Observable<PaginationModel<InviteLink>> {
    return this.http
      .get<PaginationModel<InviteLink>>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}/invite-links?page=${page}&size=${size}`, {
    });
  }

  public deleteInviteLinks(link: InviteLink, locationId: string): Observable<number> {
    return this.http
      .delete<number>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${locationId}/invite-links/${link.id}`);
  }

  public getCompletedWorkouts(
    size: number,
    page: number,
    id: string,
    filter: Filter
  ): Observable<PaginationModel<MainCompletedWorkout>> {
    return this.http
      .get<PaginationModel<MainCompletedWorkout>>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}/completed-workouts?page=${page}&size=${size}${getSearchParams(filter).join('')}`);
  }

  public getCompletedWorkoutDetails(
    id: string,
    eventId: string
  ): Observable<MainCompletedWorkoutDetails> {
    return this.http
      .get<MainCompletedWorkoutDetails>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}/completed-workouts/${eventId}/details`);
  }

  public getScheduleLocationEvents(
    eventId: string
  ): Observable<MainCompletedWorkoutDetails> {
    return this.http
      .get<MainCompletedWorkoutDetails>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/admin/schedule-location-events/${eventId}`);
  }

  public getWaitingForReaction(
    id: string
  ): Observable<PaginationModel<MainReaction>> {
    return this.http
      .get<PaginationModel<MainReaction>>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}/waiting-reactions`);
  }

  public updateUserLogResult(
    logResult: MainReaction
  ): Observable<unknown> {
    return this.http
      .put<unknown>(
        `${environment.apiUrl}/${environment.apiV.apiV1}/admin/user-log-results/${logResult.id}`, logResult);
  }

  public createInviteLink(id: string, linkLife: LinkFileDuration): Observable<InviteLink> {
    return this.http
      .post<InviteLink>(`${environment.apiUrl}/${environment.apiV.apiV1}/admin/locations/${id}/invite-links`, {
        id,
        linkLife
      });
  }

  public getCountries(prefix: string, code: string): Observable<Country[]> {
    return this.http
      .get<Country[]>(`${environment.apiUrl}/${environment.apiV.apiV1}/places/countries?prefix=${prefix}&languageCode=${code}`)
      .pipe(tap((data) => this.countryList = data.map((country) => ({ name: country.name, id: country.code }))));
  }

  public getCityList(prefix: string, countryCode: string, langCode: string): Observable<City[]> {
    return this.http.get<City[]>(`${environment.apiUrl}/${environment.apiV.apiV1}/places/cities?countryCode=${countryCode}&prefix=${prefix}&languageCode=${langCode}`)
      .pipe(tap((data) => this.cityList = data.map((country) => ({ name: country.name, id: country.name.toLocaleUpperCase() }))));
  }
}

function getSearchParams(filter): string[] {
  if (filter) {
    return Object.keys(filter.value).map((key) => {
      return `&sort=${key},${filter.value[key].join('').toLowerCase()}`;
    });
  }

  return  [];
}
